import { ExportColumn } from '@app/src/types/export'
import { getCurrentYear } from '../utils/helpers'
import { insertIf } from '../utils/helpersTs'
import { Solutions } from '../wf-constants'

const COMPANY_TRANSLATION_ID = 'export.schemas.provider.company'

export const getProviderExportColumns = (solution?: string, basePath?: string): ExportColumn[] => [
  {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.id` : 'general.id',
    exportPath: basePath ? `${basePath}.id` : 'id',
  },
  {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.name` : 'schemas.supplier.name',
    exportPath: basePath ? `${basePath}.name` : 'name',
  },
  {
    translationId: 'export.schemas.referralContact.referralContactName',
    exportPath: basePath ? `${basePath}.referralContact.name` : 'referralContact.name',
  },
  {
    translationId: 'export.schemas.referralContact.referralContactEmail',
    exportPath: basePath ? `${basePath}.referralContact.email` : 'referralContact.email',
  },
  {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.ownerUser` : 'schemas.supplier.ownerUser',
    exportPath: basePath ? `${basePath}.ownerUser.name` : 'ownerUser.name',
  },
  {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.country` : 'schemas.supplier.country',
    exportPath: basePath ? `${basePath}.country.name` : 'country.name',
  },
  {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.websiteAddress` : 'schemas.supplier.websiteAddress',
    exportPath: basePath ? `${basePath}.websiteAddress` : 'websiteAddress',
  },
  {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.linkStatus` : 'schemas.supplier.linkStatus',
    exportPath: basePath ? `${basePath}.linkStatus` : 'linkStatus',
  },
  {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.vatNumber` : 'schemas.supplier.vatNumber',
    exportPath: basePath ? `${basePath}.vatNumber` : 'vatNumber',
  },
  {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.registrationNumber` : 'schemas.supplier.registrationNumber',
    exportPath: basePath ? `${basePath}.registrationNumber` : 'registrationNumber',
  },
  {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.createdAt` : 'schemas.supplier.createdAt',
    exportPath: basePath ? `${basePath}.createdAt` : 'createdAt',
  },
  {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.duns` : 'schemas.organization.duns',
    exportPath: basePath ? `${basePath}.duns` : 'duns',
  },
  {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.customId` : 'schemas.supplier.customId',
    exportPath: basePath ? `${basePath}.customId` : 'customId',
  },
  ...insertIf(solution !== Solutions.Finance, {
    translationId: basePath
      ? `${COMPANY_TRANSLATION_ID}.providerApprovalStatus`
      : 'schemas.supplier.providerApprovalStatus',
    exportPath: basePath ? `${basePath}.providerApprovalStatusValue` : 'providerApprovalStatusValue',
  }),
  ...insertIf(solution !== Solutions.Finance, {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.activityStatus` : 'schemas.provider.activityStatus',
    exportPath: basePath ? `${basePath}.providerActivityStatusValue` : 'providerActivityStatusValue',
  }),
  {
    translationId: basePath
      ? `${COMPANY_TRANSLATION_ID}.finalRiskRating`
      : 'export.schemas.provider.company.finalRiskRating',
    exportPath: basePath ? `${basePath}.finalRiskRatingValue` : 'finalRiskRatingValue',
  },
  {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.priority` : 'schemas.provider.priority',
    exportPath: basePath ? `${basePath}.providerPriorityValue` : 'providerPriorityValue',
  },
  ...insertIf(solution !== Solutions.Finance, {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.supplierUsage` : 'schemas.provider.supplierUsage',
    exportPath: basePath ? `${basePath}.supplierUsageValue` : 'supplierUsageValue',
  }),
  ...insertIf(solution !== Solutions.Finance, {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.tier` : 'schemas.provider.tier',
    exportPath: basePath ? `${basePath}.tier` : 'tier',
  }),
  {
    translationId: basePath ? `${COMPANY_TRANSLATION_ID}.customCategories` : 'schemas.supplier.customCategories',
    exportPath: basePath ? `${basePath}.categoryOptionsNames` : 'categoryOptionsNames',
  },
  {
    translationId: 'export.schemas.provider.publicContact.publicContactName',
    exportPath: 'publiccontactname',
  },
  {
    translationId: 'export.schemas.provider.publicContact.publicContactEmail',
    exportPath: 'publiccontactemail',
  },
  {
    translationId: 'export.schemas.provider.spend',
    displayName: 'Spend ' + getCurrentYear(),
    exportPath: 'spend',
  },
]
