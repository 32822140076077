import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchCount } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewInvitationForm from '@app/src/components/Drawer/Views/DrawerViewInvitationForm'
import FailedToFetchDataEmptyState from '@app/src/components/EmptyState/FailedToFetchDataEmptyState'
import LinkButton from '@app/src/components/LinkButton'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import useLocalStorage from '@app/src/hooks/localStorage'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { ProductMappingRequest } from '@app/src/types/product'
import { InquiriesByTemplate, Inquiry, InquiryStatus, MappingRequestStatus } from '@app/src/types/resourceExplorer'
import { isPassedDeadline } from '@app/src/utils'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { PersonAdd, TrendingUp } from '@mui/icons-material'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import OpenRequestsCard from './OpenRequestsCard'
import OverdueRequestsCard from './OverdueRequestsCard'
import PendingRequests from './PendingRequests'
import RecommendationsSection from './RecommendationsSection'
import ReportingProgressCard from './ReportingProgressCard'
import RequestKpiCard from './RequestKpiCard'
import ViewAIScreenedDataSuggestionDialog from './ViewAIScreenedDataSuggestionDialog'

interface ModalStateObject {
  [key: string]: boolean
}

const modalStateKey = 'aiScreenedDataSuggestionDialogStates'

const TransparencyDashboard = () => {
  const { hasPermission } = usePermissions()
  const { formatMessage } = useIntl()
  const { trackEvent, account } = useAmplitude()
  const { openDrawer } = useDrawer()

  const currentOrganizationId = account?.organization?.id ?? ''

  const { count: responsesCount = 0 } = useFetchCount({
    key: FetchKey.Response,
    endpoint: endpoints.responsesCount,
    payload: [{ name: 'isScraped', filters: [{ operator: Operators.EqualTo, value: true }] }],
    shouldShowErrorNotification: false,
  })

  const [modalStates, setModalStates] = useLocalStorage<ModalStateObject>(modalStateKey, {})

  const aiScreenedDataSuggestionDialogShown = modalStates[currentOrganizationId?.toString()] !== false

  const shouldShowAIScreenedDataSuggestionDialogOpen = useMemo(
    () => aiScreenedDataSuggestionDialogShown && responsesCount > 0,
    [aiScreenedDataSuggestionDialogShown, responsesCount],
  )

  const [
    isAIScreenedDataSuggestionDialogOpen,
    openAIScreenedDataSuggestionDialog,
    closeAIScreenedDataSuggestionDialog,
  ] = useDialogState(false)

  useEffect(() => {
    if (!(currentOrganizationId in modalStates)) {
      setModalStates(prevStates => ({
        ...prevStates,
        [currentOrganizationId]: true,
      }))
    }
  }, [currentOrganizationId, modalStates, setModalStates])

  const handleCloseDialog = () => {
    setModalStates(prevStates => ({
      ...prevStates,
      [currentOrganizationId]: false,
    }))
    closeAIScreenedDataSuggestionDialog()
  }

  const {
    items: inquiriesByTemplate,
    isLoading,
    isError: inquiriesByTemplateIsError,
  } = useFetchCollectionWithPost<InquiriesByTemplate>({
    key: FetchKey.OpenRequests,
    endpoint: endpoints.inquiriesGroupedByTemplate,
    shouldShowErrorNotification: false,
    payload: {
      filter: [
        {
          name: 'status',
          filters: [
            {
              value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
              operator: Operators.In,
            },
          ],
        },
      ],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 99999,
      },
      sort: {
        target: 'deadline',
        order: SortOrder.ASCENDING,
      },
      include: ['creatorOrganization', 'template', 'template.image', 'provider.organization'],
    },
  })

  const {
    items: pendingInquires,
    isLoading: pendingInquiresIsLoading,
    isError: pendingInquiriesIsError,
  } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.InquiryOpenRequests,
    endpoint: endpoints.inquiriesCollection,
    shouldShowErrorNotification: false,
    payload: {
      filter: [
        {
          name: 'status',
          filters: [
            {
              value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
              operator: Operators.In,
            },
          ],
        },
      ],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 99999,
      },
      include: [],
    },
  })

  const overdueInquiriesCount = pendingInquires.filter(inquiry => isPassedDeadline(inquiry.deadline)).length

  const {
    items: pendingMappingRequests,
    isLoading: pendingProductMappingRequestsIsLoading,
    isError: pendingMappingRequestsIsError,
  } = useFetchCollectionWithPost<ProductMappingRequest>({
    key: FetchKey.PendingMappingRequests,
    endpoint: endpoints.mappingRequestCollection,
    shouldShowErrorNotification: false,
    payload: {
      filter: [
        {
          name: 'status',
          filters: [
            {
              value: [MappingRequestStatus.Requested, MappingRequestStatus.InProgress],
              operator: Operators.In,
            },
          ],
        },
        {
          name: 'responderOrganizationId',
          filters: [{ value: account?.organization?.id, operator: Operators.EqualTo }],
        },
      ],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 99999,
      },
      include: ['product', 'creatorOrganization', 'creatorOrganization.image'],
    },
    options: {
      enabled: hasPermission(Permissions.PRODUCT_MAPPING_RESPOND_ACCESS),
    },
  })

  const overdueProductMappingRequestsCount = pendingMappingRequests.filter(request =>
    isPassedDeadline(request.deadline),
  ).length

  const totalInquiryCount = inquiriesByTemplate.reduce((acc, curr) => acc + curr.inquiryInfos.length, 0)

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Dashboard.Overview.ViewedPage,
    })
  }, [])

  useEffect(() => {
    if (shouldShowAIScreenedDataSuggestionDialogOpen) {
      openAIScreenedDataSuggestionDialog()
    }
  }, [shouldShowAIScreenedDataSuggestionDialogOpen, openAIScreenedDataSuggestionDialog])

  return (
    <Stack spacing={2} my={1} height="100%">
      <Stack>
        <Helmet>
          <title>{formatMessage({ id: 'navbar.dashboard' })}</title>
        </Helmet>
        <Box px={4} py={2}>
          <Box display="flex" justifyContent="space-between">
            <Stack>
              <Typography variant="h1">{formatMessage({ id: 'navbar.dashboard' })}</Typography>
              <Typography variant="body1">
                {formatMessage({ id: 'solutionSelector.headerName' }, { name: account?.user?.givenName })}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <LinkButton startIcon={<TrendingUp />} to={paths.transparencyProviderProgress}>
                <Typography variant="button">{formatMessage({ id: 'dashboard.yourProgress' })}</Typography>
              </LinkButton>
              <Button variant="text" startIcon={<PersonAdd />} onClick={() => openDrawer(<DrawerViewInvitationForm />)}>
                {formatMessage({ id: 'dashboard.investment.inviteColleagues' })}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Stack>
      <Stack px={4}>
        {hasPermission(Permissions.PRODUCT_MAPPING_RESPOND_ACCESS) ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <RequestKpiCard
                title={formatMessage({ id: 'resourceTypes.productMappingRequest' })}
                helperText={formatMessage({ id: 'transparency.productMappingRequestsHelperText' })}
                totalCount={pendingMappingRequests.length}
                overdueCount={overdueProductMappingRequestsCount}
                isLoading={pendingProductMappingRequestsIsLoading}
                actionLink={generatePath(paths.mappingRequestCollection)}
                isError={pendingMappingRequestsIsError}
              />
            </Grid>
            <Grid item xs={6}>
              <RequestKpiCard
                title={formatMessage({ id: 'resourceTypes.questionnaireRequest' })}
                helperText={formatMessage({ id: 'transparency.questionnaireRequestsHelperText' })}
                totalCount={pendingInquires.length}
                overdueCount={overdueInquiriesCount}
                isLoading={pendingInquiresIsLoading}
                actionLink={generatePath(paths.manageRequest, { view: ViewTypeName.Requests })}
                isError={pendingInquiriesIsError}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <OverdueRequestsCard />
            </Grid>
            <Grid item xs={3}>
              <OpenRequestsCard
                pendingRequestsCount={totalInquiryCount}
                isLoading={isLoading}
                isError={inquiriesByTemplateIsError}
              />
            </Grid>
            <Grid item xs={6}>
              <ReportingProgressCard />
            </Grid>
          </Grid>
        )}
      </Stack>
      {inquiriesByTemplateIsError || pendingMappingRequestsIsError ? (
        <FailedToFetchDataEmptyState />
      ) : (
        <PendingRequests
          inquiriesByTemplate={inquiriesByTemplate}
          productMappingRequests={pendingMappingRequests}
          isLoading={isLoading}
        />
      )}
      <RecommendationsSection />
      <ViewAIScreenedDataSuggestionDialog open={isAIScreenedDataSuggestionDialogOpen} onClose={handleCloseDialog} />
    </Stack>
  )
}

export default TransparencyDashboard
