import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionSummary, Typography, alpha } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { PropsWithChildren } from 'react'

interface Props {
  label: string
  defaultCollapsed?: boolean
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  accordionRoot: {
    borderRadius: '0px',
    borderBottom: `solid 1px ${alpha(palette?.primary?.main, 0.15)}`,
    paddingRight: spacing(0.5),
    '&:before': { opacity: 0 },
  },
  expandedFilterSection: {
    margin: '0 !important',
  },
  filterSectionLabel: {
    padding: 0,
  },
  filterSectionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&>*': {
      marginBottom: spacing(1.5),
    },
  },
}))

const FilterSection: React.FC<PropsWithChildren<Props>> = ({ label, children, defaultCollapsed = false }) => {
  const classes = useStyles()
  const id = btoa(encodeURIComponent(label))

  return (
    <Accordion
      defaultExpanded={!defaultCollapsed}
      square
      elevation={0}
      classes={{ expanded: classes.expandedFilterSection, root: classes.accordionRoot }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`filters-${id}-content`}
        id={`filters-${id}-header`}
        className={classes.filterSectionLabel}
      >
        <Typography variant="subtitle1">{label}</Typography>
      </AccordionSummary>
      <div className={classes.filterSectionContainer}>{children}</div>
    </Accordion>
  )
}

export default FilterSection
