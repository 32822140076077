import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import { useDrawer_DEPRECATED } from '@app/src/components/Drawer_DEPRECATED'
import HoverDialog from '@app/src/components/HoverDialog'
import UpdateProviderCategoryDrawer from '@app/src/components/ManageProviderDrawer/CategoryDrawer/UpdateProviderCategoryDrawer'
import UpdateProvidersDrawer from '@app/src/components/ManageProviderDrawer/UpdateProvidersDrawer'
import Table from '@app/src/components/Table'
import LevelCell from '@app/src/components/Table/Cells/LevelCell'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { BaseAssessmentsExportColumnsAccessor } from '@app/src/export-columns/baseAssessments'
import useCurrentProviderTypeName from '@app/src/hooks/currentProviderTypeName'
import usePagination from '@app/src/hooks/pagination'
import useQueryFilters from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import useAssessmentQuestionnaireLink from '@app/src/hooks/useAssessmentQuestionnaireLink'
import { AssessmentViews } from '@app/src/pages/Assessments/AssessmentsScene'
import useBaseAssessmentOverviewGraph, {
  AssessmentOverViewGraphDatapoint,
} from '@app/src/pages/ResourceCollection/Collections/BaseAssessment/useBaseAssessmentOverviewGraph'
import BaseAssessmentFilters from '@app/src/pages/ResourceCollection/Filters/BaseAssessmentFilters'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene, { FacetItem } from '@app/src/pages/ResourceCollection/index'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import Assessment from '@app/src/types/assessment'
import { ProviderStandardCategoryNames } from '@app/src/types/categories'
import { Provider } from '@app/src/types/organizations'
import { AssessmentType, InquiryStatus } from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'
import { AmplitudeTrackingEvents, Assessments, ResourceTypes } from '@app/src/wf-constants'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import ReactEcharts from 'echarts-for-react'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import AssessmentHeader from './AssessmentHeader'
import AssessmentRow from './AssessmentRow'
import ResponseRateSummary from './ResponseRateSummary'

type AssessmentOverviewSceneProps = {
  allowedFilters: string[]
  userFilters: FilterGroup[]
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
}

const AssessmentOverviewScene: React.FC<AssessmentOverviewSceneProps> = ({
  allowedFilters,
  userFilters,
  tabs,
  actionButtons = [],
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [isUpdateDrawerOpen, openUpdateDrawer, closeUpdateDrawer] = useDrawer_DEPRECATED(false)
  const [isUpdatePriorityDrawerOpen, openUpdatePriorityDrawer, closeUpdatePriorityDrawer] = useDrawer_DEPRECATED(false)
  const [page, pageSize, setPage, setPageSize] = usePagination({ page: 1, pageSize: 10 })
  const [selectedProviders, setSelectedProviders] = useState<Array<number>>([])
  const { clearQueryFilter, setQueryFilter, filters } = useQueryFilters(allowedFilters)

  const levelFilter: FilterGroup[] = filters['totalLevel']
    ? [
        {
          name: 'totalLevel',
          filters: [filters['totalLevel']].flat().map(f => ({
            value: f.value,
            operator: f.operator,
            uniqueId: f.uniqueId,
          })),
        },
      ]
    : []

  const periodFilterValue = useMemo(
    () =>
      userFilters
        .find(group => group.name === 'periodName')
        ?.filters.find(filter => filter.value)
        ?.value?.toString(),
    [userFilters],
  )

  const { trackEvent } = useAmplitude()
  const { openDrawer } = useDrawer()

  const graphFacetsFilters = useMemo(
    () => [
      ...userFilters,
      {
        name: 'assessmentTemplate.name',
        filters: [
          {
            operator: Operators.EqualTo,
            value: Assessments.BaselineAssessment,
          },
        ],
      },
      ...levelFilter,
    ],
    [userFilters, levelFilter],
  )

  const {
    facets: [graphFacets = []],
  } = useFetchFacets({
    key: FetchKey.AssessmentFacets,
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'totalLevel', isEnum: true }],
    filter: graphFacetsFilters,
  })

  const { assessmentQuestionnaireLink, isLoadingAssessmentQuestionnaireLink } = useAssessmentQuestionnaireLink({
    assessmentType: AssessmentType.BaselineAssessment,
  })

  const {
    facets: [inquiryStatusFacets],
    count: totalInquriesSent,
  } = useFetchFacets({
    key: FetchKey.InquiryStatus,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'status', isEnum: true }],
    filter: [
      {
        name: 'questionnaireTemplateId',
        filters: [
          {
            value: assessmentQuestionnaireLink?.questionnaireTemplateId,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'periodName',
        filters: [
          {
            value: periodFilterValue,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'provider.deletedAt',
        filters: [
          {
            operator: Operators.IsNull,
          },
        ],
      },
    ],
    options: { enabled: Boolean(periodFilterValue) && Boolean(assessmentQuestionnaireLink?.questionnaireTemplateId) },
  })

  const submittedInquiryCount = inquiryStatusFacets
    ?.filter(status => status.label !== InquiryStatus.Requested)
    ?.reduce((total, current) => total + (current.count ?? 0), 0)

  const levels = useMemo<Record<number, FacetItem | undefined>>(
    () => ({
      1: graphFacets.find(l => l.value === 1),
      2: graphFacets.find(l => l.value === 2),
      3: graphFacets.find(l => l.value === 3),
      4: graphFacets.find(l => l.value === 4),
      5: graphFacets.find(l => l.value === 5),
    }),
    [graphFacets],
  )

  const options = useBaseAssessmentOverviewGraph(levels)
  const { hasPermission } = usePermissions()
  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)
  const [highlightedLevel, setHighlightedLevel] = React.useState<AssessmentOverViewGraphDatapoint>()
  const [offset, setOffset] = React.useState<number>(0)

  const basePayload = {
    filter: [
      {
        name: 'assessmentTemplateId',
        filters: [{ value: assessmentQuestionnaireLink?.assessmentTemplateId, operator: Operators.EqualTo }],
      },
    ],
    sort: sorting,
    include: ['provider.categoryOptions', 'provider.country.risks.riskType', 'provider.organization.contacts.user'],
    pagination: {
      itemsPerPage: pageSize,
      pageNumber: page,
    },
  }

  const filterPayload = useMemo(
    () => ({
      ...basePayload,
      include: [...basePayload.include],
      filter: [...basePayload.filter, ...levelFilter],
    }),
    [basePayload, levelFilter],
  )

  const payload = useMemo(
    () => ({
      ...basePayload,
      include: [...basePayload.include],
      filter: [...basePayload.filter, ...userFilters, ...levelFilter],
    }),
    [basePayload, userFilters, levelFilter],
  )

  const events = useMemo(
    () => ({
      mouseover: ({
        data,
        event,
      }: {
        data: AssessmentOverViewGraphDatapoint
        event: { target: { shape: { x: number } } }
      }) => {
        setOffset(event.target.shape.x)
        setHighlightedLevel(data)
      },
      click: ({
        data,
        event,
      }: {
        data: AssessmentOverViewGraphDatapoint
        event: { target: { shape: { x: number } } }
      }) => {
        if (!data || !event) return

        const level = data.name.match(/\d+/)
        if (!level) return

        const currentFilter = [filters['totalLevel']].flat()[0]
        const isAlreadyFiltered = currentFilter?.value[0] === String(level[0])

        if (isAlreadyFiltered) {
          setHighlightedLevel(undefined)
          clearQueryFilter()
        } else {
          setQueryFilter('totalLevel', [level[0]], Operators.In)
          setPage(1)
        }
      },
    }),
    [],
  )

  const {
    items: assessments,
    count,
    isLoading,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<Assessment>({
    key: FetchKey.AssessmentCollection,
    endpoint: endpoints.assessmentCollection,
    payload,
    options: { enabled: canAccessAssessmentTemplate && Boolean(assessmentQuestionnaireLink?.assessmentTemplateId) },
  })

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, provider: Provider) => {
    if (e.target.checked) {
      setSelectedProviders(prev => [...prev, provider.id])
    } else {
      setSelectedProviders(prev => prev.filter(providerId => providerId !== provider.id))
    }
  }

  const providerTypeName = useCurrentProviderTypeName({ isPlural: true, shouldCapitalize: true })

  const totalSuppliersThatResponded = useMemo(
    () => Object.values(levels)?.reduce((acc, level) => acc + (level?.count ?? 0), 0) ?? 0,
    [levels],
  )

  return (
    <ResourceCollectionScene
      enableScroll
      title={formatMessage({ id: 'navbar.baseAssessment' })}
      filter={<BaseAssessmentFilters allowedFilters={allowedFilters} />}
      tabs={tabs}
      actionButtons={[
        ...(actionButtons ?? []),
        {
          label: formatMessage({ id: 'resourceCollections.general.export' }),
          variant: 'outlined',
          onClick: () => {
            trackEvent({
              name: AmplitudeTrackingEvents.Analyze.Assessment.ExportedExcel,
            })

            openDrawer(
              <DrawerViewExport
                resourceType={ResourceTypes.Assessment}
                resourceView={AssessmentViews.Overview}
                count={count}
                userFilter={userFilters}
                exportColumns={BaseAssessmentsExportColumnsAccessor}
                rawExportPayload={filterPayload}
                disableExportSettings
              />,
            )
          },
          disabled: count === 0,
        },
      ]}
      {...props}
    >
      {totalInquriesSent > 0 && (
        <Box pr={4} mt={3} mb={3}>
          <ResponseRateSummary
            providersRespondedCount={submittedInquiryCount}
            providersWithInquiriesCount={totalInquriesSent}
            questionnaireTemplateIds={
              Array.isArray(assessmentQuestionnaireLink?.questionnaireTemplateId)
                ? assessmentQuestionnaireLink?.questionnaireTemplateId
                : [assessmentQuestionnaireLink?.questionnaireTemplateId]
            }
            period={periodFilterValue}
            isLoading={isLoadingAssessmentQuestionnaireLink}
          />
        </Box>
      )}
      <Stack spacing={1} pr={4}>
        <Paper elevation={0}>
          <Stack spacing={2} p={4} pb={5}>
            <Typography variant="overline" color="textSecondary">
              {formatMessage({ id: 'baseAssessment.baselineAssessmentOverview' })}
            </Typography>
            <HoverDialog
              placement="bottom-start"
              positionOffset={[offset, 0]}
              width={300}
              content={
                highlightedLevel && (
                  <Stack spacing={1} alignItems="start">
                    <Typography color="primary" variant="subtitle2">
                      {highlightedLevel.name}
                    </Typography>
                    <Stack direction="row" spacing={4} alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">
                        {formatMessage(
                          { id: 'baseAssessment.percentOfAllSuppliers' },
                          {
                            count: highlightedLevel.value,
                            value: Math.round((highlightedLevel.value / totalSuppliersThatResponded) * 100),
                          },
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                )
              }
            >
              <Box width="100%" py={1}>
                <ReactEcharts option={options} style={{ height: 56 }} onEvents={events} />
              </Box>
            </HoverDialog>

            <Stack direction="row" justifyContent="space-between" mt={2}>
              {Array.from({ length: 5 }).map((_, i) => (
                <Stack key={i}>
                  <LevelCell value={i + 1} disabled={!levels[i + 1]?.count} disableCell />

                  <Typography
                    variant="kpi"
                    mt={2}
                    color={!levels[i + 1]?.count ? 'text.disabled' : 'textPrimary'}
                    gutterBottom
                  >
                    {levels[i + 1]?.count ?? 0}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {formatMessage(
                      { id: `baseAssessment.levelsDescription.level${i + 1}` },
                      {
                        providerType: providerTypeName,
                        br: br,
                      },
                    )}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Paper>
        <Paper elevation={0}>
          {Boolean(selectedProviders?.length) && (
            <Box display="flex" alignItems="center" mt={2} px={3} py={2} gap={1}>
              <Typography variant="body2" color="textSecondary">
                {formatMessage({ id: 'baseAssessment.companiesCount' }, { count: selectedProviders.length })}
              </Typography>
              <Button variant="outlined" onClick={openUpdateDrawer} disabled={isLoading || isFetching} size="small">
                {formatMessage({ id: 'baseAssessment.categorize' }, { count: selectedProviders.length })}
              </Button>
              <Button
                variant="outlined"
                onClick={openUpdatePriorityDrawer}
                disabled={isLoading || isFetching}
                size="small"
              >
                {formatMessage({ id: 'resourceCollections.general.priority' }, { count: selectedProviders.length })}
              </Button>
            </Box>
          )}
          <Table<Assessment>
            RowComponent={({ row }: { row: Assessment }): JSX.Element => (
              <AssessmentRow row={row} onCheckboxChange={handleCheckboxChange} selectedProviders={selectedProviders} />
            )}
            HeaderComponent={() => (
              <AssessmentHeader
                toggleSorting={toggleSorting}
                activeSorting={sorting}
                selectedProviders={selectedProviders}
                setSelectedProviders={setSelectedProviders}
                providersInPage={assessments.flatMap(assessment => assessment.provider)}
              />
            )}
            data={assessments}
            count={count}
            isLoading={isLoading || isFetching}
            isError={isError}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            stickyColumnIndex={2}
          />
        </Paper>
      </Stack>
      <UpdateProvidersDrawer
        selectedProviders={assessments
          .flatMap(assessment => assessment.provider)
          .filter(provider => selectedProviders.includes(provider.id))
          .map(provider => ({
            ...provider,
            providerId: provider.id,
            categoryOptionIds: provider.categoryOptions?.map(catOpt => catOpt.id),
          }))}
        setSelectedProviders={setSelectedProviders}
        isDrawerOpen={isUpdateDrawerOpen}
        closeDrawer={closeUpdateDrawer}
      />
      <UpdateProviderCategoryDrawer
        categoryName={{ name: ProviderStandardCategoryNames.Priority }}
        selectedProviders={assessments
          .flatMap(assessment => assessment.provider)
          .filter(provider => selectedProviders.includes(provider.id))
          .map(provider => ({
            ...provider,
            providerId: provider.id,
            categoryOptionIds: provider.categoryOptions?.map(catOpt => catOpt.id),
          }))}
        setSelectedProviders={setSelectedProviders}
        isDrawerOpen={isUpdatePriorityDrawerOpen}
        closeDrawer={closeUpdatePriorityDrawer}
      />
    </ResourceCollectionScene>
  )
}

export default AssessmentOverviewScene
