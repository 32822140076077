import HoverDialog from '@app/src/components/HoverDialog'
import LinkButton from '@app/src/components/LinkButton'
import { Box, Stack, Typography } from '@mui/material'
import ReactEcharts, { EChartsOption } from 'echarts-for-react'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import GraphLegend from './GraphLegend'

export interface DistributionGraphBar {
  count: number
  name: string
  label: string
  detailedLabel: string
  color: string
  link: string
}

interface DistributionGraphProps {
  title: string
  bars: DistributionGraphBar[]
}

const DistributionGraph: React.FC<DistributionGraphProps> = ({ title, bars }) => {
  const { formatMessage } = useIntl()
  const [highlightedBar, setHighlightedBar] = useState<string>()
  const history = useHistory()

  const totalValue = bars.reduce((acc, bar) => acc + bar.count, 0)
  const options: EChartsOption = {
    animation: false,
    type: 'bar',
    stack: 'chart',
    itemStyle: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    xAxis: {
      show: false,
      max: totalValue,
      splitLine: {
        show: false,
      },
      triggerEvent: true,
    },
    yAxis: {
      inverse: true,
      type: 'category',

      splitLine: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      triggerEvent: true,
      axisLabel: {
        show: false,
      },
    },
    grid: {
      top: 0,
      bottom: 0,
      right: 0,
      containLabel: false,
      show: false,
      left: 0,
    },
    series: bars.map((bar, index) => {
      const hasPreviousBarsWithValue = bars.slice(0, index).some(prevBar => prevBar.count > 0)
      const hasNextBarsWithValue = bars.slice(index + 1).some(nextBar => nextBar.count > 0)
      return {
        type: 'bar',
        stack: 'x',
        data: [{ value: bar.count, name: bar.name }],

        emphasis: {
          focus: 'self',
        },

        itemStyle: {
          color: bar.color,
          borderType: 'solid',
          borderWidth: 1,
          borderColor: 'white',
          borderRadius:
            hasPreviousBarsWithValue && hasNextBarsWithValue
              ? [0, 0, 0, 0]
              : hasPreviousBarsWithValue
                ? [0, 5, 5, 0]
                : hasNextBarsWithValue
                  ? [5, 0, 0, 5]
                  : [5, 5, 5, 5],
        },
      }
    }),
  }

  const events = useMemo(
    () => ({
      mouseover: ({ data }: { data: { name: string } }) => {
        setHighlightedBar(data.name)
      },
      click: ({ data }: { data: { name: string } }) => {
        const link = bars.find(b => b.name === data.name)?.link
        if (link) history.push(link)
      },
    }),
    [],
  )

  return (
    <Stack width="100%">
      <HoverDialog
        content={
          <Box p={2} onMouseEnter={() => setHighlightedBar(undefined)}>
            <Stack spacing={1}>
              <Typography variant="overline" color="textSecondary" noWrap>
                {title}
              </Typography>
              {bars.map(bar => (
                <Stack
                  key={bar.name}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedBar === bar.name ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedBar(bar.name)}
                  onMouseLeave={() => setHighlightedBar(undefined)}
                  onClick={() => history.push(bar.link)}
                >
                  <GraphLegend color={bar.color} variant="body1" legend={bar.detailedLabel} />
                  {highlightedBar === bar.name && (
                    <LinkButton to={bar.link}>{formatMessage({ id: 'dashboard.sourcing.seeAll' })}</LinkButton>
                  )}
                </Stack>
              ))}
            </Stack>
          </Box>
        }
      >
        <Box width="100%" py={1}>
          <ReactEcharts option={options} onEvents={events} style={{ height: 50 }} />
        </Box>
      </HoverDialog>
      <Stack direction="row" spacing={2}>
        {bars.map(bar => (
          <GraphLegend key={bar.name} color={bar.color} legend={bar.label} linkTo={bar.link} />
        ))}
      </Stack>
    </Stack>
  )
}

export default DistributionGraph
