import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import FailedToFetchDataEmptyState from '@app/src/components/EmptyState/FailedToFetchDataEmptyState'
import HoverDialog from '@app/src/components/HoverDialog'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import CompanyStatisticsGraphSkeleton from '@app/src/pages/Dashboards/SourcingDashboard/CompaniesStatistics/CompanyStatisticsGraphSkeleton'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ProviderUsageValues } from '@app/src/wf-constants'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useIntl } from 'react-intl'
import GraphLegend from '../../GraphLegend'
import { companiesListAllowedFilters } from './CompaniesList'
import CompaniesStatisticGraph from './CompaniesStatisticGraph'
import { handleFilterValue } from './CompaniesStatistics'

const SupplierUsageGraph = () => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  const [highlightedUsage, setHighlightedUsage] = useState<string | number>()
  const filters = useGetApiQueryFilters(companiesListAllowedFilters)
  const { inView, ref } = useInView({ triggerOnce: true })

  const {
    facets: [providerUsage],
    isError: providerUsageIsError,
    isLoading,
  } = useFetchFacets({
    key: FetchKey.ProviderFacets,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'supplierUsage' }],
    filter: filters,
    options: {
      enabled: inView,
    },
    shouldShowErrorNotification: false,
  })

  const notSelectedCount = providerUsage?.find(company => company.label === ProviderUsageValues.NotSelected)?.count ?? 0
  const directCount = providerUsage?.find(company => company.label === ProviderUsageValues.Direct)?.count ?? 0
  const indirectCount = providerUsage?.find(company => company.label === ProviderUsageValues.Indirect)?.count ?? 0

  const totalCompanies = notSelectedCount + directCount + indirectCount

  if (providerUsageIsError)
    return (
      <Box display="flex" alignItems="center" height="100%">
        <FailedToFetchDataEmptyState />
      </Box>
    )

  return (
    <HoverDialog
      content={
        <Box p={2} onMouseEnter={() => setHighlightedUsage(undefined)}>
          <Stack spacing={1}>
            <Typography variant="overline" color="textSecondary" noWrap>
              {formatMessage({ id: 'dashboard.sourcing.companyStatistics.companiesByUsage' })}
            </Typography>
            <Filter operator={Operators.In} name="supplierUsage">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedUsage === ProviderUsageValues.Direct ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedUsage(ProviderUsageValues.Direct)}
                  onMouseLeave={() => setHighlightedUsage(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderUsageValues.Direct))}
                >
                  <GraphLegend
                    color={palette.visualization[7]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.supplierUsageValues.DirectWithCount' },
                      { count: directCount },
                    )}
                  />
                  {highlightedUsage === ProviderUsageValues.Direct && (
                    <Button onClick={() => onChange(handleFilterValue(value, ProviderUsageValues.Direct))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>

            <Filter operator={Operators.In} name="supplierUsage">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedUsage === ProviderUsageValues.Indirect ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedUsage(ProviderUsageValues.Indirect)}
                  onMouseLeave={() => setHighlightedUsage(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderUsageValues.Indirect))}
                >
                  <GraphLegend
                    color={palette.visualization[10]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.supplierUsageValues.IndirectWithCount' },
                      { count: indirectCount },
                    )}
                  />
                  {highlightedUsage === ProviderUsageValues.Indirect && (
                    <Button onClick={() => onChange(handleFilterValue(value, ProviderUsageValues.Indirect))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>

            <Filter operator={Operators.In} name="supplierUsage">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedUsage === ProviderUsageValues.NotSelected ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedUsage(ProviderUsageValues.NotSelected)}
                  onMouseLeave={() => setHighlightedUsage(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderUsageValues.NotSelected))}
                >
                  <GraphLegend
                    color={palette.grey[300]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.supplierUsageValues.NotSelectedWithCount' },
                      { count: notSelectedCount },
                    )}
                  />
                  {highlightedUsage === ProviderUsageValues.NotSelected && (
                    <Button onClick={() => onChange(handleFilterValue(value, ProviderUsageValues.NotSelected))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
          </Stack>
        </Box>
      }
    >
      <Box ref={ref}>
        {isLoading ? (
          <CompanyStatisticsGraphSkeleton />
        ) : (
          <CompaniesStatisticGraph
            highlightSetter={setHighlightedUsage}
            title={formatMessage({ id: `dashboard.sourcing.companyStatistics.directVsIndirect` })}
            data={[
              { value: directCount, name: ProviderUsageValues.Direct },
              { value: indirectCount, name: ProviderUsageValues.Indirect },
              { value: notSelectedCount, name: ProviderUsageValues.NotSelected },
            ]}
            colors={[palette.visualization[7], palette.visualization[10], palette.grey[300]]}
            value={Math.ceil(100 - (notSelectedCount * 100) / totalCompanies)}
          />
        )}
      </Box>
    </HoverDialog>
  )
}

export default SupplierUsageGraph
