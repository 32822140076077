import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import EmptyState from '@app/src/components/EmptyState'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import useActorTypes from '@app/src/hooks/useActorTypes'
import MappingNodeMenuCell from '@app/src/pages/Product/MappingNodeMenuCell'
import ValueChainTreeView from '@app/src/pages/Product/ValueChainTreeView'
import ValueChainViewHeader from '@app/src/pages/Product/ValueChainViewHeader'
import { Organization } from '@app/src/types/organizations'
import { ActorType, ActorTypeModel, MappingNode, Product } from '@app/src/types/product'
import { NotificationSeverity } from '@app/src/wf-constants'
import { AccountTreeOutlined, East, ListAlt } from '@mui/icons-material'
import {
  Box,
  Button,
  ButtonGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

interface CanAddNodeProps {
  product: Product
  onAddNodeClick?: (product: Product) => void
  onEditNodeClick?: (node: MappingNode) => void
}

interface CannotAddNodeProps {
  product?: undefined
  onAddNodeClick?: undefined
  onEditNodeClick?: undefined
}

type TruncateProps = CanAddNodeProps | CannotAddNodeProps

type Props = {
  mappingNodes: MappingNode[]
  title?: React.ReactNode
  forceView?: View
  showHeader?: boolean
  treeViewHeight?: string | number
  isProductView?: boolean
  customEmptyState?: React.ReactNode
  showConnectedProduct?: boolean
  showLastUpdate?: boolean
  isSnapshot?: boolean
  responder?: Organization
  autoAcceptValueChainEnabled?: boolean
  connectedResponsesCount?: number
  responseSubmittedAt?: string
} & TruncateProps

export enum View {
  Table = 'table',
  Tree = 'tree',
}

const assignParentNodes = (mappingNodes: MappingNode[]) => {
  return mappingNodes.map(node => ({ ...node, parentNode: mappingNodes.find(n => n.id === node.parentNodeId) ?? null }))
}

const assignActorTypes = (mappingNodes: MappingNode[], actorTypes?: ActorTypeModel[]): MappingNode[] =>
  mappingNodes.map(node => {
    const updatedNode = {
      ...node,
      actorType: node.actorType ?? (actorTypes ?? []).find(at => at.id === node.actorTypeId),
    }

    if (updatedNode.parentNode && updatedNode.parentNode.actorTypeId && !updatedNode.parentNode.actorType) {
      updatedNode.parentNode.actorType = (actorTypes ?? []).find(at => at.id === node.parentNode?.actorTypeId)
    }
    return updatedNode
  })

const ValueChainView: React.FC<Props> = ({
  title,
  mappingNodes = [],
  forceView,
  showHeader = true,
  treeViewHeight,
  isProductView = false,
  product,
  customEmptyState,
  onEditNodeClick,
  onAddNodeClick,
  isSnapshot,
  showConnectedProduct = false,
  showLastUpdate = false,
  autoAcceptValueChainEnabled = false,
  responder,
  connectedResponsesCount,
  responseSubmittedAt,
}) => {
  const [view, setView] = useState(forceView ?? View.Table)
  const { formatMessage } = useIntl()
  const confirm = useConfirm()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const count = mappingNodes?.length
  const { mutate: deleteMappingNode } = useDeleteResource()
  const { items: actorTypes } = useActorTypes({ enabled: isSnapshot })
  const mappingNodesWithParentNodes = isSnapshot ? assignParentNodes(mappingNodes) : mappingNodes
  const mappingNodesWithActorTypeModels = isSnapshot
    ? assignActorTypes(mappingNodesWithParentNodes, actorTypes)
    : mappingNodesWithParentNodes
  const sortedMappingNodes = mappingNodesWithActorTypeModels.sort((a, b) => a.tier - b.tier)

  const deleteNode = (mappingNode: MappingNode) => {
    confirm({
      title: formatMessage({ id: 'general.areYouSure' }),
      description: formatMessage({ id: 'schemas.mappingNode.deleteDialog.content' }),
      cancellationText: formatMessage({ id: 'general.cancel' }),
      confirmationText: formatMessage({ id: 'general.delete' }),
    }).then(() => {
      deleteMappingNode(
        { url: endpoints.deleteMappingNode(mappingNode.id), body: {} },
        {
          onSuccess: async () => {
            showSnackbar({
              message: formatMessage({ id: 'notifications.successfulResourceDelete' }),
              severity: NotificationSeverity.success,
            })
            queryClient.invalidateQueries(FetchKey.MappingNodeCollection)
            queryClient.invalidateQueries(FetchKey.Product)
            queryClient.invalidateQueries(FetchKey.MappingRequestsByProduct)
          },
          onError: error => {
            showErrorNotification({ requestError: error, disableAutoClose: true })
          },
        },
      )
    })
  }

  if (count <= 1) {
    return (
      <>
        {customEmptyState ?? (
          <>
            <Stack spacing={1}>{title}</Stack>
            <Box padding={8} paddingTop={isProductView ? 8 : 4}>
              <EmptyState
                iconComponent={AccountTreeOutlined}
                title={formatMessage({ id: 'schemas.product.createValueChainEmptyState' })}
                description={formatMessage({ id: 'schemas.product.emptyStateDescription' })}
              />
            </Box>
          </>
        )}
      </>
    )
  }

  return (
    <>
      {showHeader && (
        <>
          <Box mb={2} display="flex" justifyContent="space-between" flexDirection="row-reverse">
            {!forceView && count > 1 && (
              <ButtonGroup>
                <Button
                  onClick={() => setView(View.Table)}
                  sx={({ palette }) => ({ backgroundColor: view === View.Table ? palette.grey[300] : 'inherit' })}
                >
                  <ListAlt />
                </Button>
                <Button
                  onClick={() => setView(View.Tree)}
                  sx={({ palette }) => ({ backgroundColor: view === View.Tree ? palette.grey[300] : 'inherit' })}
                >
                  <AccountTreeOutlined />
                </Button>
              </ButtonGroup>
            )}
            <Stack spacing={1}>{title}</Stack>
          </Box>
          <ValueChainViewHeader
            autoAcceptValueChainEnabled={autoAcceptValueChainEnabled}
            product={product}
            responder={responder}
            showLastUpdate={showLastUpdate}
            showConnectedProduct={showConnectedProduct}
            connectedResponsesCount={connectedResponsesCount}
            responseSubmittedAt={responseSubmittedAt}
          />
        </>
      )}
      <Box
        pb={3}
        sx={{
          height: treeViewHeight === '100%' ? '100%' : 'inherit',
          overflow: isProductView ? 'hidden' : undefined,
          borderRadius: isProductView ? 1 : 0,
        }}
        display={treeViewHeight ? 'flex' : ''}
      >
        {view === View.Table && (
          <Table data-testid="supply-chain-table">
            <TableHead sx={({ palette }) => ({ background: palette.grey[200] })}>
              <TableRow>
                <TableCell>{formatMessage({ id: 'schemas.mappingNode.tier' })}</TableCell>
                <TableCell>{formatMessage({ id: 'schemas.mappingNode.from' })}</TableCell>
                <TableCell />
                <TableCell>{formatMessage({ id: 'schemas.mappingNode.to' })}</TableCell>
                {onEditNodeClick && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedMappingNodes
                .filter(mn => mn.tier)
                .map(node => {
                  return (
                    <TableRow
                      hover
                      key={node.id}
                      sx={({ palette }) => ({
                        '& > td': {
                          borderColor: palette.grey[200],
                        },
                        '&:last-child > td': { borderBottom: 'none' },
                      })}
                    >
                      <TableCell>
                        <Typography align="left" variant="body1">
                          {node.tier}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {node?.provider ? (
                          <CompanyCell company={node?.provider} drawer disableCell disableAvatar />
                        ) : node?.organization ? (
                          <CompanyCell company={node?.organization} disableCell disableAvatar />
                        ) : (
                          <Typography variant="body1">{node.name}</Typography>
                        )}

                        <Typography
                          variant="body1"
                          sx={theme => ({
                            color: theme.palette.grey[600],
                            fontSize: '0.9em',
                          })}
                        >
                          {formatMessage({
                            id: `schemas.mappingNode.actorTypeValues.${node?.actorType?.name ?? ActorType.NotSet}`,
                          })}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: 0,
                          color: theme => theme.palette.grey[600],
                        }}
                      >
                        <East
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {node?.parentNode?.provider ? (
                          <CompanyCell company={node?.parentNode?.provider} drawer disableCell disableAvatar />
                        ) : node?.parentNode?.organization ? (
                          <CompanyCell company={node?.parentNode?.organization} disableCell disableAvatar />
                        ) : (
                          <Typography variant="body1">{node.parentNode?.name}</Typography>
                        )}

                        <Typography
                          variant="body1"
                          sx={theme => ({ color: theme.palette.grey[600], fontSize: '0.9em' })}
                        >
                          {formatMessage({
                            id: `schemas.mappingNode.actorTypeValues.${
                              node?.parentNode?.actorType?.name ?? ActorType.NotSet
                            }`,
                          })}
                        </Typography>
                      </TableCell>
                      {onEditNodeClick && (
                        <TableCell sx={{ padding: 0 }}>
                          <MappingNodeMenuCell mappingNode={node} editNode={onEditNodeClick} deleteNode={deleteNode} />
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        )}
        {view === View.Tree && (
          <ValueChainTreeView
            nodes={sortedMappingNodes}
            height={treeViewHeight}
            openDrawer={onAddNodeClick && (() => onAddNodeClick(product))}
          />
        )}
      </Box>
    </>
  )
}

export default ValueChainView
