const LEGEND_ITEM_HEIGHT = 24
const LEGEND_HORIZONTAL_PADDING = 16

export const calculateLegendLines = (chartWidth: number | undefined, legendItems: string[], fontSize = 12) => {
  if (!chartWidth || !legendItems.length) return 1

  const itemWidths = legendItems.map(item => item.length * fontSize * 0.6 + LEGEND_HORIZONTAL_PADDING)

  let currentLineWidth = 0
  let lines = 1

  for (const itemWidth of itemWidths) {
    if (currentLineWidth + itemWidth > chartWidth) {
      lines++
      currentLineWidth = itemWidth
    } else {
      currentLineWidth += itemWidth
    }
  }

  return lines
}

export const getLegendSpacing = (baseSpacing: number, chartWidth: number | undefined, legendItems: string[]) => {
  const lines = calculateLegendLines(chartWidth, legendItems)
  return baseSpacing + lines * LEGEND_ITEM_HEIGHT
}
