import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewCompany from '@app/src/components/Drawer/Views/DrawerViewCompany'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { GenericOrganization, isProvider } from '@app/src/types/organizations'
import { ButtonBase, Stack, Tooltip, Typography, TypographyProps } from '@mui/material'
import React, { useState } from 'react'
import TextTruncate, { TextTruncateProps } from 'react-text-truncate'
import ParentCellOrFragment, { ParentCellOrFragmentProps } from './ParentCellOrFragment'

export type CompanyCellProps = {
  company?: GenericOrganization
  drawer?: boolean
  maxRows?: TextTruncateProps['line']
  disableCell?: boolean
  disableTooltip?: boolean
  disableTruncate?: boolean
  disableAvatar?: boolean
  sx?: ParentCellOrFragmentProps['sx']
  isPartialProviderData?: boolean
} & TypographyProps

export enum DrawerViews {
  Company = 'Company',
  RiskCard = 'RiskCard',
  FinalRiskRating = 'FinalRiskRating',
  DeleteProvider = 'DeleteProvider',
  LinkProvider = 'LinkProvider',
}

export const getOrganizationDisplayName = (company: GenericOrganization) => company?.organization?.name ?? company.name

const CompanyCell: React.FC<CompanyCellProps> = ({
  company,
  drawer,
  maxRows = 1,
  disableCell,
  disableTooltip,
  disableTruncate,
  disableAvatar,
  sx,
  isPartialProviderData,
  ...props
}) => {
  const { openDrawer } = useDrawer()

  const [truncated, setTruncated] = useState(false)

  if (!company) {
    return (
      <ParentCellOrFragment disableCell={disableCell} sx={sx}>
        <Typography variant="body1">-</Typography>
      </ParentCellOrFragment>
    )
  }

  const companyName = getOrganizationDisplayName(company)
  const img = company.image ?? (isProvider(company) && company?.organization?.image)

  if (company.deletedAt)
    return (
      <ParentCellOrFragment disableCell={disableCell} sx={sx}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <CompanyAvatar
            organizationName={companyName}
            alt={companyName}
            imageUrl={img?.url}
            size={AvatarSize.Small}
            unlinked
          />
          <Stack>
            <Typography variant="body1" color="action.disabled" component="span" {...props}>
              {disableTruncate ? companyName : <TextTruncate line={maxRows} text={companyName} />}
            </Typography>
          </Stack>
        </Stack>
      </ParentCellOrFragment>
    )

  return (
    <ParentCellOrFragment disableCell={disableCell} sx={sx}>
      <Tooltip title={truncated ? companyName : undefined} disableHoverListener={disableTooltip || !truncated}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          component={drawer ? ButtonBase : 'span'}
          onClick={
            drawer
              ? () =>
                  openDrawer(<DrawerViewCompany isPartialProviderData={isPartialProviderData} company={company} />, {
                    showAboveDialogs: true,
                  })
              : undefined
          }
          width="100%"
          data-testid="open-company-drawer"
        >
          {!disableAvatar && (
            <CompanyAvatar
              organizationName={companyName}
              alt={companyName}
              imageUrl={img?.url}
              size={AvatarSize.Small}
              unlinked={!company.linked}
            />
          )}
          <Typography
            variant="body1"
            align="left"
            sx={{ '&:hover': { textDecoration: drawer ? 'underline' : 'none' } }}
            flexGrow={1}
            component="span"
            data-testid="company-cell-name"
            {...props}
          >
            {disableTruncate ? (
              companyName
            ) : (
              <TextTruncate line={maxRows} text={companyName} onTruncated={() => setTruncated(true)} />
            )}
          </Typography>
        </Stack>
      </Tooltip>
    </ParentCellOrFragment>
  )
}

export default CompanyCell
