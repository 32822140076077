import { Permissions, usePermissions } from '@app/src/auth/permissions'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const AccessorResponseHeader: React.FC<SortableHeaderProps> = ({ toggleSorting, activeSorting }) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const verificationEnabled = hasPermission(Permissions.WRITE_VERIFICATION)

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.requestStatus' })}
        guidance={formatMessage({ id: 'schemas.request.guidance.status' })}
        minWidth={TableCellWidth.EMPTY}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.request' })}
        sorting={{ name: 'request.template.title', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.periodDisplayName' })}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.request.target' })} />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.createdAt' })}
        sorting={{ name: 'createdAt', toggleSorting, activeSorting }}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.request.deadline' })} />

      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.responseDate' })}
        sorting={{ name: 'submittedAt', toggleSorting, activeSorting }}
      />

      {verificationEnabled && (
        <>
          <TableHeaderCell
            label={formatMessage({ id: 'schemas.request.externalVerificationComment' })}
            guidance={formatMessage({ id: 'schemas.request.guidance.externalVerificationComment' })}
            maxRows={1}
          />
          <TableHeaderCell
            label={formatMessage({ id: 'schemas.request.responseInternalStatus' })}
            guidance={formatMessage({ id: 'schemas.request.guidance.internalVerificationStatus' })}
            maxRows={1}
          />
          <TableHeaderCell
            label={formatMessage({ id: 'schemas.request.internalVerificationComment' })}
            guidance={formatMessage({ id: 'schemas.request.guidance.internalVerificationComment' })}
            maxRows={1}
          />
        </>
      )}
      <TableHeaderCell label={formatMessage({ id: 'schemas.request.source' })} />
    </TableRow>
  )
}

export default AccessorResponseHeader
