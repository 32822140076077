import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import { Provider } from '@app/src/types/organizations'
import { Operators } from '../../../Filters/useFilters'

const ONE_HOUR = 60 * 60 * 1000

interface useSelectedProviderDataProps {
  selectedRowsIds: number[]
}

const useSelectedProviderData = ({ selectedRowsIds }: useSelectedProviderDataProps) => {
  const {
    facets: [matchingProviderIds],
    isLoading: isLoadingProviderIds,
  } = useFetchFacets({
    endpoint: endpoints.subscriptionsFacets,
    facetsParam: [{ name: 'responderProviderId', isEnum: true }],
    key: FetchKey.SubscriptionFacets,
    filter: [
      { name: 'request.responses.items.id', filters: [{ value: selectedRowsIds, operator: Operators.In }] },
      { name: 'responder.deletedAt', filters: [{ operator: Operators.IsNull }] },
    ],
    options: { enabled: Boolean(selectedRowsIds?.length), staleTime: ONE_HOUR },
  })
  const matchingProviderCount = matchingProviderIds?.length ?? 0

  const {
    items: providers,
    isLoading: isLoadingProviders,
    refetch: fetchProviders,
  } = useFetchCollectionWithPost<Provider>({
    endpoint: endpoints.providersCollection,
    key: FetchKey.ProviderCollection,
    payload: {
      include: [],
      filter: [
        {
          name: 'id',
          filters: [{ value: matchingProviderIds?.map(p => p.label) ?? [], operator: Operators.In }],
        },
      ],
    },
    options: { enabled: false },
  })

  const isLoadingProviderData = isLoadingProviderIds || isLoadingProviders

  return {
    matchingProviderCount,
    fetchProviders,
    providers,
    isLoadingProviderData,
  }
}

export default useSelectedProviderData
