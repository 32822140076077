import ImageWithContentDialog from '@app/src/components/Dialog/ImageWithContentDialog'
import Avatar from '@app/src/components/Ui/Avatar'
import { useAccount } from '@app/src/context/AccountContext'
import { CheckOutlined } from '@mui/icons-material'
import { Button, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'

interface ReuseAiAnswersDialogProps {
  open: boolean
  onClose: () => void
}

const ReuseAiAnswersDialog: FC<ReuseAiAnswersDialogProps> = ({ open, onClose }) => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()

  return (
    <ImageWithContentDialog imageUrl="/img/view-ai-suggestion.webp" open={open} onClose={onClose}>
      <Stack spacing={2}>
        <Typography variant="h3">
          {formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.reuseAiAnswerDialog.title' })}
        </Typography>
        <Typography>
          {formatMessage(
            { id: 'reporting.reuseFlow.reuseQuestion.reuseAiAnswerDialog.description' },
            { companyName: account?.organization?.name },
          )}
        </Typography>
        <List>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemAvatar>
              <Avatar color="primary" sx={{ bgcolor: 'grey.100', color: 'grey.900' }}>
                <CheckOutlined />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({
                id: 'reporting.reuseFlow.reuseQuestion.reuseAiAnswerDialog.applyManuallyPrimary',
              })}
              secondary={formatMessage({
                id: 'reporting.reuseFlow.reuseQuestion.reuseAiAnswerDialog.applyManuallySecondary',
              })}
              primaryTypographyProps={{ variant: 'subtitle1' }}
              secondaryTypographyProps={{ color: 'textPrimary', variant: 'body1' }}
            />
          </ListItem>
        </List>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button variant="contained" onClick={onClose}>
            {formatMessage({ id: 'general.continue' })}
          </Button>
        </Stack>
      </Stack>
    </ImageWithContentDialog>
  )
}

export default ReuseAiAnswersDialog
