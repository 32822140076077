import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import BaseNode from '@app/src/pages/Product/Tree/BaseNode'
import { NodeData } from '@app/src/pages/Product/ValueChainTreeView'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface Props {
  data: NodeData
}

const StopNode: React.FC<Props> = ({
  data: {
    node,
    nodeProps: { openDrawer },
  },
}) => {
  const { formatMessage } = useIntl()

  return (
    <BaseNode showTarget={Boolean(node.parentNode)} showSource openDrawer={openDrawer}>
      <Box padding={4} border="solid 1px #99302c" borderRadius={12}>
        <Box marginBottom={2}>
          {node?.provider ? (
            <CompanyCell company={node?.provider} drawer disableCell disableTruncate />
          ) : node?.organization ? (
            <CompanyCell company={node?.organization} disableCell disableTruncate />
          ) : (
            <Typography variant="h5">{node.name}</Typography>
          )}
        </Box>
        <Typography variant="body1" gutterBottom>
          {node.actorType ? formatMessage({ id: `schemas.mappingNode.actorTypeValues.${node.actorType.name}` }) : '-'}
        </Typography>
        {node.tier !== 0 && (
          <Typography variant="body1" gutterBottom>
            {formatMessage({ id: 'schemas.supplier.tierNumber' }, { number: node.tier })}
          </Typography>
        )}
      </Box>
    </BaseNode>
  )
}

export default StopNode
