import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import EmptyState, { EmptyStateVariant } from '@app/src/components/EmptyState/EmptyState'
import FailedToFetchDataEmptyState from '@app/src/components/EmptyState/FailedToFetchDataEmptyState'
import LinkButton from '@app/src/components/LinkButton'
import StatusChip from '@app/src/components/StatusChip'
import { useProvidersByPendingInquiries } from '@app/src/hooks/providersByPendingInquiries'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import SendIcon from '@mui/icons-material/Send'
import { Box, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import StatisticsCard from './StatisticsCard'

const PROVIDERS_TO_DISPLAY = 20

const PendingRequestsCard = () => {
  const { formatMessage } = useIntl()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const [pendingRequestHovered, setPendingRequestsHovered] = useState(false)

  const {
    facets: [pendingInquiries],
    isLoading: pendingInquiriesIsLoading,
    isError: pendingInquiriesIsError,
  } = useFetchFacets({
    key: FetchKey.InquiryOpenRequests,
    endpoint: endpoints.inquiryWithFacets,
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
            operator: Operators.In,
          },
        ],
      },
      {
        name: 'deletedAt',
        filters: [
          {
            operator: Operators.IsNull,
          },
        ],
      },
    ],
    facetsParam: [{ name: 'providerId', isEnum: true }],
    shouldShowErrorNotification: false,
  })

  const {
    providersWithOverdueInquiries,
    providersWithInquiriesOverdueWithinAWeek,
    isLoading: providersByPendingInquiriesLoading,
    isError: providersWithOverdueInquiriesIsError,
  } = useProvidersByPendingInquiries()

  const providersWithOverdueInquiriesCount = providersWithOverdueInquiries?.length
  const providersWithInquiriesOverdueWithinAWeekCount = providersWithInquiriesOverdueWithinAWeek?.length

  const relevantInquiries = () => {
    if (providersWithOverdueInquiriesCount) {
      return providersWithOverdueInquiries
    }
    if (providersWithInquiriesOverdueWithinAWeekCount) {
      return providersWithInquiriesOverdueWithinAWeek
    }
    return pendingInquiries
  }

  const providersToDisplayIds =
    relevantInquiries()
      ?.map(inquiry => inquiry.label)
      .filter((value, index, self) => self.indexOf(value) === index) ?? []

  const {
    facets: [providersToDisplay],
    isLoading: providersToDisplayIsLoading,
    isError: providersToDisplayIsError,
  } = useFetchFacets({
    key: FetchKey.OverdueProviders,
    endpoint: endpoints.providersWithFacets,
    shouldShowErrorNotification: false,
    facetsParam: [{ name: 'name' }],
    filter: [
      {
        name: 'id',
        filters: [
          {
            value: providersToDisplayIds,
            operator: Operators.In,
          },
        ],
      },
    ],
    options: {
      enabled: Boolean(providersToDisplayIds.length) && pendingRequestHovered,
    },
  })

  const getStatusChipLabel = () => {
    if (providersWithOverdueInquiriesCount) {
      return formatMessage({ id: 'dashboard.sourcing.requestsOverdue' }, { count: providersToDisplayIds?.length })
    }
    if (providersWithInquiriesOverdueWithinAWeek?.length) {
      return formatMessage({ id: 'dashboard.sourcing.requestsDueSoon' }, { count: providersToDisplayIds?.length })
    }
    if (pendingInquiries?.length) {
      return formatMessage({ id: 'dashboard.sourcing.requestsPending' }, { count: providersToDisplayIds?.length })
    }
    return formatMessage({ id: 'dashboard.sourcing.allDone' })
  }

  const isLoading = pendingInquiriesIsLoading || providersByPendingInquiriesLoading
  const isError = pendingInquiriesIsError || providersWithOverdueInquiriesIsError
  const emptyState = !isLoading && !pendingInquiries?.length

  return (
    <StatisticsCard
      title={formatMessage({ id: 'dashboard.sourcing.requestsTitle' })}
      justifyContent={!emptyState ? 'left' : 'center'}
      helperText={formatMessage({ id: 'dashboard.sourcing.requestsHelperText' })}
      action={
        !emptyState && (
          <LinkButton
            size="small"
            to={stringifyQueryFilters({
              url: generatePath(paths.manageRequest, {
                view: ViewTypeName.Requests,
              }),
              queryParams: {
                filters: [
                  {
                    name: 'status',
                    value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
                    operator: Operators.In,
                  },
                ],
              },
              includeCurrentFilters: true,
            })}
          >
            {formatMessage({ id: 'dashboard.investment.seeAll' })}
          </LinkButton>
        )
      }
      isError={isError}
      loading={{
        isLoading,
        skeleton: (
          <Stack spacing={2}>
            <Typography variant="kpi" pt={2}>
              <Skeleton width={70} variant="rounded" />
            </Typography>

            <Skeleton width={200} />
          </Stack>
        ),
      }}
    >
      <>
        {emptyState ? (
          <EmptyState
            variant={EmptyStateVariant.Small}
            title={formatMessage({ id: 'dashboard.sourcing.requestsEmptyStateTitle' })}
            iconComponent={SendIcon}
            sx={{ py: 2 }}
          />
        ) : (
          <Stack spacing={2}>
            <Typography variant="kpi" pt={2}>
              {pendingInquiries?.reduce((acc, curr) => acc + curr.count, 0)}
            </Typography>

            <Tooltip
              disableHoverListener={!providersToDisplayIds.length}
              onOpen={() => setPendingRequestsHovered(true)}
              title={
                providersToDisplayIsError ? (
                  <FailedToFetchDataEmptyState />
                ) : providersToDisplayIsLoading ? (
                  <Skeleton variant="text" width={80} />
                ) : (
                  providersToDisplay
                    ?.slice(0, PROVIDERS_TO_DISPLAY)
                    .map(provider => provider.label)
                    .join(', ') +
                  `${
                    providersToDisplay?.length > PROVIDERS_TO_DISPLAY
                      ? ` +${providersToDisplay?.length - PROVIDERS_TO_DISPLAY} others`
                      : ''
                  }`
                )
              }
            >
              <Box sx={{ '&:hover': { backgroundColor: 'grey.100', borderRadius: 4 } }}>
                <StatusChip
                  transparent
                  color={
                    providersWithOverdueInquiriesCount
                      ? 'error'
                      : providersWithInquiriesOverdueWithinAWeekCount
                        ? 'warning'
                        : pendingInquiries?.length
                          ? 'info'
                          : 'success'
                  }
                  label={getStatusChipLabel()}
                />
              </Box>
            </Tooltip>
          </Stack>
        )}
      </>
    </StatisticsCard>
  )
}

export default PendingRequestsCard
