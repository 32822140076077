import { FetchFacetsOptions, useFetchFacets } from '@app/src/api/fetchHooks'
import { OptionAdornment, OptionIcon } from '@app/src/components/Form/Select'
import SelectPaper from '@app/src/components/Form/Select/SelectPaper'
import { useAutocompleteStyles } from '@app/src/components/Form/Select/SimpleSelect'
import TextField from '@app/src/components/Ui/TextField'
import { FilterGroup, FilterGroupQueryString } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { FacetItem } from '@app/src/pages/ResourceCollection/index'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Autocomplete, AutocompleteGetTagProps, AutocompleteProps, Box, Chip, Tooltip, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getAutocompleteHeightControlStyles } from './FilterSelect'

type OnChangeMultiple = (value: string[], replace?: boolean) => void
type OnChangeSingle = (value: string | null, replace?: boolean) => void
const selectAllValue = 'selectAll'

export interface FilterFacetSelectCommonProps {
  disabled?: boolean
  filterName: string
  fieldLabel?: string
  required?: boolean
  facetsParam: FetchFacetsOptions
  size?: AutocompleteProps<never, never, never, never>['size']
  actionButtons?: JSX.Element
  modifiers?: JSX.Element
  renderOption?: AutocompleteProps<FacetItem, boolean, false, false, 'div'>['renderOption']
  renderTags?: (value: FacetItem[], getTagProps: AutocompleteGetTagProps) => JSX.Element[]
  implicitFilters?: FilterGroup[]
  replaceUrl?: boolean
  skipNotSet?: boolean
  filterOptions?: AutocompleteProps<FacetItem, boolean, undefined, undefined>['filterOptions']
  forceFetch?: boolean
  variant?: 'outlined' | 'filled' | 'standard' | undefined
  disableAutoFilter?: boolean
  selectAll?: boolean
  uniqueId?: string
  customSort?: (a: FacetItem, b: FacetItem) => number
}

export interface SingleModeProps {
  multiple: false
  value: string
  onChange: OnChangeSingle
}

export interface MultipleModeProps {
  multiple: true
  value: string[]
  onChange: OnChangeMultiple
}

export type FilterFacetSelectTruncateProps = SingleModeProps | MultipleModeProps

export type FilterFacetSelectProps = FilterFacetSelectCommonProps & FilterFacetSelectTruncateProps

const isMultipleValue = (value: FacetItem | FacetItem[] | null): value is FacetItem[] => {
  return Array.isArray(value)
}

const isMultipleStringValue: (value: string | string[] | null) => value is string[] = (
  value: string | string[] | null,
): value is string[] => {
  return Array.isArray(value)
}

export const isFacetItem = (value: unknown): value is FacetItem => {
  return typeof (value as FacetItem)?.label !== 'undefined' && typeof (value as FacetItem)?.value !== 'undefined'
}

const findValue = (facets: FacetItem[], flatValue: string): FacetItem | undefined => {
  return facets?.find((facet): boolean => facet.value.toString() === flatValue)
}

const getSingleSelectValue = (facets: FacetItem[], value: string): FacetItem | null => {
  return findValue(facets, value) || null
}

const getMultipleSelectValue = (facets: FacetItem[], value: string[] = []): FacetItem[] =>
  value.map((flatValueItem: string) => findValue(facets, flatValueItem)).filter<FacetItem>(isFacetItem)

export const facetToValue = (facet?: FacetItem | null): string => {
  if (!facet) return ''
  return (facet?.value ?? '').toString()
}

export const facetToValueArray = (facet?: FacetItem | FacetItem[] | null): string[] => {
  if (!facet) return []
  return [facet]
    .flat()
    .filter(facetItem => facetItem.value !== 'selectAll')
    .map(facetItem => (facetItem?.value ?? '').toString())
}

type SimpleFilterFacetSelectProps = FilterFacetSelectProps & {
  apiFilters: FilterGroupQueryString[]
}

const CommonFilterFacetSelect = ({
  filterName,
  value,
  fieldLabel,
  required,
  facetsParam,
  onChange,
  multiple,
  size = 'small',
  modifiers,
  actionButtons,
  renderOption,
  renderTags,
  implicitFilters = [],
  replaceUrl = false,
  skipNotSet = true,
  filterOptions,
  disabled,
  forceFetch,
  variant = 'outlined',
  selectAll = false,
  uniqueId,
  customSort,
  disableAutoFilter,
  apiFilters,
}: SimpleFilterFacetSelectProps): JSX.Element => {
  const classes = useAutocompleteStyles()
  const [open, setOpen] = useState(false)
  const { formatMessage } = useIntl()

  const { items, isLoading, refetch } = useFetchFacets({
    ...facetsParam,
    filter: [
      ...implicitFilters,
      [...(facetsParam.filter ?? [])],
      ...(disableAutoFilter
        ? []
        : apiFilters.filter(filter => filter.name !== filterName || uniqueId !== filter?.filters?.[0]?.uniqueId)),
    ].flat(),
    skipNotSet,
    options: {
      enabled: forceFetch || open,
      keepPreviousData: true,
      ...facetsParam.options,
    },
  })

  // Making sure it doesn't crash when label is null.
  const facets =
    items[0]?.items?.map(item => {
      const label = typeof item.label === 'number' ? (item.label as number).toString() : item.label ?? ''
      return { ...item, label }
    }) ?? []

  const sortedFacets = customSort ? facets.sort((a, b) => customSort(a, b)) : facets

  const PaperComponent: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>> = useCallback(
    ({ children }) => (
      <SelectPaper actionButtons={actionButtons} modifiers={modifiers}>
        {children}
      </SelectPaper>
    ),
    [actionButtons, modifiers],
  )

  useEffect(() => {
    // refetching if we have a value for this filter in the url but no options in the select (most likely first load)
    if (
      apiFilters.some(filter => filter.name === filterName && filter?.filters?.[0]?.uniqueId === uniqueId) &&
      !facets.length &&
      facetsParam.options?.enabled !== false
    ) {
      refetch()
    }
  }, [apiFilters, facets, uniqueId])

  const defaultRenderTags = useCallback(
    (value: FacetItem[], getTagProps: AutocompleteGetTagProps) =>
      value?.map((option, index) => {
        if (!option) return null
        const tagProps = getTagProps({ index })
        const { key, ...restTagProps } = tagProps
        return (
          <Tooltip
            key={`${option.value.toString()}-${index}`}
            title={option.label}
            classes={{ tooltipPlacementBottom: classes.tooltip }}
            arrow
          >
            <Chip label={option.label} size="small" {...restTagProps} />
          </Tooltip>
        )
      }),
    [classes.tooltip],
  )

  const selectAllOption: FacetItem = {
    label: formatMessage({ id: 'general.selectAll' }),
    value: selectAllValue,
    count: facets.length,
  }
  const modifiedFacets = selectAll ? [selectAllOption, ...sortedFacets] : sortedFacets

  // here we are making sure we have the correct combination of types
  const truncateProps: { multiple: true; value: FacetItem[] } | { multiple: false; value: FacetItem | null } =
    isMultipleStringValue(value) && multiple
      ? {
          multiple: true,
          value: getMultipleSelectValue(facets, value),
        }
      : {
          multiple: false,
          value: getSingleSelectValue(facets, value as string),
        }

  const defaultRenderOption: FilterFacetSelectCommonProps['renderOption'] = (props, option) => {
    let isSelected = false

    if (Array.isArray(truncateProps.value)) {
      isSelected =
        option.value === selectAllValue
          ? truncateProps.value.length === modifiedFacets.length - 1
          : truncateProps.value.some(facetItem => facetItem.value === option.value)
    } else {
      isSelected = option.value === truncateProps.value?.value
    }

    return (
      <li {...props} key={option.value?.toString()}>
        <OptionIcon selected={isSelected} multiple={multiple} />
        <Typography>{option?.label || ''}</Typography>
        <OptionAdornment option={option} />
      </li>
    )
  }

  return (
    <Box sx={{ position: 'relative', height: 40 }}>
      <Autocomplete
        {...truncateProps}
        disabled={disabled}
        size={size}
        loading={isLoading}
        clearOnBlur={false}
        limitTags={1}
        disableCloseOnSelect={multiple}
        options={modifiedFacets}
        open={open}
        sx={({ zIndex }) => getAutocompleteHeightControlStyles(zIndex)}
        onOpen={(): void => {
          setOpen(true)
        }}
        onClose={(): void => {
          setOpen(false)
        }}
        onFocus={(): void => {
          setOpen(true)
        }}
        onChange={(event, facet) => {
          // Determine if "Select All" is clicked
          const selectAllClicked = isMultipleValue(facet) && facet.some(item => item.value === selectAllValue)

          // Determine if all items are already selected
          const allItemsAlreadySelected = Array.isArray(facet) && facet.length === modifiedFacets.length

          if (selectAllClicked) {
            if (allItemsAlreadySelected) {
              // If all items are already selected and "Select All" is clicked again, uncheck all
              const onChangeMultiple: OnChangeMultiple = onChange as OnChangeMultiple
              onChangeMultiple([], replaceUrl)
            } else {
              // If not all items are selected, select all when "Select All" is clicked
              const onChangeMultiple: OnChangeMultiple = onChange as OnChangeMultiple
              onChangeMultiple(facetToValueArray(modifiedFacets), replaceUrl)
            }
          } else if (isMultipleValue(facet)) {
            const onChangeMultiple: OnChangeMultiple = onChange as OnChangeMultiple
            onChangeMultiple(facetToValueArray(facet), replaceUrl)
          } else {
            const onChangeSingle: OnChangeSingle = onChange as OnChangeSingle
            onChangeSingle(facetToValue(facet as FacetItem), replaceUrl)
          }
        }}
        getOptionLabel={option => option.label}
        renderInput={(params): JSX.Element => (
          <TextField variant={variant} label={fieldLabel} required={required} {...params} />
        )}
        renderOption={renderOption ?? defaultRenderOption}
        classes={{
          clearIndicator: classes.clearIndicator,
          popupIndicator: classes.popupIndicator,
          paper: classes.paper,
        }}
        filterOptions={filterOptions}
        popupIcon={<ExpandMoreIcon />}
        renderTags={renderTags ?? defaultRenderTags}
        PaperComponent={PaperComponent}
      />
    </Box>
  )
}

export default CommonFilterFacetSelect
