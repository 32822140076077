import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ProviderLinkStatus } from '@app/src/types/organizations'
import React from 'react'
import { ResourceCollectionSceneProps } from '../../ResourceCollectionScene'
import BaseProviderScene from './BaseProviderScene'
import { ProviderTabs } from './ProvidersScene'

type MissingContactProvidersSceneProps = {
  relatedContentFilter?: FilterGroup[]
  allowedFilters: string[]
  referralContactCount: number
} & Omit<ResourceCollectionSceneProps, 'title'>

const MissingContactProvidersScene: React.FC<MissingContactProvidersSceneProps> = ({
  relatedContentFilter = [],
  allowedFilters,
  referralContactCount,
  ...props
}) => {
  const implicitFilters = [
    {
      name: 'linkStatus',
      filters: [
        {
          operator: Operators.In,
          value: [ProviderLinkStatus.Created, ProviderLinkStatus.Invited],
        },
      ],
    },
    {
      name: 'referralContact.email',
      filters: [
        {
          operator: Operators.IsNull,
        },
      ],
    },
    ...relatedContentFilter,
  ]

  return (
    <BaseProviderScene
      implicitFilters={implicitFilters}
      allowedFilters={allowedFilters}
      resourceView={ProviderTabs.MissingContacts}
      {...props}
    />
  )
}
export default MissingContactProvidersScene
