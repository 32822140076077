import ResponseHeader from '@app/src/pages/ResourceCollection/Collections/Response/AccessorResponseHeader'
import ResponseRow from '@app/src/pages/ResourceCollection/Collections/Response/AccessorResponseRow'
import { FilterGroup, RESPONSE_LATEST_SUBMITTED_FILTER } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import React from 'react'
import BaseAccessorResponseScene from './BaseAccessorResponseScene'

interface AccessorResponseSceneProps {
  relatedContentFilter?: FilterGroup[]
  embedded?: boolean
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
}

const AccessorResponseScene: React.FC<AccessorResponseSceneProps> = props => {
  return (
    <BaseAccessorResponseScene
      {...props}
      relatedContentFilter={[...(props.relatedContentFilter ?? []), RESPONSE_LATEST_SUBMITTED_FILTER]}
      RowComponent={({ row }) => <ResponseRow row={row} />}
      HeaderComponent={({ toggleSorting, sorting }) => (
        <ResponseHeader toggleSorting={toggleSorting} activeSorting={sorting} />
      )}
    />
  )
}

export default AccessorResponseScene
