import { Permissions, usePermissions } from '@app/src/auth/permissions'
import ParentRowOrFragment from '@app/src/components/Skeleton/ParentRowOrFragment'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

type ResponseItemHeaderProps = SortableHeaderProps & {
  disableTableRow?: boolean
}

const ResponseItemHeader: React.FC<ResponseItemHeaderProps> = ({
  toggleSorting,
  activeSorting,
  disableTableRow,
}): JSX.Element => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const verificationEnabled = hasPermission(Permissions.WRITE_VERIFICATION)

  return (
    <ParentRowOrFragment disableTableRow={disableTableRow}>
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.responseItem.object' })}
        sorting={{ name: 'requestItem.description', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.responseItem.answer' })}
        minWidth={TableCellWidth.EXTRA_LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'reporting.skipQuestion.skippedComment' })}
        minWidth={TableCellWidth.EXTRA_LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.responseItem.periodDisplayName' })}
        sorting={{ name: 'response.request.periodName', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EMPTY}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.responseItem.request' })}
        sorting={{ name: 'response.request.title', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_LARGE}
      />
      {verificationEnabled && (
        <>
          <TableHeaderCell label={formatMessage({ id: 'schemas.responseItem.externalVerificationStatus' })} />
          <TableHeaderCell label={formatMessage({ id: 'schemas.responseItem.externalVerificationComment' })} />
          <TableHeaderCell label={formatMessage({ id: 'schemas.responseItem.internalVerificationStatus' })} />
          <TableHeaderCell label={formatMessage({ id: 'schemas.responseItem.internalVerificationComment' })} />
        </>
      )}
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.responseItem.responseDate' })}
        sorting={{ name: 'createdAt', toggleSorting, activeSorting }}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.request.source' })} />
    </ParentRowOrFragment>
  )
}

export default ResponseItemHeader
