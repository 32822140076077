import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import ListItemSkeleton from '@app/src/components/Skeleton/ListItem'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import useProviderType from '@app/src/hooks/useProviderType'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { AllTabs } from '@app/src/pages/ResourceExplorer/Content'
import { ActorType } from '@app/src/types/product'
import paths from '@app/src/wf-constants/paths'
import { ChevronRightOutlined, ShoppingBagOutlined } from '@mui/icons-material'
import { ListItem, ListItemAvatar, ListItemButton, ListItemText, alpha } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link, generatePath } from 'react-router-dom'

type ProductOverviewProps = {
  providerId: number
}

const ProductOverview: React.FC<ProductOverviewProps> = ({ providerId }) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const providerType = useProviderType()

  const {
    facets: [mappingNodes],
    count,
    isLoading,
  } = useFetchFacets({
    key: FetchKey.MappingNodeFacets,
    endpoint: endpoints.mappingNodeWithFacets,
    facetsParam: [{ name: 'actorType.name' }],
    skipNotSet: true,
    filter: [
      {
        name: 'tier',
        filters: [
          {
            operator: Operators.NotEqualTo,
            value: 0,
          },
        ],
      },
      {
        name: 'providerId',
        filters: [
          {
            operator: Operators.EqualTo,
            value: providerId,
          },
        ],
      },
    ],
  })

  const actorTypes =
    mappingNodes?.map(node => {
      return node.label
    }) ?? []

  if (isLoading) {
    return (
      <ListItemSkeleton
        avatar
        listItemAvatarSx={{ minWidth: 64 }}
        avatarProps={{ size: AvatarSize.XL, sx: ({ palette }) => ({ bgcolor: alpha(palette.common.black, 0.04) }) }}
        primary
        secondary
      />
    )
  }

  return (
    <ListItem disablePadding sx={{ color: 'text.primary' }}>
      <ListItemButton
        disabled={!count}
        component={Link}
        onClick={closeDrawer}
        to={generatePath(paths.resourceExplorer, {
          resourceType: providerType,
          resourceId: providerId,
          activeTabParam: AllTabs.MappingNode,
        })}
      >
        <ListItemAvatar sx={{ minWidth: 64 }}>
          <Avatar size={AvatarSize.XL} sx={({ palette }) => ({ bgcolor: alpha(palette.common.black, 0.04) })}>
            <ShoppingBagOutlined color="primary" />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={formatMessage({ id: 'companyOverview.products' }, { count })}
          secondary={
            count
              ? formatMessage(
                  { id: 'companyOverview.actorTypes' },
                  {
                    hasMultiple: actorTypes.length > 1,
                    actorType: formatMessage({
                      id: `schemas.mappingNode.actorTypeValues.${actorTypes[0] ?? ActorType.NotSet}`,
                    }),
                    count: actorTypes.length - 1,
                  },
                )
              : ''
          }
        />

        <ChevronRightOutlined color="action" />
      </ListItemButton>
    </ListItem>
  )
}

export default ProductOverview
