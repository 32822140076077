import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import Table from '@app/src/components/Table'
import { ContactExportColumns } from '@app/src/export-columns/contact'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Contact } from '@app/src/types/resourceExplorer'
import { ResourceTypes } from '@app/src/wf-constants'
import React from 'react'
import { useIntl } from 'react-intl'
import ContactsFilters from '../Filters/ContactsFilters'
import ResourceCollectionScene from '../ResourceCollectionScene'
import ContactHeader from './Contact/ContactHeader'
import ContactRow from './Contact/ContactRow'

interface Props {
  relatedContentFilter?: FilterGroup[]
  embedded?: boolean
}

const allowedFilters = [
  'user.id',
  'user.name',
  'creatorOrganization.id',
  'user.email',
  'user.phoneNumber',
  'user.position',
]

const ContactsCollection = ({ relatedContentFilter = [], embedded }: Props): JSX.Element => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const { openDrawer } = useDrawer()

  const rawExportPayload = {
    filter: relatedContentFilter,
    sort: sorting,
    include: ['user', 'creatorOrganization', 'creatorOrganization.image', 'user.image'],
  }

  const payload = {
    ...rawExportPayload,
    filter: [...rawExportPayload.filter, ...userFilters],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<Contact>({
    key: FetchKey.Contact,
    endpoint: endpoints.contactsCollection,
    payload,
  })

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'resourceTypes.contact' })}
        actionButtons={[
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Contact}
                  count={count}
                  userFilter={userFilters}
                  exportColumns={ContactExportColumns}
                  rawExportPayload={rawExportPayload}
                />,
              ),
            disabled: isLoading || count === 0,
          },
        ]}
        filter={
          <ContactsFilters
            implicitFilters={relatedContentFilter}
            allowedFilters={allowedFilters}
            resetPage={resetPage}
          />
        }
        embedded={embedded}
      >
        <Table<Contact>
          RowComponent={ContactRow}
          HeaderComponent={() => <ContactHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
          data={items}
          isLoading={isLoading || isFetching}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </ResourceCollectionScene>
    </>
  )
}

export default ContactsCollection
