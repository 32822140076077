import { ExportColumn } from '@app/src/types/export'

export const MappingNodeExportColumns: ExportColumn[] = [
  {
    translationId: 'schemas.mappingNode.productId',
    exportPath: 'productId',
  },
  {
    translationId: 'schemas.mappingNode.fromProviderId',
    exportPath: 'providerId',
  },
  {
    translationId: 'schemas.mappingNode.toProviderId',
    exportPath: 'parentNode.providerId',
  },
  {
    translationId: 'schemas.mappingNode.product',
    exportPath: 'product.name',
  },
  {
    translationId: 'schemas.product.gtin',
    exportPath: 'product.gtin',
  },
  {
    translationId: 'schemas.product.productNumber',
    exportPath: 'product.productNumber',
  },
  {
    translationId: 'schemas.product.productCustomId',
    exportPath: 'product.productCustomId',
  },
  {
    translationId: 'schemas.product.purchaseOrder',
    exportPath: 'product.purchaseOrder',
  },
  {
    translationId: 'schemas.mappingNode.tier',
    exportPath: 'tier',
  },
  {
    translationId: 'schemas.mappingNode.from',
    exportPath: 'name',
  },
  {
    translationId: 'schemas.mappingNode.activityFrom',
    exportPath: 'actorType.name',
  },
  {
    translationId: 'schemas.mappingNode.to',
    exportPath: 'parentNode.name',
  },
  {
    translationId: 'schemas.mappingNode.activityTo',
    exportPath: 'parentNode.actorType.name',
  },
]
