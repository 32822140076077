import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import useOrganizationSettings from '@app/src/hooks/useOrganizationSettings'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Unit } from '@app/src/types/resourceExplorer'

const UNIT_CACHE_TIME = 12 * 60 * 60 * 1000

interface UseOrganizationCurrencyProps {
  enabled?: boolean
}

const useOrganizationCurrency = ({ enabled = true }: UseOrganizationCurrencyProps = {}): {
  organizationCurrency?: Unit
  isLoading: boolean
} => {
  const { get, isLoading: isLoadingOrganizationSettings } = useOrganizationSettings({ enabled })
  const currencyUnitId = get('currencyUnitId')

  const { items: units, isLoading: isLoadingUnits } = useFetchCollectionWithPost<Unit>({
    key: FetchKey.Unit,
    endpoint: endpoints.units,
    payload: {
      filter: [
        {
          name: 'id',
          filters: [{ value: currencyUnitId, operator: Operators.EqualTo }],
        },
      ],
      include: [],
      pagination: { itemsPerPage: 1, pageNumber: 1 },
    },
    options: {
      enabled: enabled && Boolean(currencyUnitId),
      refetchOnMount: false,
      cacheTime: UNIT_CACHE_TIME,
      staleTime: UNIT_CACHE_TIME,
    },
  })

  return { organizationCurrency: units?.[0], isLoading: isLoadingOrganizationSettings || isLoadingUnits }
}

export default useOrganizationCurrency
