import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import LoadingButton from '@app/src/components/LoadingButton'
import { useResponseItemContext } from '@app/src/context/ResponseItemContext'
import { ResponseItemView } from '@app/src/context/ResponseItemContextProvider'
import useFetchFileAiSuggestions, { AiSuggestion } from '@app/src/pages/Questionnaire/hooks/useFetchFileAiSuggestions'
import ScrapedAiSuggestionDrawer from '@app/src/pages/Questionnaire/Provider/Report/SuggestedAnswers/ScrapedAiSuggestionDrawer'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { RequestItem } from '@app/src/types/resourceExplorer'
import { QuestionTypes } from '@app/src/wf-constants'
import { AutoAwesomeOutlined, CheckCircleOutline, HistoryOutlined } from '@mui/icons-material'
import React from 'react'
import { useIntl } from 'react-intl'
import PreviousAnswerDrawer from './PreviousAnswerDrawer'
import FileAiSuggestionDrawer from './FileAiSuggestionDrawer'

interface SuggestAnswerButtonProps {
  requestItem: RequestItem
  questionType: string
  onApplyPreviousAnswer: () => Promise<void>
  onApplyAiSuggestion: (value: string) => void
  isPreview: boolean
  isLoadingCloneFile?: boolean
}

const suggestionIsSet = (suggestion: AiSuggestion | undefined, questionType: string): suggestion is AiSuggestion => {
  if (!suggestion) return false
  if (questionType?.toLowerCase() === QuestionTypes.Options.toLowerCase()) {
    return Boolean(suggestion.selectedOptions?.length)
  }

  return Boolean(suggestion?.response?.trim().length)
}

const SuggestAnswerButton: React.FC<SuggestAnswerButtonProps> = ({
  requestItem,
  questionType,
  onApplyPreviousAnswer,
  onApplyAiSuggestion,
  isPreview,
  isLoadingCloneFile,
}) => {
  const { request, showPreviousAnswerSuggestions } = useReport()
  const { formatMessage } = useIntl()
  const { hideInputField, sectionNumber, itemNumber, previousAnswer, responseItemView } = useResponseItemContext()
  const { openDrawer, closeDrawer } = useDrawer()

  const { fetchedData: fileAiSuggestion, isLoadingFileAiSuggestions } = useFetchFileAiSuggestions(
    requestItem,
    questionType,
    isPreview,
    request?.periodName,
  )

  if (hideInputField) return null

  const fileBasedAiSuggestionsExist = suggestionIsSet(fileAiSuggestion, questionType) && !isLoadingFileAiSuggestions
  const aiScrapedAnswersExists = previousAnswer?.isScraped ?? false

  if (previousAnswer && showPreviousAnswerSuggestions && !aiScrapedAnswersExists) {
    if (responseItemView === ResponseItemView.SuggestedAnswerAccepted)
      return (
        <LoadingButton
          size="small"
          variant="text"
          startIcon={<CheckCircleOutline />}
          loading={isLoadingCloneFile}
          sx={{ mx: 4 }}
        >
          {formatMessage({ id: 'suggestedAnswers.previousAnswerApplied' })}
        </LoadingButton>
      )

    return (
      <LoadingButton
        sx={{ mx: 4 }}
        size="small"
        variant="contained"
        startIcon={<HistoryOutlined />}
        loading={isLoadingCloneFile}
        onClick={() =>
          openDrawer(
            <PreviousAnswerDrawer
              previousAnswer={previousAnswer}
              requestItem={requestItem}
              questionType={questionType}
              onApply={onApplyPreviousAnswer}
              closeDrawer={closeDrawer}
              sectionNumber={sectionNumber}
              itemNumber={itemNumber}
            />,
          )
        }
      >
        {formatMessage({ id: 'suggestedAnswers.viewPreviousAnswer' })}
      </LoadingButton>
    )
  }

  if (fileBasedAiSuggestionsExist || aiScrapedAnswersExists) {
    if (responseItemView === ResponseItemView.SuggestedAnswerAccepted)
      return (
        <LoadingButton
          size="small"
          loading={isLoadingCloneFile}
          variant="text"
          startIcon={<CheckCircleOutline />}
          sx={({ palette }) => ({
            color: palette.communication.dark,
            mx: 4,
          })}
        >
          {formatMessage({ id: 'suggestedAnswers.aiAnswerApplied' })}
        </LoadingButton>
      )

    return (
      <LoadingButton
        size="small"
        loading={isLoadingCloneFile}
        variant="contained"
        startIcon={<AutoAwesomeOutlined />}
        onClick={() => {
          if (fileBasedAiSuggestionsExist && fileAiSuggestion) {
            openDrawer(
              <FileAiSuggestionDrawer
                suggestion={fileAiSuggestion}
                requestItem={requestItem}
                questionType={questionType}
                onApply={onApplyAiSuggestion}
                closeDrawer={closeDrawer}
                sectionNumber={sectionNumber}
                itemNumber={itemNumber}
              />,
            )
          } else if (previousAnswer) {
            openDrawer(
              <ScrapedAiSuggestionDrawer
                previousAnswer={previousAnswer}
                requestItem={requestItem}
                questionType={questionType}
                onApply={onApplyPreviousAnswer}
                closeDrawer={closeDrawer}
                sectionNumber={sectionNumber}
                itemNumber={itemNumber}
              />,
            )
          }
        }}
        sx={({ palette }) => ({
          backgroundColor: palette.communication.light,
          color: palette.communication.dark,
          mx: 4,
          ':hover': {
            backgroundColor: palette.communication.main,
          },
        })}
      >
        {formatMessage({ id: 'suggestedAnswers.viewAiSuggestion' })}
      </LoadingButton>
    )
  }

  return null
}
export default SuggestAnswerButton
