import { ExportColumn } from '@app/src/types/export'

export const ProductExportColumns: ExportColumn[] = [
  {
    translationId: 'general.id',
    exportPath: 'id',
  },
  {
    translationId: 'schemas.product.name',
    exportPath: 'name',
  },
  {
    translationId: 'schemas.product.providerId',
    exportPath: 'providerId',
  },
  {
    translationId: 'schemas.categorization.supplier',
    exportPath: 'provider.name',
  },
  {
    translationId: 'schemas.product.gtin',
    exportPath: 'gtin',
  },
  {
    translationId: 'schemas.product.productNumber',
    exportPath: 'productNumber',
  },
  {
    translationId: 'schemas.product.productCustomId',
    exportPath: 'productCustomId',
  },
  {
    translationId: 'schemas.product.purchaseOrder',
    exportPath: 'purchaseOrder',
  },
  {
    translationId: 'schemas.product.countryOfOrigin',
    exportPath: 'country.name',
  },
  {
    translationId: 'schemas.product.productStatus',
    exportPath: 'productStatus',
  },
  {
    translationId: 'schemas.product.mappingStatus',
    exportPath: 'mappingStatus',
  },
  {
    translationId: 'schemas.product.createdAt',
    exportPath: 'createdAt',
  },
  {
    translationId: 'schemas.product.updatedAt',
    exportPath: 'updatedAt',
  },
]
