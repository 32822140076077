import GroupBySelectorContextProvider from '@app/src/context/GroupBySelectorContextProvider'
import DataHubInsightsScene, {
  CATEGORY_ID_FILTER_NAME,
} from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataHubInsightsScene'
import DataInsightsModalContextProvider from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataInsightsModalContextProvider'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import React from 'react'

type StatisticsProps = {
  tabs: ResourceCollectionSceneProps['tabs']
}

export const dataHubAllowedFilters = [
  'requestItem.questionId',
  'requestItem.template.options.id',
  'response.request.questionnaireTemplateId',
  'response.request.periodName',
  'requestItem.template.id',
  'response.request.subscriptions.target.country.id',
  'response.request.subscriptions.target.organizationId',
  'response.request.template.id',
  'requestItem.section.template.id',
  'requestItem.section.id',
  'response.submittedAt',
  'cannotAnswer',
  'answer',
  'response.request.subscriptions.target.providerApprovalStatus',
  'response.request.subscriptions.target.priority',
  'response.request.subscriptions.target.finalRiskRating',
  'response.request.subscriptions.target.activityStatus',
  'response.request.subscriptions.target.supplierUsage',
  'response.request.subscriptions.target.tier',
  'response.request.subscriptions.target.ownerUserId',
  'response.request.subscriptions.target.categoryOptions.id',
  'response.request.template.tags.tag',
  'response.verifications.externalVerificationStatus',
  'response.verifications.internalVerificationStatus',
  'response.request.subscriptions.target.mappingNodes.actorType.id',
  'response.request.subscriptions.target.mappingNodes.tier',
  'response.request.subscriptions.target.spends.spendClassification',
  'response.request.subscriptions.target.spends.periodName',
  'response.request.subscriptions.target.assessments.totalLevel',
  'response.request.subscriptions.target.assessments.intervalType',
  'response.request.subscriptions.target.assessments.section1Level',
  'response.request.subscriptions.target.assessments.section2Level',
  'response.request.subscriptions.target.assessments.section3Level',
  'response.request.subscriptions.target.assessments.section4Level',
  'response.request.subscriptions.target.assessments.section5Level',
  'response.request.subscriptions.target.assessments.assessmentTemplate.name',
  'response.request.subscriptions.targetProviderId',
  'isScraped',
  CATEGORY_ID_FILTER_NAME,
]

const DataInsights: React.FC<StatisticsProps> = ({ tabs }) => {
  return (
    <GroupBySelectorContextProvider>
      <DataInsightsModalContextProvider>
        <DataHubInsightsScene tabs={tabs} />
      </DataInsightsModalContextProvider>
    </GroupBySelectorContextProvider>
  )
}

export default DataInsights
