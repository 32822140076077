import TextField from '@app/src/components/Ui/TextField'
import { ExcelExportColumnsJson } from '@app/src/types/resourceExplorer'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import UndoIcon from '@mui/icons-material/Undo'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { cleanColumnPath } from './DrawerViewExport'

interface ExportColumnItemProps {
  column: ExcelExportColumnsJson
  onToggleColumn: (event: React.ChangeEvent<HTMLInputElement>, path: string) => void
  isEditingName: boolean
  originalColumnName: string
}

const ExportColumnItem: React.FC<ExportColumnItemProps> = ({
  column,
  onToggleColumn,
  isEditingName,
  originalColumnName,
}) => {
  const { palette, transitions: transitionsTheme } = useTheme()
  const { formatMessage } = useIntl()
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition: transitionSortable,
    isDragging,
  } = useSortable({
    id: column.path,
  })

  const { register, setValue, watch } = useFormContext()
  const inputFieldName = cleanColumnPath(column.path)
  const inputValue = watch(inputFieldName)

  const handleReset = useCallback(() => {
    setValue(inputFieldName, originalColumnName)
  }, [setValue, inputFieldName, originalColumnName])

  const sxStyles = useMemo(
    (): SxProps => ({
      boxShadow: isDragging ? undefined : `0 0 0 1px ${palette.grey[300]}`,
      zIndex: isDragging ? 1 : 0,
      position: isDragging ? 'relative' : undefined,
      cursor: isDragging ? 'grabbing' : undefined,
      transform: transform ? CSS.Transform.toString({ x: 0, y: transform.y, scaleX: 1, scaleY: 1 }) : '',
      transition: [
        transitionsTheme.create(['margin', 'borderRadius'], {
          duration: transitionsTheme.duration.short,
        }),
        transitionSortable,
      ]
        .filter(Boolean)
        .join(','),
      borderRadius: 0,
    }),
    [palette, isDragging, transform, transitionSortable],
  )

  return (
    <Paper ref={setNodeRef} sx={sxStyles} elevation={isDragging ? 6 : 0}>
      <Box
        pl={3}
        pr={1}
        py={1}
        sx={{
          pointerEvents: isDragging ? 'none' : undefined,
        }}
      >
        <Box display="flex" alignItems="center" width="100%">
          {isEditingName ? (
            <Box width="100%" display="flex" alignItems="center" pb={1} pr={1}>
              <Box sx={{ flexGrow: 1 }}>
                <TextField
                  name={inputFieldName}
                  defaultValue={inputValue ?? column.displayName}
                  label={originalColumnName}
                  inputRef={register}
                  autoComplete="false"
                />
              </Box>
              <Tooltip
                arrow
                placement="left"
                title={originalColumnName ? formatMessage({ id: 'export.resetColumnNameTooltip' }) : undefined}
              >
                <IconButton
                  onClick={handleReset}
                  sx={{ ml: 1, visibility: inputValue === originalColumnName ? 'hidden' : 'visible' }}
                >
                  <UndoIcon sx={{ transform: 'scale(0.8)', transformOrigin: 'center' }} />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <>
              <FormControlLabel
                control={<Checkbox checked={column.isChecked} onChange={event => onToggleColumn(event, column.path)} />}
                sx={{ marginRight: '8px' }}
                label={
                  <Box pl={1} py={1} sx={{ lineHeight: '1.5em', wordBreak: 'break-word' }}>
                    {column.displayName !== originalColumnName && (
                      <Typography variant="body2" sx={{ color: palette.grey[500] }}>
                        {originalColumnName}
                      </Typography>
                    )}
                    {column.displayName}
                  </Box>
                }
              />
              <Box sx={{ marginLeft: 'auto', flexShrink: 0 }}>
                <IconButton sx={{ cursor: isDragging ? 'grabbing' : 'grab' }} {...listeners} {...attributes}>
                  <DragHandleIcon />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Paper>
  )
}

export default ExportColumnItem
