import { Periods } from '@app/src/components/CreateNewRequestModal/PeriodSelector/PeriodSelector'
import {
  CategoryOption,
  FinalRiskRating,
  ProviderActivityStatus,
  ProviderPriority,
  SupplierUsage,
} from '@app/src/types/categories'
import {
  Contact,
  Country,
  Image,
  ProviderRisk,
  ReferralContact,
  ResourceType,
  RiskSource,
  User,
} from '@app/src/types/resourceExplorer'
import { ResourceTypes, Solutions, SpendClassification } from '@app/src/wf-constants'
import Assessment from './assessment'
import { MappingNode } from '@app/src/types/product'

export enum ProviderApprovalStatus {
  APPROVED = 'Approved',
  UNDER_OBSERVATION = 'UnderObservation',
  APPROVED_AFTER_CONSIDERATION = 'ApprovedAfterConsideration',
  NOT_APPROVED = 'NotApproved',
  NOT_SELECTED = 'NotSelected',
}

export enum ProviderActivityType {
  CHANGE_RISK_ASSESSMENT = 'ChangeRiskAssessment',
  CHANGE_APPROVAL_STATUS = 'ChangeApprovalStatus',
  CHANGE_BASELINE_ASSESSMENT_LEVEL = 'ChangeBaselineAssessmentLevel',
  CHANGE_AUTOMATED_ASSESSMENT_PERCENTAGE = 'ChangeAutomatedAssessmentPercentage',
}

export interface SimpleOrganization extends ResourceType {
  id: number
  createdAt: string
  name: string
  registrationNumber: string
  vatNumber: string
  glnNumber: string
  websiteAddress: string
  primaryAddress: string
  address?: string
  image: Image
  imageId?: number
  creatorOrganizationId?: number
  creatorUserId?: number
  type?: string
  countryId?: number
  industryNaceCode?: NaceCode
  industryId?: number
  subIndustryNaceCode?: NaceCode
  subIndustryId?: number
  companySizeRange?: CompanyRanges
  revenueRange?: CompanyRanges
}

export interface AssignedSolution {
  accountType: string
  configuration: { templateIds: number[]; indicatorIds: number[] }
  createdAt: string
  creatorOrganizationId: number
  creatorUserId: number
  id: number
  organization: Partial<Organization>
  organizationId: number
  solutionType: string
}

export interface UsersOrganization {
  organizationId: number
  solutions: { type: string; role: string }[]
}

export interface OrganizationCountriesExposure {
  country: Country
  variant: 'Operation' | 'Subsupplier'
}

export interface Spend {
  id: number
  amount: number
  percentage: number
  periodName: string
  periodType: Periods
  spendClassification?: SpendClassification
}

export interface Organization extends SimpleOrganization {
  country: Country
  locations?: Location[]
  assignedSolutions?: AssignedSolution[]
  organizationsCountriesExposure: OrganizationCountriesExposure[]
}

export interface Provider extends Organization {
  customId?: string
  targetOrganizationId: number
  organization: GenericOrganization
  linked: boolean
  linkStatus: ProviderLinkStatus
  deletedAt?: string
  contacts?: Contact[]
  ownerUser?: User
  ownerUserId?: number
  organizationId: number
  type: typeof ResourceTypes.Investee | typeof ResourceTypes.Supplier | typeof ResourceTypes.Company
  providerApprovalStatus?: ProviderApprovalStatus
  providerApprovalComment?: string
  providerApprovalSetAt?: string
  alreadyLinkedOrganization?: boolean
  categoryOptions?: CategoryOption[]
  categoryOptionIds?: number[]
  activityStatus?: ProviderActivityStatus
  finalRiskRating?: FinalRiskRating
  priority?: ProviderPriority
  supplierUsage?: SupplierUsage
  tier?: number
  riskPrioritization?: ProviderRisk[]
  referralContact?: ReferralContact
  duns?: string
  assessments?: Assessment[]
  spends?: Spend[]
  mappingNodes?: MappingNode[]
}

export interface ProviderActivity {
  id: number
  creatorUser: User
  createdAt: string
  activityType: ProviderActivityType
  comments?: Comment[]
  to: string
  from: string
}

export interface UpdateProviderFinalRiskRating {
  providerIds: number[]
  comment: string
  newFinalRiskRating: FinalRiskRating
}

export interface Comment {
  text: string
  id: number
}

export interface Investee extends Provider {
  type: typeof ResourceTypes.Investee
}

export interface Supplier extends Provider {
  type: typeof ResourceTypes.Supplier
}

export interface Company extends Provider {
  type: typeof ResourceTypes.Company
}

export interface RiskTableView {
  id: number
  parentObject: GenericOrganization
  risks: RiskSource[]
}

export type GenericOrganization = Investee | Supplier | Company

export interface NaceCode {
  level: number
  id: number
  parentId: number
  parentDescription?: string
  description: string
  code: string
  items?: NaceCode[]
}

export interface Location {
  address?: string
  createdAt: string
  creatorOrganizationId: number
  creatorUserId: number
  id: number
  lat?: number
  lng?: number
  latitude?: number
  longitude?: number
  objectType: string
  targetObjectId: number
  type: string
  formattedAddress?: string
  region?: string
  subRegion?: string
  city?: string
  district?: string
  countryId: number
}

export interface ClaimLocation {
  address: string
  country: string
  region: string
  subRegion: string
  city: string
  district: string
  lat: number
  lng: number
}

export enum CompanyRanges {
  MICRO = 'micro',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  GIANT = 'giant',
}

export interface ClaimDetails {
  name: string
  websiteAddress?: string
  industryId?: number
  subIndustryId?: number
  tags?: string
  address?: string
  location: Partial<ClaimLocation>
  country?: string
  vatNumber: string
  jobTitle?: string
  phoneNumber?: string
  logo: string
  referralCode?: string
  referralCompanyName?: string
  registrationNumber?: string
  companySizeRange?: CompanyRanges
  revenueRange?: CompanyRanges
}
export interface Claim {
  id: number
  details: ClaimDetails
  externalSourceObject?: unknown
  claimStatus?: string
  objectType?: string
  creatorOrganization?: Organization
  creatorUser?: User
  createdAt?: string
  creatorOrganizationId?: number
  creatorUserId?: number
  type?: string
  reviewReason?: string
}

export interface ClaimUpdate {
  claimIds: number[]
  claimStatus: string
}

export interface ScheduledRequests {
  templateId: number
  periodStartsAt: string
  periodEndsAt: string
  dueAt: string
}

export interface ReferralCode {
  id: number
  creatorOrganizationName: string
  code: string
  creatorOrganizationId: number
  solutionType: keyof typeof Solutions
  scheduledRequests?: ScheduledRequests[]
  country?: Country
}

export interface ProviderOrganizationResult {
  providerId: number
  organizations: Provider[]
}

export enum ProviderLinkStatus {
  Created = 'Created',
  Connected = 'Connected',
  Invited = 'Invited',
}

export enum MatchType {
  ExactMatch = 'ExactMatch',
  ApproximateMatch = 'ApproximateMatch',
}

export interface OrganizationDuplicateMatch {
  matchType: MatchType
  organization: Organization
}

export function isProvider(item: Organization | Provider): item is Provider {
  return typeof (item as Provider).linked !== 'undefined'
}
