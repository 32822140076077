import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import ChartTypeSelector, {
  ChartType,
} from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { AnswersGroupingType } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { QuestionTypes } from '@app/src/wf-constants'
import React, { useEffect } from 'react'
import { StatisticsForQuestionPerGroup } from '../../DataHubScene'

interface ChartTypeSelectorProps {
  selectedChartType: ChartType
  setSelectedChartType: React.Dispatch<React.SetStateAction<ChartType>>
  statistics: StatisticsForQuestionPerGroup
  isSummaryView?: boolean
}

const getPossibleChartTypes = (
  statistics: StatisticsForQuestionPerGroup,
  isSummaryView: boolean,
  groupBy: AnswersGroupingType,
) => {
  const barChartTypes = [ChartType.Vertical, ChartType.Horizontal]
  const isGroupByPeriodName = groupBy === AnswersGroupingType.PeriodName
  const isSinglePeriod = isGroupByPeriodName && statistics.resultForGroups.length === 1

  if (isSummaryView) {
    return {
      possible: barChartTypes,
      default: isGroupByPeriodName ? ChartType.Vertical : ChartType.Horizontal,
    }
  }

  const isNumberQuestion = statistics.questionType.toLowerCase() === QuestionTypes.Number.toLowerCase()
  const isOptionQuestion = statistics.questionType.toLowerCase() === QuestionTypes.Options.toLowerCase()

  if (isNumberQuestion && !isSinglePeriod) {
    return {
      possible: isGroupByPeriodName ? [ChartType.Line, ...barChartTypes] : barChartTypes,
      default: isGroupByPeriodName ? ChartType.Line : ChartType.Vertical,
    }
  }

  if (!isOptionQuestion) {
    return { possible: barChartTypes, default: ChartType.Vertical }
  } else if (!isGroupByPeriodName) {
    return { possible: barChartTypes, default: ChartType.Horizontal }
  }

  if (isSinglePeriod) {
    return { possible: [ChartType.Pie, ...barChartTypes], default: ChartType.Pie }
  }

  return { possible: barChartTypes, default: ChartType.Vertical }
}

const DataHubChartTypeSelector: React.FC<ChartTypeSelectorProps> = ({
  selectedChartType,
  setSelectedChartType,
  statistics,
  isSummaryView = false,
}) => {
  const { groupBy } = useGroupBySelector()
  const { possible: possibleChartTypes, default: defaultChartType } = getPossibleChartTypes(
    statistics,
    isSummaryView,
    groupBy,
  )

  useEffect(() => {
    setSelectedChartType(defaultChartType)
  }, [defaultChartType, setSelectedChartType])

  return (
    <ChartTypeSelector
      setSelectedChartType={setSelectedChartType}
      selectedChartType={selectedChartType}
      allowedChartTypes={possibleChartTypes}
    />
  )
}

export default DataHubChartTypeSelector
