import BaseProviderResponseScene from '@app/src/pages/ResourceCollection/Collections/ManageRequests/Response/BaseProviderResponseScene'
import ProviderResponseHeader from '@app/src/pages/ResourceCollection/Collections/Response/ProviderResponseHeader'
import ProviderResponseRow from '@app/src/pages/ResourceCollection/Collections/Response/ProviderResponseRow'
import { RESPONSE_IS_SCRAPED } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import React from 'react'

interface ProviderScrapedResponseSceneProps {
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
}

const ProviderScrapedResponseScene: React.FC<ProviderScrapedResponseSceneProps> = props => {
  return (
    <BaseProviderResponseScene
      {...props}
      relatedContentFilter={[RESPONSE_IS_SCRAPED]}
      RowComponent={({ row }) => <ProviderResponseRow row={row} />}
      HeaderComponent={({ toggleSorting, sorting }) => (
        <ProviderResponseHeader toggleSorting={toggleSorting} activeSorting={sorting} />
      )}
    />
  )
}

export default ProviderScrapedResponseScene
