import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { palette } from '@app/src/theme/palette'
import { DescriptionOutlined } from '@mui/icons-material'
import { ButtonBase, Stack, Tooltip, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useState } from 'react'
import TextTruncate, { TextTruncateProps } from 'react-text-truncate'
import ParentCellOrFragment from './ParentCellOrFragment'

export interface RequestCellProps {
  title: string
  onClick?: () => void
  imageUrl?: string
  tooltipText?: string
  maxRows?: TextTruncateProps['line']
  disableCell?: boolean
  isLoading?: boolean
  hideAvatar?: boolean
  isScraped?: boolean | false
}

const RequestCell: React.FC<RequestCellProps> = ({
  title,
  imageUrl,
  onClick,
  tooltipText,
  maxRows = 1,
  disableCell,
  isLoading,
  hideAvatar,
  isScraped,
}) => {
  const [truncated, setTruncated] = useState(false)

  const handleTruncate = () => {
    setTruncated(true)
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Tooltip title={truncated ? title : tooltipText}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          component={onClick && !isLoading ? ButtonBase : 'span'}
          onClick={onClick && !isLoading ? onClick : undefined}
          data-testid="request-cell"
          width="100%"
        >
          {isLoading ? (
            <CircularProgress size={20} />
          ) : hideAvatar ? null : isScraped ? (
            <DescriptionOutlined sx={{ color: palette.communication.dark }} />
          ) : (
            <Avatar src={imageUrl} size={AvatarSize.Small} sx={{ background: 'white' }}>
              <DescriptionOutlined color="primary" fontSize="medium" />
            </Avatar>
          )}
          <Typography
            variant="body1"
            align="left"
            sx={{ '&:hover': { textDecoration: onClick && !isLoading ? 'underline' : 'none' } }}
            flexGrow={1}
            component="span"
          >
            <TextTruncate line={maxRows} text={title} onTruncated={handleTruncate} data-testid="request-cell-text" />
          </Typography>
        </Stack>
      </Tooltip>
    </ParentCellOrFragment>
  )
}

export default RequestCell
