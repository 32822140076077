import AnswerDisplay from '@app/src/pages/Questionnaire/Provider/Report/AnswerDisplay'
import EditResponseWithPreviousCorrectionNeededAnswer from '@app/src/pages/Questionnaire/Provider/Report/EditResponseWithPreviousCorrectionNeededAnswer'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { QUESTION_TYPE_OPTIONS } from '@app/src/types/reporting'
import {
  RequestItem as RequestItemType,
  ResponseDraftStatus,
  ResponseItemWithPreviousCorrectionNeededItem,
} from '@app/src/types/resourceExplorer'
import { Stack } from '@mui/material'
import React from 'react'
import EditResponse, { PreviewMode } from './EditResponse'

type RequestItemProps = {
  item: RequestItemType
  responseItem?: ResponseItemWithPreviousCorrectionNeededItem
  sectionNumber: number
  itemNumber: number
}

const getQuestionType = (item: RequestItemType) => {
  const questionType = item?.questionType?.name?.toLowerCase() ?? ''

  if (questionType) {
    return questionType
  }

  const questionTypeById = item?.questionTypeId ?? 0

  if (questionTypeById) {
    return QUESTION_TYPE_OPTIONS[questionTypeById - 1].label.toLowerCase()
  }

  return ''
}

const RequestItemContent: React.FC<RequestItemProps> = ({ item, responseItem, sectionNumber, itemNumber }) => {
  const { reportStatus, isPreview, request } = useReport()
  const questionType = getQuestionType(item)

  if (reportStatus !== ResponseDraftStatus.Draft) {
    if (!responseItem) return null
    return (
      <Stack
        px={4}
        pb={4}
        pt={2}
        bgcolor={reportStatus === ResponseDraftStatus.Scraped ? 'communication.light' : undefined}
      >
        <AnswerDisplay item={responseItem} questionType={questionType} unitSymbol={item?.unit?.symbol} />
      </Stack>
    )
  }

  if (responseItem?.previousCorrectionNeededAnswer)
    return (
      <EditResponseWithPreviousCorrectionNeededAnswer
        previousCorrectionNeededAnswer={responseItem?.previousCorrectionNeededAnswer}
        item={responseItem}
        requestItem={item}
        questionType={questionType}
        unitSymbol={item?.unit?.symbol}
        sectionNumber={sectionNumber}
        itemNumber={itemNumber}
      />
    )

  return (
    <EditResponse
      responseItemId={responseItem?.id}
      isAnswered={responseItem?.isAnswered}
      questionType={questionType}
      requestItem={item}
      previewMode={isPreview ? PreviewMode.Regular : undefined}
      periodName={request?.periodName}
      sectionNumber={sectionNumber}
      itemNumber={itemNumber}
    />
  )
}

export default RequestItemContent
