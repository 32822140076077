import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCount } from '@app/src/api/fetchHooks'
import { Permissions, usePermissions } from '@app/src/auth/permissions'
import { ActionButton } from '@app/src/components/ActionButtons'
import CreateNewRequestModal from '@app/src/components/CreateNewRequestModal'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { ProvidersModalPurpose, useProvidersModal } from '@app/src/context/ProvidersModalContext'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import usePrevious from '@app/src/hooks/previous'
import { useQueryState } from '@app/src/hooks/queryState'
import AccessorResponseScene from '@app/src/pages/ResourceCollection/Collections/ManageRequests/Response/AccessorResponseScene'
import { DEFAULT_FILTER_NAME, FilterInterface } from '@app/src/pages/ResourceCollection/Filters/Filters'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { InquiryStatus, RequestStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import paths from '@app/src/wf-constants/paths'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, Route, Switch, useLocation, useParams } from 'react-router-dom'
import { Operators } from '../../Filters/useFilters'
import ConnectProvidersModal from '../Provider/ConnectProviders/ConnectProvidersModal'
import RemindProvidersModal from '../Provider/ConnectProviders/RemindProvidersModal'
import AccessorRequestsScene from './AccessorRequestsScene'
import OverviewManageRequestsScene from './Overview/OverviewManageRequestsScene'
import ProviderRequestsScene from './ProviderRequestScene'
import AccessorScrapedResponseScene from './Response/AccessorScrapedResponseScene'
import ProviderResponseScene from './Response/ProviderResponseScene'
import ProviderScrapedResponseScene from './Response/ProviderScrapedResponseScene'

interface ManageRequestsSceneParams {
  view: ViewTypeName
}

export enum ViewTypeName {
  Requests = 'requests',
  ScreenedRequests = 'screened-requests',
  Responses = 'responses',
  Overview = 'overview',
}

const VIEWS_WITH_REQUEST_FILTERS = [ViewTypeName.Overview, ViewTypeName.Requests]
const VIEWS_WITH_RESPONSE_FILTERS = [ViewTypeName.ScreenedRequests, ViewTypeName.Responses]

const transformStatusFromResponseToInquiry = (value: string): string => {
  switch (value) {
    case RequestStatus.ResponseSubmitted:
      return InquiryStatus.Submitted

    case RequestStatus.ResponseApproved:
      return InquiryStatus.Approved

    case RequestStatus.ResponseCorrectionNeeded:
      return InquiryStatus.CorrectionNeeded

    default:
      return value
  }
}

const transformStatusFromInquiryToResponse = (value: string): string => {
  switch (value) {
    case InquiryStatus.Submitted:
      return RequestStatus.ResponseSubmitted

    case InquiryStatus.Approved:
      return RequestStatus.ResponseApproved

    case InquiryStatus.CorrectionNeeded:
      return RequestStatus.ResponseCorrectionNeeded

    default:
      return value
  }
}

const transformToInquiryFilter = (filter: FilterInterface): FilterInterface => {
  switch (filter.name.toLowerCase()) {
    case 'request.requeststatus':
      return {
        ...filter,
        name: 'status',
        value: Array.isArray(filter.value)
          ? filter.value
              .filter(value => value !== RequestStatus.ResponseScraped)
              .map(value => transformStatusFromResponseToInquiry(value))
          : transformStatusFromResponseToInquiry(filter.value),
      }
    case 'verifications.internalverificationstatus':
      return {
        ...filter,
        name: 'request.responseInternalStatus',
      }
    default:
      return {
        ...filter,
        name: filter.name.startsWith('request.') ? filter.name.substring('request.'.length) : filter.name,
      }
  }
}

const transformToResponseFilter = (filter: FilterInterface): FilterInterface => {
  switch (filter.name.toLowerCase()) {
    case 'status':
      return {
        ...filter,
        name: 'request.requestStatus',
        value: Array.isArray(filter.value)
          ? filter.value
              .filter(value => value !== InquiryStatus.Requested)
              .map(value => transformStatusFromInquiryToResponse(value))
          : transformStatusFromInquiryToResponse(filter.value),
      }
    case 'request.responseinternalstatus':
      return {
        ...filter,
        name: 'verifications.internalVerificationStatus',
      }
    default:
      return {
        ...filter,
        name: `request.${filter.name}`,
      }
  }
}

const mapFilters = (filters: FilterInterface[], previousView: ViewTypeName, view: ViewTypeName) => {
  if (VIEWS_WITH_REQUEST_FILTERS.includes(previousView) && VIEWS_WITH_RESPONSE_FILTERS.includes(view)) {
    return filters.map(f => transformToResponseFilter(f))
  }

  if (VIEWS_WITH_RESPONSE_FILTERS.includes(previousView) && VIEWS_WITH_REQUEST_FILTERS.includes(view)) {
    return filters.map(f => transformToInquiryFilter(f))
  }

  return filters
}

const ManageRequestsScene: React.FC = () => {
  const { view } = useParams<ManageRequestsSceneParams>()
  const [isCreateDialogOpen, setCreateDialogOpen, closeCreateDialog] = useDialogState()
  const { isConnectDialogOpen, handleCloseConnectDialog, modalPurpose } = useProvidersModal()
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const [filters, setFilters] = useQueryState<Array<FilterInterface>>(DEFAULT_FILTER_NAME, [])
  const previousFilters = usePrevious(filters)
  const previousView = usePrevious(view)
  const { aiScraping } = useFlags()
  const isTransparency = hasPermission(Permissions.TRANSPARENCY_USER)
  const location = useLocation<{ openCreateDialog: boolean }>()

  const { count: screenedRequestsCount } = useFetchCount({
    key: FetchKey.Response,
    endpoint: endpoints.responsesCount,
    payload: [{ name: 'isScraped', filters: [{ operator: Operators.EqualTo, value: true }] }],
    shouldShowErrorNotification: false,
  })

  useEffect(() => {
    if (location.state?.openCreateDialog) {
      setCreateDialogOpen()
    }
  }, [])

  useEffect(() => {
    if (!previousView || !previousFilters?.length) return
    const mappedFilters = mapFilters(previousFilters, previousView, view)
    setFilters(mappedFilters)
  }, [view])

  const actionTabs = [
    ...insertIf(!isTransparency, {
      type: ViewTypeName.Overview,
      url: generatePath(paths.manageRequest, { view: ViewTypeName.Overview }),
      label: formatMessage({ id: 'navbar.manageRequestOverview' }),
    }),
    ...insertIf(
      (!isTransparency && aiScraping) || (isTransparency && screenedRequestsCount && screenedRequestsCount > 0),
      {
        type: ViewTypeName.ScreenedRequests,
        url: generatePath(paths.manageRequest, { view: ViewTypeName.ScreenedRequests }),
        label: formatMessage({ id: 'navbar.screenedRequests' }),
      },
    ),
    {
      type: ViewTypeName.Requests,
      url: generatePath(paths.manageRequest, { view: ViewTypeName.Requests }),
      label: formatMessage({ id: isTransparency ? 'navbar.receivedRequests' : 'navbar.sentRequests' }),
    },
    {
      type: ViewTypeName.Responses,
      url: generatePath(paths.manageRequest, { view: ViewTypeName.Responses }),
      label: formatMessage({ id: 'navbar.responses' }),
    },
  ]

  const actionButtons: ResourceCollectionSceneProps['actionButtons'] = [
    ...insertIf<ActionButton>(!isTransparency, {
      label: formatMessage({ id: 'resourceCollections.general.createRequest' }),
      onClick: setCreateDialogOpen,
      variant: 'contained',
    }),
  ]

  return (
    <>
      <Switch>
        {!isTransparency && (
          <Route path={generatePath(paths.manageRequest, { view: ViewTypeName.Overview })}>
            <OverviewManageRequestsScene
              tabs={{ actionTabs, activeTabParam: view }}
              actionButtons={actionButtons}
              setCreateDialogOpen={setCreateDialogOpen}
            />
          </Route>
        )}

        <Route path={generatePath(paths.manageRequest, { view: ViewTypeName.ScreenedRequests })}>
          {isTransparency ? (
            screenedRequestsCount && screenedRequestsCount > 0 ? (
              <ProviderScrapedResponseScene tabs={{ actionTabs, activeTabParam: view }} />
            ) : null
          ) : (
            <AccessorScrapedResponseScene tabs={{ actionTabs, activeTabParam: view }} actionButtons={actionButtons} />
          )}
        </Route>

        <Route path={generatePath(paths.manageRequest, { view: ViewTypeName.Requests })}>
          {isTransparency ? (
            <ProviderRequestsScene tabs={{ actionTabs, activeTabParam: view }} />
          ) : (
            <AccessorRequestsScene
              tabs={{ actionTabs, activeTabParam: view }}
              actionButtons={actionButtons}
              setCreateDialogOpen={setCreateDialogOpen}
            />
          )}
        </Route>

        <Route path={generatePath(paths.manageRequest, { view: ViewTypeName.Responses })}>
          {isTransparency ? (
            <ProviderResponseScene tabs={{ actionTabs, activeTabParam: view }} />
          ) : (
            <AccessorResponseScene tabs={{ actionTabs, activeTabParam: view }} actionButtons={actionButtons} />
          )}
        </Route>
      </Switch>

      <CreationModalProgressContextProvider>
        <>
          <CreateNewRequestModal open={isCreateDialogOpen} onClose={closeCreateDialog} />
          {modalPurpose === ProvidersModalPurpose.InitialInvitation ? (
            <ConnectProvidersModal onClose={handleCloseConnectDialog} open={isConnectDialogOpen} />
          ) : modalPurpose === ProvidersModalPurpose.ExtraReminder ? (
            <RemindProvidersModal onClose={handleCloseConnectDialog} open={isConnectDialogOpen} />
          ) : null}
        </>
      </CreationModalProgressContextProvider>
    </>
  )
}

export default ManageRequestsScene
