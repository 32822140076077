import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useDeleteResource, useUpdateResource } from '@app/src/api/updateHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import RequestError from '@app/src/errors/RequestError'
import useApi from '@app/src/hooks/api'
import useErrorNotification from '@app/src/hooks/errorNotification'
import {
  FilterGroup,
  Operators,
  RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
  RESPONSE_LATEST_SUBMITTED_FILTER,
} from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { typography } from '@app/src/theme/typography'
import { FileResponse } from '@app/src/types/api'
import { ExportColumn } from '@app/src/types/export'
import { RawExportPayload } from '@app/src/types/filter'
import {
  ExcelExportColumnsJson,
  ExcelExportSettings,
  ExcelExportSettingsPayload,
} from '@app/src/types/resourceExplorer'
import { Schema } from '@app/src/types/schemas'
import { insertIf } from '@app/src/utils/helpersTs'
import { br } from '@app/src/utils/translationMarkup'
import { AmplitudeTrackingEvents, NotificationSeverity, ResourceTypes } from '@app/src/wf-constants'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import EditIcon from '@mui/icons-material/Edit'
import UndoIcon from '@mui/icons-material/Undo'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useDrawer } from '../DrawerContext'
import DrawerView from '../DrawerView'
import ExportColumnItem from './ExportColumnItem'

export const nonLetterNumberOrUnderScoreRegex = /\W/g

export const cleanColumnPath = (path: string) => path.replace(nonLetterNumberOrUnderScoreRegex, '_')

enum DataExportOptions {
  FILTERED_DATA = 'filtered',
  RAW_DATA = 'raw',
  SELECTED_DATA = 'selected',
}

interface DrawerViewExportProps {
  resourceType: (typeof ResourceTypes)[keyof typeof ResourceTypes]
  count: number
  rawExportPayload: RawExportPayload
  userFilter: FilterGroup[]
  exportColumns?: ExportColumn[]
  selectedIds?: number[]
  onlyLatest?: boolean
  resourceView?: string
  disableExportSettings?: boolean
}

export const getExportColumns = (schema: Schema) =>
  schema?.items
    .filter(schema => schema.display?.export && schema.exportPath)
    .map(schema => ({ translationId: schema.translationId, exportPath: schema.exportPath ?? '' }))

const DrawerViewExport: React.FC<DrawerViewExportProps> = ({
  resourceType,
  count,
  rawExportPayload,
  userFilter,
  exportColumns,
  selectedIds = [],
  onlyLatest,
  resourceView = resourceType,
  disableExportSettings = false,
}) => {
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { formatMessage } = useIntl()
  const { post } = useApi()
  const { closeDrawer } = useDrawer()
  const [dataExportOption, setDataExportOption] = useState<DataExportOptions>(DataExportOptions.RAW_DATA)
  const [isLoading, setLoading] = useState(false)
  const [isEditingColumnNames, setIsEditingColumnNames] = useState(false)
  const queryClient = useQueryClient()
  const formMethods = useForm()
  const { trackEvent } = useAmplitude()

  const { data: excelExportSettings, isLoading: isExcelExportSettingsLoading } = useFetchResource<ExcelExportSettings>({
    key: [FetchKey.ExcelExportSettings, resourceType, resourceView],
    endpoint: endpoints.excelExportSettings(resourceType, resourceView),
  })

  const { mutateAsync: createExcelExportSettings } = useUpdateResource<
    ExcelExportSettings,
    ExcelExportSettingsPayload
  >()

  const { mutateAsync: mutateDeleteExcelExportSettings } = useDeleteResource()

  const exportColumnsResolved = useMemo(
    (): ExcelExportColumnsJson[] =>
      exportColumns?.map(column => ({
        displayName: formatMessage({ id: column.translationId }),
        translationId: column.translationId,
        path: column.exportPath,
        isChecked: true,
      })) ?? [],
    [exportColumns],
  )

  const amplitudeBaseEventProps = useMemo(
    () => ({
      resource_type: resourceType,
      resource_view: resourceView,
    }),
    [resourceType, resourceView],
  )

  const [configuredColumns, setConfiguredColumns] = useState<ExcelExportColumnsJson[]>(exportColumnsResolved ?? [])

  const { handleSubmit, reset } = formMethods

  useEffect(() => {
    setConfiguredColumns(exportColumnsResolved ?? [])
  }, [exportColumnsResolved])

  useEffect(() => {
    if (excelExportSettings?.columnsJson) {
      setConfiguredColumns(excelExportSettings.columnsJson)
    }
  }, [excelExportSettings])

  useEffect(() => {
    const formValues = configuredColumns.reduce((acc, column) => {
      const fieldName = cleanColumnPath(column.path)
      return {
        ...acc,
        [fieldName]: column.displayName,
      }
    }, {})
    reset(formValues)
  }, [configuredColumns, reset])

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Accessor.ExcelExport.Opened,
      eventProps: amplitudeBaseEventProps,
    })
  }, [])

  const handleToggleColumn = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, path: string) => {
      const updatedColumns = configuredColumns?.map(column =>
        column.path === path ? { ...column, isChecked: event.target.checked } : column,
      )
      setConfiguredColumns(updatedColumns)
    },
    [configuredColumns],
  )

  const handleToggleAllColumns = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedColumns = configuredColumns?.map(column => ({ ...column, isChecked: event.target.checked }))
      setConfiguredColumns(updatedColumns)
    },
    [configuredColumns],
  )

  const handleRadioChange: RadioGroupProps['onChange'] = event => {
    setDataExportOption(event.target.value as DataExportOptions)
  }

  const handleResetOrderAndNameClick = useCallback(() => {
    const updatedColumns = [
      ...configuredColumns
        .sort((a, b) => {
          return (
            exportColumnsResolved.findIndex(x => x.path === a.path) -
            exportColumnsResolved.findIndex(x => x.path === b.path)
          )
        })
        .map((column, index) => ({
          ...column,
          displayName: exportColumnsResolved[index].displayName,
        })),
    ]
    setConfiguredColumns(updatedColumns)
  }, [configuredColumns, exportColumnsResolved])

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event
    if (!over) return

    if (active.id !== over.id) {
      setConfiguredColumns(columns => {
        const oldIndex = columns.findIndex(x => x.path === active.id)
        const newIndex = columns.findIndex(x => x.path === over.id)
        return arrayMove(columns, oldIndex, newIndex)
      })
    }
  }, [])

  const onSaveColumnNames = (data: Record<string, string>) => {
    const updatedColumns = configuredColumns.map(column => {
      const fieldName = cleanColumnPath(column.path)
      return {
        path: column.path,
        displayName: data[fieldName],
        isChecked: column.isChecked,
      }
    })
    trackEvent({
      name: AmplitudeTrackingEvents.Accessor.ExcelExport.ChangedColumnNames,
      eventProps: amplitudeBaseEventProps,
    })
    setConfiguredColumns(updatedColumns)
    setIsEditingColumnNames(false)
  }

  const areColumnsReorderedOrRenamed = useMemo(
    () =>
      configuredColumns?.some(
        (column, index) =>
          column.path !== exportColumnsResolved[index]?.path ||
          column.displayName !== exportColumnsResolved[index]?.displayName,
      ),
    [configuredColumns, exportColumnsResolved],
  )
  const areAllColumnsChecked = useMemo(() => configuredColumns?.every(column => column.isChecked), [configuredColumns])
  const areSomeColumnsChecked = useMemo(
    () => configuredColumns?.some(column => column.isChecked) && !areAllColumnsChecked,
    [configuredColumns, areAllColumnsChecked],
  )

  const getOnlyLatestFilter = () => {
    if (!onlyLatest) return []

    if (resourceType === ResourceTypes.Response) return [RESPONSE_LATEST_SUBMITTED_FILTER]
    if ([ResourceTypes.ResponseItem, ResourceTypes.Answer].includes(resourceType))
      return [RESPONSE_ITEM_LATEST_SUBMITTED_FILTER]

    return []
  }

  const getExportObject = () => {
    const onlyLatestFilter = getOnlyLatestFilter()
    const onlyIncludedColumns = configuredColumns
      .filter(x => x.isChecked)
      .map(x => ({ displayName: x.displayName, path: x.path }))

    if (dataExportOption === DataExportOptions.RAW_DATA) {
      return {
        ...rawExportPayload,
        filter: [...(rawExportPayload?.filter ? rawExportPayload.filter : []), ...onlyLatestFilter],
        export: onlyIncludedColumns,
      }
    }
    if (dataExportOption === DataExportOptions.FILTERED_DATA) {
      return {
        ...rawExportPayload,
        filter: [...(rawExportPayload?.filter ? rawExportPayload.filter : []), ...userFilter, ...onlyLatestFilter],
        export: onlyIncludedColumns,
      }
    }
    if (dataExportOption === DataExportOptions.SELECTED_DATA) {
      return {
        ...rawExportPayload,
        filter: [
          ...(rawExportPayload?.filter ? rawExportPayload.filter : []),
          ...[{ name: 'id', filters: [{ operator: Operators.In, value: selectedIds }] }],
          ...onlyLatestFilter,
        ],
        export: onlyIncludedColumns,
      }
    }
    return {}
  }

  const handleExportSettingsUpdate = async () => {
    if (areAllColumnsChecked && !areColumnsReorderedOrRenamed && excelExportSettings) {
      await mutateDeleteExcelExportSettings({
        url: endpoints.deleteExcelExportSettings(excelExportSettings.id),
      })
      trackEvent({
        name: AmplitudeTrackingEvents.Accessor.ExcelExport.DeletedExportSettings,
        eventProps: amplitudeBaseEventProps,
      })
      queryClient.invalidateQueries([FetchKey.ExcelExportSettings, resourceType, resourceView])
    }
    if (areSomeColumnsChecked || areColumnsReorderedOrRenamed) {
      const excelExportSettingsObject: ExcelExportSettingsPayload = {
        resourceType,
        resourceView,
        columnsJson: configuredColumns,
      }
      await createExcelExportSettings({ url: endpoints.saveExcelExportSettings, body: excelExportSettingsObject })
      trackEvent({
        name: AmplitudeTrackingEvents.Accessor.ExcelExport.SavedExportSettings,
        eventProps: amplitudeBaseEventProps,
      })
    }
  }

  const handleExportRequest = async () => {
    const exportObject = getExportObject()
    const res = await post<FileResponse, { [key: string]: unknown }>({
      url: endpoints.queueDataExport(resourceType),
      body: exportObject,
    })
    trackEvent({
      name: AmplitudeTrackingEvents.Accessor.ExcelExport.ExportRequested,
      eventProps: {
        ...amplitudeBaseEventProps,
        data_export_option: dataExportOption,
        columns_reordered_or_renamed: areColumnsReorderedOrRenamed,
        all_columns_checked: areAllColumnsChecked,
        some_columns_checked: areSomeColumnsChecked,
      },
    })
    if (res) {
      showSnackbar({
        message: formatMessage({ id: 'notifications.successfulExportQueueRequest' }, { br }),
        severity: NotificationSeverity.success,
      })
    } else {
      showSnackbar({
        message: formatMessage({ id: 'notifications.error' }),
        severity: NotificationSeverity.error,
      })
    }
  }

  const handleSubmitExport = async () => {
    setLoading(true)
    try {
      await Promise.all([handleExportSettingsUpdate(), handleExportRequest()])
    } catch (e) {
      const typedError = e as { requestError: RequestError }
      showErrorNotification({ requestError: typedError.requestError })
    } finally {
      setLoading(false)
      closeDrawer()
    }
  }

  const buttons: ActionButton[] = isEditingColumnNames
    ? [
        {
          label: formatMessage({ id: 'export.doneEditColumnNames' }),
          variant: 'contained',
          type: 'submit',
        },
      ]
    : [
        ...insertIf<ActionButton>(!disableExportSettings, {
          label: formatMessage({ id: 'export.editColumnNames' }),
          startIcon: <EditIcon />,
          variant: 'outlined',
          onClick: () => setIsEditingColumnNames(prev => !prev),
        }),
        {
          label: formatMessage({ id: 'export.export' }),
          variant: 'contained',
          onClick: handleSubmitExport,
          disabled: !dataExportOption || (!areSomeColumnsChecked && !areAllColumnsChecked),
          loading: isLoading,
        },
      ]

  return (
    <FormProvider {...formMethods}>
      <DrawerView
        title={formatMessage({ id: 'export.title' })}
        subTitle={formatMessage({ id: `resourceTypes.${resourceType}` })}
        stackButtons
        buttons={buttons}
        onFormSubmit={handleSubmit(onSaveColumnNames)}
      >
        {isExcelExportSettingsLoading ? (
          <Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box px={3}>
              <FormControl variant="outlined">
                <FormLabel component="legend">{formatMessage({ id: 'export.description' })}</FormLabel>
                <RadioGroup value={dataExportOption} onChange={handleRadioChange}>
                  <FormControlLabel
                    value={DataExportOptions.RAW_DATA}
                    control={<Radio />}
                    label={formatMessage({ id: 'export.rawOption' })}
                  />
                  {Boolean(userFilter?.length) && Boolean(count) && (
                    <FormControlLabel
                      value={DataExportOptions.FILTERED_DATA}
                      control={<Radio />}
                      label={formatMessage({ id: 'export.filteredOption' }, { rows: count })}
                    />
                  )}
                  {Boolean(selectedIds?.length) && (
                    <FormControlLabel
                      value={DataExportOptions.SELECTED_DATA}
                      control={<Radio />}
                      label={formatMessage({ id: 'export.selectedOption' }, { rows: selectedIds.length })}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Box>
            {!disableExportSettings && exportColumns?.length && (
              <Box pt={2}>
                <Box pl={3} pr={2} display="flex" sx={{ alignItems: 'center' }}>
                  <Box py={1.5}>
                    <FormLabel component="legend">{formatMessage({ id: 'export.columnSettingsHeader' })}</FormLabel>
                  </Box>
                  {areColumnsReorderedOrRenamed && (
                    <Tooltip arrow placement="top" title={formatMessage({ id: 'export.resetColumnsTooltip' })}>
                      <Button size="small" onClick={handleResetOrderAndNameClick} sx={{ marginLeft: 'auto' }}>
                        <UndoIcon sx={{ transform: 'scale(0.8)', transformOrigin: 'center', marginRight: '3px' }} />
                        {formatMessage({ id: 'general.reset' })}
                      </Button>
                    </Tooltip>
                  )}
                </Box>

                <Stack sx={{ backgroundColor: 'grey.100' }} px={2} py={1} direction="row" alignItems="center">
                  {!isEditingColumnNames && (
                    <Tooltip title={formatMessage({ id: 'export.toggleAllColumns' })} arrow placement="left">
                      <Checkbox
                        sx={{ marginLeft: '-3px' }}
                        onChange={handleToggleAllColumns}
                        checked={areAllColumnsChecked}
                        indeterminate={areSomeColumnsChecked}
                      />
                    </Tooltip>
                  )}
                  <Typography fontWeight={typography.fontWeightMedium} sx={{ marginLeft: 1 }}>
                    {formatMessage({ id: 'export.column' })}
                  </Typography>
                </Stack>
                <DndContext onDragEnd={handleDragEnd}>
                  <SortableContext
                    items={configuredColumns.map(column => column.path)}
                    strategy={verticalListSortingStrategy}
                  >
                    <Stack sx={{ backgroundColor: 'grey.50' }}>
                      {configuredColumns?.map(column => (
                        <ExportColumnItem
                          key={column.path}
                          column={column}
                          isEditingName={isEditingColumnNames}
                          onToggleColumn={handleToggleColumn}
                          originalColumnName={
                            exportColumnsResolved.find(x => x.path === column.path)?.displayName ?? ''
                          }
                        />
                      ))}
                    </Stack>
                  </SortableContext>
                </DndContext>
              </Box>
            )}
          </>
        )}
      </DrawerView>
    </FormProvider>
  )
}

export default DrawerViewExport
