import StatusChip from '@app/src/components/StatusChip'
import { ExternalVerificationStatus, InquiryStatus, ResponseDraftStatus } from '@app/src/types/resourceExplorer'
import { isDateDueSoon, isPassedDeadline } from '@app/src/utils'
import { Skeleton } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type ResponseStatusProps = {
  responseStatus?: ExternalVerificationStatus
  draftStatus?: ResponseDraftStatus
  deadline?: string
  isLoadingStatus?: boolean
  inquiryStatus?: InquiryStatus
}

const StatusChipFromResponseStatus: React.FC<Omit<ResponseStatusProps, 'isLoadingStatus'>> = ({
  deadline,
  draftStatus,
  responseStatus,
  inquiryStatus,
}) => {
  const { formatMessage } = useIntl()

  //Check Inquiry Status first
  switch (inquiryStatus) {
    case InquiryStatus.CorrectionNeeded:
      return <StatusChip color="warning" label={formatMessage({ id: 'reporting.statuses.CorrectionNeeded' })} />
    case InquiryStatus.Approved:
      return <StatusChip color="success" label={formatMessage({ id: 'reporting.statuses.Approved' })} />
  }
  switch (responseStatus) {
    case ExternalVerificationStatus.CorrectionNeeded:
      return <StatusChip color="warning" label={formatMessage({ id: 'reporting.statuses.CorrectionNeeded' })} />
    case ExternalVerificationStatus.Approved:
      return <StatusChip color="success" label={formatMessage({ id: 'reporting.statuses.Approved' })} />
  }

  //Check submitted
  if (draftStatus === ResponseDraftStatus.Submitted)
    return <StatusChip color="info" label={formatMessage({ id: 'reporting.statuses.Submitted' })} />

  if (draftStatus === ResponseDraftStatus.Scraped)
    return <StatusChip color="communication" label={formatMessage({ id: 'reporting.statuses.Scraped' })} />

  //Check deadline
  if (isDateDueSoon(deadline))
    return <StatusChip color="warning" label={formatMessage({ id: 'reporting.statuses.due.DueSoon' })} />
  if (isPassedDeadline(deadline))
    return <StatusChip color="error" label={formatMessage({ id: 'reporting.statuses.due.Overdue' })} />

  return <StatusChip color="default" label={formatMessage({ id: 'reporting.statuses.Sent' })} />
}

const ResponseStatus: React.FC<ResponseStatusProps> = ({
  responseStatus,
  draftStatus,
  deadline,
  isLoadingStatus,
  inquiryStatus,
}) => {
  if (isLoadingStatus) {
    return <Skeleton variant="rounded" width="10%" height={24} />
  }

  return (
    <StatusChipFromResponseStatus
      responseStatus={responseStatus}
      draftStatus={draftStatus}
      deadline={deadline}
      inquiryStatus={inquiryStatus}
    />
  )
}

export default ResponseStatus
