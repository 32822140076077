import { createContext, useContext } from 'react'
import { AnswersGroupingType } from '../pages/ResourceCollection/Filters/StatisticsConstants'

export interface GroupBySelectorContextProps {
  groupBy: AnswersGroupingType
  groupByValue: string
  setGroupBy: (newValue: AnswersGroupingType, groupByValue?: string, isClearing?: boolean) => void
}

const GroupBySelectorContext = createContext<undefined | GroupBySelectorContextProps>(undefined)

export const useGroupBySelector = (): GroupBySelectorContextProps => {
  const context = useContext(GroupBySelectorContext)
  if (context === undefined) {
    throw new Error('useGroupBySelector must be used within GroupBySelectorContextProvider')
  }
  return context
}

export default GroupBySelectorContext
