import { ExportColumn } from '@app/src/types/export'
import { getProviderExportColumns } from './provider'
import { getResponseExportColumns } from './response'

const REQUEST_TRANSLATION_ID = 'export.schemas.request'
const INQUIRY_EXPORT_PATH = 'request.inquiries[0]'

export const getInquiryExportColumnsAccessor = (solution?: string): ExportColumn[] => [
  ...getRequestExportColumns(),
  ...getResponseExportColumns('request.responses[0]'),
  ...getProviderExportColumns(solution, 'provider'),
]

export const getInquiryExportColumnsTransparency = (): ExportColumn[] => [
  ...getRequestExportColumns(),
  {
    translationId: 'export.schemas.provider.organization.organizationId',
    exportPath: 'creatorOrganization.id',
  },
  {
    translationId: 'export.schemas.provider.organization.organizationName',
    exportPath: 'creatorOrganization.name',
  },
  {
    translationId: 'schemas.request.responseExternalStatus',
    exportPath: 'request.responseExternalStatus',
  },
  {
    translationId: 'schemas.request.activatedAt',
    exportPath: 'activatedAt',
  },
]

export const getRequestExportColumns = (basePath?: string): ExportColumn[] => [
  {
    translationId: basePath ? `${REQUEST_TRANSLATION_ID}.id` : 'general.id',
    exportPath: basePath ? `${basePath}.id` : 'id',
  },
  {
    translationId: basePath ? `${REQUEST_TRANSLATION_ID}.requestStatus` : 'schemas.request.requestStatus',
    exportPath: basePath ? `${INQUIRY_EXPORT_PATH}.status` : 'status',
  },
  {
    translationId: 'schemas.request.request',
    exportPath: basePath ? `${basePath}.template.title` : 'template.title',
  },
  {
    translationId: 'schemas.request.periodDisplayName',
    exportPath: basePath ? `${basePath}.periodName` : 'periodName',
  },
  {
    translationId: 'schemas.request.periodStartsAt',
    exportPath: basePath ? `${basePath}.periodStartsAt` : 'periodStartsAt',
  },
  {
    translationId: 'schemas.request.periodEndsAt',
    exportPath: basePath ? `${basePath}.periodEndsAt` : 'periodEndsAt',
  },
  {
    translationId: basePath ? `${REQUEST_TRANSLATION_ID}.createdAt` : 'schemas.request.createdAt',
    exportPath: basePath ? `${basePath}.createdAt` : 'createdAt',
  },
  {
    translationId: 'schemas.request.deadline',
    exportPath: basePath ? `${INQUIRY_EXPORT_PATH}.deadline` : 'deadline',
  },
]
