import { useEffect, useMemo, useCallback } from 'react'
import { Config, IntercomSolutionKeys } from '@app/src/config'
import { useAccount } from '@app/src/context/AccountContext'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { Solutions } from '@app/src/wf-constants'

const intercomKeyMap = {
  [Solutions.Finance]: IntercomSolutionKeys.EsgReportingSolution,
  [Solutions.Investment]: IntercomSolutionKeys.InvestmentsSolution,
  [Solutions.Sourcing]: IntercomSolutionKeys.SourcingSolution,
  [Solutions.SourcingStart]: IntercomSolutionKeys.SourcingStartSolution,
  [Solutions.Transparency]: IntercomSolutionKeys.TransparencySolution,
}

const useIntercom = (): { sendNewMessage: (message?: string) => void } => {
  const { account } = useAccount()
  const { accessibleOrganizations, isLoading: isAccessibleOrganizationsLoading } = useAccessibleOrganizations()
  const { familyName, givenName, createdAt, email, id: userId } = account?.user || {}
  const { name: organizationName, id: organizationId } = account?.organization || {}
  const intercomUserId = Config.FRONT_ENV === 'prod' ? userId : `${Config.FRONT_ENV}-${userId}`
  const name = familyName && givenName ? `${givenName} ${familyName}` : undefined

  const availableSolutions = useMemo(
    () =>
      accessibleOrganizations.reduce(
        (acc, o) => {
          Object.keys(intercomKeyMap).forEach(key => {
            if (o.solution.some(s => s.solution.toLowerCase() === key)) acc[intercomKeyMap[key]] = true
          })
          return acc
        },
        {
          [IntercomSolutionKeys.EsgReportingSolution]: false,
          [IntercomSolutionKeys.InvestmentsSolution]: false,
          [IntercomSolutionKeys.SourcingSolution]: false,
          [IntercomSolutionKeys.SourcingStartSolution]: false,
          [IntercomSolutionKeys.TransparencySolution]: false,
        },
      ),
    [accessibleOrganizations],
  )

  useEffect(() => {
    if (!email || !window.Intercom || isAccessibleOrganizationsLoading) return

    window.Intercom('boot', {
      app_id: 'qsqnvucn',
      user_id: intercomUserId,
      name,
      email,
      created_at: createdAt ? Math.floor(new Date(createdAt).getTime() / 1000) : undefined,
      company: organizationId
        ? {
            company_id: organizationId,
            name: organizationName ?? '',
          }
        : undefined,
      ...availableSolutions,
    })
  }, [
    createdAt,
    familyName,
    givenName,
    email,
    organizationId,
    organizationName,
    userId,
    isAccessibleOrganizationsLoading,
    availableSolutions,
  ])

  const sendNewMessage = useCallback((message?: string) => {
    if (window.Intercom) {
      window.Intercom('showNewMessage', message || '')
    }
  }, [])

  return { sendNewMessage }
}

export default useIntercom
