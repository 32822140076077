import Drawer from '@app/src/components/Drawer_DEPRECATED/Drawer'
import { OrganisationDrawerProps } from '@app/src/components/Form'
import CategoryDrawerContent, {
  CategoryDrawerViews,
  ProviderUpdateType,
} from '@app/src/components/ManageProviderDrawer/CategoryDrawer/CategoryDrawerContent'
import UpdateProviderMenu from '@app/src/components/ManageProviderDrawer/UpdateProviderMenu'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { Category } from '@app/src/types/categories'
import { SolutionType } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

type UpdateProvidersDrawerProps = {
  selectedProviders: ProviderUpdateType[]
  setSelectedProviders?: React.Dispatch<React.SetStateAction<Array<number>>>
  pageOpenedFromName?: string
}

export enum UpdateProvidersDrawerViews {
  Menu,
  CreateCategory,
  AssignCategoryOption,
  SetStandardCategory,
}

const UpdateProvidersDrawer = ({
  closeDrawer,
  isDrawerOpen,
  selectedProviders,
  setSelectedProviders,
  pageOpenedFromName,
}: UpdateProvidersDrawerProps & OrganisationDrawerProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const { trackEvent, account } = useAmplitude()
  const [drawerView, setDrawerView] = useState<UpdateProvidersDrawerViews>(UpdateProvidersDrawerViews.Menu)
  const [editingCategory, setEditingCategory] = useState<Category | Pick<Category, 'name'> | undefined>(undefined)

  const trackingEvent = () => {
    if (pageOpenedFromName) {
      trackEvent({
        name: AmplitudeTrackingEvents.Analyze.Assessment.CategorizeFromAssessmentPages,
        eventProps: {
          page: pageOpenedFromName,
          organization_id: account?.organization?.id,
          organization_name: account?.organization?.name,
          provider_count: selectedProviders.length,
          solution_type: SolutionType.Sourcing,
        },
      })
    }
  }

  const renderDrawerContent = () => {
    switch (drawerView) {
      case UpdateProvidersDrawerViews.Menu:
        return (
          <UpdateProviderMenu
            providersLength={selectedProviders.length}
            setDrawerView={setDrawerView}
            setEditCategory={setEditingCategory}
          />
        )
      case UpdateProvidersDrawerViews.CreateCategory:
        return (
          <CategoryDrawerContent
            setSelectedProviders={setSelectedProviders}
            providersUpdate={selectedProviders}
            isDrawerOpen={isDrawerOpen}
            closeDrawer={() => {
              trackingEvent()
              setDrawerView(UpdateProvidersDrawerViews.Menu)
            }}
            view={CategoryDrawerViews.CreateCategory}
          />
        )
      case UpdateProvidersDrawerViews.AssignCategoryOption:
        return (
          <CategoryDrawerContent
            providersUpdate={selectedProviders}
            setSelectedProviders={setSelectedProviders}
            category={editingCategory}
            isDrawerOpen={isDrawerOpen}
            closeDrawer={() => {
              trackingEvent()
              setDrawerView(UpdateProvidersDrawerViews.Menu)
            }}
            view={CategoryDrawerViews.AssignCategoryOptions}
          />
        )
      case UpdateProvidersDrawerViews.SetStandardCategory:
        return (
          <CategoryDrawerContent
            category={editingCategory}
            setSelectedProviders={setSelectedProviders}
            providersUpdate={selectedProviders}
            isDrawerOpen={isDrawerOpen}
            closeDrawer={() => {
              trackingEvent()
              setDrawerView(UpdateProvidersDrawerViews.Menu)
            }}
            view={CategoryDrawerViews.SetStandardCategory}
          />
        )
    }
  }

  const renderDrawerTitle = () => {
    switch (drawerView) {
      case UpdateProvidersDrawerViews.Menu:
        return formatMessage({ id: 'resourceCollections.general.categorize' }, { count: selectedProviders.length })
    }
  }

  const drawerTitle = renderDrawerTitle()
  return (
    <Drawer
      open={isDrawerOpen}
      closeDrawer={closeDrawer}
      title={drawerTitle && <Box px={3}>{drawerTitle}</Box>}
      removePaddingForContent
    >
      {renderDrawerContent()}
    </Drawer>
  )
}

export default UpdateProvidersDrawer
