import { EmptyStateVariant } from '@app/src/components/EmptyState/EmptyState'
import EmptyState from '@app/src/components/EmptyState/index'
import WifiOffIcon from '@mui/icons-material/WifiOff'
import React from 'react'
import { useIntl } from 'react-intl'

const FailedToFetchDataEmptyState = () => {
  const { formatMessage } = useIntl()
  return (
    <EmptyState
      variant={EmptyStateVariant.Error}
      title={formatMessage({ id: 'errorMessages.general.couldNotFetchData' })}
      description={formatMessage({ id: 'errorMessages.general.tryAgainLater' })}
      iconComponent={WifiOffIcon}
    />
  )
}

export default FailedToFetchDataEmptyState
