import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { ProvidersModalPurpose, useProvidersModal } from '@app/src/context/ProvidersModalContext'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import ConnectProvidersModal from '@app/src/pages/ResourceCollection/Collections/Provider/ConnectProviders/ConnectProvidersModal'
import RemindProvidersModal from '@app/src/pages/ResourceCollection/Collections/Provider/ConnectProviders/RemindProvidersModal'
import { ContactTabs } from '@app/src/pages/ResourceCollection/Collections/ProviderContactsOverview'
import { AllTabs } from '@app/src/pages/ResourceExplorer/Content'
import { Provider } from '@app/src/types/organizations'
import paths from '@app/src/wf-constants/paths'
import { Add, AddOutlined, ChevronRight, PersonOffOutlined } from '@mui/icons-material'
import {
  Avatar,
  AvatarGroup,
  Button,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import { useDrawer } from '../Drawer/DrawerContext'
import DrawerViewAddProviderContactReason from '../Drawer/Views/AddProviderContact/DrawerViewAddProviderContactReason'
import DrawerViewCreateReferralContact from '../Drawer/Views/DrawerViewCreateReferralContact'
import { getOrganizationDisplayName } from '../Table/Cells/CompanyCell'
import ReferralContactCell from '../Table/Cells/ReferralContactCell'

interface ProviderContactsProps {
  provider: Provider
}

const ProviderContacts: React.FC<ProviderContactsProps> = ({ provider }) => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { openDrawer } = useDrawer()
  const { isConnectDialogOpen, handleCloseConnectDialog, modalPurpose } = useProvidersModal()

  const isConnectedProvider = Boolean(provider?.organization)
  const hasPublicContact = Boolean(provider?.organization?.contacts?.length)
  const hasReferralContact = Boolean(provider?.referralContact)

  if (isConnectedProvider) {
    return (
      <>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1" color="textSecondary">
            {formatMessage({ id: 'schemas.provider.contacts' })}
          </Typography>
          <Button
            size="small"
            startIcon={<Add />}
            onClick={() =>
              openDrawer(
                <DrawerViewAddProviderContactReason
                  companyName={getOrganizationDisplayName(provider)}
                  providerId={provider.id}
                />,
              )
            }
          >
            {formatMessage({ id: 'general.add' })}
          </Button>
        </Stack>

        <ListItem disablePadding sx={{ mt: 2 }}>
          <ListItemButton
            onClick={() =>
              history.push(
                generatePath(paths.resourceExplorer, {
                  resourceType: provider.type.toLowerCase(),
                  resourceId: provider.id,
                  activeTabParam: AllTabs.Contact,
                  view: ContactTabs.Public,
                }),
              )
            }
            dense
            disableGutters
          >
            <ListItemAvatar sx={{ minWidth: 'unset', mr: 2 }}>
              <AvatarGroup max={2}>
                {hasPublicContact ? (
                  provider?.organization?.contacts?.map(contact => (
                    <PersonAvatar key={contact.id} name={contact.user.name ?? ''} />
                  ))
                ) : (
                  <Avatar>
                    <PersonOffOutlined />
                  </Avatar>
                )}
              </AvatarGroup>
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage(
                { id: 'general.contacts' },
                { count: provider?.organization?.contacts?.length ?? 0 },
              )}
              secondary={formatMessage({ id: 'general.viewAll' })}
            />
            <IconButton>
              <ChevronRight />
            </IconButton>
          </ListItemButton>
        </ListItem>
      </>
    )
  }

  if (hasReferralContact) {
    return (
      <Stack spacing={2}>
        <Typography variant="subtitle1" color="textSecondary">
          {formatMessage({ id: 'resourceTypes.contact' })}
        </Typography>
        <ReferralContactCell disableCell provider={provider} drawer />
        <CreationModalProgressContextProvider>
          {modalPurpose === ProvidersModalPurpose.InitialInvitation ? (
            <ConnectProvidersModal onClose={handleCloseConnectDialog} open={isConnectDialogOpen} />
          ) : (
            <RemindProvidersModal onClose={handleCloseConnectDialog} open={isConnectDialogOpen} />
          )}
        </CreationModalProgressContextProvider>
      </Stack>
    )
  }

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1" color="textSecondary">
        {formatMessage({ id: 'resourceTypes.contact' })}
      </Typography>
      <ListItem disablePadding>
        <ListItemButton onClick={() => openDrawer(<DrawerViewCreateReferralContact provider={provider} />)}>
          <ListItemText primary={formatMessage({ id: 'referral.contact.addContactPerson' })} />
          <Button startIcon={<AddOutlined />} size="small" sx={{ ml: 1 }}>
            {formatMessage({ id: 'general.add' })}
          </Button>
        </ListItemButton>
      </ListItem>
    </Stack>
  )
}

export default ProviderContacts
