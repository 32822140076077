import CategoryCell from '@app/src/components/Table/Cells/CategoryCell'
import CountryCell from '@app/src/components/Table/Cells/CountryCell'
import ProviderSpendCell from '@app/src/components/Table/Cells/ProviderSpendCell'
import TagsCell from '@app/src/components/Table/Cells/TagsCell'
import { useRiskSettings } from '@app/src/context/RiskSettingsContext'
import { AnswersGroupingType } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { Provider } from '@app/src/types/organizations'
import { RiskStatus, Unit } from '@app/src/types/resourceExplorer'
import { sortAlphabetically } from '@app/src/utils/helpers'
import React from 'react'
import { useIntl } from 'react-intl'
import SimpleRiskChipCell from '@app/src/components/Table/Cells/SimpleRiskChipCell'
import TableCell from '@mui/material/TableCell'
import { Box, Stack, Tooltip } from '@mui/material'

const getStandardCategoryValue = (provider: Provider, groupByValue: keyof Provider): string | undefined => {
  switch (groupByValue) {
    case 'providerApprovalStatus':
      return provider.providerApprovalStatus
    case 'activityStatus':
      return provider.activityStatus
    case 'finalRiskRating':
      return provider.finalRiskRating
    case 'priority':
      return provider.priority
    case 'supplierUsage':
      return provider.supplierUsage
    default:
      return undefined
  }
}

export const getStandardCategoryTranslationKey = (groupByValue: keyof Provider): string => {
  switch (groupByValue) {
    case 'providerApprovalStatus':
      return 'schemas.provider.providerApprovalStatusValues'
    case 'activityStatus':
      return 'schemas.provider.activityStatusValues'
    case 'finalRiskRating':
      return 'schemas.provider.finalRiskRatingValues'
    case 'priority':
      return 'schemas.provider.priorityValues'
    case 'supplierUsage':
      return 'schemas.provider.supplierUsageValues'
    default:
      return ''
  }
}

const distinctSortedNonEmptyValues = (values?: (string | undefined)[]): string[] => {
  return sortAlphabetically([...new Set(values?.filter(Boolean))])
}

const DataInsightsModalAdditionalTableColumn: React.FC<{
  provider: Provider
  groupBy: AnswersGroupingType
  groupByValue: string
  groupId?: string
  organizationCurrency?: Unit
}> = ({ provider, groupBy, groupByValue, groupId, organizationCurrency }) => {
  const { formatMessage } = useIntl()
  const { activeRiskSettings } = useRiskSettings()

  switch (groupBy) {
    case AnswersGroupingType.SpendClassification:
      return <ProviderSpendCell spend={provider?.spends?.[0]} organizationCurrency={organizationCurrency} />

    case AnswersGroupingType.Country:
      return <CountryCell countryCode={provider.country?.countryCode} />

    case AnswersGroupingType.CustomCategory: {
      const categoryId = Number(groupByValue)
      const categoryOptionId = Number(groupId)
      return (
        <TagsCell
          amountOfTagsShow={4}
          tags={distinctSortedNonEmptyValues(
            provider?.categoryOptions
              ?.filter(x => x.categoryId === categoryId && (!groupId || x.id === categoryOptionId))
              ?.map(x => x.name),
          )}
        />
      )
    }

    case AnswersGroupingType.StandardCategory: {
      const propertyValue = getStandardCategoryValue(provider, groupByValue as keyof Provider)
      const translationKey = getStandardCategoryTranslationKey(groupByValue as keyof Provider)
      return (
        <CategoryCell
          value={formatMessage({
            id: `${translationKey}.${propertyValue}`,
          })}
          defaultValue={formatMessage({ id: `${translationKey}.NotSelected` })}
        />
      )
    }

    case AnswersGroupingType.ActorType: {
      const actorTypeOptionId = Number(groupId)
      return (
        <TagsCell
          amountOfTagsShow={5}
          tags={distinctSortedNonEmptyValues(
            provider?.mappingNodes
              ?.filter(x => !groupId || x.actorTypeId === actorTypeOptionId)
              ?.map(
                x =>
                  x.actorType?.name && formatMessage({ id: `schemas.mappingNode.actorTypeValues.${x.actorType.name}` }),
              ),
          )}
        />
      )
    }

    case AnswersGroupingType.CountryRisk: {
      const riskStatus = groupId
      const risks = provider.country?.risks?.filter(
        c =>
          (!riskStatus || c.status === riskStatus) && activeRiskSettings.some(setting => setting.id === c.riskType.id),
      )
      const riskStatusAndNames = distinctSortedNonEmptyValues(risks.map(x => x.status)).map(status => ({
        riskStatus: status as RiskStatus,
        riskNames: risks
          .filter(risk => risk.status === status)
          .map(risk => risk.riskType.name)
          .sort(),
      }))

      return (
        <>
          <CountryCell countryCode={provider.country?.countryCode} />
          <TableCell>
            <Stack direction="row" flexWrap="wrap">
              {riskStatusAndNames.map((x, index) => {
                return (
                  <Tooltip
                    key={`${index}-${x.riskStatus}`}
                    title={x.riskNames.map(riskName => (
                      <Box key={`${index}-${riskName}`}>&bull; &nbsp;{riskName}</Box>
                    ))}
                    arrow
                    placement="left"
                  >
                    <Box mr={1} my={0.7}>
                      <SimpleRiskChipCell riskStatus={x.riskStatus} disableCell />
                    </Box>
                  </Tooltip>
                )
              })}
            </Stack>
          </TableCell>
        </>
      )
    }

    default:
      return null
  }
}

export default DataInsightsModalAdditionalTableColumn
