import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { ActionButton } from '@app/src/components/ActionButtons'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import DrawerViewSkeleton from '@app/src/components/Drawer/DrawerViewSkeleton'
import DrawerViewDeleteProvider from '@app/src/components/Drawer/Views/DrawerViewDeleteProvider'
import ProviderDrawerContent from '@app/src/components/ProviderDrawerContent'
import { getOrganizationDisplayName } from '@app/src/components/Table/Cells/CompanyCell'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { isProvider, Provider } from '@app/src/types/organizations'
import { insertIf } from '@app/src/utils/helpersTs'
import paths from '@app/src/wf-constants/paths'
import { DeleteOutlineOutlined, LabelOutlined, MoreHorizOutlined, PersonAddAlt1Outlined } from '@mui/icons-material'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import { useDrawer } from '../DrawerContext'
import DrawerViewAddProviderContactReason from './AddProviderContact/DrawerViewAddProviderContactReason'
import DrawerViewLinkProvider from './DrawerViewLinkProvider'
import DrawerViewOrganizationForm from './DrawerViewOrganizationForm'

type DrawerViewCompanyProps = {
  company: Provider
  isPartialProviderData?: boolean
} & Omit<DrawerViewProps, 'title'>

const DrawerViewCompany: React.FC<DrawerViewCompanyProps> = ({ company, isPartialProviderData = false, ...props }) => {
  const { openDrawer, closeDrawer } = useDrawer()
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const history = useHistory()
  const [dialogAnchorEl, setDialogAnchorEl] = useState<HTMLButtonElement | null>(null)

  const open = Boolean(dialogAnchorEl)
  const isProviderObject = isProvider(company)

  const { items: providers, isLoading } = useFetchCollectionWithPost<Provider>({
    endpoint: endpoints.providersCollection,
    key: FetchKey.ProviderCollection,
    payload: {
      include: ['organization.image'],
      filter: [
        {
          name: isPartialProviderData ? 'id' : 'organizationId',
          filters: [
            {
              value: company.id,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      pagination: {
        itemsPerPage: 1,
        pageNumber: 1,
      },
    },
    options: {
      enabled: !isProviderObject || isPartialProviderData,
    },
  })

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDialogAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setDialogAnchorEl(null)
  }

  if (isLoading) return <DrawerViewSkeleton buttons={2} />

  const provider: Provider | undefined = providers?.length > 0 ? providers?.[0] : company
  const companyName = getOrganizationDisplayName(provider)

  if (!provider.linked) {
    return <DrawerViewLinkProvider provider={provider} />
  }

  return (
    <DrawerView
      {...props}
      title=""
      buttons={[
        {
          variant: 'text',
          label: formatMessage({ id: 'general.more' }),
          startIcon: <MoreHorizOutlined />,
          onClick: openMenu,
        },
        ...insertIf<ActionButton>(provider.linked && !hasPermission(Permissions.TRANSPARENCY_USER), {
          label: formatMessage({ id: 'general.fullProfile' }),
          variant: 'contained',
          onClick: () => {
            history.push(
              generatePath(paths.resourceExplorer, {
                resourceType: provider.type.toLowerCase(),
                resourceId: provider.id,
              }),
            )
            closeDrawer()
          },
        }),
      ]}
    >
      <ProviderDrawerContent provider={provider} />

      <Menu
        anchorEl={dialogAnchorEl}
        open={open}
        onClose={closeMenu}
        sx={({ zIndex }) => ({
          zIndex: zIndex.modal + 2,
        })}
      >
        <MenuItem
          onClick={() => {
            openDrawer(<DrawerViewOrganizationForm provider={provider} />, { showAboveDialogs: true })
            closeMenu()
          }}
        >
          <ListItemIcon sx={({ spacing }) => ({ '&.MuiListItemIcon-root': { minWidth: spacing(7) } })}>
            <LabelOutlined />
          </ListItemIcon>
          <ListItemText>{formatMessage({ id: 'general.categorize' })}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            openDrawer(<DrawerViewAddProviderContactReason companyName={companyName} providerId={provider.id} />, {
              showAboveDialogs: true,
            })
            closeMenu()
          }}
        >
          <ListItemIcon sx={({ spacing }) => ({ '&.MuiListItemIcon-root': { minWidth: spacing(7) } })}>
            <PersonAddAlt1Outlined />
          </ListItemIcon>
          <ListItemText>{formatMessage({ id: 'schemas.addProviderContact.addContact' })}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            openDrawer(<DrawerViewDeleteProvider provider={provider} />, { showAboveDialogs: true })
            closeMenu()
          }}
        >
          <ListItemIcon sx={({ spacing }) => ({ '&.MuiListItemIcon-root': { minWidth: spacing(7) } })}>
            <DeleteOutlineOutlined />
          </ListItemIcon>
          <ListItemText>{formatMessage({ id: 'schemas.company.delete' })}</ListItemText>
        </MenuItem>
      </Menu>
    </DrawerView>
  )
}

export default DrawerViewCompany
