import { Permissions, usePermissions } from '@app/src/auth/permissions'
import StatusChip from '@app/src/components/StatusChip'
import { ExternalVerificationStatus, InquiryStatus, ResponseDraftStatus } from '@app/src/types/resourceExplorer'
import { isDateDueSoon, isPassedDeadline } from '@app/src/utils'
import React from 'react'
import { useIntl } from 'react-intl'
import ParentCellOrFragment from './ParentCellOrFragment'

type ExternalVerificationStatusCellProps = {
  responseStatus?: ExternalVerificationStatus
  draftStatus?: ResponseDraftStatus
  deadline?: string
  inquiryStatus?: InquiryStatus
}

const StatusChipFromVerificationStatuses: React.FC<ExternalVerificationStatusCellProps> = ({
  deadline,
  draftStatus,
  responseStatus,
  inquiryStatus,
}) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const isTransparency = hasPermission(Permissions.TRANSPARENCY_USER)

  //Check Inquiry Status first
  switch (inquiryStatus) {
    case InquiryStatus.CorrectionNeeded:
      return <StatusChip color="warning" label={formatMessage({ id: 'reporting.statuses.CorrectionNeeded' })} />
    case InquiryStatus.Approved:
      return <StatusChip color="success" label={formatMessage({ id: 'reporting.statuses.Approved' })} />
  }

  switch (responseStatus) {
    case ExternalVerificationStatus.CorrectionNeeded:
      return <StatusChip color="warning" label={formatMessage({ id: 'reporting.statuses.CorrectionNeeded' })} />
    case ExternalVerificationStatus.Approved:
      return <StatusChip color="success" label={formatMessage({ id: 'reporting.statuses.Approved' })} />
  }

  //Check submitted
  if (draftStatus === ResponseDraftStatus.Submitted)
    return (
      <StatusChip
        color={isTransparency ? 'default' : 'info'}
        label={formatMessage({
          id: isTransparency ? 'reporting.statuses.transparency.Submitted' : 'reporting.statuses.Submitted',
        })}
      />
    )

  const isScrapedResponse = !inquiryStatus
  if (draftStatus === ResponseDraftStatus.Scraped && (isScrapedResponse || !isTransparency)) {
    return (
      <StatusChip
        color="communication"
        label={formatMessage({
          id: isTransparency ? 'reporting.statuses.transparency.Scraped' : 'reporting.statuses.Scraped',
        })}
      />
    )
  }

  if (draftStatus === ResponseDraftStatus.Scraped && isScrapedResponse) {
    return (
      <StatusChip
        color="communication"
        label={formatMessage({
          id: isTransparency ? 'reporting.statuses.transparency.Scraped' : 'reporting.statuses.Scraped',
        })}
      />
    )
  }

  //Check deadline
  if (isDateDueSoon(deadline))
    return <StatusChip color="warning" label={formatMessage({ id: 'reporting.statuses.due.DueSoon' })} />
  if (isPassedDeadline(deadline))
    return <StatusChip color="error" label={formatMessage({ id: 'reporting.statuses.due.Overdue' })} />

  //Check draft
  if (draftStatus === ResponseDraftStatus.Draft)
    return <StatusChip color="default" label={formatMessage({ id: 'reporting.statuses.Draft' })} />

  return (
    <StatusChip color={isTransparency ? 'info' : 'default'} label={formatMessage({ id: 'reporting.statuses.Sent' })} />
  )
}

const ExternalVerificationStatusCell: React.FC<ExternalVerificationStatusCellProps> = ({
  responseStatus,
  draftStatus,
  deadline,
  inquiryStatus,
}) => (
  <ParentCellOrFragment width={10}>
    <StatusChipFromVerificationStatuses
      responseStatus={responseStatus}
      draftStatus={draftStatus}
      deadline={deadline}
      inquiryStatus={inquiryStatus}
    />
  </ParentCellOrFragment>
)

export default ExternalVerificationStatusCell
