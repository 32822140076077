import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { Response } from '@app/src/types/resourceExplorer'
import { CheckboxProps, TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface AccessorScrapedResponseHeaderProps extends SortableHeaderProps {
  isHeaderChecked?: boolean
  setSelectedResponsesIds?: React.Dispatch<React.SetStateAction<Array<number>>>
  responsesInPage?: Response[]
}

const AccessorScrapedResponseHeader: React.FC<AccessorScrapedResponseHeaderProps> = ({
  toggleSorting,
  activeSorting,
  responsesInPage,
  isHeaderChecked,
  setSelectedResponsesIds,
}) => {
  const { formatMessage } = useIntl()

  const shouldShowCheckboxRow = setSelectedResponsesIds && responsesInPage

  const handleCheckboxChange: CheckboxProps['onChange'] = (_e, checked) => {
    if (!shouldShowCheckboxRow) return
    if (checked) {
      setSelectedResponsesIds(prev => Array.from(new Set([...prev, ...responsesInPage.map(r => r.id)])))
    } else {
      setSelectedResponsesIds([])
    }
  }

  return (
    <TableRow>
      {shouldShowCheckboxRow && (
        <TableHeaderCell
          label=""
          checkbox={{ checked: isHeaderChecked, onChange: handleCheckboxChange, color: 'primary' }}
          minWidth={TableCellWidth.EMPTY}
        />
      )}
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.requestStatus' })}
        guidance={formatMessage({ id: 'schemas.request.guidance.status' })}
        minWidth={TableCellWidth.EMPTY}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.request' })}
        sorting={{ name: 'request.template.title', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.periodDisplayName' })}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.request.target' })} />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.createdAt' })}
        sorting={{ name: 'createdAt', toggleSorting, activeSorting }}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.request.source' })} />
    </TableRow>
  )
}

export default AccessorScrapedResponseHeader
