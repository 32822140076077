import endpoints from '@app/src/api/endpoints'
import { useCreateResource } from '@app/src/api/updateHooks'
import useErrorNotification from '@app/src/hooks/errorNotification'

type ScheduleScreeningPayload = {
  providerIds: number[]
  templateIds: number[]
}

export const useScheduleScreening = () => {
  const { showErrorNotification } = useErrorNotification()
  const { mutateAsync: sendScheduleScreening, isLoading: isScheduling } = useCreateResource<ScheduleScreeningPayload>()

  const scheduleScreening = async (payload: ScheduleScreeningPayload) => {
    await sendScheduleScreening(
      {
        url: endpoints.scheduleScreening,
        body: payload,
      },
      {
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  return { scheduleScreening, isScheduling }
}
