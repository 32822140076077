import FailedToFetchDataEmptyState from '@app/src/components/EmptyState/FailedToFetchDataEmptyState'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import StatisticsCard from '../StatisticsCard'

interface OpenRequestsCardProps {
  pendingRequestsCount: number
  isLoading: boolean
  isError: boolean
}

const OpenRequestsCard: React.FC<OpenRequestsCardProps> = ({ pendingRequestsCount, isLoading, isError }) => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { stringifyQueryFilters } = useStringifyQueryFilters()

  if (isLoading) {
    return (
      <Box height="100%" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <StatisticsCard
      title={formatMessage({ id: 'transparencyOverview.openRequests' })}
      action={
        <Button
          size="small"
          onClick={() =>
            history.push(
              stringifyQueryFilters({
                url: generatePath(paths.manageRequest, {
                  view: ViewTypeName.Requests,
                }),
                queryParams: {
                  filters: [
                    {
                      name: 'status',
                      value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
                      operator: Operators.In,
                    },
                  ],
                },
                includeCurrentFilters: true,
              }),
            )
          }
        >
          {formatMessage({ id: 'dashboard.investment.seeAll' })}
        </Button>
      }
      justifyContent="left"
      withBorder
    >
      {isError ? (
        <FailedToFetchDataEmptyState />
      ) : (
        <Box display="flex" alignItems="center" py={2}>
          <Typography variant="kpi">{pendingRequestsCount}</Typography>
        </Box>
      )}
    </StatisticsCard>
  )
}

export default OpenRequestsCard
