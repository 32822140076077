import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import FailedToFetchDataEmptyState from '@app/src/components/EmptyState/FailedToFetchDataEmptyState'
import HoverDialog from '@app/src/components/HoverDialog'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import CompanyStatisticsGraphSkeleton from '@app/src/pages/Dashboards/SourcingDashboard/CompaniesStatistics/CompanyStatisticsGraphSkeleton'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ProviderFinalRiskRatingValues } from '@app/src/wf-constants'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useIntl } from 'react-intl'
import GraphLegend from '../../GraphLegend'
import { companiesListAllowedFilters } from './CompaniesList'
import CompaniesStatisticGraph from './CompaniesStatisticGraph'
import { handleFilterValue } from './CompaniesStatistics'

const FinalRiskRatingGraph = () => {
  const { palette } = useTheme()
  const filters = useGetApiQueryFilters(companiesListAllowedFilters)
  const [highlightedRisk, setHighlightedRisk] = useState<string | number>()
  const { formatMessage } = useIntl()
  const { inView, ref } = useInView({ triggerOnce: true })

  const {
    facets: [providerFinalRiskRating],
    isError: providerFinalRiskRatingIsError,
    isLoading,
  } = useFetchFacets({
    key: FetchKey.ProviderFacets,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'finalRiskRating' }],
    filter: filters,
    options: {
      enabled: inView,
    },
    shouldShowErrorNotification: false,
  })

  const notSelectedCount =
    providerFinalRiskRating?.find(company => company.label === ProviderFinalRiskRatingValues.NotSelected)?.count ?? 0
  const lowRiskCount =
    providerFinalRiskRating?.find(company => company.label === ProviderFinalRiskRatingValues.LowRisk)?.count ?? 0
  const mediumRiskCount =
    providerFinalRiskRating?.find(company => company.label === ProviderFinalRiskRatingValues.MediumRisk)?.count ?? 0
  const highRiskCount =
    providerFinalRiskRating?.find(company => company.label === ProviderFinalRiskRatingValues.HighRisk)?.count ?? 0
  const extremeRiskCount =
    providerFinalRiskRating?.find(company => company.label === ProviderFinalRiskRatingValues.ExtremeRisk)?.count ?? 0

  const totalCompanies = notSelectedCount + lowRiskCount + mediumRiskCount + highRiskCount + extremeRiskCount

  if (providerFinalRiskRatingIsError)
    return (
      <Box display="flex" alignItems="center" height="100%">
        <FailedToFetchDataEmptyState />
      </Box>
    )

  return (
    <HoverDialog
      content={
        <Box p={2} onMouseEnter={() => setHighlightedRisk(undefined)}>
          <Stack spacing={1}>
            <Typography variant="overline" color="textSecondary" noWrap>
              {formatMessage({ id: 'dashboard.sourcing.companyStatistics.companiesByFinalRiskRating' })}
            </Typography>
            <Filter operator={Operators.In} name="finalRiskRating">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedRisk === ProviderFinalRiskRatingValues.ExtremeRisk ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedRisk(ProviderFinalRiskRatingValues.ExtremeRisk)}
                  onMouseLeave={() => setHighlightedRisk(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderFinalRiskRatingValues.ExtremeRisk))}
                >
                  <GraphLegend
                    color={palette.semantic.error}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.finalRiskRatingValues.E_RedWithCount' },
                      { count: extremeRiskCount },
                    )}
                  />
                  {highlightedRisk === ProviderFinalRiskRatingValues.ExtremeRisk && (
                    <Button
                      onClick={() => onChange(handleFilterValue(value, ProviderFinalRiskRatingValues.ExtremeRisk))}
                    >
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
            <Filter operator={Operators.In} name="finalRiskRating">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedRisk === ProviderFinalRiskRatingValues.HighRisk ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedRisk(ProviderFinalRiskRatingValues.HighRisk)}
                  onMouseLeave={() => setHighlightedRisk(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderFinalRiskRatingValues.HighRisk))}
                >
                  <GraphLegend
                    color={palette.error.dark}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.finalRiskRatingValues.D_OrangeWithCount' },
                      { count: highRiskCount },
                    )}
                  />
                  {highlightedRisk === ProviderFinalRiskRatingValues.HighRisk && (
                    <Button onClick={() => onChange(handleFilterValue(value, ProviderFinalRiskRatingValues.HighRisk))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
            <Filter operator={Operators.In} name="finalRiskRating">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedRisk === ProviderFinalRiskRatingValues.MediumRisk ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedRisk(ProviderFinalRiskRatingValues.MediumRisk)}
                  onMouseLeave={() => setHighlightedRisk(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderFinalRiskRatingValues.MediumRisk))}
                >
                  <GraphLegend
                    color={palette.warning.dark}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.finalRiskRatingValues.C_YellowWithCount' },
                      { count: mediumRiskCount },
                    )}
                  />
                  {highlightedRisk === ProviderFinalRiskRatingValues.MediumRisk && (
                    <Button
                      onClick={() => onChange(handleFilterValue(value, ProviderFinalRiskRatingValues.MediumRisk))}
                    >
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
            <Filter operator={Operators.In} name="finalRiskRating">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedRisk === ProviderFinalRiskRatingValues.LowRisk ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedRisk(ProviderFinalRiskRatingValues.LowRisk)}
                  onMouseLeave={() => setHighlightedRisk(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderFinalRiskRatingValues.LowRisk))}
                >
                  <GraphLegend
                    color={palette.info.dark}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.finalRiskRatingValues.B_GreenWithCount' },
                      { count: lowRiskCount },
                    )}
                  />
                  {highlightedRisk === ProviderFinalRiskRatingValues.LowRisk && (
                    <Button onClick={() => onChange(handleFilterValue(value, ProviderFinalRiskRatingValues.LowRisk))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>

            <Filter operator={Operators.In} name="finalRiskRating">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedRisk === ProviderFinalRiskRatingValues.NotSelected ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedRisk(ProviderFinalRiskRatingValues.NotSelected)}
                  onMouseLeave={() => setHighlightedRisk(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderFinalRiskRatingValues.NotSelected))}
                >
                  <GraphLegend
                    color={palette.grey[300]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.finalRiskRatingValues.A_NotSelectedWithCount' },
                      { count: notSelectedCount },
                    )}
                  />
                  {highlightedRisk === ProviderFinalRiskRatingValues.NotSelected && (
                    <Button
                      onClick={() => onChange(handleFilterValue(value, ProviderFinalRiskRatingValues.NotSelected))}
                    >
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
          </Stack>
        </Box>
      }
    >
      <Box ref={ref}>
        {isLoading ? (
          <CompanyStatisticsGraphSkeleton />
        ) : (
          <CompaniesStatisticGraph
            highlightSetter={setHighlightedRisk}
            title={formatMessage({ id: `dashboard.sourcing.companyStatistics.finalRiskRating` })}
            data={[
              { value: extremeRiskCount, name: ProviderFinalRiskRatingValues.ExtremeRisk },
              { value: highRiskCount, name: ProviderFinalRiskRatingValues.HighRisk },
              { value: mediumRiskCount, name: ProviderFinalRiskRatingValues.MediumRisk },
              { value: lowRiskCount, name: ProviderFinalRiskRatingValues.LowRisk },
              { value: notSelectedCount, name: ProviderFinalRiskRatingValues.NotSelected },
            ]}
            colors={[
              palette.semantic.error,
              palette.error.dark,
              palette.warning.dark,
              palette.info.dark,
              palette.grey[300],
            ]}
            value={Math.ceil(100 - (notSelectedCount * 100) / totalCompanies)}
          />
        )}
      </Box>
    </HoverDialog>
  )
}

export default FinalRiskRatingGraph
