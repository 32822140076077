import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCount } from '@app/src/api/fetchHooks'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import CompaniesStatisticsFilterContextProvider from '@app/src/context/CompaniesStatisticsFilterContextProvider'
import { DataHubView } from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { Equalizer } from '@mui/icons-material'
import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import AccessorReportingProgressCard from '../AccessorReportingProgressCard'
import CompaniesCard from '../CompaniesCard'
import DiscoverButton from '../DiscoverButton'
import GoalTrackerSection from '../GoalTracker/GoalTrackerSection'
import PendingRequestsCard from '../PendingRequestsCard'
import RequestsByCompanyTable from '../RequestsByCompany'
import ResponsesCard from '../ResponsesCard'
import ScrapedAnswerByDataFound from '../ScrapedAnswerByDataFound'

const GroupCompaniesDashboard: React.FC = () => {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const { trackEvent } = useAmplitude()
  const history = useHistory()
  const { aiScraping } = useFlags()

  const {
    count: providersCount = 0,
    isLoading: providersCountLoading,
    isError: providerCountIsError,
  } = useFetchCount({
    key: FetchKey.ProviderCount,
    endpoint: endpoints.providerCount,
    payload: [],
    shouldShowErrorNotification: false,
  })

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Dashboard.Overview.ViewedPage,
    })
  }, [])

  return (
    <Box mt={2} display="flex" flexDirection="column" flexGrow={1}>
      <Helmet>
        <title>{formatMessage({ id: 'navbar.dashboard' })}</title>
      </Helmet>
      <Box px={4} py={2}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h1">{formatMessage({ id: 'navbar.dashboard' })}</Typography>
          </Box>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              endIcon={<Equalizer />}
              onClick={() => {
                history.push(
                  generatePath(paths.dataHubCollection, {
                    view: DataHubView.DataInsights,
                  }),
                )
              }}
            >
              {formatMessage({ id: 'dashboard.investment.analyzeData' })}
            </Button>
            <DiscoverButton />
          </Stack>
        </Box>
      </Box>
      <Box px={4} py={2} bgcolor={palette.grey[100]} flexGrow={1} display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <CompaniesCard
              totalCompanies={providersCount}
              isLoading={providersCountLoading}
              totalCompaniesError={providerCountIsError}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <PendingRequestsCard />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ResponsesCard />
          </Grid>
          {aiScraping && (
            <Grid item xs={12} lg={6}>
              <ScrapedAnswerByDataFound />
            </Grid>
          )}
          <Grid item xs={12} lg={aiScraping ? 6 : 12}>
            <AccessorReportingProgressCard />
          </Grid>
          <Grid item xs={12}>
            <GoalTrackerSection />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12}>
            <CompaniesStatisticsFilterContextProvider>
              <RequestsByCompanyTable
                hasReferralCode
                providersCount={providersCount}
                isLoading={providersCountLoading}
                providerCountIsError={providerCountIsError}
              />
            </CompaniesStatisticsFilterContextProvider>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default GroupCompaniesDashboard
