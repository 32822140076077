import ImageWithContentDialog from '@app/src/components/Dialog/ImageWithContentDialog'
import Avatar from '@app/src/components/Ui/Avatar'
import { useAccount } from '@app/src/context/AccountContext'
import { palette } from '@app/src/theme/palette'
import {
  CheckBoxOutlined,
  CheckOutlined,
  CommentBankOutlined,
  LanguageOutlined,
  TimerOutlined,
  ToggleOnOutlined,
} from '@mui/icons-material'
import { Button, Chip, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import useIntercom from '../hooks/intercom'

interface AiScreeningInfoDialogProps {
  isScraped: boolean
  open: boolean
  onClose: () => void
}

const AiScreeningInfoDialog: FC<AiScreeningInfoDialogProps> = ({ isScraped, open, onClose }) => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()
  const { sendNewMessage } = useIntercom()

  const handleGetAccessClick = () => {
    const message = `${formatMessage({ id: 'schemas.aiScreeningMessage.firstLineText' })}\n\n${formatMessage({
      id: 'schemas.aiScreeningMessage.secondLineText',
    })}`
    sendNewMessage(message)
  }

  return (
    <ImageWithContentDialog imageUrl="/img/ai-screening.webp" open={open} onClose={onClose}>
      <Stack spacing={2}>
        <>
          <Typography variant="h3">
            {formatMessage({ id: 'schemas.aiScreeningInfo.title' })} <Chip size="small" label="Beta" />
          </Typography>
          <Typography>
            {isScraped
              ? formatMessage(
                  { id: 'schemas.aiScreeningInfo.aiScreeningOn.description' },
                  { companyName: account?.organization?.name },
                )
              : formatMessage(
                  { id: 'schemas.aiScreeningInfo.aiScreeningOff.description' },
                  { companyName: account?.organization?.name },
                )}
          </Typography>
        </>
        <List>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                sx={{
                  width: 48,
                  height: 48,
                  color: palette.communication.dark,
                  backgroundColor: palette.communication.light,
                }}
              >
                {isScraped ? <ToggleOnOutlined /> : <CheckOutlined />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                isScraped
                  ? formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOn.item1Title',
                    })
                  : formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOff.item1Title',
                    })
              }
              secondary={
                isScraped
                  ? formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOn.item1Description',
                    })
                  : formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOff.item1Description',
                    })
              }
              primaryTypographyProps={{ variant: 'subtitle1' }}
              secondaryTypographyProps={{ color: 'textPrimary', variant: 'body1' }}
            />
          </ListItem>
          <ListItem disablePadding alignItems="flex-start" sx={{ mt: 2 }}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  width: 48,
                  height: 48,
                  color: palette.communication.dark,
                  backgroundColor: palette.communication.light,
                }}
              >
                {isScraped ? <CheckBoxOutlined /> : <TimerOutlined />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                isScraped
                  ? formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOn.item2Title',
                    })
                  : formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOff.item2Title',
                    })
              }
              secondary={
                isScraped
                  ? formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOn.item2Description',
                    })
                  : formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOff.item2Description',
                    })
              }
              primaryTypographyProps={{ variant: 'subtitle1' }}
              secondaryTypographyProps={{ color: 'textPrimary', variant: 'body1' }}
            />
          </ListItem>
          <ListItem disablePadding alignItems="flex-start" sx={{ mt: 2 }}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  width: 48,
                  height: 48,
                  color: palette.communication.dark,
                  backgroundColor: palette.communication.light,
                }}
              >
                {isScraped ? <TimerOutlined /> : <LanguageOutlined />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                isScraped
                  ? formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOn.item3Title',
                    })
                  : formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOff.item3Title',
                    })
              }
              secondary={
                isScraped
                  ? formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOn.item3Description',
                    })
                  : formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOff.item3Description',
                    })
              }
              primaryTypographyProps={{ variant: 'subtitle1' }}
              secondaryTypographyProps={{ color: 'textPrimary', variant: 'body1' }}
            />
          </ListItem>
          <ListItem disablePadding alignItems="flex-start" sx={{ mt: 2 }}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  width: 48,
                  height: 48,
                  color: palette.communication.dark,
                  backgroundColor: palette.communication.light,
                }}
              >
                <CommentBankOutlined />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                isScraped
                  ? formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOn.item4Title',
                    })
                  : formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOff.item4Title',
                    })
              }
              secondary={
                isScraped
                  ? formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOn.item4Description',
                    })
                  : formatMessage({
                      id: 'schemas.aiScreeningInfo.aiScreeningOff.item4Description',
                    })
              }
              primaryTypographyProps={{ variant: 'subtitle1' }}
              secondaryTypographyProps={{ color: 'textPrimary', variant: 'body1' }}
            />
          </ListItem>
        </List>

        <Stack direction="row" spacing={1}>
          <Typography color={isScraped ? 'textSecondary' : 'textPrimary'}>
            {isScraped && formatMessage({ id: 'schemas.aiScreeningInfo.aiScreeningOn.note' })}
          </Typography>
        </Stack>
        {!isScraped && (
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button variant="contained" onClick={handleGetAccessClick}>
              {formatMessage({ id: 'schemas.aiScreeningInfo.aiScreeningOff.ctaTitle' })}
            </Button>
          </Stack>
        )}
      </Stack>
    </ImageWithContentDialog>
  )
}

export default AiScreeningInfoDialog
