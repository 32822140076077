import { ActionButton } from '@app/src/components/ActionButtons'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import DrawerViewApproveAllWhenFlags from '@app/src/components/Drawer/Views/Verification/DrawerViewApproveAllWhenFlags'
import Select, { Option } from '@app/src/components/Form/Select/ControlledSelect'
import ControlledTextFieldWithFormatting from '@app/src/components/TextFieldWithFormatting/ControlledTextFieldWithFormatting'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import CorrectionNeededExplanationList from '@app/src/pages/Questionnaire/Accessor/Actions/CorrectionNeededExplanationList'
import useAccessorQuestionnaire from '@app/src/pages/Questionnaire/hooks/useAccessorQuestionnaire'
import { ReportFlagType } from '@app/src/types/flags'
import { ExternalVerificationStatus, Response } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { FormControlLabel, Stack, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

type DrawerViewVerifyAllQuestionsInResponseProps = {
  response: Response
  onVerificationSubmitted: () => void
} & Omit<DrawerViewProps, 'title'>

const MULTIPLE_STATUSES = 'MultipleStatuses'

type VerificationStatusOptions = ExternalVerificationStatus | typeof MULTIPLE_STATUSES

interface FormData {
  externalVerificationStatus: VerificationStatusOptions
  responseComment: string
}

const getItemsThatDontRequireAction = (response: Response) => {
  return response.items.filter(
    f =>
      f.flag !== ReportFlagType.Red &&
      (f.verifications?.every(f => f.externalVerificationStatus !== ExternalVerificationStatus.CorrectionNeeded) ??
        true),
  )
}

const hasMultipleStatuses = (response: Response) => {
  const existingVerificationStatuses = response.items.map(
    it => it?.verifications?.[0]?.externalVerificationStatus ?? ExternalVerificationStatus.NotSet,
  )
  return (
    existingVerificationStatuses.length > 0 &&
    existingVerificationStatuses.some(status => status !== existingVerificationStatuses[0])
  )
}

const getPreselectedVerificationStatus = (response: Response): VerificationStatusOptions => {
  if (hasMultipleStatuses(response)) return MULTIPLE_STATUSES
  const verification = response.verifications?.[0]
  return verification?.externalVerificationStatus === ExternalVerificationStatus.CorrectionNeeded
    ? ExternalVerificationStatus.NotSet
    : verification?.externalVerificationStatus ?? ExternalVerificationStatus.NotSet
}
const DrawerViewVerifyAllQuestionsInResponse: React.FC<DrawerViewVerifyAllQuestionsInResponseProps> = ({
  response,
  onVerificationSubmitted,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { openDrawer, closeDrawer } = useDrawer()
  const [showExternalVerificationMessage, setShowExternalVerificationMessage] = useState(false)
  const { externalReviewAllItems, isLoadingCreateVerification } = useAccessorQuestionnaire()
  const { trackEvent } = useAmplitude()

  const formMethods = useForm<FormData>()

  const { watch, control, reset, handleSubmit } = formMethods
  const verification = response.verifications?.[0]
  const comment =
    verification?.externalVerificationStatus === ExternalVerificationStatus.Approved
      ? verification?.externalVerificationComment ?? ''
      : ''

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Accessor.ResponseVerification.ExternalVerification.Opened,
    })
    reset({
      responseComment: comment,
      externalVerificationStatus: getPreselectedVerificationStatus(response),
    })
    if (comment) setShowExternalVerificationMessage(true)
  }, [response])

  const externalVerificationStatus: VerificationStatusOptions = watch('externalVerificationStatus')
  const responseComment: string = watch('responseComment')

  const onClickSubmit = async (formData: FormData) => {
    if (formData.externalVerificationStatus === MULTIPLE_STATUSES) return

    const itemsThatDontRequireAction = getItemsThatDontRequireAction(response)
    const someItemsRequireAction = itemsThatDontRequireAction.length !== response.items.length

    if (someItemsRequireAction && formData.externalVerificationStatus === ExternalVerificationStatus.Approved)
      openDrawer(
        <DrawerViewApproveAllWhenFlags
          response={response}
          itemsThatDontRequireAction={itemsThatDontRequireAction ?? []}
          responseComment={responseComment}
          onVerificationSubmitted={onVerificationSubmitted}
        />,
      )
    else {
      await externalReviewAllItems({
        responseId: response.id,
        responseItemIds: response.items.map(it => it.id),
        externalVerificationStatus: formData.externalVerificationStatus,
        responseExternalVerificationComment: formData.responseComment,
      })
      trackEvent({
        name: AmplitudeTrackingEvents.Accessor.ResponseVerification.ExternalVerification.Submitted,
        eventProps: {
          verificationStatus: formData.externalVerificationStatus,
          hasComment: Boolean(formData.responseComment),
        },
      })
      onVerificationSubmitted()
      closeDrawer()
    }
  }

  return (
    <FormProvider {...formMethods}>
      <DrawerView
        title={formatMessage({
          id: 'reporting.verification.review',
        })}
        subTitle={formatMessage(
          { id: 'reporting.verification.responseVerificationSubHeader' },
          { questionsCount: response.items.length },
        )}
        stackButtons
        onFormSubmit={handleSubmit(onClickSubmit)}
        buttons={[
          {
            label: formatMessage({
              id:
                externalVerificationStatus === ExternalVerificationStatus.CorrectionNeeded
                  ? 'general.close'
                  : 'general.cancel',
            }),
            variant: 'text',
            disabled: isLoadingCreateVerification,
            onClick: closeDrawer,
          },
          ...insertIf<ActionButton>(externalVerificationStatus !== ExternalVerificationStatus.CorrectionNeeded, {
            label: formatMessage({ id: 'general.submit' }),
            variant: 'contained',
            disabled: externalVerificationStatus === MULTIPLE_STATUSES,
            loading: isLoadingCreateVerification,
            type: 'submit',
          }),
        ]}
        {...props}
      >
        <Stack pt={2} px={2} height="100%" display="flex">
          <Select<VerificationStatusOptions>
            name="externalVerificationStatus"
            control={control}
            fieldLabel={formatMessage({ id: 'general.selectStatus' })}
            variant="outlined"
            options={[
              ...insertIf<Option<VerificationStatusOptions>>(hasMultipleStatuses(response), {
                value: MULTIPLE_STATUSES,
                label: formatMessage({ id: 'reporting.reviewStatuses.multipleStatuses' }),
              }),
              {
                value: ExternalVerificationStatus.NotSet,
                label: formatMessage({
                  id: 'reporting.reviewStatuses.NotSet',
                }),
              },
              {
                value: ExternalVerificationStatus.Approved,
                label: formatMessage({ id: 'reporting.reviewStatuses.ResponseApproved' }),
              },
              {
                value: ExternalVerificationStatus.CorrectionNeeded,
                label: formatMessage({ id: 'reporting.reviewStatuses.ResponseCorrectionNeeded' }),
              },
            ]}
          />
          {externalVerificationStatus === ExternalVerificationStatus.Approved && (
            <>
              <FormControlLabel
                sx={{ mt: 2 }}
                control={
                  <Switch
                    checked={showExternalVerificationMessage}
                    onChange={() => setShowExternalVerificationMessage(prev => !prev)}
                  />
                }
                label={formatMessage({ id: 'reporting.verification.addMessage' })}
              />

              {showExternalVerificationMessage && (
                <ControlledTextFieldWithFormatting
                  name="responseComment"
                  control={control}
                  defaultValue={comment}
                  placeholder={formatMessage({ id: 'reporting.verification.message' })}
                  multiline
                  size="medium"
                  hideUndo
                  fillHeight
                  maxLength={1000}
                />
              )}
            </>
          )}
          {externalVerificationStatus === ExternalVerificationStatus.CorrectionNeeded && (
            <CorrectionNeededExplanationList />
          )}
        </Stack>
      </DrawerView>
    </FormProvider>
  )
}

export default DrawerViewVerifyAllQuestionsInResponse
