import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { CircularProgress, LinearProgress, Stack, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'

const ScreeningInProgress = () => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  return (
    <Stack width="100%" spacing={3} mt={2}>
      <LinearProgress
        sx={({ palette }) => ({
          height: 15,
          '& .MuiLinearProgress-bar': {
            backgroundColor: palette.communication.main,
          },
          '&.MuiLinearProgress-root': {
            backgroundColor: palette.communication.light,
          },
        })}
      />
      <Stack direction="row" spacing={1} alignItems="center">
        <CircularProgress size={20} sx={{ color: palette.communication.dark }} />
        <Typography variant="caption" color="communication.dark">
          {formatMessage({ id: 'dashboard.sourcing.scrapedAnswerByDataFound.aiScrapingInProgress' })}
        </Typography>
        <Tooltip
          title={formatMessage({ id: 'dashboard.sourcing.scrapedAnswerByDataFound.aiScrapingInProgressHelperText' })}
          arrow
        >
          <InfoOutlinedIcon
            sx={({ palette }) => ({
              color: palette.text.secondary,
              '&:hover': { backgroundColor: 'grey.200', borderRadius: 4, cursor: 'pointer' },
            })}
            fontSize="small"
          />
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export default ScreeningInProgress
