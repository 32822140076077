import ProvidersModalContext, { ProvidersModalPurpose } from '@app/src/context/ProvidersModalContext'
import React, { useMemo, useState } from 'react'
import { useDialogState } from '../hooks/mui-hooks'
import { Provider } from '../types/organizations'

interface Props {
  children: JSX.Element
}

const ProvidersModalContextProvider = ({ children }: Props): JSX.Element => {
  const [isConnectDialogOpen, openConnectDialog, closeConnectDialog] = useDialogState(false)
  const [providersToConnect, setProvidersToConnect] = useState<Provider[]>([])
  const [modalPurpose, setModalPurpose] = useState<ProvidersModalPurpose>(ProvidersModalPurpose.InitialInvitation)

  const updateProviderToConnect = (updatedProvider: Provider) => {
    setProvidersToConnect(prev => [...prev.filter(p => p.id !== updatedProvider.id), updatedProvider])
  }

  const handleOpenConnectDialog = (providers: Provider[], modalPurpose: ProvidersModalPurpose) => {
    setProvidersToConnect(providers)
    setModalPurpose(modalPurpose)
    openConnectDialog()
  }

  const handleCloseConnectDialog = () => {
    closeConnectDialog()
    setProvidersToConnect([])
  }

  const contextValue = useMemo(
    () => ({
      isConnectDialogOpen,
      handleCloseConnectDialog,
      handleOpenConnectDialog,
      providersToConnect,
      updateProviderToConnect,
      modalPurpose,
    }),
    [isConnectDialogOpen, closeConnectDialog, handleOpenConnectDialog, providersToConnect, setProvidersToConnect],
  )

  return <ProvidersModalContext.Provider value={contextValue}>{children}</ProvidersModalContext.Provider>
}

export default ProvidersModalContextProvider
