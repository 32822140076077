import { Chip, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import ParentCellOrFragment from './ParentCellOrFragment'

type TagsCellProps = {
  tags: Array<string>
  amountOfTagsShow: number
  disableCell?: boolean
}

const TagsCell: React.FC<TagsCellProps> = ({ tags, amountOfTagsShow, disableCell }) => {
  const { formatMessage } = useIntl()
  const [showAll, setShowAll] = useState(false)

  if (showAll) {
    return (
      <ParentCellOrFragment disableCell={disableCell}>
        {tags.map(tag => (
          <Chip key={tag} variant="outlined" size="small" label={tag} sx={{ mr: 1, my: 0.5 }} />
        ))}
      </ParentCellOrFragment>
    )
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      {tags
        ?.slice(0, amountOfTagsShow)
        .map(tag => <Chip key={tag} variant="outlined" size="small" label={tag} sx={{ mr: 1, my: 0.5 }} />)}

      {tags?.length > amountOfTagsShow && (
        <Tooltip title={formatMessage({ id: 'general.showAll' })}>
          <Chip
            variant="filled"
            size="small"
            label={formatMessage({ id: 'schemas.supplier.providerTags' }, { amount: tags?.length - amountOfTagsShow })}
            sx={{ mr: 1, my: 0.5 }}
            onClick={() => setShowAll(true)}
          />
        </Tooltip>
      )}
    </ParentCellOrFragment>
  )
}

export default TagsCell
