import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import * as echarts from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { AnswersGroupingType } from '../../../../Filters/StatisticsConstants'
import { OptionResultItemMultiPeriod, ResultForGroup, StatisticsForQuestionPerGroup } from '../../../DataHubScene'
import { useDataInsightsModal } from '../DataInsightsModalContext'
import { useOptionCategoryChartConfigs } from './CategoryChartsConfigs/useOptionCategoryChartConfigs'
import { useOptionMultiPeriodChartConfigs } from './PeriodChartsConfigs/useOptionMultiPeriodChartConfigs'
import { SummaryChartDataTypes } from './PeriodChartsConfigs/usePeriodSummaryChartConfigs'
import { useOptionSpendChartConfigs } from './SpendChartsConfigs/useOptionSpendChartConfigs'

interface OptionBarProps {
  statistics: StatisticsForQuestionPerGroup
  height: string
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
  selectedChartType: ChartType
  isFullWidth?: boolean
}

export interface EchartClickItemData {
  name: string
  value: number
  groupName: string
  groupId?: string
  summaryType?: SummaryChartDataTypes
  answersSelected: string[]
}

interface OptionsForPeriod {
  groupName: string
  groupId?: string
  options: OptionResultItemMultiPeriod[]
  notIncludedItems: string[]
}

const getOptionsForGroup = (resultForGroup: ResultForGroup, otherText: string): OptionsForPeriod => {
  const optionResult = resultForGroup.optionStatisticsViews
  const numberOfItemsInView = 24
  const optionStatisticsView = optionResult?.find(op => op.maxItemCount === numberOfItemsInView) ?? optionResult?.[0]
  const itemsInView = optionStatisticsView?.items ?? []

  const mappedItems = itemsInView.map(r => (r.isOther ? { ...r, name: otherText } : r))

  const notIncludedItems = optionStatisticsView?.valuesInOther ?? []

  return {
    groupName: resultForGroup.groupName,
    groupId: resultForGroup.groupId,
    options: mappedItems,
    notIncludedItems,
  }
}

export const OptionMultiGroupChart: React.FC<OptionBarProps> = ({
  statistics,
  height,
  echartsRef,
  selectedChartType,
  isFullWidth,
}) => {
  const { openDataInsightsModal } = useDataInsightsModal()
  const { formatMessage } = useIntl()
  const { groupBy } = useGroupBySelector()

  echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, SVGRenderer, LegendComponent])

  const resultsForGroups = statistics.resultForGroups
  const optionItemResult = useMemo(
    () => resultsForGroups?.map(r => getOptionsForGroup(r, formatMessage({ id: 'general.other' }))) ?? [],
    [formatMessage, resultsForGroups, selectedChartType],
  )
  const events = useMemo(() => {
    return {
      click: ({ data }: { data: EchartClickItemData }) => {
        const userSelection = {
          answer: data.answersSelected,
          groupName: data.groupName,
          groupId: data.groupId,
        }
        openDataInsightsModal(statistics, userSelection)
      },
    }
  }, [openDataInsightsModal, statistics])

  const periodOptions = useOptionMultiPeriodChartConfigs({
    optionItemResult,
    selectedChartType,
    itemsInOther: optionItemResult[0].notIncludedItems,
    echartsRef,
  })

  const spendOptions = useOptionSpendChartConfigs({
    optionItemResult,
    selectedChartType,
    itemsInOther: optionItemResult[0].notIncludedItems,
    echartsRef,
  })

  const categoryOptions = useOptionCategoryChartConfigs({
    optionItemResult,
    selectedChartType,
    itemsInOther: optionItemResult[0].notIncludedItems,
    echartsRef,
    isFullWidth,
  })

  const getChartOptions = useMemo(() => {
    switch (groupBy) {
      case AnswersGroupingType.SpendClassification:
        return spendOptions

      case AnswersGroupingType.PeriodName:
        return periodOptions
      default:
        return categoryOptions
    }
  }, [groupBy, periodOptions, spendOptions, categoryOptions])

  return (
    <>
      <ReactEChartsCore
        onEvents={events}
        option={getChartOptions}
        echarts={echarts}
        style={{ minWidth: '100%', height }}
        ref={echartsRef}
      />
    </>
  )
}
