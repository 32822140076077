import { useEchartsContainerWidth } from '@app/src/hooks/useEchartsContainerWidth'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { getMinMaxOnYAxisCountCharts } from '@app/src/utils/statisticsUtils'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { QuestionMonitoring, ResultForGroup } from '../../../../DataHubScene'
import { getLegendSpacing } from '../chartsUtils'
import { colors, NOT_SELECTED_COLOR, NOT_SELECTED_GROUP_NAME } from './categoryChartConfigsUtils'

interface TextAndFileCategoryChartConfigsParams {
  resultsForGroup: ResultForGroup[]
  legendTitle: string
  selectedChartType: ChartType
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
}

const getMinMaxYAxis = (optionItemResult: { questionMonitoring: QuestionMonitoring }[]) => {
  const haveAnsweredValues = optionItemResult.map(res => res.questionMonitoring.companiesHaveAnswered.number)
  return getMinMaxOnYAxisCountCharts(haveAnsweredValues)
}

export const useTextAndFileCategoryChartConfigs = ({
  resultsForGroup,
  legendTitle,
  selectedChartType,
  echartsRef,
}: TextAndFileCategoryChartConfigsParams) => {
  const { typography } = useTheme()
  const { formatMessage } = useIntl()
  const widthOfChart = useEchartsContainerWidth(echartsRef)

  const isHorizontalBarChart = selectedChartType === ChartType.Horizontal

  const existingOptions = [legendTitle]
  const { min, max } = getMinMaxYAxis(resultsForGroup)

  const valueAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'value',
      name: formatMessage({ id: 'statistics.dataInsights.count' }),
      nameLocation: 'middle',
      minInterval: 1,
      nameGap: 30,
      min: min,
      max: max,
      nameTextStyle: {
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [formatMessage, typography.body2.fontSize, typography.fontFamily, min, max],
  )

  const categoryAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'category',
      data: existingOptions,
      axisLabel: {
        interval: 0,
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [existingOptions, typography.body2.fontSize, typography.fontFamily],
  )

  const sortedResults = useMemo(() => {
    return resultsForGroup.sort((a, b) => {
      if (a.groupName === NOT_SELECTED_GROUP_NAME) return 1
      if (b.groupName === NOT_SELECTED_GROUP_NAME) return -1
      return 0
    })
  }, [resultsForGroup])

  const commonChartConfig = useMemo(
    () => ({
      tooltip: {
        trigger: 'axis' as const,
        axisPointer: {
          type: 'shadow' as const,
        },
      },
      legend: {
        icon: 'circle',
        bottom: 0,
        left: 0,
        itemWidth: 10,
        width: '100%',
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
          overflow: 'truncate' as const,
        },
      },
      series: sortedResults.map((r, i) => ({
        name: r.groupName,
        type: 'bar' as const,
        barCategoryGap: '50%',
        data: [
          {
            name: legendTitle,
            value: r.questionMonitoring.companiesHaveAnswered.number,
            groupName: r.groupName,
            groupId: r.groupId,
          },
        ],
        color: r.groupName === NOT_SELECTED_GROUP_NAME ? NOT_SELECTED_COLOR : colors[i],
      })),
    }),
    [sortedResults, legendTitle, typography.fontFamily, typography.body2.fontSize],
  )

  const horizontalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = valueAxis as XAXisOption
    const yAxis = { ...categoryAxis, show: false } as YAXisOption

    return {
      ...commonChartConfig,
      grid: {
        show: false,
        containLabel: false,
        left: 15,
        right: 7,
        top: 30,
        bottom: getLegendSpacing(
          60,
          widthOfChart,
          sortedResults.map(r => r.groupName),
        ),
      },
      xAxis,
      yAxis,
    }
  }, [valueAxis, categoryAxis, commonChartConfig])

  const verticalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = categoryAxis as XAXisOption
    const yAxis = valueAxis as YAXisOption

    return {
      ...commonChartConfig,
      grid: {
        show: false,
        containLabel: false,
        left: 30,
        right: 7,
        top: 30,
        bottom: getLegendSpacing(
          30,
          widthOfChart,
          sortedResults.map(r => r.groupName),
        ),
      },
      xAxis,
      yAxis,
    }
  }, [categoryAxis, valueAxis, commonChartConfig])

  return isHorizontalBarChart ? horizontalBarChartOptions : verticalBarChartOptions
}
