import { useEchartsContainerWidth } from '@app/src/hooks/useEchartsContainerWidth'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { getMinMaxOnYAxisCountCharts } from '@app/src/utils/statisticsUtils'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getLegendSpacing } from '../chartsUtils'
import { StatisticsSummaryPerGroup } from '../DataHubSummaryChart'
import { colors } from './categoryChartConfigsUtils'

interface CategorySummaryChartConfigsParams {
  resultsForGroup: StatisticsSummaryPerGroup[]
  selectedChartType: ChartType
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
}

export enum SummaryChartDataTypes {
  CompaniesAnswered = 'companiesAnswered',
  CompaniesAnsweredDontHaveThis = 'companiesAnsweredDontHaveThis',
  CompaniesNotAnswered = 'companiesNotAnswered',
}

const getMinMaxYAxis = (optionItemResult: StatisticsSummaryPerGroup[]) => {
  const summaryValues = optionItemResult.flatMap(
    res => res.companiesAnswered + res.companiesAnsweredDontHaveThis + res.companiesNotAnswered,
  )
  return getMinMaxOnYAxisCountCharts(summaryValues)
}

export const useCategorySummaryChartConfigs = ({
  resultsForGroup,
  selectedChartType,
  echartsRef,
}: CategorySummaryChartConfigsParams) => {
  const { typography } = useTheme()
  const { formatMessage } = useIntl()
  const widthOfChart = useEchartsContainerWidth(echartsRef)

  const existingOptions = useMemo(
    () => [
      formatMessage({ id: 'statistics.dataInsights.reported' }),
      formatMessage({ id: 'statistics.dataInsights.dontHaveThis' }),
      formatMessage({ id: 'statistics.dataInsights.noResponse' }),
    ],
    [],
  )

  const { min, max } = getMinMaxYAxis(resultsForGroup)

  const sortedGroups = useMemo(
    () => resultsForGroup.sort((a, b) => a.groupName.localeCompare(b.groupName)),
    [resultsForGroup],
  )

  const colorMap = useMemo(() => {
    const uniqueGroups = [...new Set(resultsForGroup.map(r => r.groupName))].sort()
    return Object.fromEntries(uniqueGroups.map((group, index) => [group, colors[index % colors.length]]))
  }, [resultsForGroup])

  const groupToShowLabelsFor = useMemo(() => {
    return [...new Set(resultsForGroup.map(r => r.groupName))].sort()[0]
  }, [resultsForGroup])

  const valueAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'value',
      name: formatMessage({ id: 'statistics.dataInsights.count' }),
      nameLocation: 'middle',
      nameGap: 35,
      min: min,
      max: max,
      minInterval: 1,
      nameTextStyle: {
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [formatMessage, typography.body2.fontSize, typography.fontFamily, min, max],
  )

  const categoryAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'category',
      data: existingOptions,
      axisLabel: {
        interval: 0,
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [existingOptions, typography.body2.fontSize, typography.fontFamily],
  )

  const horizontalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = valueAxis as XAXisOption
    const yAxis = { ...categoryAxis, show: false } as YAXisOption

    return {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        containLabel: false,
        left: 10,
        right: 7,
        top: 30,
        bottom: getLegendSpacing(
          55,
          widthOfChart,
          sortedGroups.map(f => f.groupName),
        ),
      },
      xAxis,
      yAxis,
      legend: {
        icon: 'circle',
        bottom: 0,
        left: 0,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      series: sortedGroups.map(r => ({
        name: r.groupName,
        type: 'bar',
        stack: 'total',
        barCategoryGap: '50%',
        data: [
          {
            name: formatMessage({ id: 'statistics.dataInsights.noResponse' }),
            value: r.companiesNotAnswered,
            groupName: r.groupName,
            groupId: r.groupId,
            summaryType: SummaryChartDataTypes.CompaniesNotAnswered,
            label:
              r.groupName === groupToShowLabelsFor
                ? {
                    show: true,
                    position: [0, -14],
                    align: 'left',
                    formatter: '{b}',
                    fontFamily: typography.fontFamily,
                    fontSize: typography.body2.fontSize,
                  }
                : undefined,
          },
          {
            name: formatMessage({ id: 'statistics.dataInsights.dontHaveThis' }),
            value: r.companiesAnsweredDontHaveThis,
            groupName: r.groupName,
            groupId: r.groupId,
            summaryType: SummaryChartDataTypes.CompaniesAnsweredDontHaveThis,
            label:
              r.groupName === groupToShowLabelsFor
                ? {
                    show: true,
                    position: [0, -14],
                    align: 'left',
                    formatter: '{b}',
                    fontFamily: typography.fontFamily,
                    fontSize: typography.body2.fontSize,
                  }
                : undefined,
          },
          {
            name: formatMessage({ id: 'statistics.dataInsights.reported' }),
            value: r.companiesAnswered,
            groupName: r.groupName,
            groupId: r.groupId,
            summaryType: SummaryChartDataTypes.CompaniesAnswered,
            label:
              r.groupName === groupToShowLabelsFor
                ? {
                    show: true,
                    position: [0, -14],
                    align: 'left',
                    formatter: '{b}',
                    fontFamily: typography.fontFamily,
                    fontSize: typography.body2.fontSize,
                  }
                : undefined,
          },
        ],
        color: colorMap[r.groupName],
      })),
    }
  }, [
    valueAxis,
    categoryAxis,
    typography.fontFamily,
    typography.body2.fontSize,
    formatMessage,
    sortedGroups,
    widthOfChart,
    colorMap,
    groupToShowLabelsFor,
  ])

  const verticalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = categoryAxis as XAXisOption
    const yAxis = valueAxis as YAXisOption

    return {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        containLabel: true,
        left: 40,
        right: 7,
        top: 30,
        bottom: getLegendSpacing(
          20,
          widthOfChart,
          sortedGroups.map(f => f.groupName),
        ),
      },
      xAxis,
      yAxis,
      legend: {
        icon: 'circle',
        bottom: 0,
        left: 0,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      series: sortedGroups.map(r => ({
        name: r.groupName,
        type: 'bar',
        stack: 'total',
        barCategoryGap: '50%',
        data: [
          {
            name: formatMessage({ id: 'statistics.dataInsights.reported' }),
            value: r.companiesAnswered,
            groupName: r.groupName,
            groupId: r.groupId,
            summaryType: SummaryChartDataTypes.CompaniesAnswered,
          },
          {
            name: formatMessage({ id: 'statistics.dataInsights.dontHaveThis' }),
            value: r.companiesAnsweredDontHaveThis,
            groupName: r.groupName,
            groupId: r.groupId,
            summaryType: SummaryChartDataTypes.CompaniesAnsweredDontHaveThis,
          },
          {
            name: formatMessage({ id: 'statistics.dataInsights.noResponse' }),
            value: r.companiesNotAnswered,
            groupName: r.groupName,
            groupId: r.groupId,
            summaryType: SummaryChartDataTypes.CompaniesNotAnswered,
          },
        ],
        color: colorMap[r.groupName],
      })),
    }
  }, [
    categoryAxis,
    valueAxis,
    typography.fontFamily,
    typography.body2.fontSize,
    formatMessage,
    sortedGroups,
    widthOfChart,
    colorMap,
  ])

  return selectedChartType === ChartType.Horizontal ? horizontalBarChartOptions : verticalBarChartOptions
}
