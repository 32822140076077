import { useSignalR } from '@app/src/context/SignalRContext'
import { useEffect } from 'react'

const useSignalRListener = (methodName: string, method: () => void): void => {
  const { on, off } = useSignalR()
  useEffect(() => {
    on(methodName, method)
    return () => off(methodName, method)
  }, [on, off, methodName, method])
}

export default useSignalRListener
