import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewPriorty from '@app/src/components/Drawer/Views/DrawerViewPriorty'
import CheckboxCell from '@app/src/components/Table/Cells/CheckboxCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import LevelCell from '@app/src/components/Table/Cells/LevelCell'
import PriorityCell from '@app/src/components/Table/Cells/PriorityCell'
import RatingCell from '@app/src/components/Table/Cells/RatingCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import Assessment from '@app/src/types/assessment'
import { Provider } from '@app/src/types/organizations'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface AssessmentRowProps {
  row: Assessment
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, provider: Provider) => void
  selectedProviders: Array<number>
}

export const AssessmentRow: React.FC<AssessmentRowProps> = ({
  row: assessment,
  onCheckboxChange,
  selectedProviders,
}) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const isChecked = Boolean(
    selectedProviders?.some(selectedProviderId => selectedProviderId === assessment.provider.id),
  )

  return (
    <TableRow>
      <CheckboxCell checked={isChecked} onChange={e => onCheckboxChange(e, assessment.provider)} />
      <CompanyCell company={assessment.provider} drawer />
      <LevelCell value={assessment.totalLevel} />
      <RatingCell value={assessment.section1Level} label={formatMessage({ id: 'baseAssessment.section1' })} />
      <RatingCell value={assessment.section2Level} label={formatMessage({ id: 'baseAssessment.section2' })} />
      <RatingCell value={assessment.section3Level} label={formatMessage({ id: 'baseAssessment.section3' })} />
      <RatingCell value={assessment.section4Level} label={formatMessage({ id: 'baseAssessment.section4' })} />
      <RatingCell value={assessment.section5Level} label={formatMessage({ id: 'baseAssessment.section5' })} />
      <PriorityCell
        value={assessment.provider.priority}
        onClick={() => openDrawer(<DrawerViewPriorty provider={assessment.provider} />)}
        size="small"
      />
      <ValueCell
        value={
          assessment?.isScreened
            ? formatMessage({ id: 'general.aiScreening' })
            : formatMessage({ id: 'general.selfReported' })
        }
      />
    </TableRow>
  )
}

export default AssessmentRow
