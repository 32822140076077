import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import FailedToFetchDataEmptyState from '@app/src/components/EmptyState/FailedToFetchDataEmptyState'
import { formatDate } from '@app/src/components/Form/ControlledDateField'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Avatar, Box, Button, CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import StatisticsCard from '../StatisticsCard'

const useStyles = makeStyles(({ palette, spacing }) => ({
  avatar: {
    backgroundColor: palette.error.main,
    marginRight: spacing(1),
    borderRadius: 4,
  },
}))

const OverdueRequestsCard = () => {
  const { avatar } = useStyles()
  const history = useHistory()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const { formatMessage } = useIntl()

  const formattedTodaysDate = formatDate(new Date())?.split(' ')?.[0] ?? ''

  const {
    facets: [overdueRequests],
    isLoading: overdueRequestsIsLoading,
    isError: overdueRequestsIsError,
  } = useFetchFacets({
    key: FetchKey.OverdueRequests,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'status' }],
    shouldShowErrorNotification: false,
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
            operator: Operators.In,
          },
        ],
      },
      {
        name: 'deadline',
        filters: [
          {
            value: formattedTodaysDate,
            operator: Operators.LowerThan,
          },
        ],
      },
    ],
  })

  const totalOverdueRequests = overdueRequests?.reduce((acc, curr) => acc + curr.count, 0)

  if (overdueRequestsIsLoading) {
    return (
      <Box height="100%" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <StatisticsCard
      title={formatMessage({ id: 'transparencyOverview.overdueRequests' })}
      action={
        <Button
          size="small"
          onClick={() =>
            history.push(
              stringifyQueryFilters({
                url: generatePath(paths.manageRequest, {
                  view: ViewTypeName.Requests,
                }),
                queryParams: {
                  filters: [
                    {
                      name: 'status',
                      value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
                      operator: Operators.In,
                    },
                    {
                      name: 'deadline',
                      value: formattedTodaysDate,
                      operator: Operators.LowerThan,
                      uniqueId: 'deadlineTo',
                    },
                  ],
                },
                includeCurrentFilters: true,
              }),
            )
          }
        >
          {formatMessage({ id: 'dashboard.investment.seeAll' })}
        </Button>
      }
      justifyContent="left"
      withBorder
    >
      <Box display="flex" alignItems="center" py={2}>
        {overdueRequestsIsError ? (
          <FailedToFetchDataEmptyState />
        ) : (
          totalOverdueRequests > 0 && (
            <Avatar variant="square" className={avatar}>
              <ErrorOutlineIcon color="primary" />
            </Avatar>
          )
        )}
        <Typography variant="kpi">{totalOverdueRequests ?? 0}</Typography>
      </Box>
    </StatisticsCard>
  )
}

export default OverdueRequestsCard
