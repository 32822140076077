import endpoints from '@app/src/api/endpoints'
import { useFileDownload } from '@app/src/api/fetchHooks'
import DrawerView from '@app/src/components/Drawer/DrawerView'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { AiSuggestion } from '@app/src/pages/Questionnaire/hooks/useFetchFileAiSuggestions'
import { RequestItem } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents, QuestionTypes } from '@app/src/wf-constants'
import { AutoAwesome } from '@mui/icons-material'
import { Box, Chip, CircularProgress, Divider, Link, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface AiSuggestionDrawerProps {
  suggestion: AiSuggestion
  requestItem: RequestItem
  questionType: string
  onApply: (value: string) => void
  closeDrawer: () => void
  sectionNumber: number
  itemNumber: number
}

const getSuggestionText = (suggestion: AiSuggestion | undefined, questionType: string) => {
  if (!suggestion) return undefined
  if (questionType?.toLowerCase() === QuestionTypes.Options.toLowerCase()) {
    return suggestion.selectedOptions?.map(option => option).join(', ')
  }

  return suggestion?.response?.trim() ? suggestion.response : undefined
}

const FileAiSuggestionDrawer: React.FC<AiSuggestionDrawerProps> = ({
  suggestion,
  requestItem,
  questionType,
  onApply,
  closeDrawer,
  sectionNumber,
  itemNumber,
}) => {
  const { formatMessage } = useIntl()
  const { downloadFile, isFileDownloading } = useFileDownload()
  const { trackEvent } = useAmplitude()

  return (
    <DrawerView
      title={formatMessage({ id: 'suggestedAnswers.drawerTitle' })}
      subTitle={`${sectionNumber}.${itemNumber} ${requestItem.description}`}
      stackButtons
      buttons={[
        {
          label: formatMessage({ id: 'suggestedAnswers.ignoreAnswer' }),
          variant: 'text',
          onClick: () => {
            trackEvent({
              name: AmplitudeTrackingEvents.Provider.Questionnaire.AiSuggestedAnswers,
              eventProps: {
                action: 'Ignored',
              },
            })
            closeDrawer()
          },
        },
        {
          label: formatMessage({ id: 'suggestedAnswers.applySuggestion' }),
          variant: 'contained',
          onClick: () => {
            trackEvent({
              name: AmplitudeTrackingEvents.Provider.Questionnaire.AiSuggestedAnswers,
              eventProps: {
                action: 'Accepted',
              },
            })
            closeDrawer()
            onApply(suggestion.response)
          },
          sx: ({ palette }) => ({
            backgroundColor: palette.communication.dark,
          }),
        },
      ]}
    >
      <Box mx={3} mt={2}>
        <Box display="flex">
          <AutoAwesome sx={{ color: 'communication.dark' }} />
          <Typography variant="subtitle1" color="communication.dark" ml={2}>
            {formatMessage({ id: 'suggestedAnswers.aiSuggestedAnswer' })}
          </Typography>
        </Box>
        <Chip
          sx={({ palette, spacing }) => ({
            p: spacing(1),
            backgroundColor: palette.communication.light,
            mt: 3,
            height: 'auto',
            '& .MuiChip-label': {
              whiteSpace: 'normal',
            },
          })}
          label={getSuggestionText(suggestion, questionType)}
        />
        <Typography color="textSecondary" my={3}>
          {formatMessage({ id: 'suggestedAnswers.fileSourceText' })}{' '}
          {suggestion.citations.map((citation, index) => (
            <React.Fragment key={citation}>
              {isFileDownloading && <CircularProgress size={15} sx={{ mr: 1 }} />}
              <Link onClick={() => downloadFile(endpoints.suggestionFile(citation))} sx={{ cursor: 'pointer' }}>
                {citation}
              </Link>
              {index !== suggestion.citations.length - 1 && ', '}
            </React.Fragment>
          ))}
        </Typography>
        <Divider />
        <Typography color="communication.dark" mt={2}>
          {formatMessage({ id: 'suggestedAnswers.disclaimer' })}
        </Typography>
      </Box>
    </DrawerView>
  )
}
export default FileAiSuggestionDrawer
