import endpoints from '@app/src/api/endpoints'
import { useFileDownload } from '@app/src/api/fetchHooks'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { GetApp } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const DownloadResponse = (): JSX.Element | null => {
  const { formatMessage } = useIntl()
  const { response, locale } = useReport()
  const { downloadFile } = useFileDownload()

  const downloadResponse = async () => {
    downloadFile(endpoints.downloadResponse(response?.id, locale ? locale.code : ''), 'xlsx')
  }

  if (!response?.id) {
    return null
  }

  return (
    <Box>
      <Button startIcon={<GetApp />} onClick={downloadResponse} sx={{ px: 2 }}>
        {formatMessage({ id: 'general.download' })}
      </Button>
    </Box>
  )
}

export default DownloadResponse
