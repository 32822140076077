import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import {
  ResponseInternalVerification,
  ResponseItemInternalVerification,
  ResponseItemsVerifications,
  ReviewAll,
} from '@app/src/types/resourceExplorer'
import { NotificationSeverity } from '@app/src/wf-constants'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

type UseAccessorQuestionnaire = {
  externalReviewAllItems: (verification: ReviewAll) => Promise<void>
  internalReviewResponse: (verification: ResponseInternalVerification) => Promise<void>
  internalReviewResponseItems: (verification: ResponseItemInternalVerification) => Promise<void>
  createVerificationResponseItem: (verification: ResponseItemsVerifications) => Promise<void>
  isLoadingCreateVerification: boolean
}

const useAccessorQuestionnaire = (): UseAccessorQuestionnaire => {
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const { formatMessage } = useIntl()
  const { showErrorNotification } = useErrorNotification()

  const { mutateAsync: mutateCreateVerification, isLoading: isLoadingCreateVerification } = useCreateResource()
  const { mutateAsync: createReviewMessage, isLoading: isLoadingCreateReviewMessage } = useCreateResource()

  const externalReviewAllItems: UseAccessorQuestionnaire['externalReviewAllItems'] = async reviewAll => {
    try {
      await createVerificationResponseItem({
        responseItemIds: reviewAll.responseItemIds,
        externalVerificationStatus: reviewAll.externalVerificationStatus,
        externalVerificationComment: reviewAll.responseItemsExternalVerificationComment,
        verifyAll: true,
      })
      if (reviewAll.responseId) {
        await setResponseReviewMessage(reviewAll.responseId, reviewAll.responseExternalVerificationComment ?? '')
      }
      showSnackbar({
        message: formatMessage({ id: 'notifications.successfulVerification' }),
        severity: NotificationSeverity.success,
      })
    } finally {
      invalidateQueries()
    }
  }

  const internalReviewResponse: UseAccessorQuestionnaire['internalReviewResponse'] = async verification => {
    await createReviewMessage(
      { url: endpoints.responseInternalVerification, body: verification },
      {
        onSuccess: () => {
          invalidateQueries()
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulVerification' }),
            severity: NotificationSeverity.success,
          })
        },
        onError: error => showErrorNotification({ requestError: error }),
      },
    )
  }

  const internalReviewResponseItems: UseAccessorQuestionnaire['internalReviewResponseItems'] = async verification => {
    await createReviewMessage(
      { url: endpoints.responseItemInternalVerification, body: verification },
      {
        onSuccess: () => {
          invalidateQueries()
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulVerification' }),
            severity: NotificationSeverity.success,
          })
        },
        onError: error => showErrorNotification({ requestError: error }),
      },
    )
  }

  const setResponseReviewMessage = async (responseId: number, reviewMessage: string) => {
    await createReviewMessage(
      {
        url: endpoints.setResponseReviewMessage,
        body: { responseId: responseId, reviewComment: reviewMessage },
      },
      {
        onError: error => showErrorNotification({ requestError: error }),
      },
    )
  }

  const createVerificationResponseItem = async (verification: ResponseItemsVerifications) => {
    await mutateCreateVerification(
      {
        url: endpoints.verifyResponseItem,
        body: verification,
      },
      {
        onError: error => showErrorNotification({ requestError: error }),
      },
    )
  }

  const createVerificationResponseItemInvalidate: UseAccessorQuestionnaire['createVerificationResponseItem'] =
    async verification => {
      console.log('createVerificationResponseItemInvalidate', verification)
      await createVerificationResponseItem(verification)
      invalidateQueries()
    }

  const invalidateQueries = () => {
    queryClient.invalidateQueries(FetchKey.Answer)
    queryClient.invalidateQueries(FetchKey.Response)
    queryClient.invalidateQueries(FetchKey.RequestHistory)
    queryClient.invalidateQueries(FetchKey.RequestPerPeriod)
  }

  return {
    createVerificationResponseItem: createVerificationResponseItemInvalidate,
    isLoadingCreateVerification: isLoadingCreateVerification || isLoadingCreateReviewMessage,
    externalReviewAllItems,
    internalReviewResponse,
    internalReviewResponseItems,
  }
}

export default useAccessorQuestionnaire
