import { NotInterestedOutlined } from '@mui/icons-material'
import { Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const AiSkippedQuestionInfo: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <Stack width="100%">
      <Divider sx={{ my: 2 }} />
      <Stack direction="row" alignItems="center" spacing={1}>
        <NotInterestedOutlined />
        <Typography variant="subtitle1" color="text.secondary">
          {formatMessage({ id: 'reporting.AiSkippedQuestionInfo.noData' })}
        </Typography>
      </Stack>
      <Typography variant="body1" color="text.secondary" mt={1}>
        {formatMessage({ id: 'reporting.AiSkippedQuestionInfo.noDataDescription' })}
      </Typography>
    </Stack>
  )
}

export default AiSkippedQuestionInfo
