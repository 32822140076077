import { useDisplayValueWithUnit } from '@app/src/hooks/useDisplayValueWithUnit'
import { useEchartsContainerWidth } from '@app/src/hooks/useEchartsContainerWidth'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { SelectedMetric } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/NumberMetricSelector'
import { StatisticsForQuestionPerGroup } from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import { Unit } from '@app/src/types/resourceExplorer'
import {
  getMinMaxOnYAxisNumber,
  getUnitDisplayText,
  numberToString,
  numberToStringWithUnit,
} from '@app/src/utils/statisticsUtils'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import { max } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getLegendSpacing } from '../chartsUtils'
import { colors, NOT_SELECTED_COLOR, NOT_SELECTED_GROUP_NAME } from './categoryChartConfigsUtils'

interface NumberMultiPeriodChartConfigsParams {
  statistics: StatisticsForQuestionPerGroup
  selectedChartType: ChartType
  selectedMetric: SelectedMetric
  unit: Unit
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
}

const calculateValueAxisNameOffsetToPreventOverlap = (
  data: {
    value: number | undefined
  }[],
  unit: Unit,
  optimalUnit: Unit | undefined,
  isHorizontalChart: boolean,
) => {
  if (isHorizontalChart) return 25
  const axisLabelLengths = data.map(v => numberToString(v.value ?? 0, unit, optimalUnit).length)
  const maxAxisLabelLength = max(axisLabelLengths) ?? 0
  return 35 + Math.max(maxAxisLabelLength - 3, 0) * 7
}

export const useNumberCategoryChartConfigs = ({
  statistics,
  selectedChartType,
  selectedMetric,
  unit,
  echartsRef,
}: NumberMultiPeriodChartConfigsParams) => {
  const { formatMessage } = useIntl()
  const { typography } = useTheme()
  const { getOptimalUnitForMultipleValues } = useDisplayValueWithUnit()

  const isHorizontalBarChart = selectedChartType === ChartType.Horizontal
  const widthOfChart = useEchartsContainerWidth(echartsRef)

  const data = useMemo(() => {
    const unsortedData = statistics.resultForGroups.map(r => ({
      name: r.groupName,
      value: selectedMetric === SelectedMetric.Average ? r.numberResult?.average : r.numberResult?.sum,
      groupName: r.groupName,
      groupId: r.groupId,
    }))

    return unsortedData.sort((a, b) => {
      if (a.name === NOT_SELECTED_GROUP_NAME) return 1
      if (b.name === NOT_SELECTED_GROUP_NAME) return -1
      return 0
    })
  }, [statistics.resultForGroups, selectedMetric])

  const values = data.flatMap(d => (d.value !== undefined && d.value !== null ? [Number(d.value)] : []))
  const optimalUnit = getOptimalUnitForMultipleValues(values, unit)

  const nameGap = calculateValueAxisNameOffsetToPreventOverlap(data, unit, optimalUnit, isHorizontalBarChart)
  const existingOptions = [getUnitDisplayText(optimalUnit, formatMessage({ id: 'statistics.dataInsights.count' }))]

  const { min, max } = getMinMaxOnYAxisNumber(values, selectedMetric, optimalUnit)

  const categoryAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'category',
      data: existingOptions,
      axisLabel: {
        interval: 0,
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
        formatter: periodName => periodName,
      },
    }),
    [existingOptions, typography.fontFamily, typography.body2.fontSize],
  )

  const valueAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'value',
      axisLabel: {
        formatter: value => numberToString(value, unit, optimalUnit),
      },
      min: min,
      max: max,
      name: getUnitDisplayText(optimalUnit, formatMessage({ id: 'statistics.dataInsights.count' })),
      nameLocation: 'middle',
      nameGap: nameGap,
      nameTextStyle: {
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [unit, optimalUnit, min, max, typography.fontFamily, typography.body2.fontSize, formatMessage, nameGap],
  )

  const horizontalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = valueAxis as XAXisOption
    const yAxis = { ...categoryAxis, show: false } as YAXisOption

    return {
      tooltip: {
        valueFormatter: value => numberToStringWithUnit(Number(value), unit, optimalUnit),
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        icon: 'circle',
        bottom: 0,
        left: 0,
        itemWidth: 10,
        width: '100%',
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
          overflow: 'truncate',
        },
      },
      grid: {
        containLabel: false,
        left: 15,
        right: 7,
        top: 30,
        bottom: getLegendSpacing(
          60,
          widthOfChart,
          data.map(d => d.name),
        ),
      },
      xAxis,
      yAxis,
      series: data.map((group, i) => ({
        name: group.name,
        type: 'bar',
        data: [
          {
            value: group.value,
            groupName: group.groupName,
            groupId: group.groupId,
          },
        ],
        label: {
          show: false,
        },
        color: group.name === NOT_SELECTED_GROUP_NAME ? NOT_SELECTED_COLOR : colors[i],
      })),
    }
  }, [valueAxis, categoryAxis, typography.fontFamily, typography.body2.fontSize, optimalUnit, data, unit])

  const verticalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = { ...categoryAxis, show: false } as XAXisOption
    const yAxis = valueAxis as YAXisOption

    return {
      tooltip: {
        valueFormatter: value => numberToStringWithUnit(Number(value), unit, optimalUnit),
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        icon: 'circle',
        bottom: 0,
        left: 0,
        itemWidth: 10,
        width: '100%',
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
          overflow: 'truncate',
        },
      },
      grid: {
        containLabel: true,
        left: 30,
        right: 7,
        top: 30,
        bottom: getLegendSpacing(
          20,
          widthOfChart,
          data.map(d => d.name),
        ),
      },
      xAxis,
      yAxis,
      series: data.map((group, i) => ({
        name: group.name,
        type: 'bar',
        data: [
          {
            value: group.value,
            groupName: group.groupName,
            groupId: group.groupId,
          },
        ],
        label: {
          show: false,
        },
        color: group.name === NOT_SELECTED_GROUP_NAME ? NOT_SELECTED_COLOR : colors[i],
      })),
    }
  }, [valueAxis, categoryAxis, typography.fontFamily, typography.body2.fontSize, optimalUnit, data, unit])

  return isHorizontalBarChart ? horizontalBarChartOptions : verticalBarChartOptions
}
