import { AnswersGroupingType } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import qs from 'qs'
import React, { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import GroupBySelectorContext from './GroupBySelectorContext'

interface Props {
  children: JSX.Element
}

const GroupBySelectorContextProvider = ({ children }: Props): JSX.Element => {
  const location = useLocation()
  const history = useHistory()

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const groupBy = queryParams.groupBy as AnswersGroupingType
  const groupByValue = queryParams.groupByValue as string

  const setGroupBy = (newValue: AnswersGroupingType, groupByValue?: string, isClearing?: boolean) => {
    const newSearch = qs.stringify(
      {
        ...(isClearing ? {} : queryParams),
        groupBy: newValue ?? AnswersGroupingType.PeriodName,
        groupByValue,
      },
      { addQueryPrefix: true, encodeValuesOnly: true },
    )
    history.push({ ...location, search: newSearch })
  }

  useEffect(() => {
    if (!groupBy) {
      setGroupBy(AnswersGroupingType.PeriodName)
    }
  }, [groupBy])

  const contextValue = useMemo(() => {
    return {
      groupBy,
      groupByValue,
      setGroupBy,
    }
  }, [groupBy, groupByValue, location.search])

  return <GroupBySelectorContext.Provider value={contextValue}>{children}</GroupBySelectorContext.Provider>
}

export default GroupBySelectorContextProvider
