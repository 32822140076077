import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { useNumberSpendChartConfigs } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/Charts/SpendChartsConfigs/useNumberSpendChartConfigs'
import {
  NumberMetricSelector,
  SelectedMetric,
} from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/NumberMetricSelector'
import { Unit } from '@app/src/types/resourceExplorer'
import { shouldShowSum } from '@app/src/utils/statisticsUtils'
import { Box } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import React, { useMemo, useState } from 'react'
import { AnswersGroupingType } from '../../../../Filters/StatisticsConstants'
import { StatisticsForQuestionPerGroup } from '../../../DataHubScene'
import { useDataInsightsModal } from '../DataInsightsModalContext'
import { useNumberCategoryChartConfigs } from './CategoryChartsConfigs/useNumberCategoryChartConfigs'
import { EchartClickItemData } from './OptionMultiGroupChart'
import { useNumberMultiPeriodChartConfigs } from './PeriodChartsConfigs/useNumberMultiPeriodChartConfigs'

interface NumberMultiGroupChartProps {
  statistics: StatisticsForQuestionPerGroup
  height: string
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
  unit: Unit
  selectedChartType: ChartType
}

export const NumberMultiGroupChart: React.FC<NumberMultiGroupChartProps> = ({
  statistics,
  height,
  echartsRef,
  unit,
  selectedChartType,
}) => {
  const showSum = shouldShowSum(unit)
  const { groupBy } = useGroupBySelector()

  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>(
    showSum ? SelectedMetric.Sum : SelectedMetric.Average,
  )
  const { openDataInsightsModal } = useDataInsightsModal()

  const events = useMemo(
    () => ({
      click: ({ data }: { data: EchartClickItemData }) => {
        const userSelection = {
          groupName: data.groupName,
          groupId: data.groupId,
        }
        openDataInsightsModal(statistics, userSelection)
      },
    }),
    [openDataInsightsModal, statistics],
  )

  const periodOptions = useNumberMultiPeriodChartConfigs({
    statistics,
    selectedChartType,
    unit,
    selectedMetric,
  })
  const spendOptions = useNumberSpendChartConfigs({
    statistics,
    selectedChartType,
    unit,
    selectedMetric,
  })
  const categoryOptions = useNumberCategoryChartConfigs({
    statistics,
    selectedChartType,
    unit,
    selectedMetric,
    echartsRef,
  })

  const getChartOptions = useMemo(() => {
    switch (groupBy) {
      case AnswersGroupingType.SpendClassification:
        return spendOptions

      case AnswersGroupingType.PeriodName:
        return periodOptions
      default:
        return categoryOptions
    }
  }, [groupBy, periodOptions, spendOptions, categoryOptions])

  return (
    <Box height={height} display="flex" flexDirection="column">
      <NumberMetricSelector selectedMetric={selectedMetric} setSelectedMetric={setSelectedMetric} showSum={showSum} />
      <ReactEChartsCore
        onEvents={events}
        option={getChartOptions}
        echarts={echarts}
        style={{ minWidth: '100%', flexGrow: 1 }}
        ref={echartsRef}
      />
    </Box>
  )
}
