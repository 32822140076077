import { ExportColumn } from '@app/src/types/export'
import { getProviderExportColumns } from './provider'
import { getResponseExportColumns } from './response'

export const getResponseItemExportColumnsAccessor = (solution?: string): ExportColumn[] => [
  {
    translationId: 'schemas.responseItem.requestItemTemplateId',
    exportPath: 'requestItem.questionId',
  },
  {
    translationId: 'schemas.responseItem.object',
    exportPath: 'requestItem.description',
  },
  {
    translationId: 'schemas.responseItem.section',
    exportPath: 'requestItem.section.title',
  },
  {
    translationId: 'schemas.responseItem.answer',
    exportPath: 'answer',
  },
  {
    translationId: 'schemas.responseItem.comment',
    exportPath: 'comment',
  },
  {
    translationId: 'schemas.responseItem.unit',
    exportPath: 'requestItem.unit.symbol',
  },
  {
    translationId: 'schemas.responseItem.externalVerificationStatus',
    exportPath: 'verifications[0].externalVerificationStatus',
  },
  {
    translationId: 'schemas.responseItem.externalVerificationComment',
    exportPath: 'verifications[0].externalVerificationComment',
  },
  {
    translationId: 'schemas.responseItem.internalVerificationStatus',
    exportPath: 'verifications[0].internalVerificationStatus',
  },
  {
    translationId: 'schemas.responseItem.internalVerificationComment',
    exportPath: 'verifications[0].internalVerificationComment',
  },
  {
    translationId: 'schemas.responseItem.request',
    exportPath: 'response.request.title',
  },
  {
    translationId: 'schemas.responseItem.periodStartsAt',
    exportPath: 'requestItem.PeriodStartsAt',
  },
  {
    translationId: 'schemas.responseItem.periodEndsAt',
    exportPath: 'requestItem.PeriodEndsAt',
  },
  {
    translationId: 'schemas.responseItem.questionType',
    exportPath: 'requestItem.QuestionType.name',
  },
  {
    translationId: 'schemas.responseItem.hasAnswered',
    exportPath: 'isAnswered',
  },
  {
    translationId: 'schemas.responseItem.expiresAt',
    exportPath: 'expiresAt',
  },
  {
    translationId: 'export.schemas.response.yourFlag',
    exportPath: 'yourflag',
  },
  {
    translationId: 'export.schemas.response.worldfavorFlag',
    exportPath: 'suggestedrisk',
  },
  ...getResponseExportColumns('response'),
  ...getProviderExportColumns(solution, 'response.request.subscriptions[0].target'),
]
