import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import { getStandardCategoryHeaderTranslationKey } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataInsightsModalTable'
import { dataHubAllowedFilters } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/index'
import { AnswersGroupingType } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { Provider } from '@app/src/types/organizations'
import { comparePeriods } from '@app/src/utils/getOrderedPeriods'
import { sortAlphabetically } from '@app/src/utils/helpers'
import { SpendClassification } from '@app/src/wf-constants'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React, { PropsWithChildren, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import DataInsightsModal from './DataInsightsModal'
import {
  DataInsightsModalContext,
  DataInsightsModalContextProps,
  ModalStatistics,
  UserSelection,
} from './DataInsightsModalContext'

const PLUS_DELIMITER = ' + '

const DataInsightsModalContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, openModal, closeModal] = useDialogState(false)
  const [userSelection, setUserSelection] = useState<UserSelection>()
  const [statistics, setStatistics] = useState<ModalStatistics>()
  const [echartsRefs, setEchartRefs] = useState<Array<React.MutableRefObject<ReactEChartsCore | null>>>([])
  const { formatPeriodName } = usePeriodName()
  const { formatMessage } = useIntl()
  const userFilters = useGetApiQueryFilters(dataHubAllowedFilters)
  const { groupBy, groupByValue } = useGroupBySelector()

  const filteredPeriodNames = useMemo(() => {
    const periodNameFilterValue = userFilters.find(x => x.name === 'response.request.periodName')?.filters[0]
      ?.value as string[]

    const safePeriodFilterValue = periodNameFilterValue
      ? Array.isArray(periodNameFilterValue)
        ? periodNameFilterValue
        : [periodNameFilterValue]
      : undefined
    return safePeriodFilterValue?.sort((a, b) => comparePeriods(a, b))
  }, [userFilters])

  const openDataInsightsModal: DataInsightsModalContextProps['openDataInsightsModal'] = (statistics, userSelection) => {
    if (userSelection && userSelection.groupName) {
      if (groupBy === AnswersGroupingType.PeriodName) {
        userSelection.periodNames = [userSelection.groupName]
      }
    }

    setStatistics(statistics)
    setUserSelection(userSelection)
    if (!isOpen) openModal()
  }

  const groupId = userSelection?.groupId

  const setDataInsightsRefForIndex = (ref: React.MutableRefObject<ReactEChartsCore | null>, index: number) => {
    setEchartRefs(currentValue => {
      currentValue[index] = ref
      return currentValue
    })
  }

  const clearEchartsRefOverIndex = useMemo(
    () => (index: number) => {
      setEchartRefs(currentValues => {
        return currentValues.slice(0, index)
      })
    },
    [],
  )

  const [periodNames, formattedPeriods] = useMemo(() => {
    const names =
      userSelection?.groupName && groupBy === AnswersGroupingType.PeriodName
        ? [userSelection.groupName]
        : userSelection?.periodNames ??
          filteredPeriodNames ??
          (groupBy === AnswersGroupingType.PeriodName
            ? statistics?.resultForGroups?.map(item => item.groupName)
            : undefined)

    const formatted = names?.map(formatPeriodName).join(PLUS_DELIMITER) ?? ''

    return [names, formatted]
  }, [userSelection, statistics])

  const groupNames = useMemo(
    () =>
      (userSelection?.groupName && [userSelection?.groupName]) ||
      statistics?.resultForGroups?.map(item => item.groupName),
    [userSelection, statistics],
  )

  const spendClassifications = useMemo(() => {
    if (groupBy === AnswersGroupingType.SpendClassification) {
      return groupNames as SpendClassification[]
    }
    return undefined
  }, [groupBy, groupNames])

  const formattedNonPeriodGroupNames = useMemo(() => {
    switch (groupBy) {
      case AnswersGroupingType.SpendClassification:
        return (
          groupNames
            ?.map(spend =>
              spend === SpendClassification.NotSet
                ? formatMessage({ id: 'statistics.dataInsights.spend.notSetLabel' })
                : formatMessage({ id: 'statistics.dataInsights.spend.intervalLabel' }, { spendClassification: spend }),
            )
            .join(PLUS_DELIMITER) ?? ''
        )
      case AnswersGroupingType.Country:
        return groupId ? groupNames?.[0] : formatMessage({ id: 'general.Country' }, { count: 2 })
      case AnswersGroupingType.CustomCategory:
        return sortAlphabetically(groupNames)?.join(PLUS_DELIMITER)
      case AnswersGroupingType.StandardCategory:
        return (
          groupNames?.join(PLUS_DELIMITER) ||
          formatMessage({ id: getStandardCategoryHeaderTranslationKey(groupByValue as keyof Provider) })
        )
      case AnswersGroupingType.ActorType:
        return groupId
          ? groupNames?.[0]
          : formatMessage({ id: 'statistics.dataInsights.actorTypes.industryActorTypes' }, { industry: groupByValue })
      case AnswersGroupingType.CountryRisk:
        return groupNames?.join(PLUS_DELIMITER)
      default:
        return ''
    }
  }, [groupBy, groupNames, groupId, groupByValue, formatMessage])

  return (
    <DataInsightsModalContext.Provider
      value={{
        openDataInsightsModal,
        statistics,
        setStatistics,
        userSelection,
        setUserSelection,
        periodNames,
        formattedPeriods,
        spendClassifications,
        groupId,
        formattedNonPeriodGroupNames,
        echartsRefs,
        setDataInsightsRefForIndex,
        clearEchartsRefOverIndex,
      }}
    >
      {children}
      {statistics && <DataInsightsModal isOpen={isOpen} closeModal={closeModal} />}
    </DataInsightsModalContext.Provider>
  )
}

export default DataInsightsModalContextProvider
