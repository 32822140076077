import { ExportColumn } from '@app/src/types/export'
import { getRequestExportColumns } from './inquiry'
import { getProviderExportColumns } from './provider'

const RESPONSE_TRANSLATION_ID = 'export.schemas.response'

export const getResponseExportColumnsAccessor = (solution?: string): ExportColumn[] => [
  ...getResponseExportColumns(),
  ...getRequestExportColumns('request'),
  ...getProviderExportColumns(solution, 'request.targetAliasObject'),
]

export const getResponseExportColumnsTransparency = (solution?: string): ExportColumn[] => [
  {
    translationId: 'export.schemas.provider.organization.organizationId',
    exportPath: 'request.subscriptions[0].creatorOrganization.id',
  },
  {
    translationId: 'export.schemas.provider.organization.organizationName',
    exportPath: 'request.subscriptions[0].creatorOrganization.name',
  },
  ...getResponseExportColumns(),
  ...getRequestExportColumns('request'),
]

export const getResponseExportColumns = (basePath?: string): ExportColumn[] => [
  {
    translationId: basePath ? `${RESPONSE_TRANSLATION_ID}.id` : 'general.id',
    exportPath: basePath ? `${basePath}.id` : 'id',
  },
  {
    translationId: basePath ? `${RESPONSE_TRANSLATION_ID}.createdAt` : 'schemas.request.createdAt',
    exportPath: basePath ? `${basePath}.createdAt` : 'createdAt',
  },
  {
    translationId: 'schemas.request.responseDate',
    exportPath: basePath ? `${basePath}.submittedAt` : 'submittedAt',
  },
  {
    translationId: basePath ? `${RESPONSE_TRANSLATION_ID}.responseStatus` : 'schemas.response.responseStatus',
    exportPath: basePath ? `${basePath}.draftStatus` : 'draftStatus',
  },
  {
    translationId: basePath
      ? `${RESPONSE_TRANSLATION_ID}.externalVerificationStatus`
      : 'schemas.response.externalVerificationStatus',
    exportPath: basePath ? `${basePath}.externalVerificationStatus` : 'externalVerificationStatus',
  },
  {
    translationId: basePath
      ? `${RESPONSE_TRANSLATION_ID}.externalVerificationComment`
      : 'schemas.response.externalVerificationComment',
    exportPath: basePath
      ? `${basePath}.verifications[0].externalVerificationComment`
      : 'verifications[0].externalVerificationComment',
  },
  {
    translationId: basePath
      ? `${RESPONSE_TRANSLATION_ID}.responseInternalStatus`
      : 'schemas.request.responseInternalStatus',
    exportPath: basePath
      ? `${basePath}.verifications[0].internalVerificationStatus`
      : 'verifications[0].internalVerificationStatus',
  },
  {
    translationId: basePath
      ? `${RESPONSE_TRANSLATION_ID}.internalVerificationComment`
      : 'schemas.request.internalVerificationComment',
    exportPath: basePath
      ? `${basePath}.verifications[0].internalVerificationComment`
      : 'verifications[0].internalVerificationComment',
  },
  {
    translationId: 'schemas.response.submittedByUser',
    exportPath: basePath
      ? `${basePath}.history[?(@.status=='Submitted')].creatorUser.name`
      : `history[?(@.status=='Submitted')].creatorUser.name`,
  },
  {
    translationId: 'export.schemas.response.aiScreened',
    exportPath: basePath ? `${basePath}.isScraped` : 'isScraped',
  },
]
