import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import FailedToFetchDataEmptyState from '@app/src/components/EmptyState/FailedToFetchDataEmptyState'
import HoverDialog from '@app/src/components/HoverDialog'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import CompanyStatisticsGraphSkeleton from '@app/src/pages/Dashboards/SourcingDashboard/CompaniesStatistics/CompanyStatisticsGraphSkeleton'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ProviderApprovalStatusValues } from '@app/src/wf-constants'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useIntl } from 'react-intl'
import GraphLegend from '../../GraphLegend'
import { companiesListAllowedFilters } from './CompaniesList'
import CompaniesStatisticGraph from './CompaniesStatisticGraph'
import { handleFilterValue } from './CompaniesStatistics'

const ApprovalStatusGraph = () => {
  const { palette } = useTheme()
  const filters = useGetApiQueryFilters(companiesListAllowedFilters)
  const { formatMessage } = useIntl()
  const [highlightedApprovalStatus, setHighlightedApprovalStatus] = useState<string | number>()
  const { inView, ref } = useInView({ triggerOnce: true })

  const {
    facets: [providerApprovalStatus],
    isError: providerApprovalStatusIsError,
    isLoading,
  } = useFetchFacets({
    key: FetchKey.ProviderFacets,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'providerApprovalStatus' }],
    filter: filters,
    options: { enabled: inView },
    shouldShowErrorNotification: false,
  })

  const notSelectedCount =
    providerApprovalStatus?.find(company => company.label === ProviderApprovalStatusValues.NotSelected)?.count ?? 0
  const notApprovedCount =
    providerApprovalStatus?.find(company => company.label === ProviderApprovalStatusValues.NotApproved)?.count ?? 0
  const underObservationCount =
    providerApprovalStatus?.find(company => company.label === ProviderApprovalStatusValues.UnderObservation)?.count ?? 0
  const approvedAfterConsiderationCount =
    providerApprovalStatus?.find(company => company.label === ProviderApprovalStatusValues.ApprovedAfterConsideration)
      ?.count ?? 0
  const approvedCount =
    providerApprovalStatus?.find(company => company.label === ProviderApprovalStatusValues.Approved)?.count ?? 0

  const totalCompanies =
    notSelectedCount + notApprovedCount + underObservationCount + approvedAfterConsiderationCount + approvedCount

  if (providerApprovalStatusIsError)
    return (
      <Box display="flex" alignItems="center" height="100%">
        <FailedToFetchDataEmptyState />
      </Box>
    )

  return (
    <HoverDialog
      content={
        <Box p={2} onMouseEnter={() => setHighlightedApprovalStatus(undefined)}>
          <Stack spacing={1}>
            <Typography variant="overline" color="textSecondary" noWrap>
              {formatMessage({ id: 'dashboard.sourcing.companyStatistics.companiesByApprovalStatus' })}
            </Typography>
            <Filter operator={Operators.In} name="providerApprovalStatus">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedApprovalStatus === ProviderApprovalStatusValues.Approved ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedApprovalStatus(ProviderApprovalStatusValues.Approved)}
                  onMouseLeave={() => setHighlightedApprovalStatus(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderApprovalStatusValues.Approved))}
                >
                  <GraphLegend
                    color={palette.visualization[2]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.providerApprovalStatusValues.ApprovedWithCount' },
                      { count: approvedCount },
                    )}
                  />
                  {highlightedApprovalStatus === ProviderApprovalStatusValues.Approved && (
                    <Button onClick={() => onChange(handleFilterValue(value, ProviderApprovalStatusValues.Approved))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
            <Filter operator={Operators.In} name="providerApprovalStatus">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={
                    highlightedApprovalStatus === ProviderApprovalStatusValues.ApprovedAfterConsideration
                      ? 'grey.100'
                      : 'white'
                  }
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() =>
                    setHighlightedApprovalStatus(ProviderApprovalStatusValues.ApprovedAfterConsideration)
                  }
                  onMouseLeave={() => setHighlightedApprovalStatus(undefined)}
                  onClick={() =>
                    onChange(handleFilterValue(value, ProviderApprovalStatusValues.ApprovedAfterConsideration))
                  }
                >
                  <GraphLegend
                    color={palette.visualization[3]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.providerApprovalStatusValues.ApprovedAfterConsiderationWithCount' },
                      { count: approvedAfterConsiderationCount },
                    )}
                  />
                  {highlightedApprovalStatus === ProviderApprovalStatusValues.ApprovedAfterConsideration && (
                    <Button
                      onClick={() =>
                        onChange(handleFilterValue(value, ProviderApprovalStatusValues.ApprovedAfterConsideration))
                      }
                    >
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
            <Filter operator={Operators.In} name="providerApprovalStatus">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={
                    highlightedApprovalStatus === ProviderApprovalStatusValues.UnderObservation ? 'grey.100' : 'white'
                  }
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedApprovalStatus(ProviderApprovalStatusValues.UnderObservation)}
                  onMouseLeave={() => setHighlightedApprovalStatus(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderApprovalStatusValues.UnderObservation))}
                >
                  <GraphLegend
                    color={palette.visualization[7]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.providerApprovalStatusValues.UnderObservationWithCount' },
                      { count: underObservationCount },
                    )}
                  />
                  {highlightedApprovalStatus === ProviderApprovalStatusValues.UnderObservation && (
                    <Button
                      onClick={() => onChange(handleFilterValue(value, ProviderApprovalStatusValues.UnderObservation))}
                    >
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>

            <Filter operator={Operators.In} name="providerApprovalStatus">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={
                    highlightedApprovalStatus === ProviderApprovalStatusValues.NotApproved ? 'grey.100' : 'white'
                  }
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedApprovalStatus(ProviderApprovalStatusValues.NotApproved)}
                  onMouseLeave={() => setHighlightedApprovalStatus(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderApprovalStatusValues.NotApproved))}
                >
                  <GraphLegend
                    color={palette.visualization[10]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.providerApprovalStatusValues.NotApprovedWithCount' },
                      { count: notApprovedCount },
                    )}
                  />
                  {highlightedApprovalStatus === ProviderApprovalStatusValues.NotApproved && (
                    <Button
                      onClick={() => onChange(handleFilterValue(value, ProviderApprovalStatusValues.NotApproved))}
                    >
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>

            <Filter operator={Operators.In} name="providerApprovalStatus">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={
                    highlightedApprovalStatus === ProviderApprovalStatusValues.NotSelected ? 'grey.100' : 'white'
                  }
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedApprovalStatus(ProviderApprovalStatusValues.NotSelected)}
                  onMouseLeave={() => setHighlightedApprovalStatus(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderApprovalStatusValues.NotSelected))}
                >
                  <GraphLegend
                    color={palette.grey[300]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.providerApprovalStatusValues.NotSelectedWithCount' },
                      { count: notSelectedCount },
                    )}
                  />
                  {highlightedApprovalStatus === ProviderApprovalStatusValues.NotSelected && (
                    <Button
                      onClick={() => onChange(handleFilterValue(value, ProviderApprovalStatusValues.NotSelected))}
                    >
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
          </Stack>
        </Box>
      }
    >
      <Box ref={ref}>
        {isLoading ? (
          <CompanyStatisticsGraphSkeleton />
        ) : (
          <CompaniesStatisticGraph
            highlightSetter={setHighlightedApprovalStatus}
            title={formatMessage({ id: `dashboard.sourcing.companyStatistics.approvalStatus` })}
            data={[
              { value: approvedCount, name: ProviderApprovalStatusValues.Approved },
              {
                value: approvedAfterConsiderationCount,
                name: ProviderApprovalStatusValues.ApprovedAfterConsideration,
              },
              { value: underObservationCount, name: ProviderApprovalStatusValues.UnderObservation },
              { value: notApprovedCount, name: ProviderApprovalStatusValues.NotApproved },
              { value: notSelectedCount, name: ProviderApprovalStatusValues.NotSelected },
            ]}
            colors={[
              palette.visualization[2],
              palette.visualization[3],
              palette.visualization[7],
              palette.visualization[10],
              palette.grey[300],
            ]}
            value={Math.ceil(100 - (notSelectedCount * 100) / totalCompanies)}
          />
        )}
      </Box>
    </HoverDialog>
  )
}

export default ApprovalStatusGraph
