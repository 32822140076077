import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import CategoryOptionLabel from '@app/src/components/CategoryOptionLabel'
import { useGetFilterLabels } from '@app/src/hooks/getFilterLabels'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import AutomationMenu from '@app/src/pages/Configurations/ConfigurationsPages/Automation/AutomationMenu'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { RequestAutomation } from '@app/src/types/automations'
import { Card, CardActionArea, Chip, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const CATEGORY_OPTIONS_NAME = 'categoryOptions.id'
const COUNTRY_FILTER_NAME = 'country.id'
const ACTOR_TYPE_ID_FILTER_NAME = 'mappingNodes.actorType.id'

function flattenArray(arr: string[][]): string[] {
  return arr.reduce((acc, val) => acc.concat(val), [])
}

interface AutomationCardProps {
  automation: RequestAutomation
  editAutomation: () => void
}

const AutomationCard: React.FC<AutomationCardProps> = ({ automation, editAutomation }) => {
  const { formatMessage, formatDate } = useIntl()
  const { getPeriodDisplayName } = usePeriodName()

  const templateNames = automation?.templateAutomations?.map(t => t.template.title) ?? []
  const categoryFilters = automation?.categoryFilters
  const periodName = getPeriodDisplayName(automation.periodStartsAt, automation.periodEndsAt)

  const requestChips = (
    <Stack direction="row" flexWrap="wrap" alignItems="center">
      {templateNames
        ?.slice(0, 3)
        ?.map(templateName => <Chip sx={{ mb: 1, mr: 1 }} label={templateName} key={templateName} />)}
      {(templateNames?.length ?? 0) > 3 && (
        <Tooltip title={templateNames?.map(templateName => templateName).join(', ')}>
          <Typography variant="body1" mb={1}>
            {formatMessage({ id: 'automatedSendout.plusMore' }, { count: (templateNames?.length ?? 0) - 3 })}
          </Typography>
        </Tooltip>
      )}
    </Stack>
  )
  const standardCategoriesFilter = categoryFilters?.filter(cf => cf.name !== CATEGORY_OPTIONS_NAME)
  const categoryOptionsFilterValue =
    (categoryFilters?.find(cf => cf.name === CATEGORY_OPTIONS_NAME)?.filters?.[0]?.value as string[]) ?? []

  const countryFilterValue =
    (categoryFilters?.find(cf => cf.name === COUNTRY_FILTER_NAME)?.filters?.[0]?.value as string[]) ?? []

  const actorTypeIdFilterValue =
    (categoryFilters?.find(cf => cf.name === ACTOR_TYPE_ID_FILTER_NAME)?.filters?.[0]?.value as string[]) ?? []

  const {
    facets: [countryNames],
    isLoading: isCountryNamesLoading,
  } = useFetchFacets({
    key: FetchKey.CountryFacets,
    endpoint: endpoints.countriesWithFacets,
    facetsParam: [{ name: 'iso3166Alpha2' }],
    options: {
      enabled: Boolean(countryFilterValue.length),
    },
    filter: [
      {
        name: 'id',
        filters: [
          {
            value: countryFilterValue,
            operator: Operators.In,
          },
        ],
      },
    ],
  })

  const {
    facets: [actorTypeNames],
    isLoading: isActorTypesLoading,
  } = useFetchFacets({
    key: FetchKey.ActorTypeFacets,
    endpoint: endpoints.ActorTypesWithFacets,
    facetsParam: [{ name: 'name' }],
    options: {
      enabled: Boolean(actorTypeIdFilterValue.length),
    },
    filter: [
      {
        name: 'id',
        filters: [
          {
            value: actorTypeIdFilterValue,
            operator: Operators.In,
          },
        ],
      },
    ],
  })

  const { getFilterLabel } = useGetFilterLabels({ countryNames, actorTypeNames })

  const allCategoriesValues = flattenArray(categoryFilters?.map(cf => cf.filters?.[0]?.value as string[]) ?? [])

  return (
    <Card
      elevation={0}
      variant="outlined"
      sx={{
        marginBottom: 2,
        height: '100%',
      }}
    >
      <CardActionArea
        sx={{
          height: '100%',
          padding: 2,
          lineHeight: 3,
          cursor: 'default',
          '&:hover .MuiCardActionArea-focusHighlight': {
            opacity: 0,
          },
        }}
      >
        <Stack height="100%" justifyContent="space-between">
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="caption" color="textSecondary">
                {formatMessage(
                  { id: automation.updatedAt ? 'automatedSendout.updatedAt' : 'automatedSendout.createdAt' },
                  {
                    date: formatDate(automation.updatedAt ? automation.updatedAt : automation.createdAt, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    }),
                  },
                )}
              </Typography>
              <AutomationMenu automationId={automation.id} editAutomation={editAutomation} />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              {isCountryNamesLoading && isActorTypesLoading ? (
                <Skeleton width={70} variant="rounded" />
              ) : automation.automationType === RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY ? (
                <>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                  >
                    {categoryFilters ? getFilterLabel(categoryFilters[0].name, [allCategoriesValues[0]]) : ''}
                  </Typography>
                  {allCategoriesValues.length > 1 && (
                    <Tooltip
                      title={
                        <>
                          {standardCategoriesFilter
                            ?.map(cf => getFilterLabel(cf.name, cf.filters[0].value as string[]))
                            .join('; ')}
                          {Boolean(standardCategoriesFilter?.length) &&
                            Boolean(categoryOptionsFilterValue.length) &&
                            '; '}
                          {categoryOptionsFilterValue?.map((v, i) => (
                            <CategoryOptionLabel
                              key={v}
                              categoryOptionId={String(v)}
                              categoryCount={categoryOptionsFilterValue.length}
                              isLastItem={i === categoryOptionsFilterValue.length - 1}
                            />
                          ))}
                        </>
                      }
                    >
                      <Typography variant="body1" ml={1} whiteSpace="nowrap">
                        {formatMessage({ id: 'automatedSendout.plusMore' }, { count: allCategoriesValues.length - 1 })}
                      </Typography>
                    </Tooltip>
                  )}
                </>
              ) : (
                <Typography variant="body1" fontWeight="bold" whiteSpace="nowrap">
                  {formatMessage({ id: 'automatedSendout.automationSpecifications.forEveryNewProvider' })}
                </Typography>
              )}
              <Typography variant="body1" fontWeight="bold">
                →
              </Typography>
              <Typography variant="body1" fontWeight="bold" whiteSpace="nowrap">
                {formatMessage({ id: 'automatedSendout.sendQuestionnaire' })}
              </Typography>
            </Stack>

            {Boolean(automation.deadlineOffSetDays) && (
              <Typography>
                {formatMessage(
                  {
                    id:
                      automation.automationType === RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY
                        ? 'automatedSendout.dueDateCategory'
                        : 'automatedSendout.dueDateNewCompany',
                  },
                  { days: automation.deadlineOffSetDays },
                )}
              </Typography>
            )}
            {Boolean(periodName) && (
              <Typography>{formatMessage({ id: 'automatedSendout.period' }, { period: periodName })}</Typography>
            )}
          </Stack>
          {Boolean(requestChips) && requestChips}
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default AutomationCard
