import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import Table from '@app/src/components/Table'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { useSelectableRowsWithPostSelectAll } from '@app/src/hooks/useSelectableRows/useSelectableRowsWithPostSelectAll'
import ResponsesFilters from '@app/src/pages/ResourceCollection/Filters/ResponsesFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { Response } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { ADMIN_ROLES, NotificationSeverity, ResourceTypes } from '@app/src/wf-constants'
import { DeleteOutlineOutlined } from '@mui/icons-material'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { FilterGroup, RESPONSE_IS_SCRAPED } from '../../../Filters/useFilters'
import AccessorScrapedResponseHeader from '../../Response/AccessorScrapedResponseHeader'
import AccessorScrapedResponseRow from '../../Response/AccessorScrapedResponseRow'
import { getResponseExportColumnsAccessor } from '@app/src/export-columns/response'

interface AccessorScrapedResponseSceneProps {
  relatedContentFilter?: FilterGroup[]
  embedded?: boolean
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
}

const allowedFilters = [
  'request.template.id',
  'externalVerificationStatus',
  'request.subscriptions.target.organization.countryId',
  'request.subscriptions.target.id',
  'request.periodName',
  'submittedAt',
  'internalVerificationStatus',
  'request.subscriptions.target.providerApprovalStatus',
  'request.subscriptions.target.priority',
  'request.subscriptions.target.finalRiskRating',
  'request.subscriptions.target.activityStatus',
  'request.subscriptions.target.supplierUsage',
  'request.subscriptions.target.tier',
  'request.subscriptions.target.ownerUserId',
  'request.subscriptions.target.categoryOptions.id',
  'request.subscriptions.target.spends.spendClassification',
  'request.subscriptions.target.spends.periodName',
  'request.inquiries.status',
  'request.requestStatus',
  'request.subscriptions.target.countryId',
  'verifications.internalVerificationStatus',
  'request.subscriptions.target.assessments.totalLevel',
  'request.subscriptions.target.assessments.intervalType',
  'request.subscriptions.target.assessments.section1Level',
  'request.subscriptions.target.assessments.section2Level',
  'request.subscriptions.target.assessments.section3Level',
  'request.subscriptions.target.assessments.section4Level',
  'request.subscriptions.target.assessments.section5Level',
  'request.subscriptions.target.assessments.assessmentTemplate.name',
]

const AccessorScrapedResponseScene: React.FC<AccessorScrapedResponseSceneProps> = ({
  embedded,
  tabs,
  actionButtons = [],
  relatedContentFilter = [],
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const { openDrawer } = useDrawer()
  const { role, solution } = useAuthentication().scope
  const isAdmin = ADMIN_ROLES.includes(role?.toLowerCase() ?? '')
  const { mutateAsync: deleteResponses, isLoading: isDeletingResponses } = useDeleteResource()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const confirm = useConfirm()
  const apiFilters = useGetApiQueryFilters(allowedFilters)

  const basePayload = {
    filter: [...relatedContentFilter, RESPONSE_IS_SCRAPED],
    sort: sorting,
    include: [
      'creatorOrganization',
      'request.responderOrganization',
      'request.template',
      'request.template.image',
      'request.subscriptions.target',
      'request.inquiries',
      'request.target.organization',
    ],
  }

  const rawExportPayload = {
    ...basePayload,
    include: [...basePayload.include, 'request.target', 'history.creatorUser'],
  }

  const payload = {
    ...basePayload,
    include: [...basePayload.include],
    filter: [...basePayload.filter, ...apiFilters],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const {
    items: pageResponses,
    count,
    isLoading,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<Response>({
    key: FetchKey.Response,
    endpoint: endpoints.responsesCollection,
    payload,
  })

  const onDeleted = {
    onSuccess: () => {
      showSnackbar({
        message: formatMessage({ id: 'notifications.successfulResourceDelete' }),
        severity: NotificationSeverity.success,
      })
      setSelectedRowsIds([])
      queryClient.invalidateQueries(FetchKey.Response)
      queryClient.invalidateQueries(FetchKey.Request)
      queryClient.invalidateQueries(FetchKey.Answer)
      queryClient.invalidateQueries(FetchKey.ResponseItemCount)
      queryClient.invalidateQueries(FetchKey.Notification)
    },
  }

  const confirmDeleteSelected = () => {
    confirm({
      description: formatMessage({ id: 'resourceCollections.delete.requests.dialogScrapedDescription' }),
      buttonOrder: ['confirm', 'cancel'],
      confirmationText: (
        <>
          <DeleteOutlineOutlined />
          {formatMessage({ id: 'general.delete' })}
        </>
      ),

      confirmationButtonProps: { style: { backgroundColor: 'white', color: 'black' } },
      cancellationButtonProps: { style: { backgroundColor: 'black', color: 'white' } },
    }).then(async () => {
      await deleteResponses({ url: endpoints.deleteScrapedResponsesByIds(selectedRowsIds) }, onDeleted)
    })
  }

  const {
    selectedRowsIds,
    setSelectedRowsIds,
    isLoadingAllRows,
    isAllSelected,
    handleSelectAll,
    handleCheckboxChange,
    isHeaderChecked,
  } = useSelectableRowsWithPostSelectAll<Response>({
    rowsInPage: pageResponses,
    basePayload: payload,
    fetchKey: FetchKey.Response,
    endpoint: endpoints.responsesCollection,
    count,
  })

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'navbar.manageRequests' })}
        tabs={tabs}
        actionButtons={[
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Response}
                  resourceView={tabs?.activeTabParam}
                  count={count}
                  userFilter={basePayload.filter}
                  exportColumns={getResponseExportColumnsAccessor(solution)}
                  rawExportPayload={rawExportPayload}
                  onlyLatest
                />,
              ),
          },
          ...actionButtons,
        ]}
        filter={
          <ResponsesFilters
            implicitFilters={[...relatedContentFilter, RESPONSE_IS_SCRAPED]}
            allowedFilters={allowedFilters}
            resetPage={resetPage}
          />
        }
        embedded={embedded}
        buttonRow={
          selectedRowsIds.length
            ? [
                ...insertIf(isAdmin, {
                  label: formatMessage(
                    { id: 'resourceCollections.delete.requests.deleteCount' },
                    { count: selectedRowsIds?.length ?? 0 },
                  ),
                  startIcon: <DeleteOutlineOutlined />,
                  onClick: confirmDeleteSelected,
                  disabled: isLoading || isFetching || isLoadingAllRows || isDeletingResponses,
                }),
                ...insertIf<ActionButton>(count > pageSize, {
                  label: formatMessage({ id: 'resourceCollections.general.selectAllRequests' }, { count }),
                  variant: 'text',
                  onClick: handleSelectAll,

                  disabled: isLoading || isFetching || isAllSelected() || isDeletingResponses,
                  loading: isLoadingAllRows,
                }),
              ]
            : undefined
        }
      >
        <Table<Response>
          RowComponent={({ row }) => (
            <AccessorScrapedResponseRow
              row={row}
              onCheckboxChange={handleCheckboxChange}
              selectedResponsesIds={selectedRowsIds}
            />
          )}
          HeaderComponent={() => (
            <AccessorScrapedResponseHeader
              toggleSorting={toggleSorting}
              activeSorting={sorting}
              isHeaderChecked={isHeaderChecked}
              responsesInPage={pageResponses}
              setSelectedResponsesIds={setSelectedRowsIds}
            />
          )}
          data={pageResponses}
          isLoading={isLoading || isFetching}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          stickyColumnIndex={2}
        />
      </ResourceCollectionScene>
    </>
  )
}

export default AccessorScrapedResponseScene
