import { Config } from '@app/src/config'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import SignalRContext, { RegisterType } from '@app/src/context/SignalRContext'
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'

interface Props {
  children: ReactNode
}

const REGISTER = 'RegisterForMessages'
const UNREGISTER = 'UnregisterForMessages'

const SignalRContextProvider = ({ children }: Props): JSX.Element => {
  const baseUrl = `${Config.API_URL}/feed`
  const [connection, setConnection] = useState<HubConnection | null>(null)
  const { scope, userId, getToken } = useAuthentication()

  const register: RegisterType = async (userId, organizationId, solution, unregister) => {
    try {
      await connection?.invoke(unregister ? UNREGISTER : REGISTER, userId, organizationId, solution)
    } catch (e) {
      console.error(e)
    }
  }

  const unregister: RegisterType = async (userId, organizationId, solution) => {
    await register(userId, organizationId, solution, true)
  }

  const onCallback: HubConnection['on'] = (methodName, newMethod) => {
    connection?.on(methodName, newMethod)
  }
  const on = useCallback(onCallback, [connection])

  const offCallback: (methodName: string, method: (...args: unknown[]) => void) => void = (methodName, method) => {
    connection?.off(methodName, method)
  }
  const off = useCallback(offCallback, [connection])

  useEffect(() => {
    if (!connection || !userId || !scope.organization || !scope.solution) return
    register(userId, scope.organization, scope.solution)

    return (): void => {
      if (!connection || !userId || !scope.organization || !scope.solution) return
      unregister(userId, scope.organization, scope.solution)
    }
  }, [connection, userId, scope.organization, scope.solution])

  useEffect(() => {
    const connectSignalR = async () => {
      const connection = new HubConnectionBuilder()
        .withUrl(baseUrl, {
          accessTokenFactory: async () => {
            return (await getToken()) ?? ''
          },
        })
        .withAutomaticReconnect()
        .build()

      try {
        await connection.start()
        setConnection(connection)
      } catch (e) {
        console.warn(e)
      }
    }
    connectSignalR()
    return (): void => {
      connection?.stop()
    }
  }, [])

  return <SignalRContext.Provider value={{ on, off }}>{children}</SignalRContext.Provider>
}

export default SignalRContextProvider
