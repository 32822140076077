import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import SimpleSelect from '@app/src/components/Form/Select/SimpleSelect'
import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { useRiskSettings } from '@app/src/context/RiskSettingsContext'
import { AnswersGroupingType } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { Category, StandardCategoryOptions } from '@app/src/types/categories'
import { ProductIndustry } from '@app/src/types/product'
import { insertIf } from '@app/src/utils/helpersTs'
import { Grid } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

interface GroupByOption {
  groupBy: AnswersGroupingType
  groupByValue?: string
}

export const GroupByFilter = ({ isDetailsTabActive }: { isDetailsTabActive?: boolean }) => {
  const { formatMessage } = useIntl()
  const { groupBy, groupByValue, setGroupBy } = useGroupBySelector()
  const { activeRiskSettings } = useRiskSettings()
  const [isOpen, setOpen] = useState(false)
  const { productMapping } = useFlags()

  const { data: categories, isLoading: isCustomCategoriesLoading } = useFetchResource<Category[]>({
    key: FetchKey.Category,
    endpoint: endpoints.category,
    options: { staleTime: 60000 },
  })

  const standardCategoriesOptions = useMemo(
    () =>
      Object.keys(StandardCategoryOptions).map(name => ({
        label: formatMessage({ id: `schemas.provider.${name}` }),
        value: {
          groupBy: AnswersGroupingType.StandardCategory,
          groupByValue: name,
        },
        additionalText: formatMessage({ id: 'schemas.supplier.standardCategories' }),
      })),
    [],
  )

  const actorTypeOptions = useMemo(
    () =>
      Object.keys(ProductIndustry)?.map(name => ({
        label: formatMessage(
          { id: 'statistics.dataInsights.actorTypes.industryActorTypes' },
          { industry: formatMessage({ id: `schemas.product.productIndustry.${name}` }) },
        ),
        value: {
          groupBy: AnswersGroupingType.ActorType,
          groupByValue: name,
        },
        additionalText: formatMessage({ id: 'navbar.productMapping' }),
      })),
    [],
  )

  const customCategoryGroupByOption = useMemo(
    () =>
      categories?.map(category => ({
        label: category.name,
        value: {
          groupBy: AnswersGroupingType.CustomCategory,
          groupByValue: String(category.id),
        },
        additionalText: formatMessage({ id: 'schemas.supplier.customCategories' }),
      })),
    [categories],
  )

  const groupByOptions: { label: string; value: GroupByOption; additionalText?: string }[] = [
    {
      label: formatMessage({ id: 'reporting.period' }),
      value: {
        groupBy: AnswersGroupingType.PeriodName,
      },
    },
    {
      label: formatMessage({ id: 'dataHub.groupBy.spend' }),
      value: {
        groupBy: AnswersGroupingType.SpendClassification,
      },
    },
    {
      label: formatMessage({ id: 'resourceCollections.general.country' }),
      value: {
        groupBy: AnswersGroupingType.Country,
      },
    },
    ...insertIf(activeRiskSettings.length > 0, {
      label: formatMessage({ id: 'general.countryRisk' }),
      value: {
        groupBy: AnswersGroupingType.CountryRisk,
      },
    }),
    ...(customCategoryGroupByOption ?? []),
    ...(productMapping ? actorTypeOptions : []),
    ...standardCategoriesOptions,
  ]

  if (isDetailsTabActive) {
    return null
  }

  return (
    <Grid item xs={2}>
      <SimpleSelect<GroupByOption>
        onChange={option => {
          if (!option) {
            setGroupBy(AnswersGroupingType.PeriodName)
          }
          if (option && !Array.isArray(option)) {
            setGroupBy(option.value.groupBy, option.value.groupByValue)
          }
        }}
        groupBy={option => option.additionalText ?? ''}
        open={isOpen}
        setOpen={setOpen}
        isLoading={isCustomCategoriesLoading}
        loading={isCustomCategoriesLoading}
        options={groupByOptions}
        fieldLabel={formatMessage({ id: 'dataHub.groupBy.name' })}
        value={
          groupByOptions.find(
            option => option.value.groupBy === groupBy && (!groupByValue || option.value.groupByValue === groupByValue),
          ) || null
        }
        variant="outlined"
        size="small"
      />
    </Grid>
  )
}
