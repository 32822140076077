import { alpha, Chip, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ProviderRiskStatus, ProviderRiskStatusType, RiskStatus } from '@app/src/types/resourceExplorer'
import ParentCellOrFragment from './ParentCellOrFragment'

export interface SimpleRiskChipCellProps {
  riskStatus?: ProviderRiskStatusType
  disableCell?: boolean
  disabled?: boolean
  onClick?: () => void
}

const getColorBasedOnRisk = (risk?: ProviderRiskStatusType): string => {
  switch (risk) {
    case ProviderRiskStatus.Low:
      return 'info.main'
    case ProviderRiskStatus.Medium:
      return 'warning.main'
    case ProviderRiskStatus.High:
      return 'error.main'
    case ProviderRiskStatus.Extreme:
      return 'semantic.error'
    default:
      return alpha('#000', 0.04)
  }
}

const SimpleRiskChipCell: React.FC<SimpleRiskChipCellProps> = ({ riskStatus, disableCell, disabled, onClick }) => {
  const { formatMessage } = useIntl()

  const color = useMemo(() => (disabled ? alpha('#000', 0.04) : getColorBasedOnRisk(riskStatus)), [riskStatus])

  if (!riskStatus) {
    return (
      <ParentCellOrFragment disableCell={disableCell}>
        <Typography variant="body2" color="text.disabled">
          {formatMessage({ id: 'schemas.risk.notAvailable' })}
        </Typography>
      </ParentCellOrFragment>
    )
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Chip
        label={formatMessage({ id: `schemas.risk.${riskStatus ? riskStatus : 'notAvailable'}` })}
        size="small"
        disabled={disabled}
        onClick={onClick}
        clickable={Boolean(onClick)}
        sx={{
          color: riskStatus === RiskStatus.Extreme && !disabled ? 'primary.contrastText' : 'primary.main',

          '& .MuiChip-label': {
            bgcolor: color,
            border: '2px solid',
            borderColor: color,
            borderRadius: 1,
          },
        }}
      />
    </ParentCellOrFragment>
  )
}

export default SimpleRiskChipCell
