import ImageWithContentDialog from '@app/src/components/Dialog/ImageWithContentDialog'
import Avatar from '@app/src/components/Ui/Avatar'
import { useAccount } from '@app/src/context/AccountContext'
import { CheckOutlined, LanguageOutlined, LockOutlined } from '@mui/icons-material'
import { Button, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import React, { FC, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'

interface ViewAIScreenedDataSuggestionDialogProps {
  open: boolean
  onClose: () => void
}

const ViewAIScreenedDataSuggestionDialog: FC<ViewAIScreenedDataSuggestionDialogProps> = ({ open, onClose }) => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()
  const history = useHistory()

  const dialogAction = useCallback(() => {
    void history.push('/manage/screened-requests')

    void onClose()
  }, [history.push, onClose])

  return (
    <ImageWithContentDialog
      imageUrl="/img/dashboards/transparency/view-ai-screened-data-suggestion.webp"
      open={open}
      onClose={onClose}
    >
      <Stack spacing={2}>
        <Typography variant="h3">
          {formatMessage(
            { id: 'reporting.reuseFlow.reuseQuestion.aiScreenedDataSuggestionDialog.title' },
            { companyName: account?.organization?.name },
          )}
        </Typography>
        <Typography>
          {formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.aiScreenedDataSuggestionDialog.description' })}
        </Typography>
        <List disablePadding>
          <ListItem disablePadding alignItems="flex-start" sx={{ pb: 2 }}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  bgcolor: theme => theme.palette.communication.light,
                  color: theme => theme.palette.communication.dark,
                  width: 48,
                  height: 48,
                }}
              >
                <CheckOutlined />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({
                id: 'reporting.reuseFlow.reuseQuestion.aiScreenedDataSuggestionDialog.applyManuallyPrimary',
              })}
              secondary={formatMessage({
                id: 'reporting.reuseFlow.reuseQuestion.aiScreenedDataSuggestionDialog.applyManuallySecondary',
              })}
              primaryTypographyProps={{ variant: 'subtitle1' }}
              secondaryTypographyProps={{ color: 'textPrimary', variant: 'body1' }}
            />
          </ListItem>
          <ListItem disablePadding alignItems="flex-start" sx={{ pb: 2 }}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  bgcolor: theme => theme.palette.communication.light,
                  color: theme => theme.palette.communication.dark,
                  width: 48,
                  height: 48,
                }}
              >
                <LanguageOutlined />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({
                id: 'reporting.reuseFlow.reuseQuestion.aiScreenedDataSuggestionDialog.publiclySourcedPrimary',
              })}
              secondary={formatMessage({
                id: 'reporting.reuseFlow.reuseQuestion.aiScreenedDataSuggestionDialog.publiclySourcedSecondary',
              })}
              primaryTypographyProps={{ variant: 'subtitle1' }}
              secondaryTypographyProps={{ color: 'textPrimary', variant: 'body1' }}
            />
          </ListItem>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                sx={{
                  bgcolor: theme => theme.palette.communication.light,
                  color: theme => theme.palette.communication.dark,
                  width: 48,
                  height: 48,
                }}
              >
                <LockOutlined />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({
                id: 'reporting.reuseFlow.reuseQuestion.aiScreenedDataSuggestionDialog.privatePrimary',
              })}
              secondary={formatMessage({
                id: 'reporting.reuseFlow.reuseQuestion.aiScreenedDataSuggestionDialog.privateSecondary',
              })}
              primaryTypographyProps={{ variant: 'subtitle1' }}
              secondaryTypographyProps={{ color: 'textPrimary', variant: 'body1' }}
            />
          </ListItem>
        </List>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button variant="contained" onClick={dialogAction}>
            {formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.aiScreenedDataSuggestionDialog.viewDataAction' })}
          </Button>
        </Stack>
      </Stack>
    </ImageWithContentDialog>
  )
}

export default ViewAIScreenedDataSuggestionDialog
