import { Box, Stack, Typography } from '@mui/material'
import ReactEcharts, { EChartsOption } from 'echarts-for-react'
import React, { useMemo } from 'react'

interface CompaniesStatisticGraphProps {
  data: { value: number | string; name: string | number }[]
  value: React.ReactNode
  colors: string[]
  title: string
  highlightSetter: React.Dispatch<React.SetStateAction<string | number | undefined>>
}

const CompaniesStatisticGraph: React.FC<CompaniesStatisticGraphProps> = ({
  colors,
  data,
  value,
  title,
  highlightSetter,
}) => {
  const options: EChartsOption = {
    animation: false,
    series: [
      {
        itemStyle: {
          normal: {
            label: {
              show: false,
            },
            labelLine: {
              show: true,
            },
            borderType: 'solid',
            borderWidth: 1,
            borderColor: 'white',
          },
        },
        type: 'pie',
        radius: ['65%', '100%'],
        color: colors,
        data,
        emphasis: {
          scale: true,
          scaleSize: 1.2,
          focus: 'self',
        },
      },
    ],
  }

  const events = useMemo(
    () => ({
      mouseover: ({ data }: { data: { name: string } }) => {
        highlightSetter(data.name)
      },
    }),
    [],
  )

  return (
    <Stack spacing={2} alignItems="center">
      <Box position="relative">
        <ReactEcharts option={options} onEvents={events} style={{ height: 160, width: 300 }} />
        <Box
          position="absolute"
          top="65px"
          bottom={0}
          left="100px"
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="30px"
          width="100px"
        >
          <Typography textAlign="center" variant="kpi">
            {`${isNaN(Number(value)) ? 0 : value}%`}
          </Typography>
        </Box>
      </Box>
      <Typography textAlign="center" variant="subtitle1" width={300}>
        {title}
      </Typography>
    </Stack>
  )
}

export default CompaniesStatisticGraph
