import { ExportColumn } from '../types/export'

export const ContactExportColumns: ExportColumn[] = [
  {
    translationId: 'schemas.contact.name',
    exportPath: 'user.name',
  },
  {
    translationId: 'export.schemas.provider.organization.organizationName',
    exportPath: 'creatorOrganization.name',
  },
  {
    translationId: 'schemas.contact.position',
    exportPath: 'position',
  },
  {
    translationId: 'schemas.contact.phone',
    exportPath: 'phoneNumber',
  },
  {
    translationId: 'schemas.contact.email',
    exportPath: 'user.email',
  },
]
