import ChipCell from '@app/src/components/Table/Cells/ChipCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import LinkCell from '@app/src/components/Table/Cells/LinkCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { ActorType, MappingNode } from '@app/src/types/product'
import paths from '@app/src/wf-constants/paths'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'

interface MappingNodeRowProps {
  row: MappingNode
}

const MappingNodeRow: React.FC<MappingNodeRowProps> = ({ row: mappingNode }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <LinkCell label={mappingNode?.product?.name} to={generatePath(paths.product, { id: mappingNode?.productId })} />
      <ValueCell value={mappingNode?.product?.gtin} />
      <ValueCell value={mappingNode?.product?.productCustomId} />
      <ValueCell value={mappingNode?.product?.purchaseOrder} />
      <ValueCell value={mappingNode?.tier} />
      <CompanyCell company={mappingNode?.provider} drawer />
      <ChipCell
        variant="outlined"
        size="small"
        value={formatMessage({
          id: `schemas.mappingNode.actorTypeValues.${mappingNode?.actorType?.name ?? ActorType.NotSet}`,
        })}
        defaultValue={formatMessage({ id: `schemas.mappingNode.actorTypeValues.NotSet` })}
      />
      {mappingNode?.parentNode?.provider ? (
        <CompanyCell company={mappingNode?.parentNode?.provider} drawer />
      ) : (
        <CompanyCell company={mappingNode?.parentNode?.organization} />
      )}

      <ChipCell
        variant="outlined"
        size="small"
        value={formatMessage({
          id: `schemas.mappingNode.actorTypeValues.${mappingNode?.parentNode?.actorType?.name ?? ActorType.NotSet}`,
        })}
        defaultValue={formatMessage({ id: `schemas.mappingNode.actorTypeValues.NotSet` })}
      />
    </TableRow>
  )
}

export default MappingNodeRow
