import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS } from '@app/src/components/CreateNewRequestModal/Common/SelectFilterStep'
import EmptyState from '@app/src/components/EmptyState'
import LoadingButton from '@app/src/components/LoadingButton'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import usePagination from '@app/src/hooks/pagination'
import StatisticsCard from '@app/src/pages/Dashboards/StatisticsCard'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { GoalTracker } from '@app/src/types/resourceExplorer'
import { mergeRefs } from '@app/src/utils/helpersTs'
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import { Box, Button, Grid, Skeleton } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useIntl } from 'react-intl'
import CreateGoalTrackerModal from './CreateGoalTrackerModal'
import GoalTrackerCard from './GoalTrackerCard'

const GOAL_TRACKER_DENSE_AMOUNT = 4
const GOAL_TRACKER_LIMIT = 20

const GoalTrackerSection = () => {
  const { formatMessage } = useIntl()
  const [isCreateGoalTrackerDialogOpen, openCreateGoalTrackerDialog, closeCreateGoalTrackerDialog] =
    useDialogState(false)
  const ref = useRef<HTMLDivElement>(null)
  const [showAll, setShowAll] = useState(false)
  const { inView, ref: inViewRef } = useInView({ triggerOnce: true })

  const [page, pageSize, , setPageSize] = usePagination({
    page: 1,
    pageSize: GOAL_TRACKER_DENSE_AMOUNT,
  })

  const {
    items: goalTrackerItems,
    isLoading: goalTrackerItemsIsLoading,
    refetch,
    count,
    pageNumber = 0,
    pagesCount = 0,
    isError: goalTrackerItemsIsError,
  } = useFetchCollectionWithPost<GoalTracker>({
    key: FetchKey.GoalTracker,
    endpoint: endpoints.goalTrackerCollection,
    shouldShowErrorNotification: false,
    payload: {
      include: ['question.sections', 'goalValueUnit'],
      filter: [],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
    options: {
      keepPreviousData: true,
      enabled: inView,
    },
  })

  const isEmptyState = !goalTrackerItemsIsLoading && !goalTrackerItems.length

  const cleanGoalTrackerItems: GoalTracker[] = goalTrackerItems.map(item => ({
    ...item,
    questionnaireTemplateId: item.question?.sections?.[0]?.questionnaireTemplateId ?? 0,
  }))

  const handleExpandClick = async () => {
    setPageSize(showAll ? 9999 : GOAL_TRACKER_DENSE_AMOUNT)
    await refetch()

    if (!showAll) ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  useEffect(() => {
    if (pageNumber < pagesCount) {
      setShowAll(true)
    } else {
      setShowAll(false)
    }
  }, [pageNumber, pagesCount])

  return (
    <Box ref={mergeRefs(ref, inViewRef)}>
      <StatisticsCard
        title={formatMessage({ id: 'schemas.goalTracker.title' })}
        isError={goalTrackerItemsIsError}
        action={
          <Button
            onClick={openCreateGoalTrackerDialog}
            startIcon={<AddIcon />}
            disabled={cleanGoalTrackerItems.length >= GOAL_TRACKER_LIMIT}
          >
            {formatMessage({ id: 'schemas.goalTracker.addGoal' })}
          </Button>
        }
        loading={{
          isLoading: goalTrackerItemsIsLoading,
          skeleton: (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={3} mt={1}>
                <Skeleton height={288} variant="rounded" />
              </Grid>
              <Grid item xs={12} sm={6} lg={3} mt={1}>
                <Skeleton height={288} variant="rounded" />
              </Grid>
              <Grid item xs={12} sm={6} lg={3} mt={1}>
                <Skeleton height={288} variant="rounded" />
              </Grid>
            </Grid>
          ),
        }}
      >
        <Box width="100%" mt={2}>
          {isEmptyState ? (
            <Box py={4}>
              <EmptyState
                iconComponent={TrackChangesIcon}
                title={formatMessage({ id: 'schemas.goalTracker.sectionEmptyState.title' })}
                description={formatMessage({ id: 'schemas.goalTracker.sectionEmptyState.description' })}
              >
                <Button onClick={openCreateGoalTrackerDialog} variant="outlined">
                  {formatMessage({ id: 'schemas.goalTracker.addGoal' })}
                </Button>
              </EmptyState>
            </Box>
          ) : (
            <>
              <Grid container spacing={2}>
                {cleanGoalTrackerItems.map(item => (
                  <Grid item xs={12} sm={6} lg={3} key={item.id}>
                    <GoalTrackerCard goalTrackerItem={item} />
                  </Grid>
                ))}
              </Grid>

              {count > GOAL_TRACKER_DENSE_AMOUNT && (
                <Box display="flex" justifyContent="center">
                  <LoadingButton
                    startIcon={showAll ? <ExpandMoreOutlined /> : <ExpandLessOutlined />}
                    onClick={handleExpandClick}
                    loading={goalTrackerItemsIsLoading}
                    sx={{ mt: 2 }}
                  >
                    {formatMessage({ id: showAll ? 'general.showAll' : 'general.showLess' })}
                  </LoadingButton>
                </Box>
              )}
            </>
          )}
        </Box>
        <CreationModalProgressContextProvider>
          <Filters allowedFilters={CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS}>
            {() => (
              <CreateGoalTrackerModal open={isCreateGoalTrackerDialogOpen} onClose={closeCreateGoalTrackerDialog} />
            )}
          </Filters>
        </CreationModalProgressContextProvider>
      </StatisticsCard>
    </Box>
  )
}

export default GoalTrackerSection
