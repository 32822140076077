import { Spend } from '@app/src/types/organizations'
import { Unit } from '@app/src/types/resourceExplorer'
import { toCurrencyString } from '@app/src/utils/currencyUtils'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import ParentCellOrFragment from './ParentCellOrFragment'
import { SpendClassification } from '@app/src/wf-constants'

export const spendClassificationColors: { [key: string]: string } = {
  [SpendClassification.Low]: 'info.dark',
  [SpendClassification.Medium]: 'warning.dark',
  [SpendClassification.High]: 'error.dark',
}

export type ProviderSpendCellProps = {
  spend?: Spend
  organizationCurrency?: Unit
  disableCell?: boolean
}

const ProviderSpendCell: React.FC<ProviderSpendCellProps> = ({ spend, organizationCurrency, disableCell }) => {
  const { formatMessage } = useIntl()

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Tooltip
        arrow
        placement="left"
        title={
          spend?.spendClassification && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                height={12}
                width={12}
                borderRadius="100%"
                bgcolor={spendClassificationColors[spend.spendClassification]}
              />
              <Typography variant="body2">
                {formatMessage(
                  { id: 'statistics.dataInsights.spend.intervalLabel' },
                  { spendClassification: spend.spendClassification },
                )}
              </Typography>
            </Stack>
          )
        }
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {spend?.spendClassification && (
            <Box
              height={12}
              width={12}
              borderRadius="100%"
              bgcolor={spendClassificationColors[spend.spendClassification]}
            />
          )}
          <Typography color={spend ? undefined : 'action.disabled'}>
            {spend?.amount || spend?.amount === 0
              ? toCurrencyString(spend.amount, organizationCurrency)
              : formatMessage({ id: 'general.notAdded' })}
          </Typography>
        </Stack>
      </Tooltip>
    </ParentCellOrFragment>
  )
}

export default ProviderSpendCell
