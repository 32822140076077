import endpoints from '@app/src/api/endpoints'
import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import CreationModalStep, { CreationModalStepProps } from '@app/src/components/CreationModal/CreationModalStep'
import { Option } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { useProvidersModal } from '@app/src/context/ProvidersModalContext'
import { SortOrder } from '@app/src/types/filter'
import { Request } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { Dialog, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Operators } from '../../../Filters/useFilters'
import { useScheduleScreening } from './hooks/scheduleScreening'
import ScheduleProviderScreeningSummary from './ScheduleProviderScreeningSummary'
import ScheduleScreeningSuccessView from './ScheduleScreeningSuccessView'
import { useFlags } from 'launchdarkly-react-client-sdk'

export enum SCHEDULE_SCREENING_QUESTION_KEYS {
  PROVIDER_IDS = 'providerIds',
  TEMPLATES = 'templates',
}

type ScheduleProviderScreeningModalProps = {
  open: boolean
  onClose: () => void
}

export type ScheduleScreeningFormData = {
  providerIds: number[]
  templates: Array<{ id: number; title: string }>
}

const ScheduleProviderScreeningModal: React.FC<ScheduleProviderScreeningModalProps> = ({ open, onClose }) => {
  const { formatMessage } = useIntl()
  const { activeStep, setActiveStep, setTotalSteps } = useCreationModalProgress()
  const { providersToConnect } = useProvidersModal()
  const { scheduleScreening, isScheduling } = useScheduleScreening()
  const { aiScrapeAnyTemplate } = useFlags()
  const formMethods = useForm<ScheduleScreeningFormData>({
    shouldUnregister: false,
    defaultValues: {
      [SCHEDULE_SCREENING_QUESTION_KEYS.PROVIDER_IDS]: providersToConnect.map(provider => provider.id),
    },
  })
  const { handleSubmit, reset, watch, control } = formMethods

  useEffect(() => {
    if (providersToConnect?.length) {
      formMethods.setValue(
        SCHEDULE_SCREENING_QUESTION_KEYS.PROVIDER_IDS,
        providersToConnect.map(({ id }) => id),
        { shouldDirty: true },
      )
    }
  }, [providersToConnect, formMethods.setValue])

  const [showSuccessScreen, setShowSuccessScreen] = useState(false)

  const watchProviders = watch(SCHEDULE_SCREENING_QUESTION_KEYS.PROVIDER_IDS)

  const QUESTIONS: CreationModalStepProps[] = [
    {
      title: formatMessage({ id: 'form.scheduleProviderScreening.selectQuestionnaire.title' }),
      description: (
        <Typography>
          {formatMessage(
            { id: 'form.scheduleProviderScreening.selectQuestionnaire.description' },
            {
              count: watchProviders.length,
            },
          )}
        </Typography>
      ),
      fieldnames: [SCHEDULE_SCREENING_QUESTION_KEYS.TEMPLATES],
      children: (
        <Select<{ title: string; id: number }, Request>
          name={SCHEDULE_SCREENING_QUESTION_KEYS.TEMPLATES}
          fieldLabel={formatMessage({ id: 'form.createRequest.selectTemplateLabel' })}
          control={control}
          required
          enableAutoSelect
          multiple
          forceFetch
          navigation={{
            url: aiScrapeAnyTemplate ? endpoints.worldfavorAndCustomTemplates : endpoints.worldfavorTemplates,
            type: 'post',
            postObject: {
              include: [],
              filter: [
                ...insertIf(!aiScrapeAnyTemplate, {
                  name: 'tags.tag',
                  filters: [
                    {
                      value: 'WfDefaultRequest3',
                      operator: Operators.EqualTo,
                    },
                  ],
                }),
              ],
              sort: { target: 'title', order: SortOrder.ASCENDING },
            },
          }}
          findSelectedValue={(value, option): boolean => value?.id === option?.value?.id}
          objectToOption={({ title, id }: Request): Option<{ title: string; id: number }> => ({
            label: title,
            value: { title, id },
          })}
        />
      ),
      disableInitialBack: true,
      overrideButtonTextId: 'general.continue',
    },
    {
      title: formatMessage({ id: 'form.scheduleProviderScreening.summary.title' }),
      description: formatMessage({ id: 'form.scheduleProviderScreening.summary.description' }),
      fieldnames: [SCHEDULE_SCREENING_QUESTION_KEYS.PROVIDER_IDS],
      children: <ScheduleProviderScreeningSummary />,
      hideNextButton: true,
      disableInitialBack: true,
      overrideButtonTextId: 'form.scheduleProviderScreening.summary.confirm',
      isLoading: isScheduling,
    },
  ]

  const activeQuestion = useMemo(() => QUESTIONS[activeStep - 1], [activeStep, QUESTIONS])

  const onSubmit = async (values: ScheduleScreeningFormData) => {
    await scheduleScreening({
      providerIds: values.providerIds,
      templateIds: values.templates.map(template => template.id),
    })

    setShowSuccessScreen(true)
  }

  useEffect(() => {
    return () => {
      setActiveStep(1)
      reset()
      setShowSuccessScreen(false)
    }
  }, [open])

  useEffect(() => {
    setTotalSteps(QUESTIONS.length)
  }, [QUESTIONS.length])

  return (
    <Dialog open={open} onClose={onClose} fullScreen closeAfterTransition>
      {showSuccessScreen ? (
        <ScheduleScreeningSuccessView providerIds={watchProviders} onClose={onClose} />
      ) : (
        <CreationModalContainer
          title={
            activeStep !== QUESTIONS.length
              ? formatMessage({ id: 'form.scheduleProviderScreening.header.screening' })
              : formatMessage({ id: 'form.scheduleProviderScreening.header.inProgress' })
          }
          onClose={onClose}
        >
          <FormProvider {...formMethods}>
            <Stack component="form" flexGrow={1} alignItems="center" onSubmit={handleSubmit(onSubmit)}>
              {activeQuestion && (
                <CreationModalStep {...activeQuestion} widthOverride={584}>
                  {activeQuestion.children}
                </CreationModalStep>
              )}
            </Stack>
          </FormProvider>
        </CreationModalContainer>
      )}
    </Dialog>
  )
}

export default ScheduleProviderScreeningModal
