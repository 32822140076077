import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { TableRow } from '@mui/material'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import ResponseItemHeader from './ResponseItemHeader'

interface ResponseItemCompanyHeaderProps extends SortableHeaderProps {
  isHeaderChecked?: boolean
  handleHeaderCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  disableRowSelection?: boolean
  additionalColumn?: ReactNode
}

const ResponseItemCompanyHeader: React.FC<ResponseItemCompanyHeaderProps> = ({
  toggleSorting,
  activeSorting,
  isHeaderChecked,
  handleHeaderCheckboxChange,
  disableRowSelection,
  additionalColumn,
}): JSX.Element => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      {!disableRowSelection && (
        <TableHeaderCell
          label=""
          checkbox={{ checked: isHeaderChecked, onChange: handleHeaderCheckboxChange, color: 'primary' }}
          minWidth={TableCellWidth.EMPTY}
        />
      )}
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.responseItem.target' })}
        sorting={{ name: 'creatorOrganizationId', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.LARGE}
      />
      {additionalColumn}
      <ResponseItemHeader activeSorting={activeSorting} toggleSorting={toggleSorting} disableTableRow />
    </TableRow>
  )
}

export default ResponseItemCompanyHeader
