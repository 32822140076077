import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import LinkButton from '@app/src/components/LinkButton'
import StatusChip from '@app/src/components/StatusChip'
import { ProviderTabs } from '@app/src/pages/ResourceCollection/Collections/Providers/ProvidersScene'
import { SortOrder } from '@app/src/types/filter'
import { Company } from '@app/src/types/organizations'
import { getDaysAgo } from '@app/src/utils'
import paths from '@app/src/wf-constants/paths'
import AddIcon from '@mui/icons-material/Add'
import { Box, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'
import StatisticsCard from './StatisticsCard'

interface CompaniesCardProps {
  totalCompanies: number
  isLoading: boolean
  totalCompaniesError: boolean
}

const CompaniesCard: React.FC<CompaniesCardProps> = ({ totalCompanies, isLoading, totalCompaniesError }) => {
  const { formatRelativeTime, formatMessage } = useIntl()

  const {
    items: latestCompany,
    isLoading: isLatestCompanyLoading,
    isError: latestCompanyError,
  } = useFetchCollectionWithPost<Company>({
    key: [FetchKey.Organization, FetchKey.Company],
    endpoint: endpoints.providersCollection,
    payload: {
      filter: [],
      include: [],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 1,
      },
      sort: {
        target: 'createdAt',
        order: SortOrder.DESCENDING,
      },
    },
    shouldShowErrorNotification: false,
  })

  const emptyState = !isLoading && !isLatestCompanyLoading && !totalCompanies
  const daysSinceLastCompanyJoined = getDaysAgo(latestCompany?.[0]?.createdAt)
  const isError = totalCompaniesError || latestCompanyError

  return (
    <StatisticsCard
      title={formatMessage({ id: 'dashboard.sourcing.companies' })}
      justifyContent={!emptyState ? 'left' : 'center'}
      helperText={formatMessage({ id: 'dashboard.sourcing.companiesHelperText' })}
      action={
        !emptyState && (
          <LinkButton size="small" to={generatePath(paths.providers, { view: ProviderTabs.All })}>
            {formatMessage({ id: 'dashboard.investment.seeAll' })}
          </LinkButton>
        )
      }
      isError={isError}
      loading={{
        isLoading: isLatestCompanyLoading || isLoading,
        skeleton: (
          <Stack spacing={2}>
            <Typography variant="kpi" pt={2}>
              <Skeleton width={70} variant="rounded" />
            </Typography>

            <Skeleton width={200} />
          </Stack>
        ),
      }}
    >
      <>
        {emptyState ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={2}>
            <LinkButton
              to={generatePath(paths.providers, { view: ProviderTabs.All })}
              startIcon={<AddIcon fontSize="small" />}
              variant="contained"
            >
              {formatMessage({ id: 'dashboard.sourcing.companiesEmptyStateAction' })}
            </LinkButton>
          </Box>
        ) : (
          <Stack spacing={2}>
            <Typography variant="kpi" pt={2}>
              {totalCompanies}
            </Typography>

            {Boolean(totalCompanies) && (
              <Tooltip title={latestCompany?.[0]?.name}>
                <Box sx={{ '&:hover': { backgroundColor: 'grey.100', borderRadius: 4 } }}>
                  <StatusChip
                    transparent
                    color="info"
                    label={formatMessage(
                      { id: 'dashboard.sourcing.latestJoined' },
                      {
                        time:
                          daysSinceLastCompanyJoined === 0
                            ? formatMessage({ id: 'dashboard.sourcing.today' })
                            : formatRelativeTime(-daysSinceLastCompanyJoined, 'day'),
                      },
                    )}
                  />
                </Box>
              </Tooltip>
            )}
          </Stack>
        )}
      </>
    </StatisticsCard>
  )
}

export default CompaniesCard
