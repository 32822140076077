import { Box, Chip, ChipProps, Typography } from '@mui/material'
import React from 'react'

export type StatusChipProps = {
  transparent?: boolean
  color?: ChipProps['color'] | 'communication'
} & Omit<ChipProps, 'color'>

const StatusChip: React.FC<StatusChipProps> = ({ transparent, size = 'small', color, label, ...props }) => {
  return (
    <Chip
      icon={
        <Box pl={transparent ? '-8px !important' : 0.5}>
          <Box
            height={8}
            width={8}
            bgcolor={!color || color === 'default' ? 'grey.400' : `${color}.dark`}
            borderRadius="100%"
          />
        </Box>
      }
      sx={{ backgroundColor: transparent ? 'transparent' : `${color}.light` }}
      size={size}
      label={<Typography variant="caption">{label}</Typography>}
      {...props}
    />
  )
}

export default StatusChip
