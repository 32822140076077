import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { ActionButtonsProps } from '@app/src/components/ActionButtons'
import SimpleBreadcrumbs from '@app/src/components/SimpleBreadcrumbs'
import { TabsProps } from '@app/src/components/Tabs'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { LibraryBooksOutlined } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router'
import { Route, Switch, generatePath, useParams } from 'react-router-dom'
import AssessmentDetailsScene from './AssessmentDetailsScene'
import AssessmentOverviewScene from './AssessmentOverviewScene'
import BaseAssessmentInfoDialog from './BaseAssessmentInfoDialog'

export enum BaseAssessmentViews {
  Overview = 'overview',
  Compare = 'compare',
}

interface BaseAssessmentSceneParams {
  view: BaseAssessmentViews
}

const allowedFilters = [
  'provider.id',
  'provider.name',
  'provider.country.id',
  'provider.createdAt',
  'provider.ownerUserId',
  'provider.websiteAddress',
  'provider.vatNumber',
  'provider.registrationNumber',
  'provider.linkStatus',
  'provider.categoryOptions.id',
  'provider.activityStatus',
  'provider.finalRiskRating',
  'provider.priority',
  'provider.providerApprovalStatus',
  'provider.supplierUsage',
  'provider.tier',
  'provider.organization.id',
  'provider.mappingNodes.actorType.id',
  'provider.mappingNodes.tier',
  'provider.spends.spendClassification',
  'provider.spends.periodName',
  'periodName',
  'totalLevel',
  'section1Level',
  'section2Level',
  'section3Level',
  'section4Level',
  'section5Level',
  'isScreened',
]

const BaseAssessmentScene: React.FC = () => {
  const { hasPermission } = usePermissions()
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const { view } = useParams<BaseAssessmentSceneParams>()
  const [open, openDialog, closeDialog] = useDialogState()
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)
  const { assessments } = useFlags()

  const actionTabs: TabsProps['tabs'] = [
    {
      type: BaseAssessmentViews.Overview,
      url: stringifyQueryFilters({
        url: generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Overview }),
        queryParams: {},
        includeCurrentFilters: true,
      }),
      label: formatMessage({ id: 'baseAssessment.tabs.overview' }),
    },
    {
      type: BaseAssessmentViews.Compare,
      url: stringifyQueryFilters({
        url: generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Compare }),
        queryParams: {},
        includeCurrentFilters: true,
      }),
      label: formatMessage({ id: 'baseAssessment.tabs.compare' }),
    },
  ]

  const actionButtons: ActionButtonsProps['buttons'] = [
    {
      label: formatMessage({ id: 'baseAssessment.infoDialog.infoButton' }),
      variant: 'text',
      startIcon: <LibraryBooksOutlined />,
      onClick: openDialog,
    },
  ]

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Analyze.Assessment.ViewedPage,
      eventProps: {
        page: view,
      },
    })
  }, [view])

  if (!canAccessAssessmentTemplate) return null

  if (!view) {
    return (
      <Redirect
        to={generatePath(paths.baseAssessment, {
          view: BaseAssessmentViews.Overview,
        })}
      />
    )
  }

  return (
    <>
      {canAccessAssessmentTemplate && assessments && (
        <>
          <Helmet>
            <title>{formatMessage({ id: 'resourceTypes.assessments' })}</title>
          </Helmet>
          <Stack px={4} pt={2}>
            <SimpleBreadcrumbs
              crumbs={[
                {
                  label: formatMessage({
                    id: 'resourceTypes.assessments',
                  }),
                  to: generatePath(paths.assessments),
                },
                {
                  label: formatMessage({
                    id: 'baseAssessment.baselineAssessment',
                  }),
                },
              ]}
            />
          </Stack>
        </>
      )}
      <Switch>
        <Route path={generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Overview })} exact>
          <AssessmentOverviewScene
            allowedFilters={allowedFilters}
            userFilters={userFilters}
            actionButtons={actionButtons}
            tabs={{ actionTabs, activeTabParam: view }}
          />
        </Route>
        <Route path={generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Compare })} exact>
          <AssessmentDetailsScene
            allowedFilters={allowedFilters}
            userFilters={userFilters}
            tabs={{ actionTabs, activeTabParam: view }}
          />
        </Route>
      </Switch>
      <BaseAssessmentInfoDialog open={open} closeDialog={closeDialog} />
    </>
  )
}

export default BaseAssessmentScene
