import Permissions, { usePermissions } from '@app/src/auth/permissions'
import DateDisplay from '@app/src/components/DateDisplay'
import RenderFileDownload from '@app/src/components/RenderFileDownload'
import VerificationButtons from '@app/src/pages/Questionnaire/Accessor/Actions/VerificationButtons'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import {
  AnswerStatus,
  ExternalVerificationStatus,
  ResponseDraftStatus,
  ResponseItem,
} from '@app/src/types/resourceExplorer'
import { AutoAwesomeOutlined } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { Palette } from '@mui/material/styles'
import { makeStyles, useTheme } from '@mui/styles'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import SkippedQuestionCard from '../../Provider/Report/SkippedQuestionCard'
import { getResponseValueTypes } from '../../utils'
import VerificationStatus from '../../VerificationStatus'
import ScrapedAnswerCitations from './ScrapedAnswerCitations'

const useStyles = makeStyles({
  value: {
    wordBreak: 'break-word',
  },
})

export interface ResponseValueProps {
  item: ResponseItem
  questionType: string
  questionDescription: string
  unitSymbol: string | null
}

const getBgColor = (item: ResponseItem, palette: Palette) => {
  const isSomeExternalStatusCorrectionNeeded = item.verifications?.some(
    x => x.externalVerificationStatus === ExternalVerificationStatus.CorrectionNeeded,
  )
  if (isSomeExternalStatusCorrectionNeeded) return palette.warning.light
  if (!item.isScraped) return palette.grey['100']
  if (item.cannotAnswer) return palette.grey['50']
  return palette.communication.light
}

const ResponseValue = ({ item, questionType, questionDescription, unitSymbol }: ResponseValueProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const { displayAsFile, displayAsOptions, selectedOptions, hasValue } = getResponseValueTypes(questionType, item)
  const { stpResponseResponseItemVerificationFilterLogic } = useFlags()
  const { response, request, latestHistory, isResponseFetching } = useReport()
  const { hasPermission } = usePermissions()
  const isLatestResponse = latestHistory?.responseId === response?.id
  const { palette } = useTheme()

  const isPrimarySubscriber = response?.request?.isPrimarySubscriber || request?.isPrimarySubscriber
  const isReviewEnabled =
    (!stpResponseResponseItemVerificationFilterLogic || isPrimarySubscriber) &&
    hasPermission(Permissions.WRITE_VERIFICATION) &&
    isLatestResponse &&
    response?.draftStatus === ResponseDraftStatus.Submitted

  const displayValue = (): React.ReactNode => {
    if (item?.answerStatus !== AnswerStatus.Answered) {
      return <SkippedQuestionCard item={item} />
    }

    if (displayAsFile) {
      return (
        <RenderFileDownload
          fileName={item.uploadReferences?.[0].originalFileName}
          id={item.uploadReferences?.[0].id ?? 0}
        >
          {item.expiresAt && (
            <Box display="flex" alignItems="flex-end" mr={2}>
              <Typography variant="caption">
                {formatMessage({ id: 'questionnaire.expiresAt' }, { date: <DateDisplay value={item.expiresAt} /> })}
              </Typography>
            </Box>
          )}
        </RenderFileDownload>
      )
    }

    if (displayAsOptions) {
      if (selectedOptions?.length) {
        return <Typography>{selectedOptions?.join(', ')}</Typography>
      }
    }

    if (hasValue) {
      return (
        <Typography className={classes.value}>
          {item.answer || ''} {unitSymbol || ''}
        </Typography>
      )
    }

    return <Typography>{formatMessage({ id: 'reporting.noResponse' })}</Typography>
  }

  return (
    <>
      <Stack px={3} py={4} bgcolor={getBgColor(item, palette)}>
        <Stack mr={isReviewEnabled ? 5 : 0} direction="row" spacing={2}>
          {response?.isScraped && item.answerStatus === AnswerStatus.Answered && (
            <AutoAwesomeOutlined sx={{ color: palette.communication.dark }} />
          )}
          {displayValue()}
        </Stack>
        {item?.isAnswered && item.scrapedAnswer?.citations?.length ? (
          <ScrapedAnswerCitations citations={item.scrapedAnswer.citations} />
        ) : null}
        <Stack alignSelf="flex-end" mt={2}>
          <VerificationButtons item={item} questionDescription={questionDescription} isLoading={isResponseFetching} />
        </Stack>
      </Stack>
      <VerificationStatus {...item} isLoading={isResponseFetching} />
    </>
  )
}

export default ResponseValue
