import BaseProviderResponseScene from '@app/src/pages/ResourceCollection/Collections/ManageRequests/Response/BaseProviderResponseScene'
import ProviderResponseHeader from '@app/src/pages/ResourceCollection/Collections/Response/ProviderResponseHeader'
import ProviderResponseRow from '@app/src/pages/ResourceCollection/Collections/Response/ProviderResponseRow'
import { FilterGroup, RESPONSE_LATEST_SUBMITTED_FILTER } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import React from 'react'

interface ProviderResponseSceneProps {
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
  relatedContentFilter?: FilterGroup[]
}

const ProviderResponseScene: React.FC<ProviderResponseSceneProps> = props => {
  return (
    <BaseProviderResponseScene
      {...props}
      relatedContentFilter={[...(props.relatedContentFilter ?? []), RESPONSE_LATEST_SUBMITTED_FILTER]}
      RowComponent={({ row }) => <ProviderResponseRow row={row} />}
      HeaderComponent={({ toggleSorting, sorting }) => (
        <ProviderResponseHeader toggleSorting={toggleSorting} activeSorting={sorting} />
      )}
    />
  )
}

export default ProviderResponseScene
