import React from 'react'

export const maxBy = <TItem, TProp>(items: Array<TItem>, fn: (item: TItem) => TProp): TItem | undefined =>
  items.reduce<TItem | undefined>((max, x) => (max && fn(max) <= fn(x) ? max : x), undefined)

export const distinctBy = <TItem, TProp>(items: TItem[], fn: (item: TItem) => TProp) =>
  Array.from(new Map(items.map(item => [fn(item), item])).values())

export const insertIf = <T>(condition: boolean, value: T) => {
  return condition ? [value] : []
}

export const insertObjIf = <T>(condition: boolean, obj: T) => {
  return condition ? obj : {}
}

// Workaround for Javascript date weirdness for timezones with a negative offset
// See https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off,
export const getDateWithoutTimeFromString = (dateString: string) => {
  return new Date(dateString.replace(/-/g, '/').replace(/T.+/, ''))
}

export function isDateOnly(input: string): boolean {
  // This regular expression checks for date formats like "YYYY-MM-DD"
  const dateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/
  return dateOnlyRegex.test(input)
}

export const percentageChange = (oldValue?: number, newValue?: number): number | 'n/a' => {
  if (!oldValue || !newValue) return 'n/a'
  // Check for division by zero
  if (oldValue === 0) {
    if (newValue === 0) {
      return 0 // No change
    } else {
      return 'n/a' // Any non-zero value over zero is considered an infinite percentage change
    }
  }

  return ((newValue - oldValue) / oldValue) * 100
}

export const triggerDownloadFromResponse = (blob: Blob, fileName: string, extension?: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName !== 'File' ? fileName : `${fileName}.${extension}`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const lowercaseFirstCharacter = (string: string) => string.charAt(0).toLowerCase() + string.slice(1)

const superscripts = ['⁰', '¹', '²', '³', '⁴', '⁵', '⁶', '⁷', '⁸', '⁹']
const toScientificNotation = (value: number) => {
  const exponentialForm = value.toExponential(1)
  const [base, exponent] = exponentialForm.split('e')
  const exponentSuperscript = exponent
    .split('')
    .map(v => (v === '-' ? '⁻' : superscripts[parseInt(v)]))
    .join('')
  return `${base}×10${exponentSuperscript}`
}

export const formatNumber = (value: number) => {
  if (isNaN(value) || value === undefined || value === null) return undefined
  if (Math.abs(value) >= 10_000_000 || (Math.abs(value) < 0.000_001 && value !== 0)) return toScientificNotation(value)

  if (value >= 10) return value.toFixed(0)
  if (Number.isInteger(value)) return value.toFixed(0)
  return value.toPrecision(2)
}

export const bytesToMegaBytes = (bytes: number) => bytes / (1024 * 1024)

export const mergeRefs =
  <T>(...refs: Array<React.Ref<T>>) =>
  (element: T | null) => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(element)
      } else if (ref && typeof ref === 'object') {
        ;(ref as React.MutableRefObject<T | null>).current = element
      }
    })
  }
