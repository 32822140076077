import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import DrawerViewGuidanceReport, { emptyPayload } from '@app/src/components/Drawer/Views/DrawerViewGuidanceReport'
import DrawerViewBulkResponseItemExternalVerification from '@app/src/components/Drawer/Views/Verification/DrawerViewBulkResponseItemExternalVerification'
import DrawerViewBulkResponseItemInternalVerification from '@app/src/components/Drawer/Views/Verification/DrawerViewBulkResponseItemInternalVerification'
import { useDrawer_DEPRECATED } from '@app/src/components/Drawer_DEPRECATED'
import UpdateProvidersDrawer from '@app/src/components/ManageProviderDrawer/UpdateProvidersDrawer'
import Table from '@app/src/components/Table'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { getResponseItemExportColumnsAccessor } from '@app/src/export-columns/responseItem'
import usePagination from '@app/src/hooks/pagination'
import useSort from '@app/src/hooks/sorting'
import useProviderType from '@app/src/hooks/useProviderType'
import { useSelectableRowsWithFacetsSelectAll } from '@app/src/hooks/useSelectableRows/useSelectableRowsWithFacetsSelectAll'
import useSelectedProviderData from '@app/src/pages/ResourceCollection/Collections/DataHub/AllAnswers/useSelectedProvidersData'
import DataHubFiltersAllAnswers from '@app/src/pages/ResourceCollection/Filters/DataHubFiltersAllAnswers'
import {
  FilterGroup,
  RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
  RESPONSE_ITEM_NOT_SCRAPED_FILTER,
} from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { ExportTemplate } from '@app/src/types/export'
import { ResponseItem } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { ResourceTypes } from '@app/src/wf-constants'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import ResponseItemCompanyHeader from './ResponseItemCompanyHeader'
import ResponseItemCompanyRow from './ResponseItemCompanyRow'
import ResponseItemHeader from './ResponseItemHeader'
import ResponseItemRow from './ResponseItemRow'
import useSelectedAnswersData from './useSelectedAnswersData'

interface DataHubAnswersTableProps {
  relatedContentFilter?: FilterGroup[]
  allowedFilters: string[]
  userFilters: FilterGroup[]
  tabs?: ResourceCollectionSceneProps['tabs']
  embedded?: ResourceCollectionSceneProps['embedded']
  showCompanyCell?: boolean
}

const DataHubAnswersTable: React.FC<DataHubAnswersTableProps> = ({
  relatedContentFilter = [],
  allowedFilters,
  userFilters,
  tabs,
  embedded,
  showCompanyCell,
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const { openDrawer } = useDrawer()
  const { solution } = useAuthentication().scope
  const responseItemSchema = getResponseItemExportColumnsAccessor(solution)
  const providerType = useProviderType()
  const [isUpdateDrawerOpen, openUpdateDrawer, closeUpdateDrawer] = useDrawer_DEPRECATED(false)

  const [onlyLatest, setOnlyLatest] = useState(true)
  const [allowGuidanceReport, setAllowGuidanceReport] = useState(false)

  const basePayload = {
    filter: relatedContentFilter,
    sort: sorting,
    include: [
      'requestItem',
      'response.request',
      'creatorOrganization',
      'response.request.creatorOrganization',
      'response.request.responderOrganization',
      'response.request.subscriptions.target',
      'response.request.subscriptions.target.country.name',
      'requestItem.unit',
      'requestItem.questionType',
      'requestItem.section',
      'files',
      'flagRule.suggestedRiskGuidance',
      'response.request.target.organization',
      'verifications',
      'scrapedAnswer.citations',
    ],
  }

  const rawExportPayload = {
    ...basePayload,
    include: [
      ...basePayload.include,
      'response.request.target',
      'response.request.subscriptions.target.categoryOptions',
      'response.verifications',
      'response.request.subscriptions.target.country.name',
    ],
  }

  const payload = {
    ...basePayload,
    filter: [...basePayload.filter, ...userFilters, ...insertIf(onlyLatest, RESPONSE_ITEM_LATEST_SUBMITTED_FILTER)],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const {
    items: pageResponseItems,
    count,
    isLoading,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<ResponseItem>({
    key: FetchKey.Answer,
    endpoint: endpoints.responseItemsCollection,
    payload,
  })

  const {
    facets: [nonScrapedItems],
  } = useFetchFacets({
    key: FetchKey.ResponseItemsFacets,
    endpoint: endpoints.responseItemsWithFacets,
    options: { enabled: true },
    facetsParam: [{ name: 'id' }],
    filter: [
      ...basePayload.filter,
      ...userFilters,
      RESPONSE_ITEM_NOT_SCRAPED_FILTER,
      ...insertIf(onlyLatest, RESPONSE_ITEM_LATEST_SUBMITTED_FILTER),
    ],
  })

  const {
    selectedRowsIds,
    isLoadingAllRows,
    isAllSelected,
    handleSelectAll,
    handleCheckboxChange,
    isHeaderChecked,
    handleHeaderCheckboxChange,
  } = useSelectableRowsWithFacetsSelectAll<ResponseItem>({
    rowsInPage: pageResponseItems,
    fetchKey: FetchKey.ResponseItemsFacets,
    endpoint: endpoints.responseItemsWithFacets,
    facetsParam: 'id',
    filters: payload.filter,
  })

  const { matchingProviderCount, fetchProviders, providers, isLoadingProviderData } = useSelectedProviderData({
    selectedRowsIds: selectedRowsIds,
  })

  const {
    fetchExternalVerificationData,
    isLoadingExternalVerificationData,
    fetchInternalVerificationData,
    isLoadingInternalVerificationData,
  } = useSelectedAnswersData({
    filters: payload.filter,
    selectedRowsIds: selectedRowsIds,
  })

  // Only after we've fetched items we call exportTemplateCollection to determine the value for allowGuidanceReport
  const { data: exportTemplates } = useFetchCollectionWithPost<ExportTemplate>({
    key: FetchKey.ExportTemplate,
    endpoint: endpoints.exportTemplateCollection,
    payload: emptyPayload,
  })

  useEffect(() => {
    if (exportTemplates?.items.length) {
      setAllowGuidanceReport(true)
    }
  }, [exportTemplates])

  const categorizeSuppliers = async () => {
    await fetchProviders()
    openUpdateDrawer()
  }

  const nonScrapedItemIds = selectedRowsIds.filter(id => nonScrapedItems?.some(item => item.value === id))

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'schemas.responseItem.responseItems' })}
        actionButtons={[
          ...insertIf<ActionButton>(allowGuidanceReport, {
            label: formatMessage({ id: 'resourceCollections.general.guidanceReport' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewGuidanceReport
                  resourceType={ResourceTypes.ResponseItem}
                  count={count}
                  rawExportPayload={rawExportPayload}
                  userFilter={userFilters}
                  exportColumns={responseItemSchema}
                  onlyLatest={onlyLatest}
                />,
              ),
            disabled: isLoading || count === 0,
          }),
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Answer}
                  count={count}
                  userFilter={userFilters}
                  exportColumns={responseItemSchema}
                  rawExportPayload={rawExportPayload}
                  onlyLatest={onlyLatest}
                />,
              ),
            disabled: isLoading || count === 0,
          },
        ]}
        buttonRow={
          selectedRowsIds.length
            ? [
                {
                  label: formatMessage({ id: 'dataHub.verification.review' }, { count: nonScrapedItemIds.length }),
                  onClick: async () => {
                    await fetchExternalVerificationData()
                    openDrawer(
                      <DrawerViewBulkResponseItemExternalVerification
                        itemIds={nonScrapedItemIds}
                        filters={payload.filter}
                      />,
                    )
                  },
                  variant: 'contained',
                  loading: isLoadingExternalVerificationData,
                  disabled: !nonScrapedItemIds.length || isLoading || isFetching || isLoadingAllRows,
                },
                {
                  label: formatMessage(
                    { id: 'dataHub.verification.internalReview' },
                    { count: nonScrapedItemIds.length },
                  ),
                  onClick: async () => {
                    await fetchInternalVerificationData()
                    openDrawer(
                      <DrawerViewBulkResponseItemInternalVerification
                        itemIds={nonScrapedItemIds}
                        filters={payload.filter}
                      />,
                    )
                  },
                  variant: 'outlined',
                  loading: isLoadingInternalVerificationData,
                  disabled: !nonScrapedItemIds.length || isLoading || isFetching || isLoadingAllRows,
                },
                {
                  label: formatMessage(
                    { id: `dataHub.categorizeProviders.${providerType.toLowerCase()}` },
                    { count: matchingProviderCount },
                  ),
                  onClick: categorizeSuppliers,
                  variant: 'outlined',
                  loading: isLoadingProviderData,
                  disabled: !selectedRowsIds.length || isLoading || isFetching || isLoadingAllRows,
                },
                ...insertIf<ActionButton>(count > pageSize, {
                  label: formatMessage({ id: 'resourceCollections.general.selectAllAnswers' }, { count }),
                  variant: 'text',
                  onClick: handleSelectAll,

                  disabled: isLoading || isFetching || isAllSelected(),
                  loading: isLoadingAllRows,
                }),
              ]
            : undefined
        }
        tabs={tabs}
        filter={
          <DataHubFiltersAllAnswers
            implicitFilters={relatedContentFilter}
            allowedFilters={allowedFilters}
            onlyLatest={onlyLatest}
            setOnlyLatest={setOnlyLatest}
          />
        }
        embedded={embedded}
      >
        <Table<ResponseItem>
          RowComponent={({ row }) =>
            showCompanyCell ? (
              <ResponseItemCompanyRow
                row={row}
                onCheckboxChange={handleCheckboxChange}
                selectedRowsIds={selectedRowsIds}
              />
            ) : (
              <ResponseItemRow row={row} showAnswerClassificationResults />
            )
          }
          HeaderComponent={() =>
            showCompanyCell ? (
              <ResponseItemCompanyHeader
                isHeaderChecked={isHeaderChecked}
                handleHeaderCheckboxChange={handleHeaderCheckboxChange}
                toggleSorting={toggleSorting}
                activeSorting={sorting}
              />
            ) : (
              <ResponseItemHeader toggleSorting={toggleSorting} activeSorting={sorting} />
            )
          }
          data={pageResponseItems}
          isLoading={isLoading || isFetching}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          stickyColumnIndex={1}
        />
      </ResourceCollectionScene>
      <UpdateProvidersDrawer
        selectedProviders={providers.map(provider => ({
          providerId: provider.id,
          name: provider.name,
          activityStatus: provider.activityStatus,
          finalRiskRating: provider.finalRiskRating,
          priority: provider.priority,
          providerApprovalStatus: provider.providerApprovalStatus,
          categoryOptionIds: provider.categoryOptions?.map(catOpt => catOpt.id),
          ownerUserId: provider.ownerUserId,
          tier: provider.tier,
          supplierUsage: provider.supplierUsage,
        }))}
        isDrawerOpen={isUpdateDrawerOpen}
        closeDrawer={closeUpdateDrawer}
      />
    </>
  )
}

export default DataHubAnswersTable
