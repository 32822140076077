import { getAnswerBasedOnQuestionType, QuestionItemAnswer } from '@app/src/components/Table/Cells/AnswerWithFlagCell'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { getSxByRank } from '@app/src/pages/QuestionEditor/AnswerClassification/ClassificationRow'
import { QuestionAnswerRank, ResponseItem } from '@app/src/types/resourceExplorer'
import { AutoAwesomeOutlined, Check, Close, Remove, ReportGmailerrorred } from '@mui/icons-material'
import { alpha, AvatarGroup, Stack, SvgIcon, Tooltip, useTheme } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../../Drawer/DrawerContext'
import DrawerViewAiScreenedAnswerDetail from '../../Drawer/Views/DrawerViewAiScreenedAnswerDetail'
import ParentCellOrFragment from './ParentCellOrFragment'

type AnswerWithClassificationCellProps = { item: ResponseItem }

const rankIconTypes = {
  [QuestionAnswerRank.NotSet]: Remove,
  [QuestionAnswerRank.Neutral]: Remove,
  [QuestionAnswerRank.Positive]: Check,
  [QuestionAnswerRank.Negative]: Close,
  [QuestionAnswerRank.NegativeAdverse]: ReportGmailerrorred,
}

export const RANK_TYPE_ORDER = [
  QuestionAnswerRank.Positive,
  QuestionAnswerRank.NegativeAdverse,
  QuestionAnswerRank.Negative,
  QuestionAnswerRank.Neutral,
  QuestionAnswerRank.NotSet,
]

const AnswerWithClassificationCell: React.FC<AnswerWithClassificationCellProps> = ({ item }) => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const answer = getAnswerBasedOnQuestionType(item.requestItem.questionTypeId, item)
  const ranks = item.questionAnswerClassificationResults?.map(q => q.rank) ?? []

  const tooltipText = useMemo(
    () =>
      ranks
        ?.sort((a, b) => RANK_TYPE_ORDER.indexOf(a) - RANK_TYPE_ORDER.indexOf(b))
        .map(x =>
          formatMessage({
            id: `templateBuilder.answerClassifications.classificationsRanks.${x}`,
          }),
        )
        .join(', '),
    [ranks],
  )

  return (
    <ParentCellOrFragment>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={({ palette }) => ({
          height: '100%',
          width: '100%',
          py: 1,
          px: 2,
          borderRadius: 1,
          cursor: item?.isScraped === true ? 'pointer' : 'default',
          '&:hover': item?.isScraped === true ? { backgroundColor: palette.communication.light } : {},
        })}
        onClick={() => {
          if (item?.isScraped === true) {
            openDrawer(<DrawerViewAiScreenedAnswerDetail item={item} answer={answer?.toString() ?? ''} />, {
              showAboveDialogs: true,
            })
          }
        }}
      >
        {Boolean(ranks?.length) && (
          <Tooltip arrow title={tooltipText}>
            <AvatarGroup
              max={4}
              sx={{
                marginLeft: '-2px',
                '& .MuiAvatar-root': {
                  marginLeft: '-16px',
                },
              }}
            >
              {ranks?.map(rank => (
                <Avatar key={rank} size={AvatarSize.Small} sx={{ bgcolor: getSxByRank(palette, rank).bgcolor }}>
                  <SvgIcon
                    fontSize="small"
                    component={rankIconTypes[rank]}
                    sx={{
                      color:
                        rank === QuestionAnswerRank.Neutral || rank === QuestionAnswerRank.Negative
                          ? alpha(palette.common.black, 0.6)
                          : palette.common.white,
                    }}
                  />
                </Avatar>
              ))}
            </AvatarGroup>
          </Tooltip>
        )}
        {item?.isScraped === true && <AutoAwesomeOutlined sx={{ color: palette.communication.dark }} />}
        <QuestionItemAnswer item={item} maxRows={2} responderName={item.creatorOrganization?.name} />
      </Stack>
    </ParentCellOrFragment>
  )
}

export default AnswerWithClassificationCell
