import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import LinkButton from '@app/src/components/LinkButton'
import { useRiskSettings } from '@app/src/context/RiskSettingsContext'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import RiskScreeningAllRisksFilters from '@app/src/pages/ResourceCollection/Filters/RiskScreeningAllRisksFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { SortOrder } from '@app/src/types/filter'
import { RiskTableView } from '@app/src/types/organizations'
import paths from '@app/src/wf-constants/paths'
import { TuneOutlined } from '@mui/icons-material'
import { Box, Chip, MenuItem, TextField as MuiTextField, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import ExposedCountryRiskMap from './ExposedCountryRiskMap'

type RiskScreeningMapProps = {
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
}

const allowedFilters: string[] = [
  'id',
  'name',
  'country.id',
  'createdAt',
  'ownerUserId',
  'websiteAddress',
  'vatNumber',
  'registrationNumber',
  'linkStatus',
  'categoryOptions.id',
  'activityStatus',
  'finalRiskRating',
  'priority',
  'providerApprovalStatus',
  'supplierUsage',
  'tier',
  'organization.id',
  'mappingNodes.actorType.id',
  'mappingNodes.tier',
  'riskStatus',
  'spends.periodName',
  'spends.spendClassification',
  'assessments.periodName',
  'assessments.totalLevel',
  'assessments.intervalType',
  'assessments.section1Level',
  'assessments.section2Level',
  'assessments.section3Level',
  'assessments.section4Level',
  'assessments.section5Level',
  'assessments.assessmentTemplate.name',
  'assessments.assessmentTemplate.AssessmentType',
]

const RiskScreeningMap: React.FC<RiskScreeningMapProps> = ({ actionButtons, tabs }) => {
  const { formatMessage } = useIntl()
  const filters = useGetApiQueryFilters(allowedFilters)
  const { activeRiskSettings, isLoadingRiskSettings } = useRiskSettings()
  const mapWrapperRef = useRef<HTMLDivElement>()

  const [activeRiskId, setActiveRiskId] = useState<number>()
  const [mapHeight, setMapHeight] = useState(500)

  const riskLevelFilter = filters.find(filter => filter.name === 'riskStatus')?.filters[0]?.value

  const { items: riskScreeningItems, isLoading } = useFetchCollectionWithPost<RiskTableView>({
    key: FetchKey.RiskScreening,
    endpoint: endpoints.riskScreeningAllRisks,
    payload: {
      filter: filters,
      include: [
        'country.risks.riskType',
        'organization.organizationsCountriesExposure',
        'organization.organizationsCountriesExposure.country',
      ],
      sort: {
        order: SortOrder.ASCENDING,
        target: 'name',
      },
      pagination: {
        pageNumber: 1,
        itemsPerPage: 9999,
      },
    },
    options: {
      enabled: Boolean(activeRiskSettings.length && activeRiskId),
    },
  })

  const filteredScreeningItems = useMemo(
    () => riskScreeningItems.filter(item => item.risks?.find(risk => risk.riskTypeId === activeRiskId)),
    [riskScreeningItems, activeRiskId],
  )
  const activeRisk = useMemo(
    () => activeRiskSettings?.find(risk => risk.id === activeRiskId),
    [activeRiskSettings, activeRiskId],
  )

  useEffect(() => {
    if (activeRiskSettings?.[0]) {
      setActiveRiskId(activeRiskSettings[0].id)
    }
  }, [activeRiskSettings])

  useEffect(() => {
    setMapHeight(mapWrapperRef.current?.clientHeight ?? 500)
  }, [mapWrapperRef])

  return (
    <ResourceCollectionScene
      title={formatMessage({ id: 'navbar.riskScreening' })}
      actionButtons={actionButtons}
      tabs={tabs}
      filter={
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            {activeRiskId && (
              <>
                <Typography variant="overline" color="textSecondary" mr={1}>
                  {formatMessage({ id: 'general.countryRisk' })}
                </Typography>
                <MuiTextField
                  select
                  defaultValue={activeRiskId}
                  size="small"
                  variant="standard"
                  onChange={e => setActiveRiskId(Number(e.target.value))}
                  value={activeRiskId}
                >
                  {activeRiskSettings?.map(risk => (
                    <MenuItem key={risk.source} value={risk.id}>
                      <Chip
                        key={risk.id}
                        label={formatMessage({ id: `schemas.risk.riskIndexes.${risk.source}.label` })}
                        size="small"
                        color="default"
                      />
                    </MenuItem>
                  ))}
                </MuiTextField>
              </>
            )}
          </Box>
          <RiskScreeningAllRisksFilters allowedFilters={allowedFilters} />
        </Box>
      }
    >
      <Box flexGrow={1} ref={mapWrapperRef} mb={1}>
        {isLoading || isLoadingRiskSettings ? (
          <Skeleton variant="rounded" height={mapHeight} />
        ) : !activeRiskId ? (
          <EmptyState
            iconComponent={TuneOutlined}
            title={formatMessage({ id: 'dashboard.sourcing.countryRiskMap.emptyState.title' })}
            description={formatMessage({ id: 'dashboard.sourcing.countryRiskMap.emptyState.description' })}
          >
            <LinkButton
              variant="outlined"
              to={generatePath(paths.configurations, { configurationsPage: CONFIGURATION_PAGE_IDS.RiskScreening })}
            >
              {formatMessage({ id: 'dashboard.sourcing.countryRiskMap.emptyState.action' })}
            </LinkButton>
          </EmptyState>
        ) : (
          <ExposedCountryRiskMap
            activeRisk={activeRisk}
            riskScreening={filteredScreeningItems}
            selectedRiskId={activeRiskId}
            riskLevelFilter={[riskLevelFilter ?? []].flat().map(filter => filter.toString())}
            height={mapHeight}
          />
        )}
      </Box>
    </ResourceCollectionScene>
  )
}

export default RiskScreeningMap
