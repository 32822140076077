import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { Provider } from '@app/src/types/organizations'
import { CheckboxProps, TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface AssessmentHeaderProps extends SortableHeaderProps {
  selectedProviders: Array<number>
  setSelectedProviders: React.Dispatch<React.SetStateAction<Array<number>>>
  providersInPage: Provider[]
}

const AssessmentHeader: React.FC<AssessmentHeaderProps> = ({
  toggleSorting,
  activeSorting,
  selectedProviders,
  setSelectedProviders,
  providersInPage,
}) => {
  const { formatMessage } = useIntl()

  const isChecked = providersInPage?.every(provider => selectedProviders?.includes(provider.id))

  const handleCheckboxChange: CheckboxProps['onChange'] = (_e, checked) => {
    if (checked) {
      setSelectedProviders(prev => Array.from(new Set([...prev, ...providersInPage.map(provider => provider.id)])))
    } else {
      setSelectedProviders([])
    }
  }

  return (
    <TableRow>
      <TableHeaderCell
        label=""
        checkbox={{ checked: isChecked, onChange: handleCheckboxChange, color: 'primary' }}
        minWidth={TableCellWidth.EMPTY}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'baseAssessment.companyName' })}
        sorting={{ name: 'name', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'baseAssessment.baselineAssessment' })}
        sorting={{ name: 'totalLevel', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'baseAssessment.section1' })}
        sorting={{ name: 'section1Level', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'baseAssessment.section2' })}
        sorting={{ name: 'section2Level', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'baseAssessment.section3' })}
        sorting={{ name: 'section3Level', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'baseAssessment.section4' })}
        sorting={{ name: 'section4Level', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'baseAssessment.section5' })}
        sorting={{ name: 'section5Level', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.provider.priority' })}
        sorting={{ name: 'priority', toggleSorting, activeSorting }}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.request.source' })} />
    </TableRow>
  )
}

export default AssessmentHeader
