import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { FinalRiskRatingIcon } from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import UserCell from '@app/src/components/Table/Cells/UserCell'
import FinalRiskRatingUpdateDrawer from '@app/src/pages/ResourceCollection/Collections/Provider/FinalRiskRatingUpdateDrawer'
import { ProviderStandardCategoryNames, StandardCategory } from '@app/src/types/categories'
import { User } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { getProviderCategoryOptions } from '@app/src/utils/providerCategoryOptions'
import { Box, List, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ProviderUpdateType, SetProvidersUpdate } from './CategoryDrawerContent'
import StandardCategoryListItem from './StandardCategoryListItem'

type Props = {
  category: StandardCategory
  providersUpdate?: ProviderUpdateType[]
  setProvidersUpdate?: SetProvidersUpdate
  setIsUnsavedChanges: Dispatch<SetStateAction<boolean>>
  closeDrawer?: () => void
}

const StandardCategoryDrawerContent = ({
  category,
  providersUpdate,
  setProvidersUpdate,
  setIsUnsavedChanges,
  closeDrawer,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl()
  const { data: colleagues } = useFetchCollectionWithPost<User>({
    key: FetchKey.Colleague,
    endpoint: endpoints.colleagues,
    payload: {
      pagination: {
        itemsPerPage: 9999,
        pageNumber: 1,
      },
      include: [],
      filter: [],
    },
    options: {
      enabled: category.name === ProviderStandardCategoryNames.OwnerUserId,
    },
  })
  const users = colleagues?.items ?? []

  const options = useMemo((): Array<string | number> => {
    if (category.name === ProviderStandardCategoryNames.OwnerUserId)
      return [...insertIf(Boolean(users.length), 0), ...users.map(user => user.id)]

    return getProviderCategoryOptions(category.name)
  }, [category.name, users])

  const renderStylingForItemPrimaryText = (): ((text: string, value: string | number) => JSX.Element) | undefined => {
    switch (category.name) {
      case ProviderStandardCategoryNames.FinalRiskRating:
        return (text: string, value: string | number): JSX.Element => (
          <Box display="flex" alignItems="center">
            <>
              <FinalRiskRatingIcon value={value as string} />
              {text}
            </>
          </Box>
        )
      case ProviderStandardCategoryNames.OwnerUserId:
        return (text: string, value: string | number): JSX.Element => {
          const user = users.find(colleague => colleague.id === value)

          return value === 0 ? (
            <Box display="flex" alignItems="center">
              <Typography>{formatMessage({ id: `schemas.provider.ownerUserNotSelected` })}</Typography>
            </Box>
          ) : (
            <UserCell user={user} disableCell />
          )
        }
      default:
        return undefined
    }
  }

  //Workaround to be able to render another component in this generic things.
  //TODO: This drawer setup needs some cleanup and simplifying
  if (category.name === ProviderStandardCategoryNames.FinalRiskRating) {
    return (
      <FinalRiskRatingUpdateDrawer
        closeDrawer={() => closeDrawer?.()}
        providers={providersUpdate}
        customHeader={<></>}
      />
    )
  }

  return (
    <List>
      {options?.map(option => (
        <StandardCategoryListItem
          setIsUnsavedChanges={setIsUnsavedChanges}
          setProvidersUpdate={setProvidersUpdate}
          providersUpdate={providersUpdate}
          key={option}
          option={option}
          category={category}
          renderPrimaryText={renderStylingForItemPrimaryText()}
        />
      ))}
    </List>
  )
}

export default StandardCategoryDrawerContent
