import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCount } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import type { NavbarItemProps } from '@app/src/components/Navbar/NavbarItems/index'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { insertIf } from '@app/src/utils/helpersTs'
import navbarLinks from '@app/src/wf-constants/navbarLinks'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import MenuWrapper, { MenuWrapperProps } from './MenuWrapper'

const RequestMenu: React.FC<NavbarItemProps> = ({ closeMenuDrawer }) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const isTransparency = hasPermission(Permissions.TRANSPARENCY_USER)
  const { aiScraping } = useFlags()

  const { count: screenedRequestsCount } = useFetchCount({
    key: FetchKey.Response,
    endpoint: endpoints.responsesCount,
    payload: [{ name: 'isScraped', filters: [{ operator: Operators.EqualTo, value: true }] }],
    shouldShowErrorNotification: false,
  })

  const showScreenedRequests =
    (isTransparency && screenedRequestsCount && screenedRequestsCount > 0) || (!isTransparency && aiScraping)

  const menuItems: MenuWrapperProps['menuItems'] = [
    ...insertIf(showScreenedRequests, {
      title: formatMessage({ id: 'navbar.screenedRequests' }),
      link: navbarLinks.screenedResponses,
      permission: [
        Permissions.TRANSPARENCY_USER,
        Permissions.SOURCING_USER,
        Permissions.INVESTMENT_USER,
        Permissions.FINANCE_USER,
      ],
    }),
    {
      title: formatMessage({ id: isTransparency ? 'navbar.receivedRequests' : 'navbar.sentRequests' }),
      link: navbarLinks.requests,
      permission: [
        Permissions.TRANSPARENCY_USER,
        Permissions.SOURCING_USER,
        Permissions.INVESTMENT_USER,
        Permissions.FINANCE_USER,
      ],
    },
    {
      title: formatMessage({ id: 'navbar.responses' }),
      link: navbarLinks.responses,
      permission: [
        Permissions.TRANSPARENCY_USER,
        Permissions.SOURCING_USER,
        Permissions.INVESTMENT_USER,
        Permissions.FINANCE_USER,
      ],
    },
  ]

  if (!isTransparency) {
    menuItems.unshift({
      title: formatMessage({ id: 'navbar.manageRequestOverview' }),
      link: navbarLinks.manageRequestOverview,
      permission: [
        Permissions.TRANSPARENCY_USER,
        Permissions.SOURCING_USER,
        Permissions.INVESTMENT_USER,
        Permissions.FINANCE_USER,
      ],
    })
  }

  return (
    <MenuWrapper
      closeMenuDrawer={closeMenuDrawer}
      menuItems={menuItems}
      title={formatMessage({ id: 'navbar.manageRequests' })}
    />
  )
}

export default RequestMenu
