import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ProviderLinkStatus } from '@app/src/types/organizations'
import React from 'react'
import { ResourceCollectionSceneProps } from '../../ResourceCollectionScene'
import BaseProviderScene from './BaseProviderScene'
import { ProviderTabs } from './ProvidersScene'

type NotConnectedProvidersSceneProps = {
  relatedContentFilter?: FilterGroup[]
  allowedFilters: string[]
} & Omit<ResourceCollectionSceneProps, 'title'>

const NotConnectedProvidersScene: React.FC<NotConnectedProvidersSceneProps> = ({
  relatedContentFilter = [],
  allowedFilters,
  ...props
}) => {
  const implicitFilters = [
    {
      name: 'linkStatus',
      filters: [
        {
          operator: Operators.In,
          value: [ProviderLinkStatus.Created, ProviderLinkStatus.Invited],
        },
      ],
    },
    ...relatedContentFilter,
  ]

  return (
    <BaseProviderScene
      implicitFilters={implicitFilters}
      allowedFilters={allowedFilters}
      resourceView={ProviderTabs.NotConnected}
      {...props}
    />
  )
}
export default NotConnectedProvidersScene
