import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import TruncatedText from '@app/src/components/Ui/TruncatedText'
import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import ChartCardMenu from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/Charts/ChartCardMenu'
import { Category } from '@app/src/types/categories'
import { Box, ButtonBase, Paper, Stack, Typography } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { AnswersGroupingType } from '../../../Filters/StatisticsConstants'
import { StatisticsForQuestionPerGroup } from '../../DataHubScene'

interface DataHubInsightsCardProps {
  children: React.ReactNode
  statistics: StatisticsForQuestionPerGroup
  eChartsRef: React.MutableRefObject<ReactEChartsCore | null>
  onClickTitle: () => void
  chartSelector: React.ReactNode
  periodNames: string[]
}

const DataHubCard: React.FC<DataHubInsightsCardProps> = ({
  children,
  statistics,
  eChartsRef,
  onClickTitle,
  chartSelector,
  periodNames,
}) => {
  const { formatMessage } = useIntl()
  const { groupBy, groupByValue } = useGroupBySelector()

  const { data: categories } = useFetchResource<Category[]>({
    key: FetchKey.Category,
    endpoint: endpoints.category,
    options: { staleTime: 60000, enabled: groupBy === AnswersGroupingType.CustomCategory },
  })

  const getGroupByLabel = useMemo(() => {
    switch (groupBy) {
      case AnswersGroupingType.PeriodName:
        return formatMessage({ id: 'reporting.period' }).toLowerCase()
      case AnswersGroupingType.SpendClassification:
        return formatMessage({ id: 'dataHub.groupBy.spend' }).toLowerCase()
      case AnswersGroupingType.Country:
        return formatMessage({ id: 'resourceCollections.general.country' }).toLowerCase()
      case AnswersGroupingType.CountryRisk:
        return formatMessage({ id: 'general.countryRisk' }).toLowerCase()
      case AnswersGroupingType.StandardCategory:
        return groupByValue
          ? formatMessage({ id: `schemas.provider.${groupByValue}` }).toLowerCase()
          : formatMessage({ id: 'schemas.supplier.standardCategories' }).toLowerCase()
      case AnswersGroupingType.CustomCategory:
        return categories?.find(category => category.id === Number(groupByValue))?.name || ''
      case AnswersGroupingType.ActorType:
        return groupByValue
          ? formatMessage(
              { id: 'statistics.dataInsights.actorTypes.industryActorTypes' },
              { industry: formatMessage({ id: `schemas.product.productIndustry.${groupByValue}` }) },
            ).toLowerCase()
          : ''
      default:
        return groupBy
    }
  }, [categories, formatMessage, groupBy, groupByValue])

  return (
    <Paper elevation={0} sx={{ height: '100%', padding: 3, outline: 0, borderRadius: 1 }}>
      <Stack justifyContent="space-between" height="100%">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="start" pr={2}>
            <Box>
              <Box component={ButtonBase} onClick={onClickTitle} textAlign="left">
                <TruncatedText text={statistics.title} line={1} variant="subtitle1" />
              </Box>

              <Typography color="text.secondary">
                {formatMessage(
                  { id: 'statistics.dataInsights.cardSubtitle' },
                  { groupBy: getGroupByLabel, period: periodNames.join(', ') },
                )}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="start" gap={1}>
            {chartSelector}
            <ChartCardMenu echartsRef={eChartsRef} title={statistics.title} />
          </Box>
        </Box>
        {children}
      </Stack>
    </Paper>
  )
}

export default DataHubCard
