import Permissions, { usePermissions } from '@app/src/auth/permissions'
import StatusChip, { StatusChipProps } from '@app/src/components/StatusChip'
import { AllStatuses } from '@app/src/types/resourceExplorer'
import { TableCellProps } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import ParentCellOrFragment from './ParentCellOrFragment'

type StatusCellProps = {
  value?: AllStatuses
  size?: StatusChipProps['size']
  verification?: boolean
  disableCell?: boolean
  width?: TableCellProps['width']
}

const getColor = (value: StatusCellProps['value'], isTransparency?: boolean): StatusChipProps['color'] => {
  switch (value) {
    case 'NotApproved':
      return 'error'

    case 'CorrectionNeeded':
    case 'ResponseCorrectionNeeded':
      return 'warning'

    case 'Approved':
    case 'ResponseApproved':
      return 'success'

    case 'Sent':
    case 'Requested':
      return isTransparency ? 'info' : 'default'

    case 'Submitted':
    case 'ResponseSubmitted':
    case 'NotSet':
      return isTransparency ? 'default' : 'info'

    case 'ResponseScraped':
    case 'Scraped':
      return 'communication'

    default:
      return 'default'
  }
}

const StatusCell: React.FC<StatusCellProps> = ({ value, size, verification, disableCell, width = 'auto' }) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()

  const isTransparency = hasPermission(Permissions.TRANSPARENCY_USER)
  const val = !value || value === 'NotSet' ? (verification ? 'ToBeVerified' : 'NotSet') : value

  if (isTransparency) {
    return (
      <ParentCellOrFragment disableCell={disableCell} width={width}>
        <StatusChip
          label={formatMessage({
            id: `reporting.statuses.transparency.${val}`,
          })}
          color={getColor(value, true)}
          size={size}
        />
      </ParentCellOrFragment>
    )
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <StatusChip
        label={formatMessage({
          id: `reporting.statuses.${val}`,
        })}
        color={getColor(value)}
        size={size}
      />
    </ParentCellOrFragment>
  )
}

export default StatusCell
