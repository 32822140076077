export enum IntercomSolutionKeys {
  EsgReportingSolution = 'Has ESG Reporting Solution',
  InvestmentsSolution = 'Has Investments Solution',
  SourcingSolution = 'Has Sourcing Solution',
  SourcingStartSolution = 'Has Sourcing Start Solution',
  TransparencySolution = 'Has Transparency Solution',
}

export type IntercomProp = {
  app_id: string
  user_id?: number | string
  name?: string
  email?: string
  created_at?: string | number
  company?: {
    company_id: number
    name: string
  }
  message?: string
  // Properties formatted like this to be more readable in Intercom
  [IntercomSolutionKeys.EsgReportingSolution]: boolean
  [IntercomSolutionKeys.InvestmentsSolution]: boolean
  [IntercomSolutionKeys.SourcingSolution]: boolean
  [IntercomSolutionKeys.SourcingStartSolution]: boolean
  [IntercomSolutionKeys.TransparencySolution]: boolean
}

declare global {
  interface Window {
    WF_CONFIG: { [key: string]: string | undefined }
    Intercom: (fn: string, prop?: number | string | IntercomProp) => void
  }
}

const envSettings = (window as Window).WF_CONFIG

const getApiUrl = () => {
  if (envSettings?.FRONT_ENV !== 'prod' && envSettings?.FRONT_ENV !== 'beta') {
    const localStorageUrl = localStorage.getItem('dev:api_url')
    if (localStorageUrl) {
      console.info('%cUsing API url from local storage:\n' + localStorageUrl, 'color: blue; font-size: 18px')
      return localStorageUrl
    }
  }
  return envSettings?.API_URL
}

export class Config {
  static API_URL = getApiUrl()
  static FRONT_ENV = envSettings?.FRONT_ENV
  static AUTH0_DOMAIN = envSettings?.AUTH0_DOMAIN
  static AUTH0_CLIENT_ID = envSettings?.AUTH0_CLIENT_ID
  static AUTH0_AUDIENCE = envSettings?.AUTH0_AUDIENCE
  static INTERCOM_APP_ID = envSettings?.INTERCOM_APP_ID
  static INTERCOM_ENABLED = envSettings?.INTERCOM_ENABLED
  static APPLICATION_INSIGHTS = envSettings?.APPLICATION_INSIGHTS
  static STORAGE_ACCOUNT_URL = envSettings?.STORAGE_ACCOUNT_URL
  static LAUNCH_DARKLY_CLIENT_SIDE_ID = envSettings?.LAUNCH_DARKLY_CLIENT_SIDE_ID
  static AMPLITUDE_API_KEY = envSettings?.AMPLITUDE_API_KEY
}
