import ScrapedAnswerCitations from '@app/src/pages/Questionnaire/Accessor/Report/ScrapedAnswerCitations'
import { palette } from '@app/src/theme/palette'
import { AnswerStatus, ResponseItem } from '@app/src/types/resourceExplorer'
import { ArrowForward, AutoAwesomeOutlined } from '@mui/icons-material'
import { Box, Chip, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import RenderFileDownload from '../../RenderFileDownload'
import { useDrawer } from '../DrawerContext'
import DrawerView, { DrawerViewProps } from '../DrawerView'
import AiSkippedQuestionInfo from './AiSkippedQuestionInfo'

type DrawerViewAiScreenedAnswerDetailProps = {
  item: ResponseItem
  answer: string
} & Omit<DrawerViewProps, 'title' | 'subtitle'>

const DrawerViewAiScreenedAnswerDetail: React.FC<DrawerViewAiScreenedAnswerDetailProps> = ({
  item,
  answer,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()

  const renderCitations = () => {
    if (item?.isAnswered && item?.scrapedAnswer?.citations?.length) {
      return <ScrapedAnswerCitations citations={item.scrapedAnswer.citations} />
    }

    if (answer?.trim()) {
      return null
    }
    return <AiSkippedQuestionInfo />
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.responseItem.answerDetail' })}
      subTitle={item.response?.request?.targetAliasObject?.name}
      buttons={[
        {
          label: formatMessage({ id: 'general.close' }),
          onClick: closeDrawer,
        },
      ]}
      {...props}
    >
      <Stack sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle1" sx={{ pl: 3 }}>
          {item.requestItem.description}
        </Typography>

        <Stack my={2} px={3} py={2} bgcolor="communication.light">
          <Stack direction="row">
            <AutoAwesomeOutlined sx={{ color: palette.communication.dark }} />
            {answer?.trim() ? (
              <Typography px={2}>{answer}</Typography>
            ) : item.answerStatus === AnswerStatus.Answered ? (
              <RenderFileDownload
                fileName={item.uploadReferences?.[0]?.originalFileName}
                id={item.uploadReferences?.[0]?.id ?? 0}
                expiresAt={item.expiresAt}
              />
            ) : (
              <Grid px={2} item container spacing={1} alignItems="flex-end">
                <Grid item>
                  <Chip label={formatMessage({ id: 'reporting.skipQuestion.skippedThisQuestion' })} />
                </Grid>
                <Grid item>
                  <Box display="flex" mb={0.5}>
                    <ArrowForward color="inherit" />
                  </Box>
                </Grid>
                <Grid item>
                  <Chip label={formatMessage({ id: `reporting.answerStatus.${item.answerStatus}` })} />
                </Grid>
              </Grid>
            )}
          </Stack>

          {renderCitations()}
        </Stack>
      </Stack>

      <Stack sx={{ mt: 'auto', mb: 2, ml: 3 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <AutoAwesomeOutlined />
          <Typography variant="subtitle2" color="text.primary">
            {formatMessage({ id: 'reporting.AiSkippedQuestionInfo.title' })}
          </Typography>
        </Stack>
        <Typography variant="body2" color="text.primary">
          {formatMessage({ id: 'reporting.AiSkippedQuestionInfo.description' })}
        </Typography>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewAiScreenedAnswerDetail
