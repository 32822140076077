import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import OnboardingForm from '@app/src/pages/SolutionSelector/OnboardingForm'
import { SortOrder } from '@app/src/types/filter'
import { CompanyRanges, NaceCode } from '@app/src/types/organizations'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { ArrowBack, Check } from '@mui/icons-material'
import { Alert, Button, Divider, FormControl, FormHelperText, FormLabel, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ClaimFormFields } from './ClaimDialog'

interface orgInfoStepProps {
  onNextStep: () => void
  onPreviousStep: () => void
}

type NaceCodeLevelOption = {
  industryId: number
  industryName: string
  subIndustryId: number
}

const OrgInfoStep: React.FC<orgInfoStepProps> = ({ onPreviousStep, onNextStep }) => {
  const { formatMessage } = useIntl()
  const { control, handleSubmit, errors } = useFormContext()
  const { trackEvent } = useAmplitude()
  const { accessibleOrganizations } = useAccessibleOrganizations()

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Onboarding.AddOrganization.OpenedIndustryDetails,
      eventProps: {
        is_first_org: !accessibleOrganizations.length,
      },
    })
  }, [])

  const { items } = useFetchCollectionWithPost<NaceCode>({
    key: FetchKey.NaceCodes,
    endpoint: endpoints.naceCodes,
    payload: {
      filter: [
        {
          name: 'level',
          filters: [
            {
              operator: Operators.In,
              value: [1, 2],
            },
          ],
        },
      ],
      sort: { target: 'description', order: SortOrder.ASCENDING },
      include: [],
    },
  })

  const naceCodeLevel1 = items.filter(naceCode => naceCode.level === 1)
  const naceCodeLevel2 = items.filter(naceCode => naceCode.level === 2)

  const naceCodeOptions = naceCodeLevel2
    .map(naceCode => {
      const parentNaceCode = naceCodeLevel1.find(parentNaceCode => parentNaceCode.id === naceCode.parentId)

      return {
        label: naceCode.description,
        value: {
          industryId: naceCode.parentId,
          industryName: parentNaceCode?.description ?? '',
          subIndustryId: naceCode.id,
        },
      }
    })
    .sort((a, b) => -b.value.industryName.localeCompare(a.value.industryName))

  return (
    <OnboardingForm
      preHeader={formatMessage({ id: 'claim.createClaim.orgInfoStep.preHeader' })}
      header={formatMessage({ id: 'claim.createClaim.orgInfoStep.header' })}
    >
      <Select<NaceCodeLevelOption>
        hoveringLabel
        name={ClaimFormFields.Industry}
        control={control}
        required
        rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
        groupBy={option => option.value.industryName ?? ''}
        error={errors?.industry?.message}
        findSelectedValue={(value, option) =>
          value?.industryId === option.value?.industryId && value?.subIndustryId === option.value?.subIndustryId
        }
        options={naceCodeOptions}
        fieldLabel={formatMessage({ id: 'claim.createClaim.orgInfoStep.industry' })}
      />
      <FormControl required>
        <FormLabel sx={{ mb: 2, mt: 2 }}>{formatMessage({ id: 'claim.companySize.label' })}</FormLabel>
        <Controller
          name={ClaimFormFields.CompanySizeRange}
          control={control}
          rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
          render={({ value, onChange }): JSX.Element => (
            <Stack direction="row" flexWrap="wrap">
              {Object.values(CompanyRanges).map(range => (
                <Button
                  key={range}
                  startIcon={value === range && <Check />}
                  sx={{ mr: 2, mb: 2 }}
                  variant={value === range ? 'contained' : 'outlined'}
                  onClick={() => onChange(range)}
                >
                  {formatMessage({ id: `claim.companySize.${range}` })}
                </Button>
              ))}
            </Stack>
          )}
        />
        <FormHelperText error={Boolean(errors?.companySizeRange)}>{errors?.companySizeRange?.message}</FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel sx={{ mb: 2, mt: 2 }}>{formatMessage({ id: 'claim.companyRevenue.label' })}</FormLabel>
        <Controller
          name={ClaimFormFields.RevenueRange}
          control={control}
          render={({ value, onChange }): JSX.Element => (
            <Stack direction="row" flexWrap="wrap">
              {Object.values(CompanyRanges).map(range => (
                <Button
                  key={range}
                  startIcon={value === range && <Check />}
                  sx={{ mr: 2, mb: 2 }}
                  variant={value === range ? 'contained' : 'outlined'}
                  onClick={() => onChange(value === range ? undefined : range)}
                >
                  {formatMessage({ id: `claim.companyRevenue.${range}` })}
                </Button>
              ))}
            </Stack>
          )}
        />
      </FormControl>
      <Stack pt={2} direction="row" justifyContent="space-between">
        <Button startIcon={<ArrowBack />} onClick={onPreviousStep}>
          {formatMessage({ id: 'general.back' })}
        </Button>
        <Button type="submit" variant="contained" onClick={handleSubmit(() => onNextStep())}>
          {formatMessage({ id: 'general.continue' })}
        </Button>
      </Stack>
      <Stack spacing={2} flexGrow={1} justifyContent="flex-end">
        <Divider />
        <Alert severity="info" sx={{ backgroundColor: 'common.white' }}>
          {formatMessage({ id: 'claim.createClaim.orgInfoStep.infoMessage' })}
        </Alert>
      </Stack>
    </OnboardingForm>
  )
}

export default OrgInfoStep
