import { usePeriodName } from '@app/src/hooks/usePeriodName'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { palette } from '@app/src/theme/palette'
import { getMinMaxOnYAxisCountCharts } from '@app/src/utils/statisticsUtils'
import { darken, useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { BarDataItemOption } from 'echarts/types/src/chart/bar/BarSeries'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { StatisticsSummaryPerGroup } from '../DataHubSummaryChart'

interface PeriodSummaryChartConfigsParams {
  resultsForGroup: StatisticsSummaryPerGroup[]
  selectedChartType: ChartType
}

export enum SummaryChartDataTypes {
  CompaniesAnswered = 'companiesAnswered',
  CompaniesAnsweredDontHaveThis = 'companiesAnsweredDontHaveThis',
  CompaniesNotAnswered = 'companiesNotAnswered',
}

const getMinMaxYAxis = (optionItemResult: StatisticsSummaryPerGroup[]) => {
  const summaryValues = optionItemResult.flatMap(
    res => res.companiesAnswered + res.companiesAnsweredDontHaveThis + res.companiesNotAnswered,
  )
  return getMinMaxOnYAxisCountCharts(summaryValues)
}

const REPORTED_COLOR = palette.visualization['5']
const DONT_HAVE_THIS_COLOR = palette.warning.dark
const NOT_ANSWERED_ITEM_COLOR = palette.visualization['11']

export const usePeriodSummaryChartConfigs = ({
  resultsForGroup,
  selectedChartType,
}: PeriodSummaryChartConfigsParams) => {
  const { typography } = useTheme()
  const { formatMessage } = useIntl()
  const { formatPeriodName } = usePeriodName()

  const isHorizontalBarChart = selectedChartType === ChartType.Horizontal

  const isSingleGroup = resultsForGroup.length === 1

  if (isHorizontalBarChart) {
    resultsForGroup = resultsForGroup.reverse()
  }

  const companiesAnsweredData: BarDataItemOption[] = resultsForGroup.map(r => ({
    name: formatPeriodName(r.groupName),
    value: r.companiesAnswered,
    groupName: r.groupName,
    summaryType: SummaryChartDataTypes.CompaniesAnswered,
    emphasis: { itemStyle: { color: darken(REPORTED_COLOR, 0.2) } },
    label: isHorizontalBarChart
      ? {
          show: true,
          position: [0, -14],
          align: 'left',
          formatter: '{b}',
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        }
      : undefined,
  }))

  const companiesAnsweredDontHaveThis: BarDataItemOption[] = resultsForGroup.map(r => ({
    name: formatPeriodName(r.groupName),
    value: r.companiesAnsweredDontHaveThis,
    groupName: r.groupName,
    summaryType: SummaryChartDataTypes.CompaniesAnsweredDontHaveThis,

    emphasis: { itemStyle: { color: darken(DONT_HAVE_THIS_COLOR, 0.2) } },
  }))

  const companiesNotAnswered: BarDataItemOption[] = resultsForGroup.map(r => ({
    name: formatPeriodName(r.groupName),
    value: r.companiesNotAnswered,
    groupName: r.groupName,
    summaryType: SummaryChartDataTypes.CompaniesNotAnswered,
  }))

  const existingOptions = useMemo(() => resultsForGroup.map(f => f.groupName), [resultsForGroup])

  const { min, max } = getMinMaxYAxis(resultsForGroup)

  const valueAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'value',
      name: formatMessage({ id: 'statistics.dataInsights.count' }),
      nameLocation: 'middle',
      nameGap: 35,
      min: min,
      max: max,
      minInterval: 1,
      nameTextStyle: {
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [formatMessage, typography.body2.fontSize, typography.fontFamily, min, max],
  )

  const categoryAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'category',
      data: existingOptions,
      axisLabel: {
        interval: 0,
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
        formatter: (params: string) => {
          return formatPeriodName(params)
        },
      },
    }),
    [existingOptions, typography.body2.fontSize, typography.fontFamily],
  )

  const horizontalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = valueAxis as XAXisOption
    const yAxis = { ...categoryAxis, show: false } as YAXisOption

    return {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        containLabel: false,
        left: 20,
        right: 7,
        top: 30,
        bottom: 90,
      },
      xAxis,
      yAxis,
      legend: {
        icon: 'circle',
        bottom: 0,
        left: 0,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      series: [
        {
          name: formatMessage(
            { id: 'statistics.dataInsights.reportedCount' },
            { count: String(companiesAnsweredData[0]?.value ?? 0), isSinglePeriod: isSingleGroup },
          ),
          type: 'bar',
          barCategoryGap: '50%',
          data: companiesAnsweredData,
          color: REPORTED_COLOR,
        },
        {
          name: formatMessage(
            { id: 'statistics.dataInsights.dontHaveThisCount' },
            { count: String(companiesAnsweredDontHaveThis[0]?.value ?? 0), isSinglePeriod: isSingleGroup },
          ),
          type: 'bar',
          data: companiesAnsweredDontHaveThis,
          color: DONT_HAVE_THIS_COLOR,
        },
        {
          name: formatMessage(
            { id: 'statistics.dataInsights.noResponseCount' },
            { count: String(companiesNotAnswered[0]?.value ?? 0), isSinglePeriod: isSingleGroup },
          ),
          type: 'bar',
          data: companiesNotAnswered,
          color: NOT_ANSWERED_ITEM_COLOR,
        },
      ],
    }
  }, [
    valueAxis,
    categoryAxis,
    typography.fontFamily,
    typography.body2.fontSize,
    formatMessage,
    companiesAnsweredData,
    isSingleGroup,
    companiesNotAnswered,
    companiesAnsweredDontHaveThis,
  ])

  const verticalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = categoryAxis as XAXisOption
    const yAxis = valueAxis as YAXisOption

    return {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        containLabel: true,
        left: 40,
        right: 7,
        top: 30,
        bottom: 50,
      },
      xAxis,
      yAxis,
      legend: {
        icon: 'circle',
        bottom: 0,
        left: 0,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      series: [
        {
          name: formatMessage(
            { id: 'statistics.dataInsights.reportedCount' },
            { count: String(companiesAnsweredData[0]?.value ?? 0), isSinglePeriod: isSingleGroup },
          ),
          type: 'bar',
          data: companiesAnsweredData,
          color: REPORTED_COLOR,
        },
        {
          name: formatMessage(
            { id: 'statistics.dataInsights.dontHaveThisCount' },
            { count: String(companiesAnsweredDontHaveThis[0]?.value ?? 0), isSinglePeriod: isSingleGroup },
          ),
          type: 'bar',
          data: companiesAnsweredDontHaveThis,
          color: DONT_HAVE_THIS_COLOR,
        },
        {
          name: formatMessage(
            { id: 'statistics.dataInsights.noResponseCount' },
            { count: String(companiesNotAnswered[0]?.value ?? 0), isSinglePeriod: isSingleGroup },
          ),
          type: 'bar',
          data: companiesNotAnswered,
          color: NOT_ANSWERED_ITEM_COLOR,
        },
      ],
    }
  }, [
    categoryAxis,
    valueAxis,
    typography.fontFamily,
    typography.body2.fontSize,
    formatMessage,
    companiesAnsweredData,
    isSingleGroup,
    companiesAnsweredDontHaveThis,
    companiesNotAnswered,
  ])

  return isHorizontalBarChart ? horizontalBarChartOptions : verticalBarChartOptions
}
