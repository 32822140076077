import { palette } from '@app/src/theme/palette'
import { OrganizationSettings, SpendIntervalType } from '@app/src/wf-constants'
import { AutoAwesomeOutlined, Sync, SyncDisabled } from '@mui/icons-material'
import {
  Box,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Skeleton,
  Switch,
  Typography,
} from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useIntl } from 'react-intl'

interface OrganizationSettingsHook {
  set: (
    key: keyof OrganizationSettings,
    value: number | boolean | SpendIntervalType,
    customSuccessMessage?: string,
  ) => Promise<void>
  get: <T = number | boolean | SpendIntervalType>(key: keyof OrganizationSettings) => T
  setMultiple: (settings: Partial<OrganizationSettings>, customSuccessMessage?: string) => Promise<void>
  getAll: () => OrganizationSettings
  isSaving: boolean
  updateError: boolean
  isLoading: boolean
  isFetching: boolean
  isFetched: boolean
}

interface AiScreeningConfigProps {
  settings: OrganizationSettingsHook
}

const AVATAR_STYLING = {
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 1,
}

const AiScreeningConfig: React.FC<AiScreeningConfigProps> = ({ settings }) => {
  const { formatMessage } = useIntl()
  const confirm = useConfirm()

  const handleAiScreeningEnableSwitch = (checked: boolean) => {
    if (checked) {
      confirm({
        content: formatMessage({ id: 'automations.aiScreening.confirmationDialog.text' }),
        confirmationText: formatMessage({
          id: 'automations.aiScreening.confirmationDialog.button',
        }),
      }).then(() => settings.set('aiScreeningEnabled', true))
      return
    }
    settings.set('aiScreeningEnabled', false)
  }

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        {formatMessage({ id: 'automations.aiScreening.heading' })}
      </Typography>
      <Paper
        sx={({ palette }) => ({
          boxShadow: 'none',
          border: `1px solid ${palette.grey[300]}`,
          mb: 4,
        })}
      >
        <List>
          <ListItem
            secondaryAction={
              <FormControlLabel
                disabled={settings.isLoading || settings.isSaving || settings.isFetching}
                control={
                  <Switch
                    checked={settings.get<boolean>('aiScreeningEnabled')}
                    onChange={(_, checked) => handleAiScreeningEnableSwitch(checked)}
                  />
                }
                label={
                  settings.isLoading || settings.isSaving || settings.isFetching ? (
                    <Skeleton width={20} variant="rounded" />
                  ) : settings.get('aiScreeningEnabled') ? (
                    formatMessage({ id: 'automations.on' })
                  ) : (
                    formatMessage({ id: 'automations.off' })
                  )
                }
              />
            }
          >
            <ListItemAvatar>
              {settings.get('aiScreeningEnabled') ? (
                <Box
                  sx={{
                    backgroundColor: palette.communication.main,
                    color: palette.communication.dark,
                    ...AVATAR_STYLING,
                  }}
                >
                  <AutoAwesomeOutlined>
                    <Sync />
                  </AutoAwesomeOutlined>
                </Box>
              ) : (
                <Box sx={{ backgroundColor: 'info.light', color: 'text.disabled', ...AVATAR_STYLING }}>
                  <AutoAwesomeOutlined>
                    <SyncDisabled />
                  </AutoAwesomeOutlined>
                </Box>
              )}
            </ListItemAvatar>
            <ListItemText disableTypography>
              <Typography variant="subtitle1">{formatMessage({ id: 'automations.aiScreening.heading' })}</Typography>
              <Typography variant="body1">{formatMessage({ id: 'automations.aiScreening.description' })}</Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Paper>
    </Box>
  )
}

export default AiScreeningConfig
