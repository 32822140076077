import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import { comparePeriods } from '@app/src/utils/getOrderedPeriods'
import { spendClassificationOrder } from '@app/src/wf-constants'
import { Stack } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import Filter from './Filter'
import FilterFacetSelect from './FilterFacetSelect'
import FilterSection from './FilterSection'
import { Operators } from './useFilters'

const periodNamesSortFunction = (a: FacetItem, b: FacetItem) => {
  return comparePeriods(a.label, b.label)
}

const spendClassificationSortFunction = (a: FacetItem, b: FacetItem) => {
  return spendClassificationOrder.indexOf(a.label) - spendClassificationOrder.indexOf(b.label)
}

interface SpendFilterProps {
  spendFilterNamePrefix?: string
  defaultCollapsed?: boolean
}

const SpendFilter = ({ spendFilterNamePrefix, defaultCollapsed }: SpendFilterProps) => {
  const { formatMessage } = useIntl()
  const { providerSpend } = useFlags()

  const spendPeriodFilterName = `${spendFilterNamePrefix ? `${spendFilterNamePrefix}.` : ''}spends.periodName`
  const spendIntervalFilterName = `${spendFilterNamePrefix ? `${spendFilterNamePrefix}.` : ''}spends.spendClassification`

  if (!providerSpend) return null

  return (
    <FilterSection
      label={formatMessage({ id: 'schemas.supplier.annualSpend.spend' })}
      defaultCollapsed={defaultCollapsed}
    >
      <Stack spacing={2}>
        <Filter operator={Operators.In} name={spendPeriodFilterName}>
          {({ value, onChange, filterName }) => (
            <FilterFacetSelect
              size="small"
              multiple
              facetsParam={{
                key: [FetchKey.ProviderFacets, filterName],
                endpoint: endpoints.providersWithFacets,
                facetsParam: [{ name: 'spends.periodName', isEnum: true }],
              }}
              filterName={filterName}
              disableAutoFilter
              onChange={value => onChange(value)}
              fieldLabel={formatMessage({ id: 'schemas.filter.spendPeriod' })}
              value={[value].flat()}
              customSort={periodNamesSortFunction}
            />
          )}
        </Filter>
        <Filter operator={Operators.In} name={spendIntervalFilterName}>
          {({ value, onChange, filterName }) => (
            <FilterFacetSelect
              size="small"
              multiple
              facetsParam={{
                key: [FetchKey.ProviderFacets, filterName],
                endpoint: endpoints.providersWithFacets,
                facetsParam: [{ name: 'spends.spendClassification', isEnum: true }],
              }}
              filterName={filterName}
              disableAutoFilter
              onChange={value => onChange(value)}
              fieldLabel={formatMessage({ id: 'schemas.filter.spendInterval' })}
              value={[value].flat()}
              customSort={spendClassificationSortFunction}
            />
          )}
        </Filter>
      </Stack>
    </FilterSection>
  )
}

export default SpendFilter
