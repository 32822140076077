import { FilterGroup } from './useFilters'

export const ITEM_TEMPLATE_ID_AND_PERIOD = 'itemTemplateIdAndPeriod'
export const REPORT_TEMPLATE_ID_AND_PERIOD = 'templateIdAndPeriod'
export const RESPONSE_REQUEST_PERIOD_NAME = 'response.request.periodName'
export const REQUEST_ITEM_TEMPLATE = 'requestItem.template'
export const STATISTICS_SPECIAL_FILTERS = [ITEM_TEMPLATE_ID_AND_PERIOD, REPORT_TEMPLATE_ID_AND_PERIOD]
const REQUEST_ITEM_QUESTION_ID = 'requestItem.questionId'
const REQUEST_SUBSCRIPTION_TARGET_ORGANIZATION_ID = 'response.request.subscriptions.target.organizationId'
export const GROUP_BY = 'groupBy'

export enum AnswersGroupingType {
  PeriodName = 'periodName',
  SpendClassification = 'spendClassification',
  CustomCategory = 'customCategory',
  StandardCategory = 'standardCategory',
  ActorType = 'actorType',
  Country = 'country',
  AnswerClassification = 'answerClassification',
  CountryRisk = 'countryRisk',
}

export const getTemplateIdAndPeriodFromFilter = (
  filters: FilterGroup[],
  fromFilter: typeof ITEM_TEMPLATE_ID_AND_PERIOD | typeof REPORT_TEMPLATE_ID_AND_PERIOD,
) => {
  const templateIdAndPeriodFilter = filters.find(filter => filter.name === fromFilter)
  const templateIdAndPeriod = [templateIdAndPeriodFilter?.filters?.[0]?.value].flat()?.[0]
  const [templateId, periodName] = templateIdAndPeriod?.toString()?.split(':') ?? [undefined, undefined]

  return { templateId, periodName }
}

export const getProviderIdsFromFilter = (filters: FilterGroup[]) => {
  const providers = filters.find(filter => filter.name === REQUEST_SUBSCRIPTION_TARGET_ORGANIZATION_ID)
  return providers?.filters[0]?.value
}

export const filtersWithoutTemplateIdAndPeriod = (filters: FilterGroup[]) =>
  filters.filter(filter => filter.name !== REPORT_TEMPLATE_ID_AND_PERIOD)

export const removeQuestionFilters = (filters: FilterGroup[]) =>
  filters.filter(filter => filter.name !== REQUEST_ITEM_QUESTION_ID && !filter.name.startsWith(REQUEST_ITEM_TEMPLATE))

export const transformFilterNamesForInquiries = (filters: FilterGroup[]) => {
  return filters.map(filter => {
    let { name } = filter

    if (name.startsWith('response.request.')) {
      name = name.replace('response.request.', 'request.')
    }

    if (name.startsWith('response.submittedAt')) {
      name = name.replace('response.submittedAt', 'request.responses.submittedAt')
    }

    if (name.startsWith('response.verifications')) {
      name = name.replace('response.verifications', 'request.responses.verifications')
    }

    if (name === 'request.questionnaireTemplateId') {
      name = 'template.id'
    }

    if (name === 'request.periodName') {
      name = 'periodName'
    }

    if (name === 'requestItem.questionId') {
      name = 'template.sections.questions.id'
    }

    if (name === 'requestItem.template.options.id') {
      name = 'template.sections.questions.options.id'
    }

    return { ...filter, name }
  })
}

export const transformProviderAssessmentFilterNames = (filters: FilterGroup[]) => {
  return filters.map(filter => {
    let { name } = filter

    if (name.startsWith('assessmentTemplate.')) {
      name = name.replace('assessmentTemplate.', 'assessments.assessmentTemplate.')
    }

    if (name.startsWith('provider.')) {
      name = name.replace('provider.', '')
    }

    if (name.startsWith('periodName')) {
      name = name.replace('periodName', 'assessments.periodName')
    }

    if (name.startsWith('totalLevel')) {
      name = name.replace('totalLevel', 'assessments.totalLevel')
    }

    if (name.startsWith('percentage')) {
      name = name.replace('percentage', 'assessments.percentage')
    }

    return { ...filter, name }
  })
}
