import { useEchartsContainerWidth } from '@app/src/hooks/useEchartsContainerWidth'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { getMinMaxOnYAxisCountCharts } from '@app/src/utils/statisticsUtils'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { OptionResultItemMultiPeriod } from '../../../../DataHubScene'
import { getLegendSpacing } from '../chartsUtils'
import { colors, NOT_SELECTED_COLOR, NOT_SELECTED_GROUP_NAME } from './categoryChartConfigsUtils'

interface OptionMultiPeriodChartConfigsParams {
  optionItemResult: { groupName: string; groupId?: string; options: OptionResultItemMultiPeriod[] }[]
  selectedChartType: ChartType
  itemsInOther: string[]
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
  isFullWidth?: boolean
}

const APPROXIMATE_WIDTH_OF_YAXIS = 60

const getAxisLabelWidth = (widthOfChart: number | undefined, existingOptions: string[]) => {
  if (!widthOfChart) return undefined
  return (widthOfChart - APPROXIMATE_WIDTH_OF_YAXIS) / existingOptions.length
}

const getMinMaxYAxis = (optionItemResult: { options: OptionResultItemMultiPeriod[] }[]) => {
  const optionValues = optionItemResult.flatMap(op => op.options.map(op => op.count))
  return getMinMaxOnYAxisCountCharts(optionValues)
}

export const useOptionCategoryChartConfigs = ({
  optionItemResult,
  selectedChartType,
  itemsInOther,
  echartsRef,
  isFullWidth,
}: OptionMultiPeriodChartConfigsParams) => {
  const { formatMessage } = useIntl()
  const { typography } = useTheme()
  const widthOfChart = useEchartsContainerWidth(echartsRef)
  const isHorizontalBarChart = selectedChartType === ChartType.Horizontal

  const itemsForChartType = useMemo(
    () =>
      selectedChartType === ChartType.Horizontal
        ? optionItemResult.map(r => ({ ...r, options: r.options.toReversed() }))
        : optionItemResult,
    [selectedChartType, optionItemResult],
  )

  const existingOptions = itemsForChartType?.[0]?.options.map(op => op.name)

  const { min, max } = useMemo(() => getMinMaxYAxis(optionItemResult), [optionItemResult])

  const categoryAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'category',
      data: existingOptions,
      triggerEvent: true,
      axisLabel: {
        interval: 0,
        width: getAxisLabelWidth(widthOfChart, existingOptions),
        overflow: 'break',
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [existingOptions, typography.body2.fontSize, typography.fontFamily],
  )

  const valueAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'value',
      name: formatMessage({ id: 'statistics.dataInsights.count' }),
      nameLocation: 'middle',
      nameGap: 35,
      min: min,
      max: max,
      minInterval: 1,
      nameTextStyle: {
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [formatMessage, typography.body2.fontSize, typography.fontFamily, min, max],
  )

  const isFullWidthCard = (width: number | undefined) => {
    return isFullWidth && width && width > 1000
  }

  const sortedItemsForChartType = useMemo(() => {
    return itemsForChartType.sort((a, b) => {
      if (a.groupName === NOT_SELECTED_GROUP_NAME) return 1
      if (b.groupName === NOT_SELECTED_GROUP_NAME) return -1
      return 0
    })
  }, [itemsForChartType])

  const horizontalBarChartOptions: EChartsOption = useMemo(() => {
    const LABEL_WIDTH = 296
    const isFullWidth = isFullWidthCard(widthOfChart)

    const dataSeries: EChartsOption['series'] = sortedItemsForChartType.map((f, seriesIndex) => ({
      name: f.groupName,
      color: f.groupName === NOT_SELECTED_GROUP_NAME ? NOT_SELECTED_COLOR : colors[seriesIndex],
      type: 'bar',
      stack: 'chart',
      barCategoryGap: '50%',
      data: f.options.map(op => ({
        value: op.count,
        name: op.name,
        label:
          seriesIndex === 0
            ? {
                show: true,
                position: isFullWidth ? 'insideLeft' : [0, -14],
                distance: isFullWidth ? -5 : 0,
                align: isFullWidth ? 'right' : 'left',
                formatter: '{b}',
                fontFamily: typography.fontFamily,
                fontSize: typography.body2.fontSize,
                overflow: 'truncate',
                width: isFullWidth ? LABEL_WIDTH : undefined,
                verticalAlign: isFullWidth ? 'middle' : undefined,
              }
            : undefined,
        groupName: f.groupName,
        groupId: f.groupId,
        answersSelected: op.isOther ? itemsInOther : [op.name],
      })),
    }))

    const xAxis = valueAxis as XAXisOption
    const yAxis = { ...categoryAxis, show: false } as YAXisOption

    return {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        containLabel: false,
        left: isFullWidth ? LABEL_WIDTH : 10,
        right: 7,
        top: 30,
        bottom: getLegendSpacing(
          60,
          widthOfChart,
          sortedItemsForChartType.map(f => f.groupName),
        ),
      },
      xAxis,
      yAxis,
      legend: {
        icon: 'circle',
        bottom: 0,
        left: 0,
        itemWidth: 10,
        width: '100%',
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
          overflow: 'truncate',
        },
      },
      series: dataSeries,
    }
  }, [categoryAxis, sortedItemsForChartType, typography.body2.fontSize, typography.fontFamily, valueAxis, widthOfChart])

  const verticalBarChartOptions: EChartsOption = useMemo(() => {
    const dataSeries: EChartsOption['series'] = sortedItemsForChartType.map((f, seriesIndex) => ({
      name: f.groupName,
      color: f.groupName === NOT_SELECTED_GROUP_NAME ? NOT_SELECTED_COLOR : colors[seriesIndex],
      type: 'bar',
      stack: 'chart',
      barCategoryGap: '35%',
      data: f?.options.map(op => ({
        value: op.count,
        name: op.name,
        groupName: f.groupName,
        groupId: f.groupId,
        answersSelected: op.isOther ? itemsInOther : [op.name],
        label: {
          position: 'top',
        },
      })),
    }))

    const xAxis = {
      ...categoryAxis,
      axisLabel: {
        ...categoryAxis.axisLabel,
        overflow: 'truncate',
        width: 100,
        align: 'center',
      },
    } as XAXisOption
    const yAxis = valueAxis as YAXisOption

    return {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        containLabel: true,
        left: 35,
        right: 7,
        top: 30,
        bottom: getLegendSpacing(
          40,
          widthOfChart,
          sortedItemsForChartType.map(f => f.groupName),
        ),
      },
      xAxis,
      yAxis,
      legend: {
        icon: 'circle',
        bottom: 0,
        left: 0,
        itemWidth: 10,
        width: '100%',
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
          overflow: 'truncate',
        },
      },
      series: dataSeries,
    }
  }, [categoryAxis, sortedItemsForChartType, typography.body2.fontSize, typography.fontFamily, valueAxis, widthOfChart])

  return isHorizontalBarChart ? horizontalBarChartOptions : verticalBarChartOptions
}
