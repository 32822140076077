import { ProviderStandardCategoryNames, StandardCategoryOptions } from '@app/src/types/categories'

export const getProviderCategoryOptions = (categoryName: string): Array<string | number> => {
  switch (categoryName) {
    case ProviderStandardCategoryNames.ActivityStatus:
      return StandardCategoryOptions.activityStatus
    case ProviderStandardCategoryNames.FinalRiskRating:
      return StandardCategoryOptions.finalRiskRating
    case ProviderStandardCategoryNames.Priority:
      return StandardCategoryOptions.priority
    case ProviderStandardCategoryNames.ApprovalStatus:
      return StandardCategoryOptions.providerApprovalStatus
    case ProviderStandardCategoryNames.SupplierUsage:
      return StandardCategoryOptions.supplierUsage
    default:
      return []
  }
}

export const MAPPING_NODE_TIER_OPTIONS = Array.from(Array(11).keys())
