import ImageWithContentDialog from '@app/src/components/Dialog/ImageWithContentDialog'
import Avatar from '@app/src/components/Ui/Avatar'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { ReuseAllQuestionsStates } from '@app/src/pages/Questionnaire/ReportContextProvider'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { CheckOutlined, DoneAllOutlined, NotInterestedOutlined } from '@mui/icons-material'
import {
  Alert,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

enum ReuseAction {
  NotSelected = 'Not Selected',
  ApplyManually = 'ApplyManually',
  ApplyAll = 'ApplyAll',
  DontDisplay = 'DontDisplay',
}

interface ReuseOptionItemProps {
  radioButtonAction: ReuseAction
  currentlySelectedAction: ReuseAction
  primaryText: string
  secondaryText: string
  icon: React.ReactNode
  setReuseAction: (action: ReuseAction) => void
}

const ReuseOptionItem: FC<ReuseOptionItemProps> = ({
  radioButtonAction,
  currentlySelectedAction,
  primaryText,
  secondaryText,
  icon,
  setReuseAction,
}) => (
  <ListItem
    sx={{
      pl: 0,
      cursor: 'pointer',
      ...(radioButtonAction === currentlySelectedAction ? { backgroundColor: 'grey.100' } : {}),
    }}
    alignItems="flex-start"
    secondaryAction={<Radio value={radioButtonAction} checked={currentlySelectedAction === radioButtonAction} />}
    onClick={() => setReuseAction(radioButtonAction)}
  >
    <ListItemAvatar>
      <Avatar color="primary" sx={{ bgcolor: 'grey.100', color: 'grey.900' }}>
        {icon}
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={primaryText}
      secondary={secondaryText}
      primaryTypographyProps={{ variant: 'subtitle1' }}
      secondaryTypographyProps={{ color: 'textPrimary', variant: 'body1' }}
      sx={{ mr: 2 }}
    />
  </ListItem>
)

interface ReuseAllPreviousAnswersDialogProps {
  open: boolean
  onClose: () => void
}

const ReuseAllPreviousAnswersDialog: FC<ReuseAllPreviousAnswersDialogProps> = ({ open, onClose }) => {
  const { previouslyRespondedQuestions, setReuseAllQuestionsState, setShowPreviousAnswerSuggestions } = useReport()
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()
  const [reuseAction, setReuseAction] = useState(ReuseAction.NotSelected)
  const [hasSelectedContinueWithoutAReuseAction, setHasSelectedContinueWithoutAReuseAction] = useState(false)

  const previouslyAnswerResponsesCount = previouslyRespondedQuestions?.filter(p => !p.isScraped)?.length

  const onContinueSelected = () => {
    if (reuseAction === ReuseAction.NotSelected) {
      setHasSelectedContinueWithoutAReuseAction(true)
      return
    }

    if (reuseAction === ReuseAction.DontDisplay) {
      trackEvent({
        name: AmplitudeTrackingEvents.Provider.Questionnaire.SuggestedAnswersModal,
        eventProps: {
          action: 'Skipped',
        },
      })
      setShowPreviousAnswerSuggestions(false)
    } else {
      const reuseAllQuestionState =
        reuseAction === ReuseAction.ApplyAll ? ReuseAllQuestionsStates.ApplyAll : ReuseAllQuestionsStates.Review
      setReuseAllQuestionsState(reuseAllQuestionState)
      trackEvent({
        name: AmplitudeTrackingEvents.Provider.Questionnaire.SuggestedAnswersModal,
        eventProps: {
          action: reuseAllQuestionState,
        },
      })
    }
    onClose()
  }

  useEffect(() => {
    if (hasSelectedContinueWithoutAReuseAction && reuseAction !== ReuseAction.NotSelected)
      setHasSelectedContinueWithoutAReuseAction(false)
  }, [reuseAction, hasSelectedContinueWithoutAReuseAction])

  useEffect(() => {
    if (!open) return
    trackEvent({
      name: AmplitudeTrackingEvents.Provider.Questionnaire.SuggestedAnswersModal,
      eventProps: {
        action: 'ModalViewed',
      },
    })
  }, [open])

  return (
    <ImageWithContentDialog imageUrl="/img/previous-answer.webp" open={open}>
      <Stack spacing={2}>
        {hasSelectedContinueWithoutAReuseAction && (
          <Alert severity="error">{formatMessage({ id: 'general.selectAnOptionToContinue' })}</Alert>
        )}
        <Typography variant="h3">
          {formatMessage(
            { id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.title' },
            { previouslyRespondedQuestionsCount: previouslyAnswerResponsesCount },
          )}
        </Typography>
        <Typography>
          {formatMessage(
            { id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.description' },
            { previouslyRespondedQuestionsCount: previouslyAnswerResponsesCount },
          )}
        </Typography>
        <RadioGroup value={reuseAction} onChange={(_, value) => setReuseAction(value as ReuseAction)}>
          <List>
            <ReuseOptionItem
              radioButtonAction={ReuseAction.ApplyManually}
              currentlySelectedAction={reuseAction}
              setReuseAction={setReuseAction}
              icon={<CheckOutlined />}
              primaryText={formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.reviewPrimary' })}
              secondaryText={formatMessage({
                id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.reviewSecondary',
              })}
            />
            <ReuseOptionItem
              radioButtonAction={ReuseAction.ApplyAll}
              currentlySelectedAction={reuseAction}
              setReuseAction={setReuseAction}
              icon={<DoneAllOutlined />}
              primaryText={formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.applyAllPrimary' })}
              secondaryText={formatMessage({
                id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.applyAllSecondary',
              })}
            />
            <ReuseOptionItem
              radioButtonAction={ReuseAction.DontDisplay}
              currentlySelectedAction={reuseAction}
              setReuseAction={setReuseAction}
              icon={<NotInterestedOutlined />}
              primaryText={formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.dontDisplayPrimary' })}
              secondaryText={formatMessage({
                id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.dontDisplaySecondary',
              })}
            />
          </List>
        </RadioGroup>
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="contained" onClick={onContinueSelected}>
            {formatMessage({ id: 'general.continue' })}
          </Button>
        </Stack>
      </Stack>
    </ImageWithContentDialog>
  )
}

export default ReuseAllPreviousAnswersDialog
