import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResourceWithPost } from '@app/src/api/fetchHooks'
import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { Provider } from '@app/src/types/organizations'
import { Box, Divider, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const ScrapedReporter = () => {
  const { request, response } = useReport()
  const { formatMessage } = useIntl()
  const { formatValueToDate } = useGetFormattedDate()
  const providerId = request?.subscriptions?.[0].responderProviderId

  const { data: provider } = useFetchResourceWithPost<Provider, string[]>({
    endpoint: endpoints.provider(providerId),
    body: [],
    key: [FetchKey.Provider, providerId],
    options: { enabled: Boolean(providerId) },
  })

  if (!provider) return null

  return (
    <Box pt={2}>
      <ListItem disablePadding>
        <ListItemAvatar sx={({ spacing }) => ({ minWidth: spacing(5) })}>
          <CompanyAvatar imageUrl={provider.image?.url} organizationName={provider.name} size={AvatarSize.Medium} />
        </ListItemAvatar>
        <ListItemText
          primary={provider.name}
          secondary={
            <Typography variant="caption">
              {formatMessage(
                { id: 'reporting.sharing.suggestedByAi' },
                {
                  date: formatValueToDate({ value: response?.createdAt ?? '' }),
                  sparkle: (chunks: React.ReactNode) => (
                    <Box component="span" color="communication.dark">
                      {chunks}
                    </Box>
                  ),
                },
              )}
            </Typography>
          }
        />
      </ListItem>

      <Divider variant="fullWidth" />
    </Box>
  )
}

export default ScrapedReporter
