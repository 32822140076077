import { ConnectProvidersFormData } from '@app/src/pages/ResourceCollection/Collections/Provider/ConnectProviders/hooks/inviteEmail'
import { Provider } from '@app/src/types/organizations'
import { CheckCircleOutline, ErrorOutlineOutlined } from '@mui/icons-material'
import { Stack } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { CONNECT_PROVIDERS_QUESTION_KEYS } from './ConnectProvidersModal'
import ExistingProvidersAccordion from './ExistingProvidersAccordion'
import ProvidersAccordion, { ProviderDisplayMode } from './ProvidersAccordion'

type ProvidersListProps = {
  providers: Provider[]
  selectedProviderIds: number[]
  setSelectedProviderIds: React.Dispatch<React.SetStateAction<number[]>>
}

const ProvidersList: React.FC<ProvidersListProps> = ({ providers, selectedProviderIds, setSelectedProviderIds }) => {
  const { formatMessage } = useIntl()
  const { watch, setValue } = useFormContext<ConnectProvidersFormData>()

  const companiesWithRefContact = providers.filter(provider => provider.referralContact)
  const companiesWithoutRefContact = providers.filter(provider => !provider.referralContact)

  const watchProviders = watch(CONNECT_PROVIDERS_QUESTION_KEYS.PROVIDER_IDS)

  const handleChangeUpdateState = (providerId: number) => {
    const toBeChecked = !selectedProviderIds.includes(providerId)

    if (toBeChecked) {
      setSelectedProviderIds(Array.from(new Set([...selectedProviderIds, providerId])))
    } else {
      setSelectedProviderIds(selectedProviderIds.filter(watchProviderId => watchProviderId !== providerId))
    }
  }

  const handleChangeSetFormValues = (providerId: number) => {
    handleChangeUpdateState(providerId)

    const toBeChecked = !watchProviders.includes(providerId)

    if (toBeChecked) {
      setValue(CONNECT_PROVIDERS_QUESTION_KEYS.PROVIDER_IDS, Array.from(new Set([...watchProviders, providerId])))
    } else {
      setValue(
        CONNECT_PROVIDERS_QUESTION_KEYS.PROVIDER_IDS,
        watchProviders.filter(watchProviderId => watchProviderId !== providerId),
      )
    }
  }

  const handleChange = (providerId: number) => {
    if (companiesWithRefContact.some(provider => provider.id === providerId)) {
      return handleChangeSetFormValues(providerId)
    }

    return handleChangeUpdateState(providerId)
  }

  return (
    <Stack spacing={3}>
      <ProvidersAccordion
        title={formatMessage(
          { id: 'form.connectProviders.review.canBeInvited' },
          { count: companiesWithRefContact.length },
        )}
        providers={companiesWithRefContact}
        selectedProviderIds={selectedProviderIds}
        onChange={handleChange}
        displayMode={ProviderDisplayMode.REFERRAL_CONTACT}
        data-testid="can-connect-accordion"
        icon={<CheckCircleOutline sx={{ color: 'brandDecorative.emerald' }} />}
      />

      {Boolean(companiesWithoutRefContact.length) && (
        <ProvidersAccordion
          title={formatMessage(
            { id: 'form.connectProviders.review.missingInformation' },
            { count: companiesWithoutRefContact.length },
          )}
          providers={companiesWithoutRefContact}
          selectedProviderIds={selectedProviderIds}
          onChange={handleChange}
          displayMode={ProviderDisplayMode.REFERRAL_CONTACT}
          icon={<ErrorOutlineOutlined sx={{ color: 'semantic.error' }} />}
          defaultExpanded
        />
      )}

      <ExistingProvidersAccordion
        providers={providers}
        selectedProviderIds={selectedProviderIds}
        onChange={handleChange}
      />
    </Stack>
  )
}

export default ProvidersList
