import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import LinkButton from '@app/src/components/LinkButton'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import { WF_SYSTEM_USER } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import StatisticsBar from '../StatisticsBar'
import StatisticsCard from '../StatisticsCard'

const ReportingProgressCard = () => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { stringifyQueryFilters } = useStringifyQueryFilters()

  const {
    facets: [inquiries],
    isLoading,
    isError,
  } = useFetchFacets({
    key: FetchKey.InquiryStatus,
    shouldShowErrorNotification: false,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'status' }],
    filter: [],
  })

  if (isLoading) {
    return (
      <Box height="100%" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  const requestedRequests = inquiries?.find(i => i.value === InquiryStatus.Requested)?.count ?? 0
  const correctionNeededRequests = inquiries?.find(i => i.value === InquiryStatus.CorrectionNeeded)?.count ?? 0
  const pendingRequests = requestedRequests + correctionNeededRequests

  const totalRequests = inquiries?.reduce((prev, curr) => prev + curr.count, 0) ?? 0

  return (
    <StatisticsCard
      title={formatMessage({ id: 'transparencyOverview.reportingProgress' })}
      justifyContent="left"
      withBorder
      isError={isError}
    >
      <Box width="100%" py={2}>
        <StatisticsBar
          value={totalRequests - pendingRequests}
          max={totalRequests}
          color={palette.secondary.main}
          onBarClick={() =>
            history.push(
              stringifyQueryFilters({
                url: generatePath(paths.manageRequest, {
                  view: ViewTypeName.Requests,
                }),
                queryParams: {
                  filters: [
                    {
                      name: 'request.creatorUserId',
                      value: WF_SYSTEM_USER,
                      operator: Operators.NotEqualTo,
                    },
                    {
                      name: 'status',
                      value: [InquiryStatus.Submitted, InquiryStatus.Approved],
                      operator: Operators.In,
                    },
                  ],
                },
                includeCurrentFilters: true,
              }),
            )
          }
          percent
          withCount
          showBackground
        />

        <Box display="flex" flexWrap="wrap">
          <LinkButton
            to={stringifyQueryFilters({
              url: generatePath(paths.manageRequest, {
                view: ViewTypeName.Requests,
              }),
              queryParams: {
                filters: [
                  {
                    name: 'request.creatorUserId',
                    value: WF_SYSTEM_USER,
                    operator: Operators.NotEqualTo,
                  },
                  {
                    name: 'status',
                    value: [InquiryStatus.Submitted, InquiryStatus.Approved],
                    operator: Operators.In,
                  },
                ],
              },
              includeCurrentFilters: true,
            })}
            startIcon={<Box height={16} width={16} bgcolor={palette.secondary.main} borderRadius="4px" mr={0.5} />}
          >
            <Typography variant="caption">{formatMessage({ id: 'dashboard.investment.responded' })}</Typography>
          </LinkButton>
          <LinkButton
            to={stringifyQueryFilters({
              url: generatePath(paths.manageRequest, {
                view: ViewTypeName.Requests,
              }),
              queryParams: {
                filters: [
                  {
                    name: 'status',
                    value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
                    operator: Operators.In,
                  },
                  {
                    name: 'creatorUserId',
                    value: WF_SYSTEM_USER,
                    operator: Operators.NotEqualTo,
                  },
                ],
              },
              includeCurrentFilters: true,
            })}
            startIcon={<Box height={16} width={16} bgcolor={palette.grey[200]} borderRadius="4px" mr={0.5} />}
          >
            <Typography variant="caption">{formatMessage({ id: 'dashboard.investment.requested' })}</Typography>
          </LinkButton>
        </Box>
      </Box>
    </StatisticsCard>
  )
}

export default ReportingProgressCard
