import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import useCurrentProviderTypeName from '@app/src/hooks/currentProviderTypeName'
import useAssessmentQuestionnaireLink from '@app/src/hooks/useAssessmentQuestionnaireLink'
import AssessmentInsightsQuestionCard from '@app/src/pages/Assessments/AssessmentInsight/AssessmentInsightsQuestionCard'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import Assessment from '@app/src/types/assessment'
import { AssessmentTemplate, Question } from '@app/src/types/resourceExplorer'
import { ChatOutlined } from '@mui/icons-material'
import { Grid } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type AssessmentInsightsChartsProps = {
  assessmentTemplate: AssessmentTemplate
  userFilters?: FilterGroup[]
  periodName?: string
  questions?: Question[]
}

const staleTime = 60000

const AssessmentInsightsCharts: React.FC<AssessmentInsightsChartsProps> = ({
  assessmentTemplate,
  userFilters,
  periodName,
  questions,
}) => {
  const { formatMessage } = useIntl()
  const providerTypeNamePlural = useCurrentProviderTypeName({ isPlural: true })

  const { items: assessments } = useFetchCollectionWithPost<Assessment>({
    key: FetchKey.AssessmentCollection,
    endpoint: endpoints.assessmentCollection,
    payload: {
      filter: [
        ...(userFilters ?? []),
        {
          name: 'assessmentTemplateId',
          filters: [{ operator: Operators.EqualTo, value: assessmentTemplate.id }],
        },
        {
          name: 'periodName',
          filters: [{ operator: Operators.EqualTo, value: periodName }],
        },
      ],
      include: [],
      pagination: {
        itemsPerPage: 9999,
        pageNumber: 1,
      },
    },
    options: { enabled: questions && !!periodName, staleTime: staleTime },
  })

  const { assessmentQuestionnaireLinks } = useAssessmentQuestionnaireLink({
    assessmentTemplateId: assessmentTemplate.id,
    includeAssessment: false,
  })

  const linkedQuestionnaireTemplateIds = assessmentQuestionnaireLinks.map(x => x.questionnaireTemplateId)
  const providerIds = assessments.map(assessment => assessment.providerId)

  if (!providerIds?.length) {
    return (
      <EmptyState
        iconComponent={ChatOutlined}
        title={formatMessage({ id: 'assessments.overviewGraph.noResponsesEmptyState.title' }, { periods: periodName })}
        description={formatMessage(
          { id: 'assessments.overviewGraph.noResponsesEmptyState.description' },
          { providerTypePlural: providerTypeNamePlural },
        )}
      />
    )
  }

  return (
    <Grid container spacing={2} sx={{ px: 4, pb: 4 }}>
      {periodName &&
        questions?.map(question => (
          <Grid key={question.id} item xs={12} md={6}>
            <AssessmentInsightsQuestionCard
              question={question}
              period={periodName}
              providerIds={providerIds}
              assessmentTemplate={assessmentTemplate}
              linkedQuestionnaireTemplateIds={linkedQuestionnaireTemplateIds}
            />
          </Grid>
        ))}
    </Grid>
  )
}

export default AssessmentInsightsCharts
