import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import DataInsights from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights'
import { ReportFlagRule } from '@app/src/types/flags'
import { Unit } from '@app/src/types/resourceExplorer'
import { Flows } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, Redirect, Route, Switch, useParams } from 'react-router'
import {
  AnswersGroupingType,
  ITEM_TEMPLATE_ID_AND_PERIOD,
  REPORT_TEMPLATE_ID_AND_PERIOD,
} from '../Filters/StatisticsConstants'
import { FilterGroup } from '../Filters/useFilters'
import DataHubAnswersTable from './DataHub/AllAnswers/DataHubAnswersTable'

export type DataHubSceneParams = {
  flow: string
  view: string
  viewType: ViewType
}

export interface OptionResultItemMultiPeriod {
  count: number
  name: string
  isOther: boolean
}

interface OptionStatisticsView {
  maxItemCount: number
  items: OptionResultItemMultiPeriod[]
  valuesInOther: string[]
}

export interface FileResult {
  expirationDateItems: FileExpirationDateItemResultModel[]
  totalCount: number
}

export interface FileExpirationDateItemResultModel {
  expirationDateInterval: string
  amount: number
  percentage: number
}

export interface NumberResult {
  min: number
  average: number
  max: number
  sum: number
}

export interface AnswerStat {
  number: number
  percentage: number
}

export interface QuestionMonitoring {
  companiesHaveAnswered: AnswerStat
  companiesHaveNotAnswered: AnswerStat
}

export interface ResultForGroup {
  groupName: string
  groupId?: string
  questionMonitoring: QuestionMonitoring
  unansweredQuestionCount?: number
  optionStatisticsViews?: OptionStatisticsView[]
  numberResult?: NumberResult
  fileResult?: FileResult
}

export interface StatisticsForQuestionPerGroup {
  id: number
  title: string
  questionType: string
  isMultiChoice: boolean
  unit: Unit
  resultForGroups: ResultForGroup[]
}

export interface DataInsightsQuestionsQuery {
  filter: FilterGroup[]
  groupBy: AnswersGroupingType
  groupByValue?: string
}

type DataHubSceneProps = {
  relatedContentFilter?: FilterGroup[]
  embedded?: boolean
}

export interface FlagResult {
  flaggedCount: number
  reportFlagRules?: ReportFlagRule[]
}

export interface OptionItemResult {
  count: number
  name: string
  percentage: number
  isOther?: boolean
}

export interface OptionsResult {
  items: OptionItemResult[]
  totalCount: number
}

export interface ResponseItemStatisticsByTemplateModel {
  id: number
  title: string
  questionType: string
  unit: Unit
  questionMonitoring: QuestionMonitoring
  optionsResult: OptionsResult
  numberResult: NumberResult | null
  fileResult: FileResult
  periodName: string
  flagResult: FlagResult
}

export enum ViewType {
  GRAPH = 'graph',
  TABLE = 'table',
}

export enum DataHubView {
  Answers = 'answers',
  DataInsights = 'data-insights',
}

export const tableViewAllowedFilters = [
  'requestItem.template.id',
  'response.request.periodName',
  'response.request.subscriptions.target.country.id',
  'response.request.subscriptions.target.id',
  'response.request.template.id',
  'requestItem.section.template.id',
  'requestItem.section.id',
  'requestItem.section.title',
  'response.submittedAt',
  'cannotAnswer',
  'answer',
  'response.request.questionnaireTemplateId',
  'response.request.subscriptions.target.providerApprovalStatus',
  'response.request.subscriptions.target.priority',
  'response.request.subscriptions.target.finalRiskRating',
  'response.request.subscriptions.target.activityStatus',
  'response.request.subscriptions.target.supplierUsage',
  'response.request.subscriptions.target.tier',
  'response.request.subscriptions.target.ownerUserId',
  'response.request.subscriptions.target.categoryOptions.id',
  ITEM_TEMPLATE_ID_AND_PERIOD,
  'response.request.template.tags.tag',
  REPORT_TEMPLATE_ID_AND_PERIOD,
  'verifications.externalVerificationStatus',
  'verifications.internalVerificationStatus',
  'response.request.subscriptions.target.mappingNodes.actorType.id',
  'response.request.subscriptions.target.mappingNodes.tier',
  'answerStatus',
  'requestItem.questionId',
  'requestItem.template.options.id',
  'response.request.subscriptions.target.organizationId',
  'response.request.subscriptions.target.providerId',
  'response.request.subscriptions.target.spends.spendClassification',
  'response.request.subscriptions.target.spends.periodName',
  'response.request.subscriptions.target.assessments.totalLevel',
  'response.request.subscriptions.target.assessments.intervalType',
  'response.request.subscriptions.target.assessments.section1Level',
  'response.request.subscriptions.target.assessments.section2Level',
  'response.request.subscriptions.target.assessments.section3Level',
  'response.request.subscriptions.target.assessments.section4Level',
  'response.request.subscriptions.target.assessments.section5Level',
  'response.request.subscriptions.target.assessments.assessmentTemplate.name',
  'response.request.subscriptions.targetProviderId',
  'isScraped',
]

const DataHubScene: React.FC<DataHubSceneProps> = ({ relatedContentFilter = [], embedded }) => {
  const { formatMessage } = useIntl()
  const { flow = Flows.Interaction, view } = useParams<DataHubSceneParams>()
  const userFilters = useGetApiQueryFilters(tableViewAllowedFilters)

  const actionTabs = [
    {
      type: DataHubView.DataInsights,
      url: generatePath(paths.dataHubCollection, { view: DataHubView.DataInsights }),
      label: formatMessage({ id: 'dataHub.dataInsights' }),
    },
    {
      type: DataHubView.Answers,
      url: generatePath(paths.dataHubCollection, { view: DataHubView.Answers }),
      label: formatMessage({ id: 'dataHub.answers' }),
    },
  ]

  if (embedded) {
    return (
      <DataHubAnswersTable
        relatedContentFilter={relatedContentFilter}
        allowedFilters={tableViewAllowedFilters}
        userFilters={userFilters}
        embedded={embedded}
        showCompanyCell={view === DataHubView.Answers || view === DataHubView.DataInsights}
      />
    )
  }

  if (!view) {
    return (
      <Redirect
        to={generatePath(paths.dataHubCollection, {
          view: DataHubView.DataInsights,
        })}
      />
    )
  }

  return (
    <Switch>
      <Route
        exact
        path={generatePath(paths.dataHubCollection, {
          flow,
          view: DataHubView.DataInsights,
        })}
      >
        <DataInsights tabs={{ actionTabs, activeTabParam: view }} />
      </Route>
      <Route
        exact
        path={generatePath(paths.dataHubCollection, {
          flow,
          view: DataHubView.Answers,
        })}
      >
        <DataHubAnswersTable
          tabs={{ actionTabs, activeTabParam: view }}
          relatedContentFilter={relatedContentFilter}
          allowedFilters={tableViewAllowedFilters}
          userFilters={userFilters}
          showCompanyCell={view === DataHubView.Answers || view === DataHubView.DataInsights}
        />
      </Route>
    </Switch>
  )
}

export default DataHubScene
