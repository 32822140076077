import endpoints from '@app/src/api/endpoints'
import { FetchBaseParameters, FetchKey, useFetchFacets, useFetchResource } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import { EmptyStateVariant } from '@app/src/components/EmptyState/EmptyState'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import useSignalRListener from '@app/src/hooks/signalRListener'
import DistributionGraph, { DistributionGraphBar } from '@app/src/pages/Dashboards/DistributionGraph'
import ScreeningInProgress from '@app/src/pages/Dashboards/ScreeningInProgress'
import { DataHubView } from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { AnswerStatus } from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'
import paths from '@app/src/wf-constants/paths'
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined'
import WifiOffIcon from '@mui/icons-material/WifiOff'
import { Box, Skeleton, Stack } from '@mui/material'
import { useTheme } from '@mui/styles'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { generatePath } from 'react-router'
import StatisticsCard from './StatisticsCard'

enum ScrapedAnswerType {
  DataFound = 'DataFound',
  NoDataFound = 'NoDataFound',
}

const toPercentage = (scrapedWithAnswersCount: number, total: number) => {
  return Math.round((scrapedWithAnswersCount / total) * 100)
}

const SCRAPER_IS_RUNNING_QUERY_KEY: FetchBaseParameters<unknown>['key'] = [FetchKey.JobRunning, 'scraper']

const ScrapedAnswerByDataFound = () => {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const queryClient = useQueryClient()
  const { inView, ref } = useInView({ triggerOnce: true })

  const {
    facets: [cannotAnswerFacet],
    isLoading,
    isError: scrapedAnswerError,
  } = useFetchFacets({
    endpoint: endpoints.responseItemsWithFacets,
    facetsParam: [{ name: 'cannotAnswer', isEnum: true }],
    filter: [{ name: 'isScraped', filters: [{ value: true, operator: Operators.EqualTo }] }],
    key: FetchKey.ResponseItemsFacets,
    shouldShowErrorNotification: false,
    options: {
      enabled: inView,
    },
  })

  const {
    data: scraperIsRunning,
    isFetching: isFetchingScraperRunning,
    isError: scaperIsRunningIsError,
  } = useFetchResource<boolean>({
    endpoint: endpoints.scraperRunning,
    key: SCRAPER_IS_RUNNING_QUERY_KEY,
    shouldShowErrorNotification: false,
    options: {
      enabled: inView,
    },
  })

  useSignalRListener('AiScrapingStarted', () => queryClient.setQueryData(SCRAPER_IS_RUNNING_QUERY_KEY, true))
  useSignalRListener('AiScrapingCompleted', () => queryClient.setQueryData(SCRAPER_IS_RUNNING_QUERY_KEY, false))

  const scrapedWithAnswersCount = cannotAnswerFacet?.find(f => !f.value)?.count ?? 0
  const scrapedWithoutAnswersCount = cannotAnswerFacet?.find(f => f.value)?.count ?? 0
  const noData = !scrapedWithAnswersCount && !scrapedWithoutAnswersCount
  const isError = (scrapedAnswerError || scaperIsRunningIsError) && !scraperIsRunning
  const loading = (isLoading || isFetchingScraperRunning) && !scraperIsRunning

  const generateDataHubLink = (answerStatuses: AnswerStatus[]) =>
    stringifyQueryFilters({
      url: generatePath(paths.dataHubCollection, {
        view: DataHubView.Answers,
      }),
      queryParams: {
        filters: [
          {
            name: 'answerStatus',
            value: answerStatuses,
            operator: Operators.In,
          },
          {
            name: 'isScraped',
            value: true,
            operator: Operators.EqualTo,
          },
        ],
      },
    })

  const total = scrapedWithAnswersCount + scrapedWithoutAnswersCount

  const bars: DistributionGraphBar[] = [
    {
      name: ScrapedAnswerType.DataFound,
      count: scrapedWithAnswersCount,
      color: palette.communication.dark,
      label: formatMessage({ id: 'dashboard.sourcing.scrapedAnswerByDataFound.dataFound' }),
      detailedLabel: formatMessage(
        { id: 'dashboard.sourcing.scrapedAnswerByDataFound.dataFoundWithPercentage' },
        { count: scrapedWithAnswersCount, percentage: toPercentage(scrapedWithAnswersCount, total) },
      ),
      link: generateDataHubLink([AnswerStatus.Answered]),
    },
    {
      name: ScrapedAnswerType.NoDataFound,
      count: scrapedWithoutAnswersCount,
      color: palette.communication.main,
      label: formatMessage({ id: 'dashboard.sourcing.scrapedAnswerByDataFound.noDataFound' }),
      detailedLabel: formatMessage(
        { id: 'dashboard.sourcing.scrapedAnswerByDataFound.noDataFoundWithPercentage' },
        { count: scrapedWithoutAnswersCount, percentage: toPercentage(scrapedWithoutAnswersCount, total) },
      ),
      link: generateDataHubLink([AnswerStatus.NotApplicable, AnswerStatus.NotAvailable, AnswerStatus.Other]),
    },
  ]

  return (
    <Box ref={ref}>
      <StatisticsCard
        title={formatMessage({ id: 'dashboard.sourcing.scrapedAnswerByDataFound.title' })}
        helperText={formatMessage({ id: 'dashboard.sourcing.scrapedAnswerByDataFound.helperText' }, { br })}
        loading={{
          isLoading: loading,
          skeleton: (
            <Stack width="100%">
              <Skeleton width="100%" height={50} sx={{ my: 1 }} />

              <Stack direction="row" spacing={2}>
                <Skeleton width="10%" height={32} variant="rounded" />
                <Skeleton width="10%" height={32} variant="rounded" />
                <Skeleton width="10%" height={32} variant="rounded" />
              </Stack>
            </Stack>
          ),
        }}
      >
        <>
          {isError ? (
            <Box display="flex" justifyContent="center" alignItems="center" py={4}>
              <EmptyState
                variant={EmptyStateVariant.Error}
                title={formatMessage({ id: 'dashboard.sourcing.scrapedAnswerByDataFound.error.title' })}
                description=""
                iconComponent={WifiOffIcon}
              />
            </Box>
          ) : scraperIsRunning ? (
            <ScreeningInProgress />
          ) : noData ? (
            <Box display="flex" justifyContent="center" alignItems="center" py={4}>
              <EmptyState
                title={formatMessage({ id: 'dashboard.sourcing.scrapedAnswerByDataFound.noData.title' })}
                description={formatMessage({
                  id: 'dashboard.sourcing.scrapedAnswerByDataFound.noData.description',
                })}
                iconComponent={SignalCellularAltOutlinedIcon}
              />
            </Box>
          ) : (
            <DistributionGraph
              bars={bars}
              title={formatMessage({ id: 'dashboard.sourcing.scrapedAnswerByDataFound.title' })}
            />
          )}
        </>
      </StatisticsCard>
    </Box>
  )
}

export default ScrapedAnswerByDataFound
