import { useEchartsContainerWidth } from '@app/src/hooks/useEchartsContainerWidth'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { getMinMaxOnYAxisCountCharts, getVisualizationColorsMaxContrast } from '@app/src/utils/statisticsUtils'
import { darken, useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { OptionResultItemMultiPeriod } from '../../../../DataHubScene'
import { getLegendSpacing } from '../chartsUtils'

interface OptionMultiPeriodChartConfigsParams {
  optionItemResult: { groupName: string; options: OptionResultItemMultiPeriod[] }[]
  selectedChartType: ChartType
  itemsInOther: string[]
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
}

const APPROXIMATE_WIDTH_OF_YAXIS = 60

const getAxisLabelWidth = (widthOfChart: number | undefined, existingOptions: string[]) => {
  if (!widthOfChart) return undefined
  return (widthOfChart - APPROXIMATE_WIDTH_OF_YAXIS) / existingOptions.length
}

const getMinMaxYAxis = (optionItemResult: { options: OptionResultItemMultiPeriod[] }[]) => {
  const optionValues = optionItemResult.flatMap(op => op.options.map(op => op.count))
  return getMinMaxOnYAxisCountCharts(optionValues)
}

export const useOptionMultiPeriodChartConfigs = ({
  optionItemResult,
  selectedChartType,
  itemsInOther,
  echartsRef,
}: OptionMultiPeriodChartConfigsParams) => {
  const { formatMessage } = useIntl()
  const { typography } = useTheme()
  const widthOfChart = useEchartsContainerWidth(echartsRef)
  const isHorizontalBarChart = selectedChartType === ChartType.Horizontal
  const { formatPeriodName } = usePeriodName()

  const existingOptions = optionItemResult?.[0]?.options.map(op => op.name)
  const colors = getVisualizationColorsMaxContrast(existingOptions?.length || 0)

  const { min, max } = useMemo(() => getMinMaxYAxis(optionItemResult), [optionItemResult])

  const categoryAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'category',
      data: optionItemResult.map(item => formatPeriodName(item.groupName)),
      triggerEvent: true,
      axisLabel: {
        interval: 0,
        width: getAxisLabelWidth(
          widthOfChart,
          optionItemResult.map(i => i.groupName),
        ),
        overflow: 'break',
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [existingOptions, optionItemResult, typography.body2.fontSize, typography.fontFamily],
  )

  const valueAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'value',
      name: formatMessage({ id: 'statistics.dataInsights.count' }),
      nameLocation: 'middle',
      nameGap: 35,
      min: min,
      max: max,
      minInterval: 1,
      nameTextStyle: {
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [formatMessage, typography.body2.fontSize, typography.fontFamily, min, max],
  )

  const horizontalBarChartOptions: EChartsOption = useMemo(() => {
    const dataSeries: EChartsOption['series'] =
      existingOptions?.map((option, optionIndex) => ({
        name: option,
        type: 'bar',
        barCategoryGap: '35%',
        emphasis: { itemStyle: { color: darken(colors[optionIndex], 0.2) } },
        data: optionItemResult.map(g => ({
          value: g.options.find(op => op.name === option)?.count || 0,
          name: formatPeriodName(g.groupName),
          groupName: g.groupName,
          answersSelected: [option],
          label: {
            show: optionIndex === 0,
            position: [0, -14],
            align: 'left',
            formatter: '{b}',
            fontFamily: typography.fontFamily,
            fontSize: typography.body2.fontSize,
            overflow: 'truncate',
            width: widthOfChart ? widthOfChart : undefined,
          },
        })),
        label: {
          show: false,
        },
      })) || []

    const xAxis = valueAxis as XAXisOption
    const yAxis = { ...categoryAxis, show: false } as YAXisOption

    return {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        containLabel: false,
        left: 15,
        right: 7,
        top: 30,
        bottom: getLegendSpacing(70, widthOfChart, existingOptions || []),
      },
      color: colors,
      xAxis,
      yAxis,
      legend: {
        icon: 'circle',
        bottom: 0,
        left: 0,
        itemWidth: 10,
        width: '100%',
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
          overflow: 'truncate',
        },
      },
      series: dataSeries,
    }
  }, [categoryAxis, optionItemResult, typography.body2.fontSize, typography.fontFamily, valueAxis, widthOfChart])

  const verticalBarChartOptions: EChartsOption = useMemo(() => {
    const dataSeries: EChartsOption['series'] =
      existingOptions?.map((option, optionIndex) => ({
        name: option,
        type: 'bar',
        barCategoryGap: '35%',
        emphasis: { itemStyle: { color: darken(colors[optionIndex], 0.2) } },
        data: optionItemResult.map(period => {
          const optionData = period.options.find(op => op.name === option)
          return {
            value: optionData?.count || 0,
            name: formatPeriodName(period.groupName),
            groupName: period.groupName,
            answersSelected: optionData?.isOther ? itemsInOther : [option],
            label: {
              position: 'top',
            },
          }
        }),
      })) || []

    const xAxis = categoryAxis as XAXisOption
    const yAxis = valueAxis as YAXisOption

    return {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        containLabel: true,
        left: 40,
        right: 7,
        top: 30,
        bottom: getLegendSpacing(20, widthOfChart, existingOptions || []),
      },
      color: colors,
      xAxis,
      yAxis,
      legend: {
        icon: 'circle',
        bottom: 0,
        left: 0,
        itemWidth: 10,
        width: '100%',
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
          overflow: 'truncate',
        },
      },
      series: dataSeries,
    }
  }, [categoryAxis, optionItemResult, typography.body2.fontSize, typography.fontFamily, valueAxis, widthOfChart])

  return isHorizontalBarChart ? horizontalBarChartOptions : verticalBarChartOptions
}
