import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import DrawerTemplate, { DrawerProps } from '@app/src/components/Drawer_DEPRECATED/DrawerTemplate'
import { ProviderUpdateType } from '@app/src/components/ManageProviderDrawer/CategoryDrawer/CategoryDrawerContent'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { FinalRiskRating } from '@app/src/types/categories'
import { UpdateProviderFinalRiskRating } from '@app/src/types/organizations'
import { AmplitudeTrackingEvents, NotificationSeverity } from '@app/src/wf-constants'
import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import FinalRiskRatingView from './FinalRiskRatingView'

type FinalRiskRatingDrawerProps = {
  closeDrawer: () => void
  providers?: ProviderUpdateType[]
  showBackButton?: boolean
} & DrawerProps

const FinalRiskRatingUpdateDrawer: React.FC<FinalRiskRatingDrawerProps> = ({
  closeDrawer,
  providers,
  showBackButton,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const providerIds = providers?.map(provider => provider.providerId) ?? []
  const formMethods = useForm<Omit<UpdateProviderFinalRiskRating, 'providerIds'>>({
    mode: 'onChange',
    defaultValues: {
      newFinalRiskRating: providers?.[0]?.finalRiskRating ?? FinalRiskRating.NotSelected,
      comment: '',
    },
  })
  const { mutate: saveResource, isLoading: isUpdating } = useCreateResource<UpdateProviderFinalRiskRating>()
  const { trackEvent } = useAmplitude()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const finalRiskRatingWatcher = formMethods.watch('newFinalRiskRating')

  const handleSaveFinalRiskRating = async (values: Omit<UpdateProviderFinalRiskRating, 'providerIds'>) => {
    saveResource(
      { url: endpoints.providerRiskRating, body: { ...values, providerIds } },
      {
        onError: requestError => {
          showErrorNotification({ requestError })
        },
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulFinalRiskAssessment' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })

          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          queryClient.invalidateQueries(FetchKey.Provider)
          queryClient.invalidateQueries(FetchKey.ProviderRisk)
          queryClient.invalidateQueries(FetchKey.ProviderActivity)
          closeDrawer()
        },
        onSettled: () => {
          trackEvent({
            name: AmplitudeTrackingEvents.Analyze.RiskAssessment.Saved,
          })
        },
      },
    )
  }

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Analyze.RiskAssessment.Opened,
    })
  }, [])

  return (
    <FormProvider {...formMethods}>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        component="form"
        onSubmit={formMethods.handleSubmit(handleSaveFinalRiskRating)}
        overflow="hidden"
      >
        <DrawerTemplate
          closeDrawer={closeDrawer}
          title={formatMessage({ id: 'schemas.providerRisk.assessment' })}
          buttons={[
            {
              label: formatMessage({ id: 'general.cancel' }),
              onClick: () => {
                closeDrawer()
              },
              variant: 'text',
              color: 'primary',
              fullWidth: true,
            },
            {
              loading: isUpdating,
              label: formatMessage({ id: 'general.update' }),
              variant: 'contained',
              color: 'primary',
              fullWidth: true,
              type: 'submit',
              disabled: !finalRiskRatingWatcher,
            },
          ]}
          {...props}
        >
          <FinalRiskRatingView providerIds={providerIds} />
        </DrawerTemplate>
      </Box>
    </FormProvider>
  )
}

export default FinalRiskRatingUpdateDrawer
