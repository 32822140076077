import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import DrawerViewSkeleton from '@app/src/components/Drawer/DrawerViewSkeleton'
import LoadingButton from '@app/src/components/LoadingButton'
import InviteSentContent from '@app/src/components/ReferralContact/InviteSentContent'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { ProvidersModalPurpose, useProvidersModal } from '@app/src/context/ProvidersModalContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { useReferralContact } from '@app/src/hooks/referralContact'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { Provider } from '@app/src/types/organizations'
import { AmplitudeTrackingEvents, NotificationSeverity } from '@app/src/wf-constants'
import { DeleteOutline, MailOutline, NotificationsActiveOutlined } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useDrawer } from '../DrawerContext'
import DrawerView, { DrawerViewProps } from '../DrawerView'
import DrawerViewLinkProvider from './DrawerViewLinkProvider'

type DrawerViewReferralContactProps = {
  provider: Provider
} & Omit<DrawerViewProps, 'title'>

const DrawerViewReferralContact: React.FC<DrawerViewReferralContactProps> = ({ provider, ...props }) => {
  const { mutateAsync: deleteResource, isLoading: isDeleting } = useDeleteResource()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const { trackEvent } = useAmplitude()
  const queryClient = useQueryClient()
  const { openDrawer, closeDrawer } = useDrawer()
  const { formatMessage } = useIntl()
  const [isConfirmationDialogOpen, openConfirmationDialog, closeConfirmationDialog] = useDialogState()
  const { handleOpenConnectDialog } = useProvidersModal()
  const { referralContact, isReferralContactsLoading } = useReferralContact(provider.id)

  const hasSentInvite = Boolean(referralContact?.referralEmailHistory?.length)

  const handleDeleteReferralContact = async () => {
    if (!referralContact) return
    deleteResource(
      { url: endpoints.deleteReferralContact(referralContact.id) },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'referral.delete.successMessage' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries(FetchKey.ReferralContactCollection)
          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          queryClient.invalidateQueries(FetchKey.ProviderReferralContactFacets)

          trackEvent({
            name: AmplitudeTrackingEvents.Accessor.InviteSupplier.ContactDeleted,
          })

          const updatedProvider = { ...provider, referralContact: undefined }

          openDrawer(<DrawerViewLinkProvider provider={updatedProvider} showBackButton={false} />)
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  if (isReferralContactsLoading) {
    return <DrawerViewSkeleton buttons={2} />
  }

  return (
    <DrawerView
      title={referralContact?.name}
      subTitle={provider?.name}
      stackButtons
      buttons={[
        {
          label: formatMessage({ id: 'referral.sendInvite.delete' }),
          startIcon: <DeleteOutline />,
          onClick: openConfirmationDialog,
          loading: isDeleting,
        },
        {
          label: hasSentInvite
            ? formatMessage({ id: 'referral.sendInvite.resendInviteToConnect' })
            : formatMessage({ id: 'referral.sendInvite.inviteToConnect' }),
          startIcon: hasSentInvite ? <NotificationsActiveOutlined /> : <MailOutline />,
          variant: 'contained',
          onClick: () => {
            handleOpenConnectDialog([provider], ProvidersModalPurpose.ExtraReminder)
            closeDrawer()
          },
          disabled: isDeleting,
        },
      ]}
      {...props}
    >
      <Stack alignItems="center" py={2}>
        <PersonAvatar name={referralContact?.name} size={AvatarSize.XXL} disabled />
        <Typography variant="h6" gutterBottom sx={{ mt: 2, mb: 0 }}>
          {referralContact?.name}
        </Typography>
        <Typography variant="body1">{referralContact?.email}</Typography>
      </Stack>

      {hasSentInvite && <InviteSentContent provider={provider} />}

      <Dialog open={isConfirmationDialogOpen} maxWidth="sm">
        <DialogTitle>{formatMessage({ id: 'referral.delete.title' })}</DialogTitle>
        <DialogContent>{formatMessage({ id: 'referral.delete.description' }, { br: <br /> })}</DialogContent>

        <DialogActions>
          <Button disabled={isDeleting} onClick={closeConfirmationDialog}>
            {formatMessage({ id: 'general.cancel' })}
          </Button>
          <LoadingButton color="error" loading={isDeleting} onClick={handleDeleteReferralContact}>
            {formatMessage({ id: 'referral.delete.deleteContact' })}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </DrawerView>
  )
}

export default DrawerViewReferralContact
