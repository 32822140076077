import { TableRow } from '@mui/material'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const MappingNodeHeader: React.FC<SortableHeaderProps> = ({ toggleSorting, activeSorting }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingNode.product' })}
        sorting={{ name: 'product.name', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.product.gtin' })}
        sorting={{ name: 'product.gtin', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM_SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.product.productCustomId' })}
        sorting={{ name: 'product.productCustomId', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM_SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.product.purchaseOrder' })}
        sorting={{ name: 'product.purchaseOrder', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM_SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingNode.tier' })}
        sorting={{ name: 'tier', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingNode.from' })}
        sorting={{ name: 'name', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingNode.activityFrom' })}
        sorting={{ name: 'actorType.name', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingNode.to' })}
        sorting={{ name: 'parentNode.name', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingNode.activityTo' })}
        sorting={{ name: 'parentNode.actorType.name', toggleSorting, activeSorting }}
      />
    </TableRow>
  )
}

export default MappingNodeHeader
