import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import Permissions from '@app/src/auth/permissions'
import { usePermissions } from '@app/src/auth/permissions/usePermissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import { OptionIcon } from '@app/src/components/Form/Select'
import { FinalRiskRatingIcon } from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import useCurrentProviderType from '@app/src/hooks/currentProviderType'
import AllFiltersButton from '@app/src/pages/ResourceCollection/Filters/AllFiltersButton'
import CustomCategoriesFilters from '@app/src/pages/ResourceCollection/Filters/CustomCategoriesFilters'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import { PeriodFilterOptions } from '@app/src/pages/ResourceCollection/Filters/PeriodFilterDisplay'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { FacetItem, FiltersProps } from '@app/src/pages/ResourceCollection/index'
import { SortOrder } from '@app/src/types/filter'
import { AmplitudeTrackingEvents, Solutions } from '@app/src/wf-constants'
import { Box, Button, Chip, createFilterOptions, Grid } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { capitalize } from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import AssessmentSubFilter from './AssessmentSubFilter'
import useLocalisedCountryFilter from './Common/useLocalisedCountryFilter'
import OptionWithLabel from './OptionWithLabel'
import RangeFilter from './RangeFilter'
import SpendFilter from './SpendFilter'

const ProvidersFilters = ({ allowedFilters, resetPage, implicitFilters }: FiltersProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { renderWithPermission } = usePermissions()
  const { trackEvent } = useAmplitude()
  const { solution } = useAuthentication().scope
  const { productMapping, assessments } = useFlags()
  const { hasPermission } = usePermissions()
  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)
  const effectiveImplicitFilters = implicitFilters || []
  const { renderOption, renderTags, customFilterOption } = useLocalisedCountryFilter()
  const resourceType = useCurrentProviderType()
  const tierFilter = createFilterOptions<FacetItem>({
    stringify: option =>
      formatMessage(
        {
          id: `schemas.provider.tierValues`,
        },
        { tier: option.value },
      ),
  })

  return (
    <Grid container spacing={1}>
      <Filters resetPage={resetPage} allowedFilters={allowedFilters}>
        {({ filters, inDrawerFiltersCount, clearQueryFilter }) => {
          const isFiltered = Object.keys(filters).length > 0
          return (
            <>
              <Grid item md={4} sm={8}>
                <Filter operator={Operators.In} name="id">
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.Provider, filterName],
                        endpoint: endpoints.providersWithFacets,
                        facetsParam: [{ name: 'name' }],
                      }}
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage(
                        {
                          id: `general.${capitalize(resourceType)}`,
                        },
                        { count: 1 },
                      )}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={3} sm={4}>
                <Filter operator={Operators.In} name="country.id">
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.Provider, filterName],
                        endpoint: endpoints.providersWithFacets,
                        facetsParam: [{ name: 'country.iso3166Alpha2' }],
                      }}
                      renderOption={renderOption}
                      renderTags={renderTags}
                      filterOptions={customFilterOption}
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.filter.country.select' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={3}>
                <Box display="inline-block">
                  <AllFiltersButton
                    inDrawerFiltersCount={inDrawerFiltersCount}
                    openDrawer={() =>
                      openDrawer(
                        <DrawerViewFilters allowedFilters={allowedFilters}>
                          <Box px={2} mt={2}>
                            <Filter name="assessments.periodName" operator={Operators.In}>
                              {({ value, onChange, filterName }) => (
                                <FilterFacetSelect
                                  implicitFilters={implicitFilters}
                                  size="small"
                                  multiple
                                  facetsParam={{
                                    key: [FetchKey.Provider, filterName],
                                    endpoint: endpoints.providersWithFacets,
                                    facetsParam: [{ name: filterName, isEnum: true }],
                                    sort: { target: 'label', order: SortOrder.DESCENDING },
                                  }}
                                  filterName={filterName}
                                  onChange={value => onChange(value)}
                                  fieldLabel={formatMessage({ id: 'schemas.request.period' })}
                                  value={[value].flat()}
                                  {...PeriodFilterOptions}
                                />
                              )}
                            </Filter>
                          </Box>
                          <Box px={2}>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.companies' })}>
                              <Filter operator={Operators.In} name="id">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.Provider, filterName],
                                      endpoint: endpoints.providersWithFacets,
                                      facetsParam: [{ name: 'name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={
                                      solution === Solutions.Investment || solution === Solutions.Finance
                                        ? formatMessage({ id: 'schemas.investee.investee' })
                                        : formatMessage({ id: 'schemas.filter.companyName' })
                                    }
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter operator={Operators.In} name="country.id">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.Provider, filterName],
                                      endpoint: endpoints.providersWithFacets,
                                      facetsParam: [{ name: 'country.iso3166Alpha2' }],
                                    }}
                                    renderOption={renderOption}
                                    renderTags={renderTags}
                                    filterName={filterName}
                                    filterOptions={customFilterOption}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.country.select' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter operator={Operators.In} name="linkStatus">
                                {({ value, filterName, onChange }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.Provider, filterName],
                                      endpoint: endpoints.providersWithFacets,
                                      facetsParam: [{ name: filterName, isEnum: true }],
                                      sort: {
                                        order: SortOrder.ASCENDING,
                                        target: 'value',
                                      },
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.linkStatus' })}
                                    value={[value].flat()}
                                    renderOption={(props, option, { selected }) => (
                                      <OptionWithLabel
                                        key={option.label}
                                        props={props}
                                        label={formatMessage({
                                          id: option?.label
                                            ? `resourceExplorer.provider.status.${option.label}`
                                            : 'general.notAvailable',
                                        })}
                                        multiple
                                        selected={selected}
                                      />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                      value?.map(
                                        (option, index) =>
                                          option && (
                                            <Chip
                                              {...getTagProps({ index })}
                                              key={option.value.toString() || index}
                                              label={formatMessage({
                                                id: `resourceExplorer.provider.status.${option.label}`,
                                              })}
                                              size="small"
                                            />
                                          ),
                                      )
                                    }
                                  />
                                )}
                              </Filter>
                              <RangeFilter
                                uniqueIdFrom="createdFrom"
                                uniqueIdTo="createdTo"
                                name="createdAt"
                                fieldLabelFrom={formatMessage({ id: 'schemas.filter.createdFrom' })}
                                fieldLabelTo={formatMessage({ id: 'schemas.filter.createdTo' })}
                              />
                              <Filter operator={Operators.In} name="websiteAddress">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.Provider, filterName],
                                      endpoint: endpoints.providersWithFacets,
                                      facetsParam: [{ name: 'websiteAddress', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.organization.websiteAddress' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter operator={Operators.In} name="vatNumber">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.Provider, filterName],
                                      endpoint: endpoints.providersWithFacets,
                                      facetsParam: [{ name: 'vatNumber', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.organization.vatNumber' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter operator={Operators.In} name="registrationNumber">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.Provider, filterName],
                                      endpoint: endpoints.providersWithFacets,
                                      facetsParam: [{ name: 'registrationNumber', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.organization.registrationNumber' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                            {canAccessAssessmentTemplate && (
                              <AssessmentSubFilter
                                effectiveImplicitFilters={effectiveImplicitFilters}
                                canAccessAssessmentTemplate={canAccessAssessmentTemplate}
                                assessments={assessments}
                                assessmentFilterNamePrefix="assessments"
                                fetchKey={FetchKey.Provider}
                                endpoint={endpoints.providersWithFacets}
                              />
                            )}
                            <FilterSection
                              defaultCollapsed
                              label={formatMessage({ id: 'schemas.filter.additionalInformation' })}
                            >
                              {solution === Solutions.Finance ? (
                                <>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="ownerUserId">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.Provider, filterName],
                                            endpoint: endpoints.providersWithFacets,
                                            facetsParam: [{ name: 'ownerUser.name' }],
                                          }}
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="finalRiskRating">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.Provider, filterName],
                                            endpoint: endpoints.providersWithFacets,
                                            facetsParam: [{ name: 'finalRiskRating', isEnum: true }],
                                          }}
                                          renderOption={(props, option, { selected }): JSX.Element => (
                                            <li {...props}>
                                              <OptionIcon selected={selected} multiple />
                                              <FinalRiskRatingIcon value={option?.label} />
                                              {option?.label
                                                ? formatMessage({
                                                    id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                                  })
                                                : ''}
                                            </li>
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    icon={<FinalRiskRatingIcon value={option.label} />}
                                                    label={formatMessage({
                                                      id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={value => {
                                            if (value.length) {
                                              trackEvent({
                                                name: AmplitudeTrackingEvents.Analyze.Filter.RiskAssessment,
                                                eventProps: {
                                                  filter_value: value,
                                                },
                                              })
                                            }

                                            onChange(value)
                                          }}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="priority">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.Provider, filterName],
                                            endpoint: endpoints.providersWithFacets,
                                            facetsParam: [{ name: 'priority', isEnum: true }],
                                          }}
                                          renderOption={(props, option, { selected }) => (
                                            <OptionWithLabel
                                              key={option.label}
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.priorityValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage({
                                                      id: `schemas.provider.priorityValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="ownerUserId">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.Provider, filterName],
                                            endpoint: endpoints.providersWithFacets,
                                            facetsParam: [{ name: 'ownerUser.name' }],
                                          }}
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="activityStatus">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.Provider, filterName],
                                            endpoint: endpoints.providersWithFacets,
                                            facetsParam: [{ name: 'activityStatus', isEnum: true }],
                                          }}
                                          renderOption={(props, option, { selected }) => (
                                            <OptionWithLabel
                                              key={option.label}
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.activityStatusValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage({
                                                      id: `schemas.provider.activityStatusValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.activityStatus' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="finalRiskRating">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.Provider, filterName],
                                            endpoint: endpoints.providersWithFacets,
                                            facetsParam: [{ name: 'finalRiskRating', isEnum: true }],
                                          }}
                                          renderOption={(props, option, { selected }) => (
                                            <OptionWithLabel
                                              key={option.label}
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.finalRiskRatingValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    icon={<FinalRiskRatingIcon value={option.label} />}
                                                    label={formatMessage({
                                                      id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="priority">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.Provider, filterName],
                                            endpoint: endpoints.providersWithFacets,
                                            facetsParam: [{ name: 'priority', isEnum: true }],
                                          }}
                                          renderOption={(props, option, { selected }) => (
                                            <OptionWithLabel
                                              key={option.label}
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.priorityValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage({
                                                      id: `schemas.provider.priorityValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="providerApprovalStatus">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.Provider, filterName],
                                            endpoint: endpoints.providersWithFacets,
                                            facetsParam: [{ name: 'providerApprovalStatus', isEnum: true }],
                                          }}
                                          renderOption={(props, option, { selected }) => (
                                            <OptionWithLabel
                                              key={option.label}
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.providerApprovalStatusValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage({
                                                      id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                </>
                              )}
                              {renderWithPermission({
                                [Permissions.SOURCING_USER]: (
                                  <>
                                    <Box mb={2}>
                                      <Filter operator={Operators.In} name="supplierUsage">
                                        {({ value, onChange, filterName }) => (
                                          <FilterFacetSelect
                                            implicitFilters={implicitFilters}
                                            size="small"
                                            multiple
                                            facetsParam={{
                                              key: [FetchKey.Provider, filterName],
                                              endpoint: endpoints.providersWithFacets,
                                              facetsParam: [{ name: 'supplierUsage', isEnum: true }],
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                              <OptionWithLabel
                                                key={option.label}
                                                props={props}
                                                label={formatMessage({
                                                  id: option?.label
                                                    ? `schemas.provider.supplierUsageValues.${option.label}`
                                                    : 'general.notAvailable',
                                                })}
                                                multiple
                                                selected={selected}
                                              />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                              value?.map(
                                                (option, index) =>
                                                  option && (
                                                    <Chip
                                                      {...getTagProps({ index })}
                                                      key={option?.value?.toString() || index}
                                                      label={formatMessage({
                                                        id: `schemas.provider.supplierUsageValues.${option.label}`,
                                                      })}
                                                      size="small"
                                                    />
                                                  ),
                                              )
                                            }
                                            filterName={filterName}
                                            onChange={onChange}
                                            fieldLabel={formatMessage({ id: 'schemas.provider.supplierUsage' })}
                                            value={[value].flat()}
                                          />
                                        )}
                                      </Filter>
                                    </Box>
                                    <Box mb={2}>
                                      <Filter operator={Operators.In} name="tier">
                                        {({ value, onChange, filterName }) => (
                                          <FilterFacetSelect
                                            implicitFilters={implicitFilters}
                                            size="small"
                                            multiple
                                            facetsParam={{
                                              key: [FetchKey.Provider, filterName],
                                              endpoint: endpoints.providersWithFacets,
                                              facetsParam: [{ name: 'tier', isEnum: true }],
                                            }}
                                            filterOptions={(options, state) => [...tierFilter(options, state)]}
                                            renderOption={(props, option, { selected }) => (
                                              <OptionWithLabel
                                                key={option.label}
                                                props={props}
                                                label={formatMessage(
                                                  {
                                                    id: `schemas.provider.tierValues`,
                                                  },
                                                  { tier: option?.label ?? '' },
                                                )}
                                                multiple
                                                selected={selected}
                                              />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                              value?.map(
                                                (option, index) =>
                                                  option && (
                                                    <Chip
                                                      {...getTagProps({ index })}
                                                      key={option?.value?.toString() || index}
                                                      label={formatMessage(
                                                        {
                                                          id: `schemas.provider.tierValues`,
                                                        },
                                                        { tier: option.label },
                                                      )}
                                                      size="small"
                                                    />
                                                  ),
                                              )
                                            }
                                            filterName={filterName}
                                            onChange={onChange}
                                            fieldLabel={formatMessage({ id: 'schemas.provider.tier' })}
                                            value={[value].flat()}
                                          />
                                        )}
                                      </Filter>
                                    </Box>
                                  </>
                                ),
                              })}
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="customId">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.Provider, filterName],
                                        endpoint: endpoints.providersWithFacets,
                                        facetsParam: [{ name: 'customId', isEnum: true }],
                                      }}
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.organization.customId' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                            </FilterSection>
                            <SpendFilter defaultCollapsed />
                            <CustomCategoriesFilters />

                            {productMapping && (
                              <FilterSection defaultCollapsed label={formatMessage({ id: 'navbar.productMapping' })}>
                                <Filter operator={Operators.In} name="mappingNodes.actorType.id">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.Provider, filterName],
                                        endpoint: endpoints.providersWithFacets,
                                        facetsParam: [{ name: 'mappingNodes.actorType.name' }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.mappingNode.actorTypeValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                label={formatMessage({
                                                  id: `schemas.mappingNode.actorTypeValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={value => onChange(value)}
                                      fieldLabel={formatMessage({ id: 'schemas.mappingNode.actorType' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                                <Filter operator={Operators.In} name="mappingNodes.tier">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.Provider, filterName],
                                        endpoint: endpoints.providersWithFacets,
                                        facetsParam: [{ name: 'mappingNodes.tier', isEnum: true }],
                                      }}
                                      filterName={filterName}
                                      onChange={value => onChange(value)}
                                      fieldLabel={formatMessage({ id: 'schemas.mappingNode.tier' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </FilterSection>
                            )}
                          </Box>
                        </DrawerViewFilters>,
                      )
                    }
                  />
                </Box>

                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Grid>
              <Filter name="organization.id" operator={Operators.In}>
                {({ value, onChange }) => (
                  <>
                    {value && (
                      <Grid item xs={12}>
                        <Chip
                          label={formatMessage({ id: 'schemas.provider.recentlyAddedCompanies' })}
                          onDelete={() => onChange(null)}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Filter>
            </>
          )
        }}
      </Filters>
    </Grid>
  )
}

export default ProvidersFilters
