import { useProvidersModal } from '@app/src/context/ProvidersModalContext'
import { DescriptionOutlined, InfoOutlined } from '@mui/icons-material'
import { Alert, AlertTitle, alpha, Chip, List, ListItem, ListItemText, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import ProvidersAccordion, { ProviderDisplayMode } from '../ConnectProviders/ProvidersAccordion'
import { SCHEDULE_SCREENING_QUESTION_KEYS, ScheduleScreeningFormData } from './ScheduleProviderScreeningModal'

const ScheduleProviderScreeningSummary: React.FC = () => {
  const { formatMessage } = useIntl()
  const { providersToConnect } = useProvidersModal()
  const { watch, setValue, getValues } = useFormContext<ScheduleScreeningFormData>()

  const watchProviders = watch(SCHEDULE_SCREENING_QUESTION_KEYS.PROVIDER_IDS)

  const handleChangeSetFormValues = (providerId: number) => {
    const toBeChecked = !watchProviders.includes(providerId)

    if (toBeChecked) {
      setValue(SCHEDULE_SCREENING_QUESTION_KEYS.PROVIDER_IDS, Array.from(new Set([...watchProviders, providerId])))
    } else {
      setValue(
        SCHEDULE_SCREENING_QUESTION_KEYS.PROVIDER_IDS,
        watchProviders.filter(watchProviderId => watchProviderId !== providerId),
      )
    }
  }

  return (
    <Stack flexGrow={1} spacing={2}>
      <Typography variant="subtitle1" gutterBottom>
        {formatMessage({ id: 'form.scheduleProviderScreening.summary.companies' })}
      </Typography>
      <ProvidersAccordion
        displayMode={ProviderDisplayMode.COUNTRY}
        title={formatMessage(
          { id: 'form.scheduleProviderScreening.summary.companiesSelected' },
          { count: watchProviders.length },
        )}
        providers={providersToConnect ?? []}
        selectedProviderIds={watchProviders}
        onChange={handleChangeSetFormValues}
        data-testid="can-connect-accordion"
      />
      <Typography variant="subtitle1">
        {formatMessage({ id: 'form.scheduleProviderScreening.summary.screenForRequests' })}
      </Typography>
      <List>
        {getValues(SCHEDULE_SCREENING_QUESTION_KEYS.TEMPLATES).map((template, index) => (
          <ListItem
            key={template.id}
            sx={{
              bgcolor: 'grey.100',
              color: 'text.secondary',
              padding: 2,
              borderTop: index === 0 ? 'none' : `1px solid ${alpha('#000', 0.12)}`,
            }}
          >
            <DescriptionOutlined sx={{ color: 'text.secondary', marginRight: 2 }} fontSize="medium" />
            <ListItemText
              primary={
                <Typography variant="body1" title={template.title}>
                  {template.title}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle1">
          {formatMessage({ id: 'form.scheduleProviderScreening.summary.period' })}
        </Typography>
        <Tooltip
          arrow
          placement="top"
          title={formatMessage({ id: 'form.scheduleProviderScreening.summary.periodTooltip' })}
        >
          <InfoOutlined fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
        </Tooltip>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Chip label={new Date().getFullYear() - 1} />
      </Stack>
      <Alert variant="filled" severity="info" sx={{ backgroundColor: 'common.white', alignItems: 'flex-start' }}>
        <AlertTitle>{formatMessage({ id: 'form.scheduleProviderScreening.summary.informationAlertTitle' })}</AlertTitle>
        {formatMessage({ id: 'form.scheduleProviderScreening.summary.informationAlert' })}
      </Alert>
    </Stack>
  )
}

export default ScheduleProviderScreeningSummary
