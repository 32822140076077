import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import Permissions from '@app/src/auth/permissions'
import { usePermissions } from '@app/src/auth/permissions/usePermissions'
import { OptionIcon } from '@app/src/components/Form/Select'
import { FinalRiskRatingIcon } from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import useLocalisedCountryFilter from '@app/src/pages/ResourceCollection/Filters/Common/useLocalisedCountryFilter'
import CustomCategoriesFilters from '@app/src/pages/ResourceCollection/Filters/CustomCategoriesFilters'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import OptionWithLabel from '@app/src/pages/ResourceCollection/Filters/OptionWithLabel'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Solutions } from '@app/src/wf-constants'
import { Box, Chip, createFilterOptions } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'

interface ProviderFilterDrawerProps {
  implicitFilters?: FilterGroup[]
}

export const PROVIDER_SELECTOR_ALLOWED_FILTERS = [
  'id',
  'name',
  'country.id',
  'createdAt',
  'ownerUserId',
  'websiteAddress',
  'vatNumber',
  'registrationNumber',
  'linked',
  'categoryOptions.id',
  'activityStatus',
  'finalRiskRating',
  'priority',
  'providerApprovalStatus',
  'supplierUsage',
  'tier',
  'mappingNodes.actorType.id',
  'mappingNodes.tier',
]

const ProviderSelectorFilterDrawer = ({ implicitFilters }: ProviderFilterDrawerProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const { renderWithPermission } = usePermissions()
  const { solution } = useAuthentication().scope
  const { productMapping } = useFlags()
  const { renderOption, renderTags, customFilterOption } = useLocalisedCountryFilter()

  const tierFilter = createFilterOptions<FacetItem>({
    stringify: option =>
      formatMessage(
        {
          id: `schemas.provider.tierValues`,
        },
        { tier: option.value },
      ),
  })

  return (
    <>
      <FilterSection label={formatMessage({ id: 'schemas.filter.companies' })}>
        <Filter operator={Operators.In} name="id">
          {({ value, onChange, filterName }) => (
            <FilterFacetSelect
              implicitFilters={implicitFilters}
              size="small"
              multiple
              facetsParam={{
                key: [FetchKey.Provider, filterName],
                endpoint: endpoints.providersWithFacets,
                facetsParam: [{ name: 'name' }],
              }}
              filterName={filterName}
              onChange={value => onChange(value)}
              fieldLabel={formatMessage({ id: 'schemas.organization.name' })}
              value={[value].flat()}
            />
          )}
        </Filter>

        <Filter operator={Operators.In} name="country.id">
          {({ value, onChange, filterName }) => (
            <FilterFacetSelect
              implicitFilters={implicitFilters}
              size="small"
              multiple
              facetsParam={{
                key: [FetchKey.Provider, filterName],
                endpoint: endpoints.providersWithFacets,
                facetsParam: [{ name: 'country.iso3166Alpha2' }],
              }}
              renderOption={renderOption}
              renderTags={renderTags}
              filterName={filterName}
              filterOptions={customFilterOption}
              onChange={value => onChange(value)}
              fieldLabel={formatMessage({ id: 'schemas.filter.country.select' })}
              value={[value].flat()}
            />
          )}
        </Filter>
      </FilterSection>

      <FilterSection defaultCollapsed label={formatMessage({ id: 'schemas.filter.additionalInformation' })}>
        {solution === Solutions.Finance ? (
          <>
            <Box mb={2}>
              <Filter operator={Operators.In} name="ownerUserId">
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    implicitFilters={implicitFilters}
                    size="small"
                    multiple
                    facetsParam={{
                      key: [FetchKey.Provider, filterName],
                      endpoint: endpoints.providersWithFacets,
                      facetsParam: [{ name: 'ownerUser.name' }],
                    }}
                    filterName={filterName}
                    onChange={onChange}
                    fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Box>
            <Box mb={2}>
              <Filter operator={Operators.In} name="finalRiskRating">
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    implicitFilters={implicitFilters}
                    size="small"
                    multiple
                    facetsParam={{
                      key: [FetchKey.Provider, filterName],
                      endpoint: endpoints.providersWithFacets,
                      facetsParam: [{ name: 'finalRiskRating', isEnum: true }],
                    }}
                    renderOption={(props, option, { selected }): JSX.Element => (
                      <li {...props}>
                        <OptionIcon selected={selected} multiple />
                        <FinalRiskRatingIcon value={option?.label} />
                        {option?.label !== undefined
                          ? formatMessage({
                              id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                            })
                          : ''}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value?.map(
                        (option, index) =>
                          option && (
                            <Chip
                              {...getTagProps({ index })}
                              key={option.value.toString() || index}
                              icon={<FinalRiskRatingIcon value={option.label} />}
                              label={formatMessage({
                                id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                              })}
                              size="small"
                            />
                          ),
                      )
                    }
                    filterName={filterName}
                    onChange={onChange}
                    fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Box>
            <Box mb={2}>
              <Filter operator={Operators.In} name="priority">
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    implicitFilters={implicitFilters}
                    size="small"
                    multiple
                    facetsParam={{
                      key: [FetchKey.Provider, filterName],
                      endpoint: endpoints.providersWithFacets,
                      facetsParam: [{ name: 'priority', isEnum: true }],
                    }}
                    renderOption={(props, option, { selected }): JSX.Element => (
                      <li {...props}>
                        <OptionIcon selected={selected} multiple />
                        {option?.label !== undefined
                          ? formatMessage({ id: `schemas.provider.priorityValues.${option.label}` })
                          : ''}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value?.map(
                        (option, index) =>
                          option && (
                            <Chip
                              {...getTagProps({ index })}
                              key={option.value.toString() || index}
                              label={formatMessage({
                                id: `schemas.provider.priorityValues.${option.label}`,
                              })}
                              size="small"
                            />
                          ),
                      )
                    }
                    filterName={filterName}
                    onChange={onChange}
                    fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Box>
          </>
        ) : (
          <>
            <Box mb={2}>
              <Filter operator={Operators.In} name="ownerUserId">
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    implicitFilters={implicitFilters}
                    size="small"
                    multiple
                    facetsParam={{
                      key: [FetchKey.Provider, filterName],
                      endpoint: endpoints.providersWithFacets,
                      facetsParam: [{ name: 'ownerUser.name' }],
                    }}
                    filterName={filterName}
                    onChange={onChange}
                    fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Box>
            <Box mb={2}>
              <Filter operator={Operators.In} name="activityStatus">
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    implicitFilters={implicitFilters}
                    size="small"
                    multiple
                    facetsParam={{
                      key: [FetchKey.Provider, filterName],
                      endpoint: endpoints.providersWithFacets,
                      facetsParam: [{ name: 'activityStatus', isEnum: true }],
                    }}
                    renderOption={(props, option, { selected }): JSX.Element => (
                      <li {...props}>
                        <OptionIcon selected={selected} multiple />
                        {option?.label !== undefined
                          ? formatMessage({ id: `schemas.provider.activityStatusValues.${option.label}` })
                          : ''}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value?.map(
                        (option, index) =>
                          option && (
                            <Chip
                              {...getTagProps({ index })}
                              key={option.value.toString() || index}
                              label={formatMessage({
                                id: `schemas.provider.activityStatusValues.${option.label}`,
                              })}
                              size="small"
                            />
                          ),
                      )
                    }
                    filterName={filterName}
                    onChange={onChange}
                    fieldLabel={formatMessage({ id: 'schemas.provider.activityStatus' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Box>
            <Box mb={2}>
              <Filter operator={Operators.In} name="finalRiskRating">
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    implicitFilters={implicitFilters}
                    size="small"
                    multiple
                    facetsParam={{
                      key: [FetchKey.Provider, filterName],
                      endpoint: endpoints.providersWithFacets,
                      facetsParam: [{ name: 'finalRiskRating', isEnum: true }],
                    }}
                    renderOption={(props, option, { selected }): JSX.Element => (
                      <li {...props}>
                        <OptionIcon selected={selected} multiple />
                        <FinalRiskRatingIcon value={option?.label} />
                        {option?.label !== undefined
                          ? formatMessage({
                              id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                            })
                          : ''}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value?.map(
                        (option, index) =>
                          option && (
                            <Chip
                              {...getTagProps({ index })}
                              key={option.value.toString() || index}
                              icon={<FinalRiskRatingIcon value={option.label} />}
                              label={formatMessage({
                                id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                              })}
                              size="small"
                            />
                          ),
                      )
                    }
                    filterName={filterName}
                    onChange={onChange}
                    fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Box>
            <Box mb={2}>
              <Filter operator={Operators.In} name="priority">
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    implicitFilters={implicitFilters}
                    size="small"
                    multiple
                    facetsParam={{
                      key: [FetchKey.Provider, filterName],
                      endpoint: endpoints.providersWithFacets,
                      facetsParam: [{ name: 'priority', isEnum: true }],
                    }}
                    renderOption={(props, option, { selected }): JSX.Element => (
                      <li {...props}>
                        <OptionIcon selected={selected} multiple />
                        {option?.label !== undefined
                          ? formatMessage({ id: `schemas.provider.priorityValues.${option.label}` })
                          : ''}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value?.map(
                        (option, index) =>
                          option && (
                            <Chip
                              {...getTagProps({ index })}
                              key={option.value.toString() || index}
                              label={formatMessage({
                                id: `schemas.provider.priorityValues.${option.label}`,
                              })}
                              size="small"
                            />
                          ),
                      )
                    }
                    filterName={filterName}
                    onChange={onChange}
                    fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Box>
            <Box mb={2}>
              <Filter operator={Operators.In} name="providerApprovalStatus">
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    implicitFilters={implicitFilters}
                    size="small"
                    multiple
                    facetsParam={{
                      key: [FetchKey.Provider, filterName],
                      endpoint: endpoints.providersWithFacets,
                      facetsParam: [{ name: 'providerApprovalStatus', isEnum: true }],
                    }}
                    renderOption={(props, option, { selected }): JSX.Element => (
                      <li {...props}>
                        <OptionIcon selected={selected} multiple />
                        {option?.label !== undefined
                          ? formatMessage({
                              id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                            })
                          : ''}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value?.map(
                        (option, index) =>
                          option && (
                            <Chip
                              {...getTagProps({ index })}
                              key={option.value.toString() || index}
                              label={formatMessage({
                                id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                              })}
                              size="small"
                            />
                          ),
                      )
                    }
                    filterName={filterName}
                    onChange={onChange}
                    fieldLabel={formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Box>
          </>
        )}
        {renderWithPermission({
          [Permissions.SOURCING_USER]: (
            <>
              <Box mb={2}>
                <Filter operator={Operators.In} name="supplierUsage">
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.Provider, filterName],
                        endpoint: endpoints.providersWithFacets,
                        facetsParam: [{ name: 'supplierUsage', isEnum: true }],
                      }}
                      renderOption={(props, option, { selected }): JSX.Element => (
                        <li {...props}>
                          <OptionIcon selected={selected} multiple />
                          {option?.label !== undefined
                            ? formatMessage({ id: `schemas.provider.supplierUsageValues.${option.label}` })
                            : ''}
                        </li>
                      )}
                      renderTags={(value, getTagProps) =>
                        value?.map(
                          (option, index) =>
                            option && (
                              <Chip
                                {...getTagProps({ index })}
                                key={option.value.toString() || index}
                                label={formatMessage({
                                  id: `schemas.provider.supplierUsageValues.${option.label}`,
                                })}
                                size="small"
                              />
                            ),
                        )
                      }
                      filterName={filterName}
                      onChange={onChange}
                      fieldLabel={formatMessage({ id: 'schemas.provider.supplierUsage' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Box>
              <Box mb={2}>
                <Filter operator={Operators.In} name="tier">
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.Provider, filterName],
                        endpoint: endpoints.providersWithFacets,
                        facetsParam: [{ name: 'tier', isEnum: true }],
                      }}
                      filterOptions={(options, state) => [...tierFilter(options, state)]}
                      renderOption={(props, option, { selected }): JSX.Element => (
                        <li {...props}>
                          <OptionIcon selected={selected} multiple />
                          {option?.value !== undefined
                            ? formatMessage(
                                {
                                  id: `schemas.provider.tierValues`,
                                },
                                { tier: option.label },
                              )
                            : ''}
                        </li>
                      )}
                      renderTags={(value, getTagProps) =>
                        value?.map(
                          (option, index) =>
                            option && (
                              <Chip
                                {...getTagProps({ index })}
                                key={option.value.toString() || index}
                                label={formatMessage(
                                  {
                                    id: `schemas.provider.tierValues`,
                                  },
                                  { tier: option.label },
                                )}
                                size="small"
                              />
                            ),
                        )
                      }
                      filterName={filterName}
                      onChange={onChange}
                      fieldLabel={formatMessage({ id: 'schemas.provider.tier' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Box>
            </>
          ),
        })}
      </FilterSection>
      <CustomCategoriesFilters />

      {productMapping && (
        <FilterSection defaultCollapsed label={formatMessage({ id: 'navbar.productMapping' })}>
          <Filter operator={Operators.In} name="mappingNodes.actorType.id">
            {({ value, onChange, filterName }) => (
              <FilterFacetSelect
                implicitFilters={implicitFilters}
                size="small"
                multiple
                facetsParam={{
                  key: [FetchKey.Provider, filterName],
                  endpoint: endpoints.providersWithFacets,
                  facetsParam: [{ name: 'mappingNodes.actorType.name' }],
                }}
                renderOption={(props, option, { selected }) => (
                  <OptionWithLabel
                    props={props}
                    label={formatMessage({
                      id: option?.label
                        ? `schemas.mappingNode.actorTypeValues.${option.label}`
                        : 'general.notAvailable',
                    })}
                    multiple
                    selected={selected}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value?.map(
                    (option, index) =>
                      option && (
                        <Chip
                          {...getTagProps({ index })}
                          key={option?.value?.toString() || index}
                          label={formatMessage({
                            id: `schemas.mappingNode.actorTypeValues.${option.label}`,
                          })}
                          size="small"
                        />
                      ),
                  )
                }
                filterName={filterName}
                onChange={value => onChange(value)}
                fieldLabel={formatMessage({ id: 'schemas.mappingNode.actorType' })}
                value={[value].flat()}
              />
            )}
          </Filter>
          <Filter operator={Operators.In} name="mappingNodes.tier">
            {({ value, onChange, filterName }) => (
              <FilterFacetSelect
                implicitFilters={implicitFilters}
                size="small"
                multiple
                facetsParam={{
                  key: [FetchKey.Provider, filterName],
                  endpoint: endpoints.providersWithFacets,
                  facetsParam: [{ name: 'mappingNodes.tier', isEnum: true }],
                }}
                filterName={filterName}
                onChange={value => onChange(value)}
                fieldLabel={formatMessage({ id: 'schemas.mappingNode.tier' })}
                value={[value].flat()}
              />
            )}
          </Filter>
        </FilterSection>
      )}
    </>
  )
}

export default ProviderSelectorFilterDrawer
