import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewCreateReferralContact from '@app/src/components/Drawer/Views/DrawerViewCreateReferralContact'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import CountryCell from '@app/src/components/Table/Cells/CountryCell'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { Provider } from '@app/src/types/organizations'
import { AddOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  alpha,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import PersonAvatar from '../../CellComponents/PersonAvatar'

export enum ProviderDisplayMode {
  COUNTRY = 'country',
  REFERRAL_CONTACT = 'referralContact',
}

type ProvidersAccordionProps = {
  title: ReactNode
  providers: Array<Provider>
  selectedProviderIds: Array<number>
  displayMode: ProviderDisplayMode
  onChange: (providerId: number) => void
  icon?: ReactNode
} & Omit<AccordionProps, 'onChange' | 'children'>

const ProvidersAccordion: React.FC<ProvidersAccordionProps> = ({
  title,
  providers,
  selectedProviderIds,
  onChange,
  displayMode,
  icon,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  return (
    <Accordion
      elevation={0}
      disableGutters
      {...props}
      sx={[{ bgcolor: 'grey.100', '&:before': { position: 'unset' } }, ...[props.sx ?? []].flat()]}
    >
      <AccordionSummary expandIcon={<ExpandMoreOutlined fontSize="medium" sx={{ mx: 1 }} />} sx={{ py: 1 }}>
        <Stack direction="row">
          {icon}
          <Typography variant="body1" ml={3}>
            {title}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List disablePadding>
          {providers.map(provider => (
            <ListItem
              key={provider.id}
              sx={{
                justifyContent: 'space-between',
                borderTop: `1px solid ${alpha('#000', 0.12)}`,
              }}
              disablePadding
            >
              <ListItemButton
                selected={selectedProviderIds.includes(provider.id)}
                onClick={() => onChange(provider.id)}
              >
                <Stack direction="row" alignItems="center" gap={1} width="60%">
                  <Checkbox
                    edge="start"
                    checked={selectedProviderIds.includes(provider.id)}
                    onChange={() => onChange(provider.id)}
                  />
                  <CompanyCell company={provider} disableCell data-testid="accordion-company-name" />
                </Stack>

                {displayMode === ProviderDisplayMode.REFERRAL_CONTACT ? (
                  <Stack direction="row" width="40%">
                    {provider.referralContact ? (
                      <>
                        <PersonAvatar name={provider.referralContact?.name} size={AvatarSize.Small} disabled />
                        <Typography ml={1}>{provider.referralContact?.name}</Typography>
                      </>
                    ) : (
                      <Button
                        startIcon={<AddOutlined color="primary" />}
                        onClick={e => {
                          e.stopPropagation()
                          openDrawer(<DrawerViewCreateReferralContact provider={provider} onlySave />, {
                            showAboveDialogs: true,
                          })
                        }}
                      >
                        {formatMessage({ id: 'referral.contact.addContact' })}
                      </Button>
                    )}
                  </Stack>
                ) : displayMode === ProviderDisplayMode.COUNTRY ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                      '& .MuiTableCell-root': {
                        height: 24,
                        padding: 0,
                        border: 'none',
                      },
                    }}
                  >
                    <CountryCell countryCode={provider.country?.countryCode} />
                  </Stack>
                ) : null}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

export default ProvidersAccordion
