import { QueryParamType, useQueryState } from '@app/src/hooks/queryState'
import {
  FilterInterface,
  FiltersChildrenRenderProps,
  FiltersContextProps,
} from '@app/src/pages/ResourceCollection/Filters/Filters'
import { FilterGroup, FilterGroupQueryString, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ResetPage } from '@app/src/types/filter'
import { insertObjIf } from '@app/src/utils/helpersTs'

export const isQueryFilter = (queryParam: QueryParamType | FilterInterface): queryParam is FilterInterface => {
  if (typeof queryParam === 'string') return false
  if (typeof queryParam === 'number') return false
  if (typeof queryParam === 'boolean') return false
  if (Array.isArray(queryParam)) return false
  return Boolean(queryParam?.['name'] && queryParam?.['value'] && queryParam?.['operator'])
}

export const createUniqueFilters = (
  filters: FilterInterface[],
  name: string,
  value: FilterInterface['value'],
  operator: Operators,
  uniqueId?: string,
  intersect?: boolean,
): FilterInterface[] => {
  const newFilters = [...filters]
  const filterIndex = newFilters.findIndex(f => {
    if (uniqueId) {
      return f?.['name'] === name && f?.['uniqueId'] === uniqueId
    }
    return f?.['name'] === name
  })
  const hasValue = Boolean([value].flat().length)

  if (filterIndex < 0 && hasValue) {
    newFilters.push({
      name,
      value,
      operator,
      ...insertObjIf(Boolean(intersect), { intersect: 'true' }),
      ...insertObjIf(Boolean(uniqueId), { uniqueId }),
    })
  } else if (hasValue) {
    newFilters[filterIndex].value = value
    newFilters[filterIndex].operator = operator
  } else {
    delete newFilters[filterIndex]
  }

  return newFilters
}

interface UseQueryFilters {
  filters: FiltersContextProps['filters']
  setQueryFilter: FiltersContextProps['setQueryFilter']
  setQueryFilterOperator: FiltersContextProps['setQueryFilterOperator']
  clearQueryFilter: () => void
  setQueryFilters: (value: QueryParamType, replace?: boolean | undefined) => void
}

const useQueryFilters = (
  allowedFilters: FiltersContextProps['allowedFilters'],
  resetPage?: ResetPage,
  filterParamName = 'filters',
): UseQueryFilters => {
  const [queryFilters, setQueryFilters] = useQueryState(filterParamName, [])
  const typedQueryFilters = [queryFilters].flat().filter(isQueryFilter)
  const allowedQueryFilters = typedQueryFilters.filter(f => allowedFilters.includes(f['name']))

  const setQueryFiltersAndResetPage = (filters: FilterInterface[], isSettingQueryFilterOperator?: boolean) => {
    if (!isSettingQueryFilterOperator) resetPage?.()
    setQueryFilters(filters, isSettingQueryFilterOperator)
  }

  const setQueryFilter: FiltersContextProps['setQueryFilter'] = (
    name,
    value,
    operator,
    uniqueId,
    replace,
    intersect,
  ) => {
    setQueryFiltersAndResetPage(
      createUniqueFilters(allowedQueryFilters, name, value, operator, uniqueId, intersect),
      replace,
    )
  }

  const setQueryFilterOperator: FiltersContextProps['setQueryFilterOperator'] = (name, operator, uniqueId) => {
    const newFilters = [...allowedQueryFilters]
    const filterIndex = newFilters.findIndex(f => {
      if (uniqueId) {
        return f?.uniqueId === uniqueId && f?.['name'] === name
      }
      return f?.['name'] === name
    })
    if (filterIndex >= 0) {
      newFilters[filterIndex].operator = operator

      if (newFilters[filterIndex].operator !== Operators.In && newFilters[filterIndex].operator !== Operators.NotIn) {
        if (Array.isArray(newFilters[filterIndex].value)) {
          newFilters[filterIndex].value = newFilters[filterIndex].value[0]
        }
      } else {
        newFilters[filterIndex].value = [newFilters[filterIndex].value].flat()
      }
    }
    setQueryFiltersAndResetPage(newFilters, true)
  }

  const clearQueryFilter = () => {
    setQueryFiltersAndResetPage([])
  }

  const filters = allowedQueryFilters.reduce(
    (prev, curr) => {
      if (prev[curr['name']]) {
        prev[curr['name']] = [...[prev[curr['name']]].flat(), curr]
      } else {
        prev[curr['name']] = curr
      }
      return prev
    },
    {} as FiltersChildrenRenderProps['filters'],
  )

  return {
    filters,
    setQueryFilter,
    clearQueryFilter,
    setQueryFilterOperator,
    setQueryFilters,
  }
}

export const useGetApiQueryFilters = (
  allowedFilters: FiltersContextProps['allowedFilters'],
  filterParamName = 'filters',
): FilterGroup[] => {
  const [queryFilters] = useQueryState(filterParamName, [])

  const typedQueryFilters = [queryFilters].flat().filter(isQueryFilter)

  return typedQueryFilters
    .filter(f => allowedFilters.includes(f['name']))
    .map(f => ({
      name: f.name,
      ...(f.intersect === 'true' ? { intersect: true } : {}),
      filters: [
        {
          value: f.value,
          operator: f.operator,
          uniqueId: f.uniqueId,
        },
      ],
    })) as FilterGroupQueryString[]
}

export const useGetQueryFilters = (filterParamName = 'filters'): { getQueryFilters: () => FilterInterface[] } => {
  const [queryFilters] = useQueryState(filterParamName, [])
  const getQueryFilters = () => {
    return [queryFilters].flat().filter(isQueryFilter)
  }
  return { getQueryFilters }
}

export default useQueryFilters
