import FailedToFetchDataEmptyState from '@app/src/components/EmptyState/FailedToFetchDataEmptyState'
import LinkButton from '@app/src/components/LinkButton'
import StatusChip from '@app/src/components/StatusChip'
import { CheckCircleOutlined } from '@mui/icons-material'
import { Box, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import StatisticsCard from '../StatisticsCard'

interface Props {
  title: string
  helperText?: string
  totalCount: number
  overdueCount?: number
  isLoading: boolean
  actionLink: string
  isError: boolean
}

const RequestKpiCard: React.FC<Props> = ({
  totalCount,
  overdueCount,
  isLoading,
  actionLink,
  title,
  helperText,
  isError,
}) => {
  const { formatMessage } = useIntl()

  return (
    <StatisticsCard
      title={title}
      helperText={helperText}
      action={
        <LinkButton size="small" to={actionLink}>
          {formatMessage({ id: 'general.seeAll' })}
        </LinkButton>
      }
      justifyContent="left"
      loading={{
        isLoading: isLoading,
        skeleton: (
          <Stack spacing={2}>
            <Typography variant="kpi" pt={2}>
              <Skeleton width={70} variant="rounded" />
            </Typography>
            <Skeleton width={200} />
          </Stack>
        ),
      }}
      withBorder
    >
      {isError ? (
        <FailedToFetchDataEmptyState />
      ) : (
        <>
          <Stack spacing={2}>
            <Typography variant="kpi" pt={2} sx={{ ...(!totalCount && { color: 'rgba(0, 0, 0, 0.38)' }) }}>
              {totalCount}
            </Typography>

            {Boolean(overdueCount) && (
              <Box sx={{ '&:hover': { backgroundColor: 'grey.100', borderRadius: 4 } }}>
                <StatusChip
                  transparent
                  color="error"
                  label={`${overdueCount} ${formatMessage({ id: 'transparencyOverview.overdue' })}`}
                />
              </Box>
            )}

            {!totalCount && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                sx={{ '&:hover': { backgroundColor: 'grey.100', borderRadius: 4 } }}
              >
                <CheckCircleOutlined></CheckCircleOutlined>
                <Typography ml={1} variant="caption">
                  {formatMessage({ id: 'transparency.caughtUp' })}
                </Typography>
              </Box>
            )}
          </Stack>
        </>
      )}
    </StatisticsCard>
  )
}

export default RequestKpiCard
