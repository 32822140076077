import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import CreateNewRequestModal from '@app/src/components/CreateNewRequestModal'
import EmptyState from '@app/src/components/EmptyState'
import FailedToFetchDataEmptyState from '@app/src/components/EmptyState/FailedToFetchDataEmptyState'
import StatusChip from '@app/src/components/StatusChip'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import { useAccount } from '@app/src/context/AccountContext'
import { useCompaniesStatisticsFilter } from '@app/src/context/CompaniesStatisticsFilterContext'
import { Ownership } from '@app/src/context/CompaniesStatisticsFilterContextProvider'
import { ReportType } from '@app/src/context/CreationModalProgressContext'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { useProvidersByPendingInquiries } from '@app/src/hooks/providersByPendingInquiries'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { ProviderTabs } from '@app/src/pages/ResourceCollection/Collections/Providers/ProvidersScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Company } from '@app/src/types/organizations'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { ArrowForward, Business } from '@mui/icons-material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import StatisticsCard from './StatisticsCard'

interface RequestsByCompanyProps {
  providersCount: number
  hasReferralCode?: boolean
  isLoading: boolean
  providerCountIsError: boolean
}

const RequestsByCompany: React.FC<RequestsByCompanyProps> = ({
  providersCount,
  hasReferralCode,
  isLoading,
  providerCountIsError,
}) => {
  const history = useHistory()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useDialogState()
  const { selectedOwnership, setSelectedOwnership } = useCompaniesStatisticsFilter()
  const [isOwnershipMenuOpen, setIsOwnershipMenuOpen] = useState<boolean>(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { account } = useAccount()

  const {
    providersWithPendingInquiries,
    providersWithOverdueInquiries,
    providersWithInquiriesOverdueWithinAWeek,
    isLoading: providersByPendingInquiriesLoading,
    isError: providerByPendingInquiriesIsError,
  } = useProvidersByPendingInquiries()

  const {
    items: companies,
    isLoading: companiesLoading,
    isError: companiesIsError,
  } = useFetchCollectionWithPost<Company>({
    key: [FetchKey.Organization, FetchKey.Supplier],
    endpoint: endpoints.providersCollection,
    shouldShowErrorNotification: false,
    payload: {
      filter: [
        ...(selectedOwnership === Ownership.MyCompanies
          ? [
              {
                name: 'ownerUserId',
                filters: [
                  {
                    value: account?.user.id,
                    operator: Operators.EqualTo,
                  },
                ],
              },
            ]
          : []),
      ],
      include: ['organization.*'],
    },
  })

  const data = companies.map(company => ({
    ...company,
    pendingRequests: providersWithPendingInquiries?.find(inquiry => inquiry.value === company.id)?.count || 0,
    overdueRequests: providersWithOverdueInquiries?.find(inquiry => inquiry.value === company.id)?.count || 0,
    dueSoonRequests:
      providersWithInquiriesOverdueWithinAWeek?.find(inquiry => inquiry.value === company.id)?.count || 0,
  }))

  const sortedData = data.sort((a, b) => b.pendingRequests - a.pendingRequests)

  const emptyStateMode = !sortedData.length && !providersCount
  const noOwnedCompanies = !sortedData.length && Boolean(providersCount)
  const isError = providerByPendingInquiriesIsError || companiesIsError || providerCountIsError

  return (
    <StatisticsCard
      title={formatMessage({ id: 'dashboard.investment.companies' })}
      loading={{
        isLoading: isLoading || providersByPendingInquiriesLoading || companiesLoading,
        skeleton: (
          <Stack spacing={0.5} width="100%" mt={1}>
            <Skeleton height={40} variant="rounded" />
            <Skeleton height={30} />
            <Skeleton height={30} />
            <Skeleton height={30} />
          </Stack>
        ),
      }}
      action={
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography color="primary" variant="body1">
            {formatMessage({ id: 'dashboard.sourcing.companyStatistics.ownership' })}
          </Typography>
          <Box>
            <Button
              endIcon={<ArrowDropDownIcon />}
              size="small"
              onClick={() => setIsOwnershipMenuOpen(true)}
              ref={anchorRef}
            >
              {formatMessage({ id: `dashboard.sourcing.companyStatistics.${selectedOwnership}` })}
            </Button>
            <Menu
              open={isOwnershipMenuOpen}
              anchorEl={anchorRef.current}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              elevation={1}
              onClose={() => setIsOwnershipMenuOpen(false)}
            >
              <MenuItem
                onClick={() => {
                  setSelectedOwnership(Ownership.All)
                  setIsOwnershipMenuOpen(false)
                }}
              >
                {formatMessage({ id: `dashboard.sourcing.companyStatistics.all` })}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectedOwnership(Ownership.MyCompanies)
                  setIsOwnershipMenuOpen(false)
                }}
              >
                {formatMessage({ id: `dashboard.sourcing.companyStatistics.myCompanies` })}
              </MenuItem>
            </Menu>
          </Box>
        </Stack>
      }
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Box my={2}>
          <Typography color="textSecondary" variant="caption">
            {formatMessage({ id: 'dashboard.investment.requestsByCompanyInfo' })}
          </Typography>
        </Box>
        <>
          {isError ? (
            <FailedToFetchDataEmptyState />
          ) : emptyStateMode ? (
            <Box bgcolor="grey.50" flexGrow={1} borderRadius={4} display="flex" alignItems="center" py={8} mb={1}>
              <EmptyState
                title={formatMessage({ id: 'dashboard.investment.requestsByCompanyEmptyTitle' })}
                description={formatMessage({ id: 'dashboard.investment.requestsByCompanyEmptyInfo' })}
                iconComponent={Business}
              >
                <Box>
                  <Button
                    variant="outlined"
                    onClick={() => history.push(generatePath(paths.providers, { view: ProviderTabs.All }))}
                  >
                    {formatMessage({ id: 'dashboard.investment.companiesEmpty' })}
                  </Button>
                  {!hasReferralCode && (
                    <>
                      <Button variant="contained" onClick={openCreateDialog} sx={{ ml: 1 }}>
                        {formatMessage({ id: 'dashboard.investment.getStartedWithSFDR' })}
                      </Button>
                      <CreationModalProgressContextProvider>
                        <CreateNewRequestModal
                          open={isCreateDialogOpen}
                          onClose={closeCreateDialog}
                          reportType={ReportType.SFDR_WITHOUT_COMPANIES}
                          disableInitialBack
                        />
                      </CreationModalProgressContextProvider>
                    </>
                  )}
                </Box>
              </EmptyState>
            </Box>
          ) : noOwnedCompanies ? (
            <Box bgcolor="grey.50" flexGrow={1} borderRadius={4} display="flex" alignItems="center" py={8} mb={1}>
              <EmptyState
                title={formatMessage({ id: 'dashboard.investment.requestsByCompanyNoOwnedCompaniesTitle' })}
                description={formatMessage({ id: 'dashboard.investment.requestsByCompanyNoOwnedCompaniesInfo' })}
                iconComponent={PersonOutlineOutlinedIcon}
              >
                <Button
                  variant="outlined"
                  onClick={() => history.push(generatePath(paths.providers, { view: ProviderTabs.All }))}
                >
                  {formatMessage({ id: 'dashboard.investment.requestsByCompanyNoOwnedCompaniesCTA' })}
                </Button>
              </EmptyState>
            </Box>
          ) : (
            <Box>
              {sortedData.map((company, i) => (
                <React.Fragment key={company.id}>
                  <Box
                    sx={{
                      '&:hover': {
                        backgroundColor: palette.grey[100],
                      },
                    }}
                    px={1}
                    py={2}
                    borderRadius={4}
                  >
                    <Grid container>
                      <Grid item xs={6} display="flex" alignItems="center">
                        <Box width="100%" pr={1}>
                          <CompanyCell company={company} disableCell drawer />
                        </Box>
                      </Grid>
                      <Grid item xs={5} display="flex" alignItems="center">
                        <Stack direction="row" flexWrap="wrap">
                          {company.overdueRequests > 0 && (
                            <Box mr={1} my={0.5}>
                              <StatusChip
                                label={formatMessage(
                                  { id: `dashboard.sourcing.companyStatistics.requestsOverdue` },
                                  { count: company.overdueRequests },
                                )}
                                size="small"
                                color="error"
                              />
                            </Box>
                          )}
                          {company.dueSoonRequests > 0 && (
                            <Box mr={1} my={0.5}>
                              <StatusChip
                                label={formatMessage(
                                  { id: `dashboard.sourcing.companyStatistics.requestsDueSoon` },
                                  { count: company.dueSoonRequests },
                                )}
                                size="small"
                                color="warning"
                              />
                            </Box>
                          )}
                          {company.pendingRequests - company.overdueRequests - company.dueSoonRequests > 0 && (
                            <Box mr={1} my={0.5}>
                              <StatusChip
                                label={formatMessage(
                                  { id: `dashboard.sourcing.companyStatistics.pendingRequests` },
                                  {
                                    count: company.pendingRequests - company.overdueRequests - company.dueSoonRequests,
                                  },
                                )}
                                size="small"
                              />
                            </Box>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={1} display="flex" alignItems="center" justifyContent="flex-end">
                        <IconButton
                          size="small"
                          onClick={() =>
                            history.push(
                              stringifyQueryFilters({
                                url: generatePath(paths.manageRequest, {
                                  view: ViewTypeName.Requests,
                                }),
                                queryParams: {
                                  filters: [
                                    {
                                      name: 'provider.id',
                                      value: [company.id],
                                      operator: Operators.In,
                                    },
                                    {
                                      name: 'status',
                                      value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
                                      operator: Operators.In,
                                    },
                                  ],
                                },
                                includeCurrentFilters: true,
                              }),
                            )
                          }
                        >
                          <ArrowForward />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                  {i !== sortedData.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </Box>
          )}
        </>
      </Box>
    </StatisticsCard>
  )
}

export default RequestsByCompany
