import { Box, Skeleton } from '@mui/material'
import React from 'react'

const CompanyStatisticsGraphSkeleton = () => (
  <Box display="flex" justifyContent="center" flexDirection="column" justifySelf="center" width={165}>
    <Skeleton variant="circular" height={165} />
    <Skeleton variant="text" width={70} sx={{ margin: '0 auto', mt: 2 }} />
  </Box>
)

export default CompanyStatisticsGraphSkeleton
