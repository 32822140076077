import { OptionAdornment, OptionIcon } from '@app/src/components/Form/Select'
import SelectPaper from '@app/src/components/Form/Select/SelectPaper'
import TextField from '@app/src/components/Ui/TextField'
import { capitalize } from '@app/src/utils/helpers'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  Box,
  Chip,
  CircularProgress,
  createFilterOptions,
  InputProps,
  TextFieldVariants,
  Tooltip,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { SetStateAction, useCallback } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useIntl } from 'react-intl'

export const SELECT_ALL_OPTION_VALUE = 'SELECT_ALL_OPTION_VALUE'

export const useAutocompleteStyles = makeStyles(({ shadows, palette, spacing }) => ({
  clearIndicator: {
    color: palette.primary.main,
  },
  popupIndicator: {
    color: palette.primary.main,
  },
  paper: {
    boxShadow: shadows[10],
    borderRadius: 12,
  },
  icon: {
    color: palette.primary.main,
  },
  tooltip: {
    marginTop: 2,
  },
  chip: {
    maxWidth: 200,
  },
  autoCompleteGroup: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
}))

export interface Option<T = number | string> {
  label: string
  value: T
  adornment?: string
  //Not used unless renderOption is used
  additionalText?: string
  disabled?: boolean
}

export interface PassedThroughProps<T> {
  fieldLabel: string
  size?: 'medium' | 'small'
  error?: FieldErrors | string //todo typing : check if we ever send a FieldError
  disabled?: boolean
  isLoading?: boolean
  actionButtons?: JSX.Element
  modifiers?: JSX.Element
  enableSelectAll?: boolean
  hoveringLabel?: boolean
  groupBy?: AutocompleteProps<Option<T>, false, undefined, undefined>['groupBy']
  renderOption?: AutocompleteProps<Option<T>, boolean, false, false, 'div'>['renderOption']
  fullWidth?: boolean
  variant?: TextFieldVariants
  name?: string
  InputProps?: InputProps
}

interface CommonProps<T> {
  onChange: (value: Option<T> | Option<T>[] | null) => void
  open: boolean
  setOpen: React.Dispatch<SetStateAction<boolean>>
  loading: boolean
  optionsError?: string | null
  options: Option<T>[]
  required?: boolean
  onClick?: () => void
  onInputChange?: (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => void
}

interface SingleModeProps<T> {
  multiple?: false
  enableSelectAll?: false
  value: Option<T> | null
  isOptionEqualToValue?: AutocompleteProps<Option<T>, boolean, false, false>['isOptionEqualToValue']
}

interface MultipleModeProps<T> {
  multiple: true
  enableSelectAll: boolean
  value: Option<T>[]
  isOptionEqualToValue?: AutocompleteProps<Option<T>, boolean, false, false>['isOptionEqualToValue']
}

export type SimpleSelectTruncateProps<T> = SingleModeProps<T> | MultipleModeProps<T>

export type ControlledProps<T> = (CommonProps<T> & PassedThroughProps<T>) & SimpleSelectTruncateProps<T>

export type SimpleSelectProps<T = number | string> = ControlledProps<T>

export function isMultipleSelectValue<T>(option: Option<T> | Option<T>[] | null): option is Option<T>[] {
  return Array.isArray(option)
}
export function isSingleSelectValue<T>(option: Option<T> | Option<T>[] | null): option is Option<T> {
  return option !== null && !Array.isArray(option)
}

const valueHasBeenSelected = <T,>(value: Option<T> | Option<T>[] | null) => {
  if (value === null) return false
  if ('length' in value) return value.length > 0
  return Boolean(value.value)
}

function SimpleSelect<T>({
  options,
  size,
  required,
  error,
  fieldLabel,
  disabled,
  isLoading,
  multiple,
  enableSelectAll,
  hoveringLabel,
  actionButtons,
  modifiers,
  groupBy,
  renderOption,
  fullWidth,
  onChange,
  value,
  open,
  setOpen,
  loading,
  optionsError,
  onClick,
  onInputChange,
  isOptionEqualToValue,
  variant = 'filled',
  name,
  InputProps,
}: SimpleSelectProps<T>): JSX.Element {
  const classes = useAutocompleteStyles()
  const { formatMessage } = useIntl()

  const shouldShowHovering = variant === 'filled' || hoveringLabel

  const allSelected = enableSelectAll && value.length === options.length
  const filter = createFilterOptions<Option<T>>({
    stringify: option => `${option.label}${option.additionalText ?? ''}`,
  })

  const defaultRenderOption: PassedThroughProps<T>['renderOption'] = (props, option, { selected }) => {
    const selectAllProps = option.value === SELECT_ALL_OPTION_VALUE ? { selected: Boolean(allSelected) } : {}

    return (
      <li {...props}>
        <OptionIcon selected={selected} multiple={multiple} {...selectAllProps} />
        {option?.label || ''}
        <OptionAdornment option={option} />
      </li>
    )
  }

  const handleChange: AutocompleteProps<Option<T>, boolean, false, false>['onChange'] = (_event, value, reason) => {
    if ((enableSelectAll && reason === 'selectOption') || reason === 'removeOption') {
      const selectAllOption = [value].flat().find(option => option?.value === SELECT_ALL_OPTION_VALUE)

      if (selectAllOption) {
        onChange(allSelected ? [] : options)
      } else {
        onChange(value)
      }
    } else {
      onChange(value)
    }
  }

  const PaperComponent: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>> = useCallback(
    ({ children }) => (
      <SelectPaper actionButtons={actionButtons} modifiers={modifiers}>
        {children}
      </SelectPaper>
    ),
    [actionButtons, modifiers],
  )

  return (
    <Autocomplete
      fullWidth={fullWidth}
      size={size}
      groupBy={groupBy}
      clearOnBlur
      popupIcon={<ExpandMoreIcon />}
      classes={{
        clearIndicator: classes.clearIndicator,
        popupIndicator: classes.popupIndicator,
        paper: classes.paper,
      }}
      isOptionEqualToValue={
        isOptionEqualToValue ? isOptionEqualToValue : (option, value) => option.value === value.value
      }
      multiple={multiple}
      disabled={disabled}
      disableCloseOnSelect={multiple}
      open={open}
      onOpen={(): void => {
        setOpen(true)
      }}
      onClose={(): void => {
        setOpen(false)
      }}
      onFocus={(): void => {
        onClick && onClick()
        setOpen(true)
      }}
      loading={loading}
      noOptionsText={optionsError || formatMessage({ id: 'general.noResults' })}
      getOptionLabel={option =>
        option?.label ? capitalize(option.label) : formatMessage({ id: 'form.unnamedResource' })
      }
      options={options}
      getOptionDisabled={option => Boolean(option.disabled)}
      filterOptions={(options, state) => {
        const filtered = [...filter(options, state)]

        if (enableSelectAll) {
          return [
            { label: formatMessage({ id: 'general.selectAll' }), value: SELECT_ALL_OPTION_VALUE as T },
            ...filter(options, state),
          ]
        }

        return filtered
      }}
      renderInput={(params): JSX.Element => (
        <TextField
          variant={hoveringLabel ? 'filled' : variant}
          label={
            isLoading
              ? formatMessage(
                  { id: 'general.loadingField' },
                  {
                    fieldLabel: fieldLabel,
                  },
                )
              : fieldLabel
          }
          required={required && !valueHasBeenSelected(value)}
          hoveringLabel={shouldShowHovering}
          placeholder={shouldShowHovering ? formatMessage({ id: 'textFieldPlaceholders.select' }) : undefined}
          error={Boolean(error)}
          helperText={typeof error === 'string' ? error : error?.message}
          {...params}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
            name,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
                {InputProps?.endAdornment}
              </>
            ),
          }}
          InputLabelProps={{
            variant: hoveringLabel ? 'filled' : variant,
            required: required,
            ...(params.InputLabelProps ?? {}),
          }}
          translate="no"
        />
      )}
      renderTags={(value, getTagProps): JSX.Element[] =>
        value?.map(
          (option, index) =>
            option && (
              <Tooltip key={index} title={option.label} classes={{ tooltipPlacementBottom: classes.tooltip }} arrow>
                <Chip label={option.label} size="small" {...getTagProps({ index })} classes={{ root: classes.chip }} />
              </Tooltip>
            ),
        )
      }
      renderGroup={(params): JSX.Element => (
        <Box key={params.key}>
          <Typography variant="subtitle1" className={classes.autoCompleteGroup}>
            {params.group}
          </Typography>
          {params.children}
        </Box>
      )}
      onChange={handleChange}
      onInputChange={onInputChange}
      value={value}
      renderOption={renderOption || defaultRenderOption}
      limitTags={1}
      PaperComponent={PaperComponent}
    ></Autocomplete>
  )
}

export default SimpleSelect
