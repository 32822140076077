export const colors = [
  '#F6918D',
  '#F6AD63',
  '#6BBFCC',
  '#C94087',
  '#2E22CD',
  '#FF9AD1',
  '#F7CCCA',
  '#7E95F6',
  '#D37745',
  '#AE320F',
  '#234404',
  '#046E17',
  '#D8C324',
  '#1393A1',
  '#1964F4',
  '#3E4B9D',
  '#041678',
  '#CE5EDF',
  '#D1A3F3',
  '#6B2E0C',
  '#07677C',
  '#C0AA82',
]

export const NOT_SELECTED_COLOR = '#D3D3D3'
export const NOT_SELECTED_GROUP_NAME = 'Not selected'
