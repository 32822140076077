import DateDisplay from '@app/src/components/DateDisplay'
import AiSkippedQuestionInfo from '@app/src/components/Drawer/Views/AiSkippedQuestionInfo'
import { ResponseItem } from '@app/src/types/resourceExplorer'
import { ArrowForward, MessageOutlined } from '@mui/icons-material'
import { alpha, Box, Chip, ChipProps, Grid, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface SkippedQuestionCardProps {
  item: ResponseItem
  chipVariant?: ChipProps['variant']
}

const SkippedQuestionCard: React.FC<SkippedQuestionCardProps> = ({ item, chipVariant = 'outlined' }) => {
  const { formatMessage } = useIntl()
  return (
    <Grid container color={({ palette }) => alpha(palette.common.black, 0.6)} spacing={1}>
      <Grid item container spacing={1} alignItems="flex-end">
        <Grid item>
          <Chip label={formatMessage({ id: 'reporting.skipQuestion.skipThisQuestion' })} variant={chipVariant} />
        </Grid>
        <Grid item>
          <Box display="flex" mb={0.5}>
            <ArrowForward color="inherit" />
          </Box>
        </Grid>
        <Grid item>
          <Chip label={formatMessage({ id: `reporting.answerStatus.${item.answerStatus}` })} variant={chipVariant} />
        </Grid>
        {item.estimatedAnswerAt && (
          <>
            <Grid item>
              <Box display="flex" mb={0.5}>
                <ArrowForward color="inherit" />
              </Box>
            </Grid>
            <Grid item>
              <Chip
                label={
                  <>
                    {`${formatMessage({ id: 'reporting.expectedAnswerAt' })} `}
                    <DateDisplay value={item.estimatedAnswerAt} />
                  </>
                }
                variant={chipVariant}
              />
            </Grid>
          </>
        )}
      </Grid>

      {item.isScraped ? (
        <Box ml={1} mt={1} width="100%">
          <AiSkippedQuestionInfo />
        </Box>
      ) : (
        <Grid
          item
          container
          spacing={1}
          my={1}
          color={({ palette }) => (item.comment ? 'inherit' : alpha(palette.common.black, 0.38))}
        >
          <Grid item>
            <MessageOutlined color="inherit" fontSize="small" />
          </Grid>
          <Grid item>
            <Typography color="inherit" variant="subtitle1">
              {formatMessage({
                id: item.comment ? 'reporting.skipQuestion.comment' : 'reporting.skipQuestion.noCommentAdded',
              })}
            </Typography>
          </Grid>
        </Grid>
      )}

      {item.comment && (
        <Grid item>
          <Typography variant="body1" color="text.primary">
            {item.comment}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default SkippedQuestionCard
