import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { Provider, ProviderLinkStatus } from '@app/src/types/organizations'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Stack, Typography } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useDrawer } from '../DrawerContext'

type DrawerViewDeleteProviderProps = {
  provider: Provider
} & Omit<DrawerViewProps, 'title' | 'buttons'>

const FETCH_KEY = FetchKey.ProviderCollection
const DrawerViewDeleteProvider: React.FC<DrawerViewDeleteProviderProps> = ({ provider, ...props }) => {
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useDeleteResource()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { closeDrawer, goBack } = useDrawer()
  const confirm = useConfirm()

  const deleteProvider = () => {
    mutate(
      { url: endpoints.bulkDeleteProviders([provider.id]) },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulResourceDelete' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })

          queryClient.invalidateQueries({
            predicate: query => {
              if (!Array.isArray(query.queryKey)) return query.queryKey === FETCH_KEY
              return query.queryKey.includes(FETCH_KEY) && !query.queryKey.includes(provider.id)
            },
          })
          closeDrawer()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  const handleSubmit = async (): Promise<void> => {
    if (provider.linkStatus === ProviderLinkStatus.Invited && provider.referralContact) {
      confirm({
        description: formatMessage({ id: 'resourceExplorer.provider.delete.deleteWhenReferralContact' }),
        confirmationButtonProps: { variant: 'contained' },
        confirmationText: formatMessage({ id: 'general.deleteAnyway' }),
        buttonOrder: ['confirm', 'cancel'],
      })
        .then(deleteProvider)
        .catch(goBack)
    } else {
      deleteProvider()
    }
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'resourceExplorer.provider.delete.header' })}
      subTitle={provider.name}
      buttons={[
        {
          label: formatMessage({ id: 'resourceCollections.general.delete' }),
          variant: 'contained',
          color: 'error',
          onClick: handleSubmit,
          loading: isLoading,
        },
      ]}
      {...props}
    >
      <Stack px={2}>
        <Typography variant="h5" paragraph>
          {formatMessage({ id: 'resourceExplorer.provider.delete.title' }, { providerName: provider.name })}
        </Typography>
        <Typography>{formatMessage({ id: 'resourceExplorer.provider.delete.description' })}</Typography>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewDeleteProvider
