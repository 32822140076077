import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import { formatDate } from '@app/src/components/Form/ControlledDateField'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import DistributionGraph, { DistributionGraphBar } from '@app/src/pages/Dashboards/DistributionGraph'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { br } from '@app/src/utils/translationMarkup'
import { WF_SYSTEM_USER } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined'
import { Box, Button, darken, Menu, MenuItem, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import React, { useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import StatisticsCard from './StatisticsCard'

const ALL_PERIODS = 'All'

const AccessorReportingProgressCard = () => {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const [selectedPeriod, setSelectedPeriod] = useState<string>(ALL_PERIODS)
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const [isPeriodMenuOpen, setIsPeriodMenuOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { formatPeriodName } = usePeriodName()
  const { inView, ref } = useInView({ triggerOnce: true })

  const today = new Date()
  const formattedTodaysDate = formatDate(today)?.split(' ')?.[0] ?? ''

  const {
    facets: [inquiries],
    isLoading: isInquiriesLoading,
    isError: isInquiriesError,
  } = useFetchFacets({
    key: FetchKey.InquiryStatus,
    shouldShowErrorNotification: false,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'status' }],
    filter: [
      ...(selectedPeriod.length && selectedPeriod !== ALL_PERIODS
        ? [
            {
              name: 'periodName',
              filters: [
                {
                  value: [selectedPeriod],
                  operator: Operators.In,
                },
              ],
            },
          ]
        : []),
    ],
    options: {
      enabled: inView,
    },
  })
  const {
    facets: [overdueInquiries],
    isLoading: isOverdueInquiriesLoading,
    isError: isOverdueInquiriesError,
  } = useFetchFacets({
    key: FetchKey.InquiryStatus,
    shouldShowErrorNotification: false,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'status' }],
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.Requested],
            operator: Operators.In,
          },
        ],
      },
      {
        name: 'deadline',
        filters: [
          {
            value: formattedTodaysDate,
            operator: Operators.LowerThan,
          },
        ],
      },
      ...insertIf(Boolean(selectedPeriod.length) && selectedPeriod !== ALL_PERIODS, {
        name: 'periodName',
        filters: [
          {
            value: [selectedPeriod],
            operator: Operators.In,
          },
        ],
      }),
    ],
    options: {
      enabled: inView,
    },
  })

  const stats = useMemo(() => {
    const approved = inquiries?.find(i => i.value === InquiryStatus.Approved)?.count ?? 0
    const reported = inquiries?.find(i => i.value === InquiryStatus.Submitted)?.count ?? 0
    const notResponded = inquiries?.find(i => i.value === InquiryStatus.Requested)?.count ?? 0
    const overdue = overdueInquiries?.find(i => i.value === InquiryStatus.Requested)?.count ?? 0
    const notRespondedDisplay = Math.max(notResponded - overdue, 0)
    const correctionNeededRequests = inquiries?.find(i => i.value === InquiryStatus.CorrectionNeeded)?.count ?? 0
    const noReportData = reported === 0 && notResponded === 0 && correctionNeededRequests === 0

    return {
      approved,
      reported,
      notResponded,
      overdue,
      notRespondedDisplay,
      correctionNeededRequests,
      noReportData,
    }
  }, [inquiries, overdueInquiries])

  const {
    facets: [requestedPeriods],
    isLoading: requestedPeriodsIsLoading,
    isError: requestedPeriodsIsError,
  } = useFetchFacets({
    key: [FetchKey.Inquiry, 'periods'],
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'periodName', isEnum: true }],
    filter: [],
    options: {
      enabled: inView,
    },
    shouldShowErrorNotification: false,
  })

  const isLoading = isInquiriesLoading || requestedPeriodsIsLoading || isOverdueInquiriesLoading
  const isError = isInquiriesError || requestedPeriodsIsError || isOverdueInquiriesError

  const availablePeriods = requestedPeriods?.map(facet => facet.label).sort((a, b) => parseInt(b) - parseInt(a))

  const emptyState = !isLoading && !requestedPeriods?.length && stats.noReportData
  const noData = Boolean(requestedPeriods?.length) && stats.noReportData

  const commonLinkFilters = [
    ...insertIf(Boolean(requestedPeriods?.length) && selectedPeriod !== ALL_PERIODS, {
      name: 'periodName',
      value: [selectedPeriod],
      operator: Operators.In,
    }),
    {
      name: 'request.creatorUserId',
      value: WF_SYSTEM_USER,
      operator: Operators.NotEqualTo,
    },
  ]

  const createStatusFilterLink = (status: InquiryStatus) => {
    return stringifyQueryFilters({
      url: generatePath(paths.manageRequest, {
        view: ViewTypeName.Requests,
      }),
      queryParams: {
        filters: [
          ...commonLinkFilters,
          {
            name: 'status',
            value: [status],
            operator: Operators.In,
          },
        ],
      },
      includeCurrentFilters: true,
    })
  }

  const bars: DistributionGraphBar[] = [
    ...insertIf(stats.approved > 0, {
      name: formatMessage({ id: 'dashboard.sourcing.reportingProgress.approved' }),
      link: createStatusFilterLink(InquiryStatus.Approved),
      color: darken(palette.success.dark, 0.3),
      count: stats.approved,
      label: formatMessage({ id: 'dashboard.sourcing.reportingProgress.approved' }),
      detailedLabel: formatMessage(
        { id: 'dashboard.sourcing.reportingProgress.approvedWithCount' },
        { count: stats.approved },
      ),
    }),
    ...insertIf(stats.reported > 0, {
      name: formatMessage({ id: 'dashboard.sourcing.reportingProgress.reported' }),
      link: createStatusFilterLink(InquiryStatus.Submitted),
      color: palette.visualization[1],
      count: stats.reported,
      label: formatMessage({ id: 'dashboard.sourcing.reportingProgress.reported' }),
      detailedLabel: formatMessage(
        { id: 'dashboard.sourcing.reportingProgress.reportedWithCount' },
        { count: stats.reported },
      ),
    }),
    ...insertIf(stats.correctionNeededRequests > 0, {
      name: formatMessage({ id: 'dashboard.sourcing.reportingProgress.correctionNeeded' }),
      link: createStatusFilterLink(InquiryStatus.CorrectionNeeded),
      color: palette.warning.dark,
      count: stats.correctionNeededRequests,
      label: formatMessage({ id: 'dashboard.sourcing.reportingProgress.correctionNeeded' }),
      detailedLabel: formatMessage(
        { id: 'dashboard.sourcing.reportingProgress.correctionNeededWithCount' },
        { count: stats.correctionNeededRequests },
      ),
    }),
    ...insertIf(stats.notRespondedDisplay > 0, {
      name: formatMessage({ id: 'dashboard.sourcing.reportingProgress.notReported' }),
      link: createStatusFilterLink(InquiryStatus.Requested),
      color: palette.grey[300],
      count: stats.notRespondedDisplay,
      label: formatMessage({ id: 'dashboard.sourcing.reportingProgress.notReported' }),
      detailedLabel: formatMessage(
        { id: 'dashboard.sourcing.reportingProgress.notReportedWithCount' },
        { count: stats.notResponded },
      ),
    }),
    ...insertIf(stats.overdue > 0, {
      name: formatMessage({ id: 'dashboard.sourcing.reportingProgress.overdue' }),
      link: stringifyQueryFilters({
        url: generatePath(paths.manageRequest, {
          view: ViewTypeName.Requests,
        }),
        queryParams: {
          filters: [
            ...commonLinkFilters,
            { name: 'status', value: [InquiryStatus.Requested], operator: Operators.In },
            {
              name: 'deadline',
              value: formattedTodaysDate,
              operator: Operators.LowerThan,
              uniqueId: 'deadlineTo',
            },
          ],
        },
        includeCurrentFilters: true,
      }),
      color: palette.error.dark,
      count: stats.overdue,
      label: formatMessage({ id: 'dashboard.sourcing.reportingProgress.overdue' }),
      detailedLabel: formatMessage(
        { id: 'dashboard.sourcing.reportingProgress.overdueWithCount' },
        { count: stats.overdue },
      ),
    }),
  ]

  return (
    <Box ref={ref}>
      <StatisticsCard
        title={formatMessage({ id: 'dashboard.sourcing.reportingProgress.title' })}
        helperText={formatMessage(
          { id: 'dashboard.sourcing.reportingProgress.helperText' },
          { br, b: (chunks: React.ReactNode) => <b>{chunks}</b> },
        )}
        isError={isError}
        action={
          !emptyState && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography color="primary" variant="body1">
                {formatMessage({ id: 'schemas.request.periodDisplayName' })}
              </Typography>
              <Box>
                <Button
                  endIcon={<ArrowDropDownIcon />}
                  size="small"
                  onClick={() => setIsPeriodMenuOpen(true)}
                  ref={anchorRef}
                >
                  {formatPeriodName(selectedPeriod)}
                </Button>
                <Menu
                  open={isPeriodMenuOpen}
                  anchorEl={anchorRef.current}
                  anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                  elevation={1}
                  onClose={() => setIsPeriodMenuOpen(false)}
                >
                  <MenuItem
                    onClick={() => {
                      setIsPeriodMenuOpen(false)
                      setSelectedPeriod(ALL_PERIODS)
                    }}
                  >
                    {formatMessage({ id: `dashboard.sourcing.companyStatistics.all` })}
                  </MenuItem>
                  {availablePeriods
                    ?.sort((a, b) => {
                      return parseInt(b) - parseInt(a)
                    })
                    .map(period => (
                      <MenuItem
                        key={period}
                        onClick={() => {
                          setIsPeriodMenuOpen(false)
                          setSelectedPeriod(period)
                        }}
                      >
                        {formatPeriodName(period)}
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
            </Stack>
          )
        }
        loading={{
          isLoading,
          skeleton: (
            <Stack width="100%">
              <Skeleton width="100%" height={50} sx={{ my: 1 }} />

              <Stack direction="row" spacing={2}>
                <Skeleton width="10%" height={32} variant="rounded" />
                <Skeleton width="10%" height={32} variant="rounded" />
                <Skeleton width="10%" height={32} variant="rounded" />
              </Stack>
            </Stack>
          ),
        }}
      >
        {emptyState ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={4}>
            <EmptyState
              title={formatMessage({ id: 'dashboard.sourcing.reportingProgress.emptyState.title' })}
              description={formatMessage({
                id: 'dashboard.sourcing.reportingProgress.emptyState.description',
              })}
              iconComponent={SignalCellularAltOutlinedIcon}
            />
          </Box>
        ) : noData ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={4}>
            <EmptyState
              title={formatMessage({ id: 'dashboard.sourcing.reportingProgress.noData.title' })}
              description={formatMessage({
                id: 'dashboard.sourcing.reportingProgress.noData.description',
              })}
              iconComponent={SignalCellularAltOutlinedIcon}
            />
          </Box>
        ) : (
          <DistributionGraph bars={bars} title={formatMessage({ id: 'dashboard.sourcing.reportingProgress.title' })} />
        )}
      </StatisticsCard>
    </Box>
  )
}

export default AccessorReportingProgressCard
