import DrawerView from '@app/src/components/Drawer/DrawerView'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import ScrapedAnswerCitations from '@app/src/pages/Questionnaire/Accessor/Report/ScrapedAnswerCitations'
import AnswerValue from '@app/src/pages/Questionnaire/Provider/Report/AnswerValue'
import { RequestItem, ResponseItem } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { AutoAwesomeOutlined } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

interface ScrapedAiSuggestionDrawerProps {
  previousAnswer: ResponseItem
  requestItem: RequestItem
  questionType: string
  onApply: () => Promise<void>
  closeDrawer: () => void
  sectionNumber: number
  itemNumber: number
}

const ScrapedAiSuggestionDrawer: React.FC<ScrapedAiSuggestionDrawerProps> = ({
  previousAnswer,
  requestItem,
  questionType,
  onApply,
  closeDrawer,
  sectionNumber,
  itemNumber,
}) => {
  const { formatMessage } = useIntl()
  const [isApplying, setIsApplying] = useState(false)
  const { trackEvent } = useAmplitude()

  return (
    <DrawerView
      title={formatMessage({ id: 'suggestedAnswers.suggestion' })}
      stackButtons
      buttons={[
        {
          label: formatMessage({ id: 'suggestedAnswers.applySuggestion' }),
          variant: 'contained',
          loading: isApplying,
          onClick: () => {
            trackEvent({
              name: AmplitudeTrackingEvents.Provider.Questionnaire.PreviousSuggestedAnswers,
              eventProps: {
                action: 'Accepted',
              },
            })

            setIsApplying(true)
            closeDrawer()
            onApply().finally(() => setIsApplying(false))
          },
        },
      ]}
    >
      <Stack mx={3} height="100%" id="asdasdasd">
        <Stack flexGrow={1}>
          <Stack direction="row" spacing={3} mb={4}>
            <Typography variant="subtitle1">
              {sectionNumber}.{itemNumber}
            </Typography>
            <Typography variant="subtitle2">{requestItem.description}</Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <AutoAwesomeOutlined sx={({ palette }) => ({ color: palette.communication.dark })} />
            <AnswerValue item={previousAnswer} questionType={questionType} unitSymbol={requestItem.unit?.symbol} />
          </Stack>
          <ScrapedAnswerCitations citations={previousAnswer.scrapedAnswer?.citations ?? []} />
        </Stack>
        <Typography mt={2}>{formatMessage({ id: 'suggestedAnswers.disclaimer' })}</Typography>
      </Stack>
    </DrawerView>
  )
}
export default ScrapedAiSuggestionDrawer
