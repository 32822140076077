import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import Table, { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { GroupBySelectorContextProps, useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import useOrganizationCurrency from '@app/src/hooks/organizationCurrency'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import DataInsightsModalAdditionalTableColumn from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataInsightsModalAdditionalTableColumn'
import InquiryHeader from '@app/src/pages/ResourceCollection/Collections/Request/AccessorInquiryHeader'
import {
  AnswersGroupingType,
  removeQuestionFilters,
  transformFilterNamesForInquiries,
} from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import {
  FilterGroup,
  Operators,
  RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
} from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Provider, Spend } from '@app/src/types/organizations'
import { Inquiry, InquiryStatus, ResponseItem } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { SpendClassification } from '@app/src/wf-constants'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { dataHubAllowedFilters } from '.'
import { AccessorInquiryRow } from '../../Request/AccessorInquiryRow'
import ResponseItemCompanyHeader from '../AllAnswers/ResponseItemCompanyHeader'
import ResponseItemCompanyRow from '../AllAnswers/ResponseItemCompanyRow'
import { SummaryChartDataTypes } from './Charts/PeriodChartsConfigs/usePeriodSummaryChartConfigs'
import { useDataInsightsModal, UserSelection } from './DataInsightsModalContext'

type GroupTableHeader = {
  primary?: string
  secondary?: string
}

const groupTableHeaderPerGroupingTypeObject: Record<AnswersGroupingType, GroupTableHeader> = {
  [AnswersGroupingType.SpendClassification]: { primary: 'schemas.supplier.annualSpend.spend' },
  [AnswersGroupingType.CustomCategory]: { primary: 'schemas.supplier.customCategories' },
  [AnswersGroupingType.PeriodName]: {},
  [AnswersGroupingType.StandardCategory]: {},
  [AnswersGroupingType.ActorType]: { primary: 'dashboard.sourcing.companyStatistics.actorTypes' },
  [AnswersGroupingType.Country]: { primary: 'resourceCollections.general.country' },
  [AnswersGroupingType.AnswerClassification]: {},
  [AnswersGroupingType.CountryRisk]: {
    primary: 'resourceCollections.general.country',
    secondary: 'general.countryRisk',
  },
}

export const getStandardCategoryHeaderTranslationKey = (groupByValue: keyof Provider): string => {
  switch (groupByValue) {
    case 'providerApprovalStatus':
      return 'schemas.provider.providerApprovalStatus'
    case 'activityStatus':
      return 'schemas.provider.activityStatus'
    case 'finalRiskRating':
      return 'schemas.provider.finalRiskRating'
    case 'priority':
      return 'schemas.provider.priority'
    case 'supplierUsage':
      return 'schemas.provider.supplierUsage'
    default:
      return ''
  }
}

const renderAdditionalColumn = (
  groupBy: AnswersGroupingType,
  groupByValue: string,
  groupTableHeaderPerGroupingTypeObject: Record<AnswersGroupingType, { primary?: string; secondary?: string }>,
  additionalColumnWidth: TableCellWidth,
  formatMessage: (message: { id: string }) => string,
) => {
  if (groupBy === AnswersGroupingType.StandardCategory) {
    const translationKey = getStandardCategoryHeaderTranslationKey(groupByValue as keyof Provider)
    return <TableHeaderCell label={formatMessage({ id: translationKey })} minWidth={additionalColumnWidth} />
  }

  const headerConfig = groupTableHeaderPerGroupingTypeObject[groupBy]
  if (!headerConfig?.primary) {
    return null
  }

  return (
    <>
      <TableHeaderCell label={formatMessage({ id: headerConfig.primary })} minWidth={additionalColumnWidth} />
      {headerConfig.secondary && (
        <TableHeaderCell label={formatMessage({ id: headerConfig.secondary })} minWidth={additionalColumnWidth} />
      )}
    </>
  )
}

const getGroupingFilterPropertyPath = (groupBy: string, groupByValue: string): string | undefined => {
  switch (groupBy) {
    case AnswersGroupingType.Country:
      return 'countryId'
    case AnswersGroupingType.CustomCategory:
      return 'categoryOptions.id'
    case AnswersGroupingType.StandardCategory:
      return groupByValue
    case AnswersGroupingType.ActorType:
      return 'mappingNodes.actorTypeId'
    case AnswersGroupingType.CountryRisk:
      return 'country.risks.status'
    default:
      return undefined
  }
}

const getProviderPropertyFilters = (
  providerPropertyPath: string,
  groupingSelectorContext: GroupBySelectorContextProps,
  periodNames?: string[],
  spendClassifications?: string[],
  groupId?: string,
  providerIdsWithSpend?: number[],
): FilterGroup[] => {
  const { groupBy, groupByValue } = groupingSelectorContext ?? {}
  const groupingFilterPropertyPath = getGroupingFilterPropertyPath(groupBy, groupByValue)

  const groupIdFilter = insertIf(Boolean(groupingFilterPropertyPath && groupId), {
    name: `${providerPropertyPath}.${groupingFilterPropertyPath}`,
    filters: [
      {
        value: groupId,
        operator: Operators.EqualTo,
      },
    ],
  })

  switch (groupBy) {
    case AnswersGroupingType.SpendClassification:
      return [
        ...groupIdFilter,
        ...insertIf(!spendClassifications?.includes(SpendClassification.NotSet), {
          name: `${providerPropertyPath}.spends.spendClassification`,
          filters: [
            {
              value: spendClassifications,
              operator: Operators.In,
            },
          ],
        }),
        ...insertIf(Boolean(periodNames && !spendClassifications?.includes(SpendClassification.NotSet)), {
          name: `${providerPropertyPath}.spends.periodName`,
          filters: [
            {
              value: periodNames,
              operator: Operators.In,
            },
          ],
        }),
        ...insertIf(
          Boolean(
            providerIdsWithSpend?.length &&
              spendClassifications?.length === 1 &&
              spendClassifications[0] === SpendClassification.NotSet,
          ),
          {
            name: `${providerPropertyPath}.id`,
            filters: [
              {
                value: providerIdsWithSpend,
                operator: Operators.NotIn,
              },
            ],
          },
        ),
      ]
    case AnswersGroupingType.CustomCategory:
      return [
        ...groupIdFilter,
        {
          name: `${providerPropertyPath}.categoryOptions.categoryId`,
          filters: [
            {
              value: groupByValue,
              operator: Operators.EqualTo,
            },
          ],
        },
      ]
    case AnswersGroupingType.ActorType:
      return [
        ...groupIdFilter,
        {
          name: `${providerPropertyPath}.mappingNodes.deletedAt`,
          filters: [
            {
              operator: Operators.IsNull,
            },
          ],
        },
        {
          name: `${providerPropertyPath}.mappingNodes.actorType.industry`,
          filters: [
            {
              value: groupByValue,
              operator: Operators.EqualTo,
            },
          ],
        },
      ]
    default:
      return [...groupIdFilter]
  }
}

const filterMatchingOrNotSetSpends = (
  periodNames: string[] | undefined,
  spendClassifications: SpendClassification[] | undefined,
  spends: Spend[] | undefined,
): { isValid: boolean; spends: Spend[] } => {
  if (!spends?.length) return { isValid: true, spends: [] }
  if (periodNames?.length) {
    spends = spends?.filter(spend => periodNames.includes(spend.periodName))
  }

  if (!spends?.length) return { isValid: true, spends: [] }

  const validSpends = spends.filter(spend =>
    spendClassifications?.includes(spend.spendClassification ?? SpendClassification.NotSet),
  )
  return { isValid: Boolean(validSpends.length), spends: validSpends }
}

const getResponseItemFilters = (
  questionId?: number,
  selection?: UserSelection,
  cleanFilters?: FilterGroup[],
  periodNames?: string[],
): FilterGroup[] => {
  const hasFilterForCompaniesAnswered = selection?.summaryType?.includes(SummaryChartDataTypes.CompaniesAnswered)
  const hasFilterForWeDontHaveThis = selection?.summaryType?.includes(
    SummaryChartDataTypes.CompaniesAnsweredDontHaveThis,
  )

  return [
    ...(cleanFilters ?? []),
    RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
    ...insertIf(!(hasFilterForWeDontHaveThis && hasFilterForCompaniesAnswered), {
      name: 'cannotAnswer',
      filters: [
        {
          value: hasFilterForWeDontHaveThis ?? false,
          operator: Operators.EqualTo,
        },
      ],
    }),
    ...insertIf(Boolean(questionId), {
      name: 'requestItem.questionId',
      filters: [
        {
          value: questionId,
          operator: Operators.EqualTo,
        },
      ],
    }),
    ...insertIf(Boolean(selection?.answer?.length) && !selection?.summaryType, {
      name: 'answer',
      filters: [
        {
          value: selection?.answer,
          operator: Operators.In,
        },
      ],
    }),
    ...insertIf(Boolean(periodNames?.length), {
      name: 'response.request.periodName',
      filters: [
        {
          value: periodNames,
          operator: Operators.In,
        },
      ],
    }),
  ]
}

const DataInsightsModalTable: React.FC = () => {
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const { formatMessage } = useIntl()
  const { statistics, userSelection, periodNames, spendClassifications, groupId } = useDataInsightsModal()
  const userFilters = useGetApiQueryFilters(dataHubAllowedFilters)

  const cleanFilters = useMemo(() => removeQuestionFilters(userFilters), [userFilters])
  const renamedFiltersForInquiries = useMemo(() => transformFilterNamesForInquiries(cleanFilters), [cleanFilters])

  const isCompaniesNotAnswered =
    userSelection?.summaryType?.includes(SummaryChartDataTypes.CompaniesNotAnswered) ?? false

  const groupingSelectorContext = useGroupBySelector()
  const { groupBy, groupByValue } = groupingSelectorContext
  const isGroupBySpendClassification = groupBy === AnswersGroupingType.SpendClassification
  const isGroupBySpendAndSpendIncludesNotSet =
    isGroupBySpendClassification && spendClassifications?.includes(SpendClassification.NotSet)
  const { organizationCurrency } = useOrganizationCurrency({ enabled: isGroupBySpendClassification })

  // If spendClassifications includes "NotSet" we need to fetch providers with spend
  // using a facet call, to filter out providers that don't have spend in the collection call.
  const {
    facets: [providersWithSpendFacet],
    isFetched: providersWithSpendFacetIsFetched,
  } = useFetchFacets({
    key: FetchKey.Provider,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'id', isEnum: true }],
    filter: [
      {
        name: 'spends.spendClassification',
        filters: [
          {
            value:
              spendClassifications?.length === 1
                ? [SpendClassification.Low, SpendClassification.Medium, SpendClassification.High]
                : spendClassifications?.filter(x => x !== SpendClassification.NotSet),
            operator: Operators.In,
          },
        ],
      },
      {
        name: 'spends.periodName',
        filters: [
          {
            value: periodNames,
            operator: Operators.In,
          },
        ],
      },
    ],
    options: {
      staleTime: 60000,
      enabled: isGroupBySpendAndSpendIncludesNotSet,
    },
  })

  const providerIdsWithSpend = useMemo(
    () =>
      isGroupBySpendAndSpendIncludesNotSet ? providersWithSpendFacet?.map(facet => Number(facet.value)) : undefined,
    [isGroupBySpendAndSpendIncludesNotSet, providersWithSpendFacet],
  )

  const responseItemFilters = useMemo(
    () => [
      ...getResponseItemFilters(statistics?.id, userSelection, cleanFilters, periodNames),
      ...getProviderPropertyFilters(
        'response.request.subscriptions.target',
        groupingSelectorContext,
        periodNames,
        spendClassifications,
        groupId,
        providerIdsWithSpend,
      ),
    ],
    [
      statistics?.id,
      userSelection,
      cleanFilters,
      periodNames,
      groupingSelectorContext,
      spendClassifications,
      groupId,
      providerIdsWithSpend,
    ],
  )

  const responseItemsPayload = {
    filter: responseItemFilters,
    sort: sorting,
    include: [
      'requestItem',
      'response.request',
      'verifications',
      'creatorOrganization',
      'response.request.creatorOrganization',
      'response.request.responderOrganization',
      'response.request.subscriptions.target',
      'response.request.subscriptions.target.country.name',
      'requestItem.unit',
      'requestItem.questionType',
      'requestItem.section',
      'files',
      'flagRule.suggestedRiskGuidance',
      'response.request.target.organization',
      isGroupBySpendClassification && 'response.request.subscriptions.target.spends',
      groupBy === AnswersGroupingType.CustomCategory && 'response.request.subscriptions.target.categoryOptions',
      groupBy === AnswersGroupingType.ActorType && 'response.request.subscriptions.target.mappingNodes.actorType',
      groupBy === AnswersGroupingType.CountryRisk && 'response.request.subscriptions.target.country.risks.riskType',
    ].filter(Boolean) as string[],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const inquiriesPayload = useMemo(
    () => ({
      include: [
        'creatorOrganization',
        'request.responses',
        'request.responses.verifications',
        'template',
        'template.image',
        'provider.organization',
        isGroupBySpendClassification && 'provider.spends',
        groupBy === AnswersGroupingType.Country && 'provider.country',
        groupBy === AnswersGroupingType.CustomCategory && 'provider.categoryOptions',
        groupBy === AnswersGroupingType.ActorType && 'provider.mappingNodes.actorType',
        groupBy === AnswersGroupingType.CountryRisk && 'provider.country.risks',
      ].filter(Boolean) as string[],
      sort: sorting,
      filter: [
        ...renamedFiltersForInquiries,
        {
          name: 'status',
          filters: [
            {
              value: InquiryStatus.Requested,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'template.sections.questions.id',
          filters: [
            {
              operator: Operators.EqualTo,
              value: statistics?.id,
            },
          ],
        },
        {
          name: 'periodName',
          filters: [
            {
              operator: Operators.In,
              value: periodNames,
            },
          ],
        },
        ...getProviderPropertyFilters(
          'provider',
          groupingSelectorContext,
          periodNames,
          spendClassifications,
          groupId,
          providerIdsWithSpend,
        ),
      ],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    }),
    [
      renamedFiltersForInquiries,
      sorting,
      statistics?.id,
      periodNames,
      groupingSelectorContext,
      spendClassifications,
      groupId,
      providerIdsWithSpend,
      page,
      pageSize,
    ],
  )

  const {
    items: responseItems,
    count: responseItemsCount,
    isFetched: isResponseItemsFetched,
    isError: isResponseItemsError,
  } = useFetchCollectionWithPost<ResponseItem>({
    key: FetchKey.Answer,
    endpoint: endpoints.responseItemsCollection,
    payload: responseItemsPayload,
    options: {
      staleTime: 60000,
      enabled:
        Boolean(statistics) &&
        !isCompaniesNotAnswered &&
        (!isGroupBySpendAndSpendIncludesNotSet || providersWithSpendFacetIsFetched),
    },
  })

  const {
    items: inquiries,
    count: inquiriesCount,
    isFetched: isInquiriesFetched,
    isError: isInquiriesError,
  } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.Inquiry,
    endpoint: endpoints.inquiriesCollection,
    payload: inquiriesPayload,
    options: {
      staleTime: 60000,
      enabled:
        Boolean(statistics) &&
        isCompaniesNotAnswered &&
        (!isGroupBySpendAndSpendIncludesNotSet || providersWithSpendFacetIsFetched),
    },
  })

  // When spendClassifications includes "NotSet" we need to perform additional filtering in the frontend because
  // the backend doesn't support filtering by "NotSet" since there is no spend to filter on.

  const responseItemsWithFilteredSpend = useMemo(() => {
    if (isCompaniesNotAnswered) return []
    if (!isGroupBySpendClassification) return responseItems

    return responseItems.filter(item => {
      const { isValid, spends } = filterMatchingOrNotSetSpends(
        periodNames,
        spendClassifications,
        item.response.request.targetAliasObject.spends,
      )
      if (!isValid) return false // Filter out response items that don't have matching spends

      item.response.request.targetAliasObject.spends = spends // Assign spends to include only matching spends
      return true
    })
  }, [isCompaniesNotAnswered, responseItems, isGroupBySpendClassification, spendClassifications, periodNames])

  const inquiriesWithFilteredSpend = useMemo(() => {
    if (!isCompaniesNotAnswered) return []
    if (!isGroupBySpendClassification) return inquiries

    return inquiries.filter(inquiry => {
      const { isValid, spends } = filterMatchingOrNotSetSpends(
        periodNames,
        spendClassifications,
        inquiry.provider.spends,
      )
      if (!isValid) return false // Filter out inquiries that don't have matching spends

      inquiry.provider.spends = spends // Assign spends to include only matching spends
      return true
    })
  }, [isCompaniesNotAnswered, inquiries, isGroupBySpendClassification, spendClassifications, periodNames])

  const additionalColumnWidth = useMemo(() => {
    switch (groupBy) {
      case AnswersGroupingType.SpendClassification:
        return TableCellWidth.SMALL
      case AnswersGroupingType.ActorType:
        if (!groupId) return TableCellWidth.LARGE
        break
      default:
        return TableCellWidth.MEDIUM
    }
  }, [groupBy, groupId])

  if (isCompaniesNotAnswered) {
    return (
      <>
        <Table<Inquiry>
          RowComponent={({ row: inquiry }) => (
            <AccessorInquiryRow
              row={inquiry}
              additionalColumn={
                <DataInsightsModalAdditionalTableColumn
                  provider={inquiry.provider}
                  groupBy={groupBy}
                  groupByValue={groupByValue}
                  groupId={groupId}
                  organizationCurrency={organizationCurrency}
                />
              }
            />
          )}
          HeaderComponent={() => (
            <InquiryHeader
              toggleSorting={toggleSorting}
              activeSorting={sorting}
              additionalColumn={renderAdditionalColumn(
                groupBy,
                groupByValue,
                groupTableHeaderPerGroupingTypeObject,
                additionalColumnWidth ?? TableCellWidth.MEDIUM,
                formatMessage,
              )}
            />
          )}
          data={inquiriesWithFilteredSpend}
          isLoading={!isInquiriesFetched}
          count={inquiriesCount}
          isError={isInquiriesError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </>
    )
  }

  return (
    <>
      <Table<ResponseItem>
        RowComponent={({ row: responseItem }) => (
          <ResponseItemCompanyRow
            row={responseItem}
            disableRowSelection
            additionalColumn={
              <DataInsightsModalAdditionalTableColumn
                provider={responseItem.response?.request?.targetAliasObject}
                groupBy={groupBy}
                groupByValue={groupByValue}
                groupId={groupId}
                organizationCurrency={organizationCurrency}
              />
            }
          />
        )}
        HeaderComponent={() => (
          <ResponseItemCompanyHeader
            toggleSorting={toggleSorting}
            activeSorting={sorting}
            disableRowSelection
            additionalColumn={renderAdditionalColumn(
              groupBy,
              groupByValue,
              groupTableHeaderPerGroupingTypeObject,
              additionalColumnWidth ?? TableCellWidth.MEDIUM,
              formatMessage,
            )}
          />
        )}
        data={responseItemsWithFilteredSpend}
        isLoading={!isResponseItemsFetched}
        count={responseItemsCount}
        isError={isResponseItemsError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </>
  )
}

export default DataInsightsModalTable
