import _EvaluationsSchema from '@app/src/schemas/evaluation'
import _InquirySchema from '@app/src/schemas/inquiry'
import _OrganizationSchema from '@app/src/schemas/organization'
import _ReportsSchema from '@app/src/schemas/report'
import _RiskScreeningSchema from '@app/src/schemas/risk-screening'
import { Schema } from '@app/src/types/schemas'
import { capitalize } from '@app/src/utils/helpers'

export const OrganizationSchema = _OrganizationSchema
export const InquirySchema = _InquirySchema
export const EvaluationSchema = _EvaluationsSchema
export const ReportSchema = _ReportsSchema
export const RiskScreeningSchema = _RiskScreeningSchema

const Schemas: { [schema: string]: Schema } = {
  OrganizationSchema,
  RequestSchema: InquirySchema,
  EvaluationSchema,
  ReportSchema,
  RiskScreeningSchema,
}

export const getSchemaByResourceType = (type: string, solutionType?: string): Schema | undefined => {
  return Schemas[
    `${type
      .split('-')
      .map(part => capitalize(part))
      .join('')}Schema`
  ]
}

export default Schemas
