import { createContext, useContext } from 'react'
import { Provider } from '../types/organizations'

export enum ProvidersModalPurpose {
  InitialInvitation = 'InitialInvitation',
  ExtraReminder = 'ExtraReminder',
  ScheduleScreening = 'ScheduleScreening',
}

export interface ProvidersModalContextProps {
  isConnectDialogOpen: boolean
  handleCloseConnectDialog: () => void
  handleOpenConnectDialog: (providers: Provider[], modalPurpose: ProvidersModalPurpose) => void
  providersToConnect: Provider[]
  modalPurpose: ProvidersModalPurpose
  updateProviderToConnect: (provider: Provider) => void
}

const ProvidersModalContext = createContext<undefined | ProvidersModalContextProps>(undefined)

export const useProvidersModal = (): ProvidersModalContextProps => {
  const context = useContext(ProvidersModalContext)
  if (context === undefined) {
    throw new Error('useProvidersModal must be used within ProvidersModalContextProvider')
  }
  return context
}

export default ProvidersModalContext
