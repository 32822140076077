import { AutoAwesomeOutlined, Close } from '@mui/icons-material'
import { Alert, Box, Button, IconButton } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { palette } from '../theme/palette'
import AiScreeningInfoDialog from './AiScreeningInfoDialog'

const SESSION_STORAGE_KEY_SCREENING_ON = 'screening-dialog-hidden-on'
const SESSION_STORAGE_KEY_SCREENING_OFF = 'screening-dialog-hidden-off'

const AiScreeningInfoBar: React.FC = () => {
  const { formatMessage } = useIntl()
  const [isScreeningOnVisible, setIsScreeningOnVisible] = useState(false)
  const [isScreeningOffVisible, setIsScreeningOffVisible] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const { aiScraping } = useFlags()

  useEffect(() => {
    if (aiScraping) {
      const shouldHide = sessionStorage.getItem(SESSION_STORAGE_KEY_SCREENING_ON) === 'true'
      setIsScreeningOnVisible(!shouldHide)
      sessionStorage.removeItem(SESSION_STORAGE_KEY_SCREENING_OFF)
      setIsScreeningOffVisible(false)
    } else {
      sessionStorage.removeItem(SESSION_STORAGE_KEY_SCREENING_ON)
      setIsScreeningOnVisible(false)
      const shouldHide = sessionStorage.getItem(SESSION_STORAGE_KEY_SCREENING_OFF) === 'true'
      setIsScreeningOffVisible(!shouldHide)
    }
  }, [aiScraping])

  const closeInfoBar = () => {
    if (aiScraping) {
      setIsScreeningOnVisible(false)
      sessionStorage.setItem(SESSION_STORAGE_KEY_SCREENING_ON, 'true')
    } else {
      setIsScreeningOffVisible(false)
      sessionStorage.setItem(SESSION_STORAGE_KEY_SCREENING_OFF, 'true')
    }
  }

  const handleOpenInfoDialog = () => setDialogOpen(true)
  const handleCloseInfoDialog = () => setDialogOpen(false)

  if (!isScreeningOnVisible && !isScreeningOffVisible) return null

  return (
    <>
      <Alert
        sx={{ borderRadius: 0, backgroundColor: palette.communication.main }}
        action={
          <Box>
            <Button sx={{ mr: 2.5 }} onClick={handleOpenInfoDialog} color="inherit">
              {aiScraping ? formatMessage({ id: 'general.learnMore' }) : formatMessage({ id: 'general.getAccess' })}
            </Button>
            <IconButton size="small" onClick={closeInfoBar} color="inherit">
              <Close fontSize="small" />
            </IconButton>
          </Box>
        }
        icon={<AutoAwesomeOutlined />}
      >
        {aiScraping
          ? formatMessage({ id: 'schemas.aiScreeningInfo.bannerTitleOn' })
          : formatMessage({ id: 'schemas.aiScreeningInfo.bannerTitleOff' })}
      </Alert>

      <AiScreeningInfoDialog isScraped={aiScraping} open={dialogOpen} onClose={handleCloseInfoDialog} />
    </>
  )
}

export default AiScreeningInfoBar
