import CheckboxCell from '@app/src/components/Table/Cells/CheckboxCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import ExternalVerificationStatusCell from '@app/src/components/Table/Cells/ExternalVerificationStatusCell'
import PeriodNameCell from '@app/src/components/Table/Cells/PeriodNameCell'
import RequestCell from '@app/src/components/Table/Cells/RequestCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { useAccount } from '@app/src/context/AccountContext'
import { ExternalVerificationStatus, Response } from '@app/src/types/resourceExplorer'
import { reporting } from '@app/src/wf-constants/paths'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

interface ScrapedResponseRow {
  row: Response
  onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>, inquiry: Response) => void
  selectedResponsesIds?: Array<number>
}

export const AccessorScrapedResponseRow: React.FC<ScrapedResponseRow> = ({
  row: response,
  selectedResponsesIds,
  onCheckboxChange,
}) => {
  const history = useHistory()
  const { account } = useAccount()
  const { formatMessage } = useIntl()

  const inquiry = response?.request?.inquiries?.find(
    inquiry => inquiry.creatorOrganizationId === account?.organization?.id,
  )

  const isChecked = Boolean(selectedResponsesIds?.find(responseId => responseId === response.id))

  return (
    <TableRow selected={isChecked}>
      {onCheckboxChange && <CheckboxCell checked={isChecked} onChange={e => onCheckboxChange(e, response)} />}
      <ExternalVerificationStatusCell
        responseStatus={response?.verifications?.[0]?.externalVerificationStatus ?? ExternalVerificationStatus.NotSet}
        draftStatus={response?.draftStatus}
        inquiryStatus={inquiry?.status}
      />
      <RequestCell
        title={response.request.title}
        imageUrl={response.request?.template?.image?.url}
        isScraped={response.isScraped}
        onClick={() =>
          history.push({ pathname: reporting, search: `requestId=${response.request.id}&responseId=${response.id}` })
        }
      />
      <PeriodNameCell periodName={response.request?.periodName} />
      <CompanyCell
        company={{ ...response?.request?.targetAliasObject, organization: response?.request?.targetObject }}
        drawer
      />
      <DateCell value={response.createdAt} />
      <ValueCell
        value={
          response?.isScraped
            ? formatMessage({ id: 'general.aiScreening' })
            : formatMessage({ id: 'general.selfReported' })
        }
      />
    </TableRow>
  )
}

export default AccessorScrapedResponseRow
