import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { Category } from '@app/src/types/categories'
import { Chip } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { AnswersGroupingType } from '../../../Filters/StatisticsConstants'

const GroupByChip = () => {
  const { formatMessage } = useIntl()
  const { groupBy, groupByValue } = useGroupBySelector()

  const { data: categories } = useFetchResource<Category[]>({
    key: FetchKey.Category,
    endpoint: endpoints.category,
    options: {
      enabled: groupBy === AnswersGroupingType.CustomCategory,
    },
  })

  const getChipLabel = () => {
    switch (groupBy) {
      case AnswersGroupingType.SpendClassification:
        return formatMessage({ id: `dataHub.groupBy.spend` })
      case AnswersGroupingType.Country:
        return formatMessage({ id: 'resourceCollections.general.country' })
      case AnswersGroupingType.CustomCategory:
        return categories?.find(c => c.id === Number(groupByValue))?.name
      case AnswersGroupingType.AnswerClassification:
        return formatMessage({ id: 'schemas.filter.answerClassification' })
      case AnswersGroupingType.CountryRisk:
        return formatMessage({ id: 'general.countryRisk' })
      case AnswersGroupingType.StandardCategory:
        return formatMessage({ id: `schemas.provider.${groupByValue}` })
      case AnswersGroupingType.ActorType:
        return formatMessage({ id: `schemas.product.productIndustry.${groupByValue}` })
      default:
        return formatMessage({ id: `reporting.period` })
    }
  }

  return (
    <Chip
      label={`${formatMessage({ id: `dataHub.groupBy.name` })}: ${getChipLabel()}`}
      size="small"
      sx={theme => ({
        margin: theme.spacing(0.5),
        height: 'auto',
        whiteSpace: 'wrap',
        '& .MuiChip-label': {
          whiteSpace: 'wrap',
          wordBreak: 'break-word',
        },
      })}
    />
  )
}

export default GroupByChip
