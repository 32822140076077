import { useDisplayValueWithUnit } from '@app/src/hooks/useDisplayValueWithUnit'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { palette } from '@app/src/theme/palette'
import { Unit } from '@app/src/types/resourceExplorer'
import {
  getMinMaxOnYAxisNumber,
  getUnitDisplayText,
  numberToString,
  numberToStringWithUnit,
} from '@app/src/utils/statisticsUtils'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import { max } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { StatisticsForQuestionPerGroup } from '../../../../DataHubScene'
import { SelectedMetric } from '../../NumberMetricSelector'

interface NumberMultiPeriodChartConfigsParams {
  statistics: StatisticsForQuestionPerGroup
  selectedChartType: ChartType
  selectedMetric: SelectedMetric
  unit: Unit
}

const calculateValueAxisNameOffsetToPreventOverlap = (
  data: {
    value: number | undefined
  }[],
  unit: Unit,
  optimalUnit: Unit | undefined,
  isHorizontalChart: boolean,
) => {
  if (isHorizontalChart) return 25
  const axisLabelLengths = data.map(v => numberToString(v.value ?? 0, unit, optimalUnit).length)
  const maxAxisLabelLength = max(axisLabelLengths) ?? 0
  return 35 + Math.max(maxAxisLabelLength - 3, 0) * 7
}

const COLORS = [palette.visualization['5']]

export const useNumberMultiPeriodChartConfigs = ({
  statistics,
  selectedChartType,
  selectedMetric,
  unit,
}: NumberMultiPeriodChartConfigsParams) => {
  const { formatMessage } = useIntl()
  const { typography } = useTheme()
  const { getOptimalUnitForMultipleValues } = useDisplayValueWithUnit()
  const { formatPeriodName } = usePeriodName()

  const isHorizontalBarChart = selectedChartType === ChartType.Horizontal

  const data = statistics.resultForGroups.map(r => ({
    name: formatPeriodName(r.groupName),
    value: selectedMetric === SelectedMetric.Average ? r.numberResult?.average : r.numberResult?.sum,
    groupName: r.groupName,
  }))

  const values = data.flatMap(d => (d.value !== undefined && d.value !== null ? [Number(d.value)] : []))
  const optimalUnit = getOptimalUnitForMultipleValues(values, unit)

  const nameGap = calculateValueAxisNameOffsetToPreventOverlap(data, unit, optimalUnit, isHorizontalBarChart)
  const existingOptions = statistics.resultForGroups.map(f => f.groupName)

  const questionTitle = statistics.title

  const { min, max } = getMinMaxOnYAxisNumber(values, selectedMetric, optimalUnit)

  const categoryAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'category',
      data: existingOptions,
      axisLabel: {
        interval: 0,
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
        formatter: periodName => formatPeriodName(periodName),
      },
    }),
    [existingOptions, typography.fontFamily, typography.body2.fontSize],
  )

  const valueAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'value',
      axisLabel: {
        formatter: value => numberToString(value, unit, optimalUnit),
      },
      min: min,
      max: max,
      name: getUnitDisplayText(optimalUnit, formatMessage({ id: 'statistics.dataInsights.count' })),
      nameLocation: 'middle',
      nameGap: nameGap,
      nameTextStyle: {
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [unit, optimalUnit, min, max, typography.fontFamily, typography.body2.fontSize, formatMessage, nameGap],
  )

  const horizontalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = valueAxis as XAXisOption
    const yAxis = { ...categoryAxis, show: false } as YAXisOption

    return {
      tooltip: {
        valueFormatter: value => numberToStringWithUnit(Number(value), unit, optimalUnit),
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        icon: 'circle',
        bottom: 10,
        left: 0,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      color: COLORS,
      grid: {
        containLabel: false,
        left: 15,
        right: 7,
        top: 30,
        bottom: 70,
      },
      xAxis,
      yAxis,
      series: [
        {
          name: getUnitDisplayText(optimalUnit, questionTitle),
          type: 'bar',
          barCategoryGap: '30%',
          label: {
            show: true,
            position: [0, -14],
            align: 'left',
            color: 'black',
            formatter: params => String(params.name),
            fontFamily: typography.fontFamily,
            fontSize: typography.body2.fontSize,
          },
          data: data,
        },
      ],
    }
  }, [
    valueAxis,
    categoryAxis,
    typography.fontFamily,
    typography.body2.fontSize,
    optimalUnit,
    questionTitle,
    data,
    unit,
  ])

  const verticalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = categoryAxis as XAXisOption
    const yAxis = valueAxis as YAXisOption

    return {
      tooltip: {
        valueFormatter: value => numberToStringWithUnit(Number(value), unit, optimalUnit),
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        icon: 'circle',
        bottom: 10,
        left: 0,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      color: COLORS,
      grid: {
        containLabel: true,
        left: 30,
        right: 7,
        top: 30,
        bottom: 50,
      },
      xAxis,
      yAxis,
      series: [
        {
          name: getUnitDisplayText(optimalUnit, questionTitle),
          type: 'bar',
          barCategoryGap: '30%',
          label: {
            show: true,
            position: 'top',
            align: 'center',
            color: 'black',
            formatter: params => numberToStringWithUnit(Number(params.value), unit, optimalUnit),
            fontFamily: typography.fontFamily,
            fontSize: typography.body2.fontSize,
          },
          data: data,
        },
      ],
    }
  }, [
    valueAxis,
    categoryAxis,
    typography.fontFamily,
    typography.body2.fontSize,
    optimalUnit,
    questionTitle,
    data,
    unit,
  ])

  return isHorizontalBarChart ? horizontalBarChartOptions : verticalBarChartOptions
}
