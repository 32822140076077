import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import useSort from '@app/src/hooks/sorting'
import BaseAssessmentFilters from '@app/src/pages/ResourceCollection/Filters/BaseAssessmentFilters'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection/index'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { SortOrder } from '@app/src/types/filter'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import AssessmentOverviewHeader from './AssessmentOverviewHeader'
import AssessmentOverviewRow, { AssessmentOverviewRowProps } from './AssessmentOverviewRow'

type AssessmentDetailsSceneProps = {
  allowedFilters: string[]
  userFilters: FilterGroup[]
} & Pick<ResourceCollectionSceneProps, 'tabs'>

const AssessmentDetailsScene: React.FC<AssessmentDetailsSceneProps> = ({ allowedFilters, userFilters, ...props }) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()

  const { items: tableFacets } = useFetchFacets({
    key: FetchKey.AssessmentFacets,
    endpoint: endpoints.assessmentFacet,
    sort: sorting,
    facetsParam: [
      {
        name: 'section1Level',
        isEnum: true,
      },
      {
        name: 'section2Level',
        isEnum: true,
      },
      {
        name: 'section3Level',
        isEnum: true,
      },
      {
        name: 'section4Level',
        isEnum: true,
      },
      {
        name: 'section5Level',
        isEnum: true,
      },
    ],
    filter: userFilters,
  })

  const rows: AssessmentOverviewRowProps['row'][] = tableFacets
    .map(facet => ({
      section: facet.propertyName.replace('Level', ''),
      section1Level: facet.items.find(l => l.value === 1)?.count ?? 0,
      section2Level: facet.items.find(l => l.value === 2)?.count ?? 0,
      section3Level: facet.items.find(l => l.value === 3)?.count ?? 0,
      section4Level: facet.items.find(l => l.value === 4)?.count ?? 0,
      section5Level: facet.items.find(l => l.value === 5)?.count ?? 0,
    }))
    .sort((a, b) => {
      if (sorting.target === 'id') return 0
      const target = sorting.target as keyof Omit<AssessmentOverviewRowProps['row'], 'section'>
      switch (sorting.order) {
        case SortOrder.ASCENDING:
          return a[target] - b[target]
        case SortOrder.DESCENDING:
          return b[target] - a[target]
        default:
          return 0
      }
    })

  return (
    <ResourceCollectionScene
      enableScroll
      title={formatMessage({ id: 'navbar.baseAssessment' })}
      filter={
        <>
          <BaseAssessmentFilters allowedFilters={allowedFilters} />
        </>
      }
      {...props}
    >
      <>
        <Box mr={4} mt={2}>
          <Typography variant="overline" color="textSecondary">
            {formatMessage({ id: 'baseAssessment.assessmentBySection' })}
          </Typography>
          <Table<AssessmentOverviewRowProps['row']>
            noPagination
            data={rows}
            count={rows.length}
            isLoading={false}
            isError={false}
            HeaderComponent={() => <AssessmentOverviewHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
            RowComponent={AssessmentOverviewRow}
            page={1}
            pageSize={rows.length}
          />
        </Box>
      </>
    </ResourceCollectionScene>
  )
}

export default AssessmentDetailsScene
