import { CloseOutlined } from '@mui/icons-material'
import { Box, Dialog, Divider, IconButton, Stack } from '@mui/material'
import React, { FC } from 'react'

interface ImageWithContentDialogProps {
  children: React.ReactNode
  imageUrl: string
  open: boolean
  onClose?: () => void
}

const ImageWithContentDialog: FC<ImageWithContentDialogProps> = ({ open, onClose, imageUrl, children }) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
    <Box alignSelf="flex-end" p={2}>
      <IconButton onClick={onClose}>
        <CloseOutlined />
      </IconButton>
    </Box>
    <Divider />
    <Stack direction="row">
      <Box
        sx={{
          width: '50%',
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Box
        p={4}
        sx={{
          width: '50%',
          display: 'flex',
        }}
      >
        {children}
      </Box>
    </Stack>
    <Divider />
    <Box alignSelf="flex-end" p={4} />
  </Dialog>
)

export default ImageWithContentDialog
