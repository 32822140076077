import { Request, RequestItem, ResponseItem } from '@app/src/types/resourceExplorer'

const isRequiredAndCannotAnswer = (totalRequestItems: RequestItem[], questionId: number, item: ResponseItem) => {
  const itemOnCurrentRequest = totalRequestItems.find(reqItem => reqItem?.questionId === questionId)
  return Boolean(itemOnCurrentRequest?.isRequired) && Boolean(item.cannotAnswer)
}

const responseShouldBeUsed = (
  questionId: number,
  newItem: ResponseItem,
  existingItem: ResponseItem | undefined,
  requestItems: RequestItem[],
) => {
  if (isRequiredAndCannotAnswer(requestItems, questionId, newItem)) return false
  if (newItem.isScraped && newItem.cannotAnswer) return false
  if (!existingItem) return true
  if (existingItem.isScraped && !newItem.isScraped) return true
  if (!existingItem.isScraped && newItem.isScraped) return false
  return new Date(newItem.response.submittedAt) > new Date(existingItem.response.submittedAt)
}

export const getSuggestedAnswerPerQuestion = (
  previousAnswerItems: ResponseItem[],
  request?: Request,
): ResponseItem[] => {
  const totalRequestItems = request?.sections?.flatMap(section => section?.items ?? []) ?? []

  const responseItemsMap = previousAnswerItems?.reduce(
    (acc, item) => {
      const questionId = item.requestItem.questionId
      const currentItem = acc[questionId]

      if (responseShouldBeUsed(questionId, item, currentItem, totalRequestItems)) {
        acc[questionId] = item
      }

      return acc
    },
    {} as { [key: number]: ResponseItem },
  )

  return Object.values(responseItemsMap)
}
