import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import CreationModalStep, { CreationModalStepProps } from '@app/src/components/CreationModal/CreationModalStep'
import InviteProviderEmailPreview from '@app/src/components/ReferralContact/InviteProviderEmailPreview'
import SendEmailCustomization from '@app/src/components/ReferralContact/SendEmailCustomization'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { useProvidersModal } from '@app/src/context/ProvidersModalContext'
import ReminderSuccessScreen from '@app/src/pages/ResourceCollection/Collections/ManageRequests/RequestsReminder/ReminderSuccessScreen'
import SendReminderReview from '@app/src/pages/ResourceCollection/Collections/ManageRequests/RequestsReminder/SendReminderReview'
import {
  ConnectProvidersFormData,
  useSendInviteEmail,
} from '@app/src/pages/ResourceCollection/Collections/Provider/ConnectProviders/hooks/inviteEmail'
import { SendOutlined } from '@mui/icons-material'
import { Dialog, Stack } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

type RemindProvidersModalProps = {
  open: boolean
  onClose: () => void
}

export type RemindProvidersFormData = Omit<ConnectProvidersFormData, 'providerIds'>

function RemindProvidersModal({ open, onClose }: RemindProvidersModalProps) {
  const { formatMessage } = useIntl()
  const { providersToConnect } = useProvidersModal()
  const { activeStep, setActiveStep, setTotalSteps } = useCreationModalProgress()
  const formMethods = useForm<RemindProvidersFormData>({
    shouldUnregister: false,
  })
  const { handleSubmit, reset } = formMethods
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  const { sendInvite, isSendingInvite } = useSendInviteEmail()

  const QUESTIONS: CreationModalStepProps[] = [
    {
      title: formatMessage({ id: 'form.requestsReminder.emailPreview' }),
      description: '',
      fieldnames: [],
      children: <SendEmailCustomization />,
      disableInitialBack: true,
      splitView: {
        enabled: true,
        component: <InviteProviderEmailPreview provider={providersToConnect[0]} />,
      },
      overrideButtonTextId: 'general.continue',
    },
    {
      title: '',
      description: '',
      fieldnames: [],
      children: (
        <SendReminderReview
          respondersCount={providersToConnect?.length ?? 0}
          descriptionText={formatMessage(
            { id: 'form.remindProviders.reviewDescription' },
            { respondersCount: providersToConnect?.length ?? 0 },
          )}
        />
      ),
      hideNextButton: true,
      disableInitialBack: true,
      overrideButtonTextId: 'general.send',
      nextButtonProps: {
        startIcon: <SendOutlined />,
      },
      isLoading: isSendingInvite,
    },
  ]

  const activeQuestion = useMemo(() => QUESTIONS[activeStep - 1], [activeStep, QUESTIONS])

  const onSubmit = async (values: ConnectProvidersFormData) => {
    await sendInvite(
      {
        providerIds: providersToConnect.map(p => p.id),
        customMessage: values.customMessage,
        contactPersonInfo: values.contactPersonInfo,
      },
      () => setShowSuccessScreen(true),
    )
  }

  useEffect(() => {
    if (open) return
    setActiveStep(1)
    reset()
    setShowSuccessScreen(false)
  }, [open])

  useEffect(() => {
    setTotalSteps(QUESTIONS.length)
  }, [QUESTIONS.length])

  return (
    <Dialog open={open} onClose={onClose} fullScreen closeAfterTransition>
      {showSuccessScreen ? (
        <ReminderSuccessScreen onClose={onClose} respondersCount={providersToConnect?.length ?? 0} />
      ) : (
        <CreationModalContainer title={formatMessage({ id: 'form.requestsReminder.sendReminder' })} onClose={onClose}>
          <FormProvider {...formMethods}>
            <Stack component="form" flexGrow={1} alignItems="center" onSubmit={handleSubmit(onSubmit)}>
              {activeQuestion && (
                <CreationModalStep {...activeQuestion} widthOverride={584}>
                  {activeQuestion.children}
                </CreationModalStep>
              )}
            </Stack>
          </FormProvider>
        </CreationModalContainer>
      )}
    </Dialog>
  )
}

export default RemindProvidersModal
