import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchResource } from '@app/src/api/fetchHooks'
import { Permissions, usePermissions } from '@app/src/auth/permissions'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { useQueryState } from '@app/src/hooks/queryState'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import {
  Inquiry,
  ReportAccessModel,
  Request,
  RequestHistory,
  ResponseItem,
  ResponseWithPreviousCorrectionNeededItem,
} from '@app/src/types/resourceExplorer'
import { distinctBy } from '@app/src/utils/helpersTs'
import { getSuggestedAnswerPerQuestion } from '@app/src/utils/suggestedAnswerFinder'
import { dashboard } from '@app/src/wf-constants/paths'
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import SubmitSuccessScreen from './Provider/Actions/SubmitSuccessScreen'
import ReportContext, { LanguageInfo, ReportContextProps } from './ReportContext'

type ReportContextProviderProps = {
  children: ReactNode
}

export enum ReuseAllQuestionsStates {
  ApplyAll = 'ApplyAll',
  Review = 'Review',
}

const ReportContextProvider: FC<ReportContextProviderProps> = ({ children }: ReportContextProviderProps) => {
  const history = useHistory()
  const [isSuccessSubmitDialogOpen, openSuccessSubmitDialog, closeSuccessSubmitDialog] = useDialogState()
  const [requestId, setRequestId] = useQueryState('requestId')
  const [responseId, setResponseId] = useQueryState('responseId')
  const [showAccessNudge, setShowAccessNudge] = useState(false)
  const [isLoadingAccessNudge, setLoadingAccessNudge] = useState(true)
  const [reuseAllQuestionsState, setReuseAllQuestionsState] = useState<ReuseAllQuestionsStates>(
    ReuseAllQuestionsStates.Review,
  )
  const [showPreviousAnswerSuggestions, setShowPreviousAnswerSuggestions] = useState(true)

  const [locale, setLocale] = useState<LanguageInfo | null>(null)
  const { hasPermission } = usePermissions()

  const isTransparency = hasPermission(Permissions.TRANSPARENCY_USER)

  const { data: request, isLoading: isRequestLoading } = useFetchResource<Request>({
    endpoint: endpoints.request(requestId, locale?.code),
    key: [FetchKey.Request, Number(requestId), locale?.code],
    options: {
      enabled: Boolean(requestId),
      onError: () => {
        history.replace(dashboard)
      },
    },
  })

  const getResponderFilter = (request?: Request) => {
    if (!request) return null
    const key = request.responderOrganizationId ? 'responderOrganizationId' : 'subscriptions.targetProviderId'
    const value = request.responderOrganizationId ?? request.subscriptions?.[0]?.targetProviderId

    return {
      name: key,
      filters: [{ value, operator: Operators.EqualTo }],
    }
  }

  const responderFilter = useMemo(() => getResponderFilter(request), [request])

  const { items: providerInquiries, isFetching: isFetchingProviderInquiries } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.InquiryOpenRequests,
    endpoint: endpoints.inquiriesCollection,
    payload: {
      filter: [
        {
          name: 'questionnaireTemplateId',
          filters: [
            {
              operator: Operators.EqualTo,
              value: request?.questionnaireTemplateId,
            },
          ],
        },
        {
          name: 'periodName',
          filters: [
            {
              operator: Operators.EqualTo,
              value: request?.periodName,
            },
          ],
        },
      ],
      include: ['creatorOrganization.image'],
    },
    options: { enabled: Boolean(request) && isTransparency },
  })

  const questionIds = request?.sections?.flatMap(section => section?.items.map(question => question?.questionId))

  const { items: aiRespondedQuestionsSameRequest, isLoading: aiRespondedQuestionsLoading } =
    useFetchCollectionWithPost<ResponseItem>({
      key: FetchKey.ResponseItemsCollection,
      endpoint: endpoints.responseItemsCollection,
      shouldShowErrorNotification: false,
      payload: {
        filter: [
          {
            name: 'response.targetRequestId',
            filters: [{ operator: Operators.EqualTo, value: request?.id }],
          },
          {
            name: 'response.isLatestSubmitted',
            filters: [{ operator: Operators.EqualTo, value: true }],
          },
          {
            name: 'response.isScraped',
            filters: [{ operator: Operators.EqualTo, value: true }],
          },
        ],
        include: ['response.request', 'scrapedAnswer.citations'],
      },
      options: {
        enabled: Boolean(request) && isTransparency,
      },
    })

  const { items: previouslyRespondedQuestions, isLoading: isPreviouslyRespondedQuestionsLoading } =
    useFetchCollectionWithPost<ResponseItem>({
      key: FetchKey.ResponseItemsCollection,
      endpoint: endpoints.responseItemsCollection,
      shouldShowErrorNotification: false,
      payload: {
        filter: [
          {
            name: 'requestItem.questionId',
            filters: [{ operator: Operators.In, value: questionIds }],
          },
          {
            name: 'response.targetRequestId',
            filters: [{ operator: Operators.NotEqualTo, value: request?.id }],
          },
          {
            name: 'response.isLatestSubmitted',
            filters: [{ operator: Operators.EqualTo, value: true }],
          },
        ],
        include: ['response.request', 'scrapedAnswer.citations'],
      },
      options: {
        enabled: Boolean(request) && isTransparency,
      },
    })

  const isLoadingPreviousAnswer = aiRespondedQuestionsLoading || isPreviouslyRespondedQuestionsLoading

  const previouslyRespondedItems = useMemo(
    () => getSuggestedAnswerPerQuestion([...previouslyRespondedQuestions, ...aiRespondedQuestionsSameRequest], request),
    [previouslyRespondedQuestions, aiRespondedQuestionsSameRequest, request],
  )

  const languageToTranslateResponseTo = isTransparency ? undefined : locale?.code
  const {
    data: response,
    isLoading: isResponseLoading,
    isFetching: isResponseFetching,
  } = useFetchResource<ResponseWithPreviousCorrectionNeededItem>({
    endpoint: endpoints.response(responseId, languageToTranslateResponseTo, isTransparency),
    key: [FetchKey.Response, Number(responseId), languageToTranslateResponseTo],
    options: {
      enabled: Boolean(responseId),
      onError: () => {
        setResponseId(undefined, true)
      },
    },
  })

  const { data: currentAccess, isLoading: isCurrentAccessLoading } = useFetchResource<ReportAccessModel>({
    key: [FetchKey.ResponseSharing, Number(requestId)],
    endpoint: endpoints.getAccessForReport(requestId),
    options: { enabled: Boolean(requestId) },
  })

  const { data: requestHistory, isLoading: isRequestHistoryLoading } = useFetchResource<RequestHistory[]>({
    key: [FetchKey.RequestHistory, Number(requestId)],
    endpoint: endpoints.requestHistory(requestId),
    options: {
      enabled: Boolean(requestId),
    },
  })

  const { items: requestsPerPeriod, isLoading: isRequestsPerPeriodLoading } = useFetchCollectionWithPost<Request>({
    key: FetchKey.RequestPerPeriod,
    endpoint: endpoints.requests,
    payload: {
      include: ['responses'],
      filter: [
        {
          name: 'questionnaireTemplateId',
          filters: [
            {
              value: request?.questionnaireTemplateId,
              operator: Operators.EqualTo,
            },
          ],
        },
        ...(responderFilter ? [responderFilter] : []),
      ],
      sort: {
        target: 'periodName',
        order: SortOrder.ASCENDING,
      },
    },
    options: {
      enabled: Boolean(request),
    },
  })

  const latestHistory = useMemo(() => requestHistory?.[0], [requestHistory])
  const reportStatus = useMemo(
    () => (responseId !== latestHistory?.responseId ? response?.draftStatus : latestHistory?.status),
    [responseId, latestHistory, response],
  )

  const updateRequestIdParam: ReportContextProps['updateRequestIdParam'] = (requestId?) => {
    setRequestId(requestId, true)
  }

  const updateResponseIdParam: ReportContextProps['updateResponseIdParam'] = (responseId?) => {
    setResponseId(responseId, true)
  }

  useEffect(() => {
    if (!responseId && latestHistory?.responseId) {
      setResponseId(latestHistory.responseId, true)
    }
  }, [responseId, latestHistory])

  const uniqueSubscriberOrganizations = request
    ? distinctBy(
        request.subscriptions?.map(subscription => subscription?.creatorOrganization),
        org => org?.id,
      )
    : []

  const senderOrganizationsNames = uniqueSubscriberOrganizations?.map(s => s.name)

  const formattedSenderNames =
    senderOrganizationsNames?.length > 1
      ? senderOrganizationsNames.slice(0, -1).join(', ') + ' and ' + senderOrganizationsNames.slice(-1)
      : senderOrganizationsNames?.[0] || ''

  return (
    <ReportContext.Provider
      value={{
        responseId: responseId ? Number(responseId) : undefined,
        requestId: requestId ? Number(requestId) : undefined,
        request,
        providerInquiries,
        response,
        previouslyRespondedQuestions: previouslyRespondedItems,
        requestsPerPeriod,
        currentAccess,
        requestHistory,
        latestHistory,
        reportStatus,
        locale,
        showAccessNudge,
        isLoadingAccessNudge,
        isRequestLoading,
        isFetchingProviderInquiries,
        isResponseLoading,
        isResponseFetching,
        isCurrentAccessLoading,
        isRequestHistoryLoading,
        isRequestsPerPeriodLoading,
        isPreviouslyRespondedQuestionsLoading: isLoadingPreviousAnswer,
        reuseAllQuestionsState,
        updateRequestIdParam,
        updateResponseIdParam,
        setReportLocale: setLocale,
        setShowAccessNudge,
        setLoadingAccessNudge,
        setReuseAllQuestionsState,
        showPreviousAnswerSuggestions,
        setShowPreviousAnswerSuggestions,
        openSuccessSubmitDialog,
      }}
    >
      {children}
      <SubmitSuccessScreen
        onClose={closeSuccessSubmitDialog}
        open={isSuccessSubmitDialogOpen}
        senderName={formattedSenderNames}
      />
    </ReportContext.Provider>
  )
}

export default ReportContextProvider
