import { CloseOutlined, ContentPasteSearchOutlined } from '@mui/icons-material'
import { Button, DialogContent, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type ScheduleScreeningSuccessViewProps = {
  providerIds: number[]
  onClose: () => void
}

const ScheduleScreeningSuccessView: React.FC<ScheduleScreeningSuccessViewProps> = ({ providerIds = [], onClose }) => {
  const { formatMessage } = useIntl()

  return (
    <DialogContent>
      <Stack height="100%" alignItems="center">
        <Stack flexGrow={1} alignItems="center" justifyContent="center" width={588}>
          <ContentPasteSearchOutlined sx={{ fontSize: 64 }} />

          <Typography variant="subtitle1" mt={3}>
            {formatMessage({ id: 'form.scheduleProviderScreening.success.screeningInProgress' })}
          </Typography>

          <Typography variant="caption" color="textSecondary" mt={2}>
            {formatMessage({ id: 'form.scheduleProviderScreening.success.screeningInProgressDescription' })}
          </Typography>

          <Stack spacing={2} mt={4}>
            <Button onClick={onClose} startIcon={<CloseOutlined />}>
              {formatMessage({ id: 'general.close' })}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </DialogContent>
  )
}

export default ScheduleScreeningSuccessView
