import endpoints from '@app/src/api/endpoints'
import { FetchCollectionParameters, FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { Option } from '@app/src/components/Form/Select'
import SimpleSelect from '@app/src/components/Form/Select/SimpleSelect'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import RequestError from '@app/src/errors/RequestError'
import useApi from '@app/src/hooks/api'
import useErrorNotification from '@app/src/hooks/errorNotification'
import {
  FilterGroup,
  RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
  RESPONSE_LATEST_SUBMITTED_FILTER,
} from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { FileResponse } from '@app/src/types/api'
import { ExportColumn, ExportTemplate } from '@app/src/types/export'
import { RawExportPayload } from '@app/src/types/filter'
import { br } from '@app/src/utils/translationMarkup'
import { NotificationSeverity, ResourceTypes } from '@app/src/wf-constants'
import { GetApp } from '@mui/icons-material'
import { Alert, Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'
import DrawerView, { DrawerViewProps } from '../DrawerView'

const useStyles = makeStyles(({ palette, spacing }) => ({
  drawer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  drawerRoot: {
    height: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  filterCountBox: {
    marginBottom: spacing(3),
    padding: `${spacing(1)} 0`,
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
  },
  alert: {
    marginBottom: spacing(3),
    borderRadius: spacing(0.5),
  },
  templateBox: {
    backgroundColor: palette.background.paper,
    borderRadius: spacing(0.5),
    padding: spacing(4),
  },
  badgePosition: {
    marginLeft: spacing(2.8),
    marginTop: -4.5,
  },
  guidanceReportDescription: {
    marginBottom: spacing(2),
  },
}))

interface RowsType {
  raw: number
  filtered: number
}

export const emptyPayload: FetchCollectionParameters<never>['payload'] = {
  filter: [],
  include: [],
  pagination: {
    pageNumber: 1,
    itemsPerPage: 25,
  },
} //todo add default values in the hook?

type DrawerViewGuidanceReportProps = {
  resourceType: string
  rawExportPayload: RawExportPayload
  userFilter: FilterGroup[]
  count: number
  onlyLatest?: boolean
  exportColumns?: ExportColumn[]
} & Omit<DrawerViewProps, 'title'>

const DrawerViewGuidanceReport: React.FC<DrawerViewGuidanceReportProps> = ({
  resourceType,
  rawExportPayload,
  userFilter,
  exportColumns,
  count,
  onlyLatest,
  ...props
}) => {
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { formatMessage } = useIntl()
  const [rows, setRows] = useState<RowsType>({ raw: 0, filtered: 0 })
  const [isLoading, setLoading] = useState(false)
  const { post } = useApi()
  const classes = useStyles()
  const { closeDrawer } = useDrawer()

  const isUserFilterActive = Boolean(userFilter?.length)
  const filterNumber = userFilter?.length
  const [selectedTemplate, setSelectedTemplate] = useState<ExportTemplate | null>(null)
  const [isSelectOpen, setSelectOpen] = useState(false)

  const { data, isLoading: isLoadingExportTemplates } = useFetchCollectionWithPost<ExportTemplate>({
    key: FetchKey.ExportTemplate,
    endpoint: endpoints.exportTemplateCollection,
    payload: emptyPayload,
  })
  const templates = data?.items
  const hasOnlyOneTemplate = templates?.length === 1

  useEffect(() => {
    //auto select template if there's only one
    if (hasOnlyOneTemplate && templates?.[0].id !== selectedTemplate?.id) {
      setSelectedTemplate(templates?.[0] ?? null)
    }
  }, [templates])

  useEffect(() => {
    isUserFilterActive ? setRows({ ...rows, filtered: count }) : setRows({ ...rows, raw: count })
    // eslint-disable-next-line
  }, [isUserFilterActive, count])

  const exportColumnsResolved = useMemo(
    () =>
      exportColumns?.map(column => ({
        displayName: column.displayName ?? formatMessage({ id: column.translationId }),
        path: column.exportPath,
      })),
    [exportColumns],
  )

  const getOnlyLatestFilter = (): FilterGroup[] => {
    if (!onlyLatest) return []

    if (resourceType === ResourceTypes.Response) return [RESPONSE_LATEST_SUBMITTED_FILTER]
    if ([ResourceTypes.ResponseItem, ResourceTypes.Answer].includes(resourceType))
      return [RESPONSE_ITEM_LATEST_SUBMITTED_FILTER]

    return []
  }

  const handleSubmit = async (): Promise<void> => {
    if (!selectedTemplate) {
      showSnackbar({
        message: formatMessage({ id: 'notifications.exportTemplate.warningSelectFirst' }),
        severity: NotificationSeverity.warning,
      })
      return
    }

    const exportObject = {
      ...rawExportPayload,
      exportTemplateId: selectedTemplate?.id,
      filter: [...(rawExportPayload?.filter ? rawExportPayload.filter : []), ...userFilter, ...getOnlyLatestFilter()],
      export: exportColumnsResolved,
    }

    setLoading(true)

    try {
      const res = await post<FileResponse, { [key: string]: unknown }>({
        url:
          resourceType === 'responseItem'
            ? endpoints.queueResponseItemsExport
            : endpoints.queueDataExport(resourceType),
        body: exportObject,
      })
      if (res) {
        showSnackbar({
          message: formatMessage({ id: 'notifications.successfulExportQueueRequest' }, { br }),
          severity: NotificationSeverity.success,
        })
      } else {
        throw new Error(formatMessage({ id: 'notifications.error' }))
      }
    } catch (e) {
      const typedError = e as { requestError: RequestError }
      showErrorNotification({ requestError: typedError.requestError })
    } finally {
      setLoading(false)
      closeDrawer()
    }
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'guidanceReport.title' })}
      buttons={[
        {
          startIcon: <GetApp />,
          label: formatMessage({ id: 'guidanceReport.export' }),
          variant: 'contained',
          onClick: handleSubmit,
          loading: isLoading,
          disabled: !selectedTemplate,
        },
      ]}
      {...props}
    >
      <Box className={classes.drawer} px={2}>
        <Box className={classes.content}>
          <Box marginBottom={3}>
            {formatMessage(
              { id: 'guidanceReport.description' },
              {
                p: (chunks: ReactNode) => (
                  <Typography className={classes.guidanceReportDescription} gutterBottom>
                    {chunks}
                  </Typography>
                ),
              },
            )}
          </Box>
          {!onlyLatest && (
            <Alert className={classes.alert} severity="error">
              <Typography variant="body1">{formatMessage({ id: 'guidanceReport.unselectedLatestAnswers' })}</Typography>
            </Alert>
          )}
          <Box className={classes.filterCountBox}>
            <Typography>{formatMessage({ id: 'guidanceReport.filtersActive' }, { count: filterNumber })}</Typography>
          </Box>

          {!hasOnlyOneTemplate && (
            <Box marginBottom={3}>
              <SimpleSelect<ExportTemplate>
                size="small"
                onChange={value => setSelectedTemplate((value as Option<ExportTemplate>)?.value)}
                open={isSelectOpen}
                setOpen={setSelectOpen}
                loading={isLoadingExportTemplates}
                options={templates?.map(template => ({ value: template, label: template.name })) ?? []}
                fieldLabel={formatMessage({ id: 'guidanceReport.selectReport' })}
                value={selectedTemplate ? { value: selectedTemplate, label: selectedTemplate.name } : null}
              />
            </Box>
          )}

          {selectedTemplate && (
            <Box className={classes.templateBox}>
              <Typography component="h2" variant="h6" gutterBottom>
                {selectedTemplate?.name}
              </Typography>
              <Typography variant="body1">{selectedTemplate?.description}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </DrawerView>
  )
}

export default DrawerViewGuidanceReport
