import LinkButton from '@app/src/components/LinkButton'
import { ScrapedAnswerCitation } from '@app/src/types/resourceExplorer'
import { ContentPasteSearchOutlined, OpenInNew } from '@mui/icons-material'
import { Divider, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface ScraperAnswerCitationProps {
  citations: ScrapedAnswerCitation[]
}

const ScrapedAnswerCitations: React.FC<ScraperAnswerCitationProps> = ({ citations }) => {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  return (
    <Stack alignItems="baseline">
      {citations.map(citation => (
        <>
          <Divider sx={{ mt: 3, mb: 3, width: '100%', color: palette.communication.light }} />
          <Stack direction="row" spacing={1} mb={2}>
            <ContentPasteSearchOutlined sx={{ color: palette.text.primary }} />
            <Typography variant="subtitle1" color="textPrimary">
              {formatMessage({ id: 'reporting.citations.title' }, { citationType: citation.citationType })}
            </Typography>
          </Stack>
          <Typography color="textSecondary" mb={2}>
            {citation.text}
          </Typography>
          <LinkButton
            key={citation.url}
            variant="text"
            size="small"
            fullWidth={false}
            startIcon={<OpenInNew />}
            target="_blank"
            to={{ pathname: citation.url }}
          >
            {formatMessage({ id: 'reporting.citations.linkToSource' })}
          </LinkButton>
        </>
      ))}
    </Stack>
  )
}

export default ScrapedAnswerCitations
