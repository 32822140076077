import endpoints from '@app/src/api/endpoints'
import { SmartFacetSettings } from '@app/src/pages/ResourceCollection/Filters/SmartFilterFacetSelect'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ResponseDraftStatus } from '@app/src/types/resourceExplorer'

type SmartFilterSpecification = Omit<SmartFacetSettings, 'key'>
export const RESPONSE_ITEM_QUESTIONNAIRE_SMART_FILTER_SETTING: SmartFilterSpecification = {
  moreEfficientEndpoint: endpoints.requestsWithFacets,
  moreEfficientFacetParam: 'template.title',
  additionalFiltersForEfficient: [
    {
      name: 'responseDraftStatus',
      filters: [{ value: [ResponseDraftStatus.Submitted, ResponseDraftStatus.Scraped], operator: Operators.In }],
    },
  ],
  fallbackEndpoint: endpoints.responseItemsWithFacets,
  fallbackFacetParam: 'response.request.template.title',
  filterPrefix: 'response.request',
}

export const RESPONSE_ITEM_QUESTION_SMART_FILTER_SETTING: SmartFilterSpecification = {
  moreEfficientEndpoint: endpoints.requestsWithFacets,
  moreEfficientFacetParam: 'template.sections.questions.questionText',
  additionalFiltersForEfficient: [
    {
      name: 'responseDraftStatus',
      filters: [{ value: [ResponseDraftStatus.Submitted, ResponseDraftStatus.Scraped], operator: Operators.In }],
    },
  ],
  fallbackEndpoint: endpoints.responseItemsWithFacets,
  fallbackFacetParam: 'requestItem.template.questionText',
  filterPrefix: 'response.request',
}

export const RESPONSE_ITEM_PERIOD_SMART_FILTER_SETTING: SmartFilterSpecification = {
  moreEfficientEndpoint: endpoints.requestsWithFacets,
  moreEfficientFacetParam: 'periodName',
  additionalFiltersForEfficient: [
    {
      name: 'responseDraftStatus',
      filters: [{ value: [ResponseDraftStatus.Submitted, ResponseDraftStatus.Scraped], operator: Operators.In }],
    },
  ],
  fallbackEndpoint: endpoints.responseItemsWithFacets,
  fallbackFacetParam: 'response.request.periodName',
  filterPrefix: 'response.request',
  isEnum: true,
}

export const RESPONSE_ITEM_ORGANIZATION_SMART_FILTER_SETTING: SmartFilterSpecification = {
  moreEfficientEndpoint: endpoints.requestsWithFacets,
  moreEfficientFacetParam: 'subscriptions.target.name',
  additionalFiltersForEfficient: [
    {
      name: 'responseDraftStatus',
      filters: [{ value: [ResponseDraftStatus.Submitted, ResponseDraftStatus.Scraped], operator: Operators.In }],
    },
  ],
  fallbackEndpoint: endpoints.responseItemsWithFacets,
  fallbackFacetParam: 'response.request.subscriptions.target.name',
  filterPrefix: 'response.request',
}
